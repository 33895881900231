//app/local/blocks/1ci/0.1ci_police_textblock
.police-block {
  padding: $spacer 0 $spacer*1.5;

  &_header {
    margin-top: $spacer*1.5;
  }

  &_description {
    white-space: pre-wrap;
  }

  &_list {
    padding-left: $spacer;

    .additional__text {
      margin: $spacer/2 0;
    }
  }
}

// app/local/blocks/1ci/3.1ci_main_title
.header-w-description {

  &_header {
    text-align: center;
  }

  &_description {
    font-size: $display5-size;
    font-weight: $display5-weight;
    text-align: center;

    [href^="mailto:"] {
      color: currentColor;
      position: relative;
      @include hover-footer-links;
    }
  }
}

// app/local/blocks/1ci/2.1ci_three_cols_fix_title_and_text
.three-cols-block {
  padding: $spacer*2.5 0;

  &_item {
    position: relative;
    min-height: 272px;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: $spacer*1.5;
    background-color: $white;
    @include border-radius($border-radius);
    @include box-shadow-overwrite($box-shadow-sm);
    @include transition($transition-base);

    &:hover {
      box-shadow: $box-shadow;
    }

    &--fixedheight {
      min-height: 160px;
      background-color: $white;
    }
  }

  &_icon {
    width: 40px;
    height: 40px;
    margin-bottom: $spacer;
  }

  &_textblock {
    color: $dark-gray;
    margin-bottom: $spacer;
  }

  &_buttonblock {
    margin-top: auto;
    .btn {
      text-align: left;
      display: inline;
      line-height: 1;
    }
  }

  @include hover-btn-arrow($link-color, $link-hover-color);
}

//app/local/blocks/1ci/2.1ci_ready_started_title_and_text_w_link
.ready-started {
  &_title {
    color: $black;
  }

  &_item {
    display: flex;
    height: 100%;
    padding-bottom: $spacer*2;
    @include media-breakpoint-down("sm") {
      flex-direction: column;
    }
  }

  &_textblock {
    //display: flex;
    //flex-direction: column;
    &--text {
      color: $dark-gray;
    }
  }

  &_iconblock {
    min-width: 40px;
    width: 40px;
    height: 40px;
    margin-right: 16px;
    @include media-breakpoint-down("sm") {
      margin-bottom: $spacer;
    }
    @include media-breakpoint-up("md") {
      transform: translateY(-8px);
    }

    &--icon {
      width: 100%;
      height: 100%;
    }
  }

  &_buttonblock {
    margin-top: auto;
  }

  @include hover-btn-arrow($link-color, $link-hover-color);
}

// app/local/blocks/1ci/4.1ci_developers_block
.developers-block {
  width: 100%;
  background-color: $dark-blue;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  padding: $spacer * 4 0;
  background-size: cover;

  &--fixed {
    @include media-breakpoint-up("md") {
      background-attachment: fixed;
    }
  }

  &_title {
    text-align: center;
    color: $white;
  }

  &_description {
    text-align: center;
    font-size: $display5-size;
    font-weight: $display5-weight;
    color: $white;
    padding: 0 $spacer*2;
    line-height: 1.45;
  }

  &_buttonblock {
    text-align: center;
    margin: 0;
  }

  @include hover-btn-arrow($white, $white);

  &_button {
    color: $white;

    &:hover {
      color: $white;
    }
  }
}

// app/local/blocks/1ci/5.1ci_customer_stories_block
.customer-stories {
  width: 100%;
  background-color: $gray-300;
  padding-top: $spacer;

  &_title {
    text-align: center;
  }

  &_description {
    text-align: center;
    font-family: $font-family-base;
    font-size: $display5-size;
    font-weight: $display5-weight;
    padding-bottom: $spacer*2;
  }

  &_story {

    .customer-story {
      min-height: 144px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      padding: $spacer *1.75 $spacer;
      margin-bottom: $spacer;
      @include border-radius($border-radius);
      @include transition($transition-base);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;

      &:hover {
        opacity: .9;
      }

      &_title {
        color: $white;
        text-align: center;
        text-transform: uppercase;
        font-size: $font-size-xs;
      }

      &_description {
        color: $white;
        text-align: center;
      }

      &_buttonblock {
        color: $white;
        text-align: center;
        margin: 0;
      }

      @include hover-btn-arrow($white, $white);

      &_button {
        color: $white;
      }
    }
  }

  &_wo-title {
    .customer-stories_story .customer-story {
      border-radius: $border-radius/2;
      margin-bottom: $spacer * 2;
    }
  }
}

// app/local/blocks/1ci/6.1ci_testimonials_block/
.testimonial {
  margin: $spacer*3 0 $spacer*5 0;

  &_img {
    position: absolute;
    top: 0;
    right: 20px;
    left: 20px;
    width: 100px;
    margin: 0 auto;
  }

  &_image {
    width: 100%;
    height: auto;
    border-radius: 100%;
  }

  &_textblock {
    text-align: center;
    background: $gray-300;
    padding: $spacer*4 $spacer*2 0;
    margin-top: $spacer*3;
    min-height: 100%;
    box-shadow: none;
    @include transition($transition-base);
  }

  &_title {
    font-size: $h2-font-size;
  }

  &_subtitle {
    font-size: $h3-font-size;
    font-weight: $font-weight-base;
    margin-bottom: $spacer;
  }

  &_text {
    color: $dark-gray;
  }
}

.testimonial-w-quote {
  padding: $spacer*3 0;

  &_block {
    background: $gray-300;
    padding: $spacer*3.375 $spacer*2.5 0;
    min-height: 100%;
    @include transition($transition-base);

    &::before {
      content: "";
      position: absolute;
      top: -15px;
      right: 30px;
      width: 58px;
      height: 48px;
      background-image: url("/local/templates/1ci/i/icons/Quote.svg");
      background-repeat: no-repeat;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: -15px;
      left: 30px;
      width: 58px;
      height: 48px;
      background-image: url("/local/templates/1ci/i/icons/Quote.svg");
      background-repeat: no-repeat;
    }
  }

  &_small-block {
    padding-top: $spacer;

    &:first-child {
      padding-top: $spacer*3;
      @include media-breakpoint-up("lg") {
        padding-top: $spacer*2.5;
      }
    }
  }

  &_image {
    width: 72px;
    height: auto;
    border-radius: 100%;
  }

  &_title-block {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &_name-block {
    padding: $spacer;
    align-self: center;
  }

  &_title,
  &_subtitle {
    font-family: $headings-font-family;
    font-size: $font-size-xs;
    font-weight: $font-weight-base;
    font-style: italic;
    line-height: 1.33;
    letter-spacing: .7px;
    margin-bottom: 0;
  }

  &_text {
    font-size: $font-size-base*1.375;
    color: $dark-indigo;
    padding: $spacer*2 0 $spacer*4;
  }

  &_small-text {
    font-size: $font-size-base*1.1875;
    color: $dark-gray;
    padding: $spacer 0;
  }
}

// app/local/blocks/1ci/5.1ci_partners_logos_block
.partners-logos {
  padding: $spacer 0 $spacer*2.5;

  &_item {
    text-align: center;
    @include transition($transition-base);

    &-erp {
      margin-bottom: $spacer*1.5;
    }
  }

  &_img {
    height: 94px;
    padding: 0 1px;
    display: block;
    margin: 0 auto;

    &-erp {
      height: 104px;
      @include media-breakpoint-down("xs") {
        width: 124px;
        height: 94px;
      }
    }
  }
}

// app/local/blocks/1ci/6.1ci_become_partner_block
.become-partner {
  padding: $spacer*3.5 0 $spacer*2.5;
  background-color: $gray-300;

  &_title {
    text-align: center;
  }

  &_text {
    padding-bottom: $spacer;
  }

  &_subtitle {
    text-align: center;
    font-family: $font-family-base;
    font-size: $display5-size;
    font-weight: $display5-weight;
    margin-bottom: $spacer*1.5;
  }

  &_btnblock {
    padding-top: $spacer;
    text-align: center;
  }

  &_item {
    margin-bottom: 0;
  }

  &_dot {
    margin-right: $spacer/2;
    margin-bottom: $spacer*1.5;
    float: left;
  }
}

// app/local/blocks/1ci/7.1ci_solutions_3_cols_block
.solution-3-cols-block {
  padding: $spacer 0 $spacer*1.5;

  &_item {
    text-align: center;
  }

  &_img {
    width: 112px;
    height: 112px;
  }

  &_title {
    margin-top: $spacer;
    margin-bottom: $spacer*2;
  }

  &_maintitle {
    margin-top: $spacer;
    margin-bottom: $spacer/2;
  }

  &_subtitle {
    margin-bottom: $spacer*2;
  }

}

// app/local/blocks/1ci/7.1ci_solutions_3_cols_block-1c-drive
.solution-3-cols-block-3-1c-drive {

  &_item {
    text-align: left;
  }

  &_img {
    width: 56px;
    height: 56px;
  }

  &_maintitle {
    margin-top: $spacer;
    margin-bottom: $spacer/2;
  }

  &_subtitle {
    margin-bottom: $spacer*2;
    color: $dark-gray;
  }
}

//app/local/blocks/1ci/7.1ci_solutions_4_cols_block_career
.solution-4-cols-block-career {
  .header-w-description_columns {
    margin-bottom: $spacer*1.5;
  }
  &_image {
    width: 112px;
    height: 112px;
    margin-bottom: $spacer;
  }

  &_subtitle {
    margin-bottom: $spacer*2;
    color: $dark-gray;
  }
}

// app/local/blocks/1ci/7.1ci_solutions_6_cols_block
.solution-6-cols-block {
  padding: $spacer*3.5 0 $spacer*1.5;
  background-color: $gray-300;

  &_title {
    text-align: center;
    margin-bottom: $spacer*3;
  }

  &_img {
    width: 48px;
    height: 48px;
    margin-bottom: $spacer;
  }

  &_text {
    margin-bottom: $spacer*2;
  }
}

// app/local/blocks/1ci/7.1ci_solutions_4_cols_block
.solution-4-cols-block {
  padding: $spacer*2.5 0 $spacer;
  background-color: $gray-300;

  &_item {
    display: flex;
  }

  &_img {
    width: 32px;
    height: 32px;
    margin-bottom: $spacer*1.5;
    margin-right: $spacer/2;
  }

  &_text {
    margin-bottom: $spacer*1.5;
  }
}

// app/local/blocks/1ci/7.1ci_solutions_3_cols_partner
.solution-3-cols-partner {
  padding: $spacer*1.5 0;
  background-color: $gray-300;

  &_row {
    display: flex;
    justify-content: center;
  }

  &_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 97px;
    @include media-breakpoint-up("sm") {
      flex-direction: row;
      width: auto;
      margin: 0 35px;
    }
    @include media-breakpoint-up("lg") {
      margin: 0 45px;
    }
  }

  &_img {
    width: 24px;
    height: 24px;
    margin-bottom: $spacer/2;
    @include media-breakpoint-up("sm") {
      margin-bottom: 0;
      margin-right: $spacer/2;
    }
  }

  &_text {
    margin-bottom: 0;
    font-size: $font-size-m;
  }
}

// app/local/blocks/1ci/7.1ci_solutions_partner_open
.solutions-open {

  &_card {
    margin-bottom: $spacer*2.5;
  }

  &_img {
    height: 48px;
    width: 48px;
    margin-bottom: $spacer;
  }

  &_item {
    font-family: $font-family-sans-serif;
    font-size: $font-size-m;
    padding-left: 0;

    &--edit {
      display: flex;
      line-height: 1.41;
      color: $dark-gray;
      list-style: none;
      padding-top: $spacer/2;

      &::before {
        content: "";
        position: relative;
        top: 10px;
        min-width: 4px;
        height: 4px;
        border-radius: 100%;
        background-color: $dark-gray;
        margin-right: 20px;
      }
    }
  }

  &_buttonblock {
    margin-top: $spacer/2;

    & > .btn {
      margin-right: 12px;
      line-height: 1.41;

      &.more {
        @include plus;
        @include plus-hover;
      }

      &.less {
        @include minus;
      }
    }

  }
}

// app/local/blocks/1ci/7.1ci_solution_cards
.solution-cards {
  width: 100%;
  padding-bottom: $spacer;

  .text-wrapper {
    position: relative;
  }

  &_col {
    margin-bottom: $spacer*2;
  }

  &_card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 264px;
    height: 100%;
    padding: $spacer $spacer*1.5;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    @include border-radius($border-radius);
    @include transition($transition-base);

    &--bg {
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: -1;

      &-grey {
        background-color: $dark-gray;
      }

      &-blue {
        background-color: $blue;
      }
    }

    &--img {
      display: block;
      position: absolute;
      height: 100%;
      opacity: .25;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    &:hover {
      opacity: .8;
    }
  }

  &_category {
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    font-size: $font-size-xs;
    color: $white;
    text-transform: uppercase;
    text-align: left;
    margin-bottom: $spacer/2;
    letter-spacing: 1px;
    @include media-breakpoint-up("lg") {
      margin-bottom: 0;
      text-align: right;
    }
  }

  &_title {
    color: $white;
    margin: $spacer 0;
    z-index: 1;

    &--wcategory {
      margin: 0 0 $spacer;
    }
  }

  &_text {
    color: $white;
    z-index: 1;
  }

  &_btnblock {
    margin: auto 0 0;
    @include hover-btn-arrow($white, $white);
    z-index: 1;
  }

  &_btn {
    color: $white;

    &:hover {
      color: $white;
    }
  }
}

// app/local/blocks/1ci/7.1ci_customer_stories_cards
// app/local/blocks/1ci/11.1ci_customer_stories_cards
.customer-stories-cards {
  padding-bottom: $spacer;

  &_title {
    text-align: center;
    font-size: $display3-size;
    font-weight: $display3-weight;
  }

  &_subtitle {
    text-align: center;
    font-size: $display5-size;
    font-weight: $display5-weight;
    margin: 0 0 $spacer;
  }
}

// app/local/blocks/1ci/8.1ci_request_block
.request-block {
  background-color: $blue;
  width: 100%;
  margin: 0;
  padding: $spacer*2 $spacer;

  &_title {
    text-align: center;
    color: $white;
    margin-bottom: 0;
  }

  &_subtitle {
    text-align: center;
    color: $white;
  }

  &_btnblock {
    text-align: center;
    margin-bottom: 0;
  }

  //app/local/blocks/1ci/8.1ci_request_block_column horizontal request block
  &_horizontal__text {
    padding-bottom: 0;
  }

  &_horizontal__text h2,
  &_horizontal__text p {
    @include media-breakpoint-up("sm") {
      text-align: left;
      margin-bottom: 0;
    }
  }

  &_horizontal__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-up("sm") {
      justify-content: flex-end;
    }
  }


}

// app/local/blocks/1ci/8.1ci_developers_adv_block
.developers-adv-block {
  background-color: $gray-300;
  padding: $spacer*3 0 $spacer;

  &_img {
    width: 56px;
    height: 57px;
    padding: 0;
  }

  &_desc {
    padding: $spacer/2 $spacer $spacer*2 0;
    margin-bottom: 0;
    font-size: $display24-size;
    font-weight: $display5-weight;
  }

  &_itemtitle {
    font-size: $display42-size;
    font-weight: $font-weight-light;
    line-height: $display42-size;
    margin: 0;
  }

  &_itemdesc {
    color: $dark-gray;
    margin-bottom: $spacer*1.5;
    @include media-breakpoint-up("sm") {
      margin-bottom: $spacer*2;
    }
  }
}

// app/local/blocks/1ci/8.1ci_developers_6_cols_block
.developers-6-cols-block {
  background-color: transparent;
  padding: $spacer*1.5 0 $spacer*2;

  &_title {
    text-align: center;
    font-size: $display3-size;
    font-weight: $display3-weight;
    margin: $spacer*2 0;
  }

  &_img {
    width: 48px;
    height: 48px;
    margin-bottom: $spacer;
  }

  &_text {
    margin-bottom: $spacer*2;
  }

  &_link {
    justify-content: flex-start;
    display: flex;
    align-items: flex-end;
    flex-direction: row;

    .main_link {

      .button-icon,
      .arrow {
        transition: $transition-base;
      }

      &:hover .button-icon {
        margin-left: 3px;
      }

      &:hover .button-icon .arrow {
        fill: $link-hover-color;
      }
    }
  }

  &_buttonlink {
    position: relative;
    width: $spacer*14;
    @include media-breakpoint-only("md") {
      width: auto;
      text-align: center;
    }
    @include media-breakpoint-up("lg") {
      margin-top: $spacer*3.75;
    }
    @include media-breakpoint-up("xl") {
      margin-top: $spacer*3.875;
    }
    @include hover-btn-arrow($link-color, $link-hover-color);

    &--btn {
      text-align: left;
      line-height: 1;
      display: inline;
      margin-right: $spacer*.125;
      @include media-breakpoint-up("md") {
        text-align: center;
      }
      @include media-breakpoint-up("lg") {
        text-align: left;
      }
    }
  }
}

// app/local/blocks/1ci/8.1ci_title_w_image_block
.block-title-w-image {
  background-color: $gray-300;
  padding: $spacer*2.5 0 $spacer*3;

  &_img {
    padding-top: $spacer*1.5;
    width: 100%;
    max-width: 710px;
    height: auto;
  }
}

// app/local/blocks/1ci/8.1ci_join_block
.join-block {
  position: relative;
  width: 100%;
  background: $dark-blue url("/local/templates/1ci/i/developers/developers_bg.jpg") no-repeat center;
  padding: $spacer * 4 0;
  margin: 0 0 $spacer;
  background-size: cover;

  &::before {
    position: absolute;
    content: " ";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 0;
    background-color: rgba(23, 43, 77, .5);
  }

  &_title {
    text-align: center;
    font-size: $display4-size;
    font-weight: $display4-weight;
    color: $white;
  }

  &_description {
    text-align: center;
    font-size: $display5-size;
    font-weight: $display5-weight;
    color: $white;
    padding: 0 $spacer*2;
  }

  &_buttonblock {
    text-align: center;
    margin: 0;

    a {
      margin: $spacer;
    }
  }
}

// app/local/blocks/1ci/3.1ci_main_link
.main-link {
  &_row {
    justify-content: center;
  }

  &_block {
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: row;

    @include hover-btn-arrow($link-color, $link-hover-color);
  }
}

//app/local/blocks/1ci/9.1ci_timeline
.timeline {
  padding: $spacer*2 0 $spacer/2;
  background-color: $blue;

  &_item {
    height: 98px;
    display: flex;
    @include media-breakpoint-up("lg") {
      flex-direction: column;
      height: auto;
      margin-bottom: $spacer*3;
    }

    &:not(:last-child) .timeline_item--number::before {
      content: "";
      position: absolute;
      top: 100%;
      left: 49.9%;
      width: 1px;
      height: 70px;
      background-color: $white;
      @include media-breakpoint-up("lg") {
        left: 100%;
        top: 50%;
        height: 1px;
        width: 164px;
      }
      @include media-breakpoint-up("xl") {
        width: 200px;
      }
    }

    @include media-breakpoint-only("md") {
      //only odd columns
      &:nth-child(2n+1) {
        .timeline_item--number {
          left: -15px;
        }

        .timeline_item--text {
          padding-left: $spacer/2;
        }
      }
      //even columns
      &:nth-child(2n) {
        flex-direction: row-reverse;

        .timeline_item--number {
          right: -15px;
        }

        .timeline_item--text {
          padding-right: $spacer/2;
          text-align: right;
        }
      }
    }

    &--number,
    &--text {
      font-family: $font-family-sans-serif;
      font-size: $font-size-m;
      color: $white;
      line-height: 1.41;
    }

    &--number {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      width: 30px;
      min-height: 30px;
      min-width: 30px;
      border-radius: 100%;
      border: 1px solid $white;
      @include media-breakpoint-up("lg") {
        position: relative;
      }
    }

    &--text {
      padding-left: $spacer*2.5;
      margin: 0;
      @include media-breakpoint-up("lg") {
        padding-left: 0;
        padding-top: $spacer;
      }
    }
  }
}

//app/local/blocks/1ci/13.1ci_promo_banner
.promo__banner {
  position: relative;

  @include media-breakpoint-down("sm") {
    background-color: $gray-300;
  }

  &-bg {
    background-color: $gray-300;

    @include media-breakpoint-up("md") {
      padding-left: $spacer*2;
      padding-right: $spacer*2;
    }
    @include media-breakpoint-up("lg") {
      padding-left: $spacer*4;
      padding-right: $spacer*4;
    }

    & > .row {
      padding-top: $spacer*2.5;
      padding-bottom: $spacer*2.5;
    }
  }

  &-type {
    font-family: $headings-font-family;
    font-size: $font-size-xs;
    font-weight: $font-weight-semibold;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: $spacer*.05;
    margin-bottom: $spacer;

    &_bulb {
      position: relative;

      &::before {
        content: "";
        position: relative;
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        box-shadow: 0 0 8px 0 $yellow;
        background-color: $yellow;
        margin-right: $spacer/2;
        margin-top: 3px;
      }
    }

    @include media-breakpoint-only("md") {
      width: 100%;
    }
    @include media-breakpoint-only("lg") {
      width: 100%;
    }

    @include media-breakpoint-up("xl") {
      margin-right: $spacer*2;
      margin-bottom: 0;
    }
  }

  &-image__wrapper {
    height: 100%;

    @include media-breakpoint-down("sm") {
      margin-bottom: 100px;
    }
    @media screen and (min-width: 400px) and (max-width: 520px) {
      margin-bottom: 82px;
    }
    @media screen and (min-width: 320px) and (max-width: 400px) {
      margin-bottom: 64px;
    }
    @media screen and (max-width: 320px) {
      margin-bottom: 56px;
    }

    &_image {
      height: 100%;
      width: 100%;
      position: relative;
      z-index: 1;
      object-fit: cover;

      &--shadow {
        display: block;
        position: relative;
        width: 78%;
        height: 90%;

        @include media-breakpoint-up("md") {
          width: 180px;
          height: 176px;
          margin-bottom: $spacer*2;
        }
        @include media-breakpoint-up("lg") {
          width: 230px;
        }
        @include media-breakpoint-up("xl") {
          width: 290px;
        }

        &::after {
          position: absolute;
          content: "";
          width: 100%;
          height: 100%;
          bottom: -18%;
          right: -28%;
          background-color: $dark-indigo;
          border-radius: $border-radius/2;
          @include media-breakpoint-up("md") {
            bottom: -$spacer*2;
            right: -$spacer*3.5;
          }
        }
      }
    }
  }

  &-additional {
    margin-bottom: $spacer*1.5;

    @include media-breakpoint-up("md") {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: $spacer*1;
    }

    &_date,
    &_time,
    &_place,
    &_lang {
      display: flex;
      align-items: center;
      margin-bottom: $spacer/2;

      @include media-breakpoint-up("md") {
        margin-right: $spacer*2;
      }

      &--icon {
        max-width: 16px;
        max-height: 16px;
        margin-right: 8px;
      }

      &--text {
        margin: 0;
        font-size: $font-size-sm;
        line-height: 16px;
      }
    }
  }
}

// app/local/blocks/1ci/5.1ci_customer_stories_block
.newsroom__media {
  &-block {
    padding: $spacer*6 $spacer;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include media-breakpoint-up("md") {
      padding-left: $spacer*3.75;
      padding-right: $spacer*3.75;
    }
    @include media-breakpoint-up("lg") {
      padding-left: $spacer*11.875;
      padding-right: $spacer*11.875;
    }

    &_title {
      color: $white;
    }

    &_description {
      color: $white;
      margin-bottom: $spacer*1.5;
      text-align: center;
      font-size: $display5-size;
      line-height: 32px;
    }
  }
  &-btn {
    margin: 0 auto $spacer*2;
  }
}

// app/local/blocks/1ci/15.1ci_newsroom_cards
.newsroom__cards {
  &-card {
    padding: $spacer*2.5 $spacer;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $white;
    border-radius: $border-radius/2;
    @include media-breakpoint-up("md") {
      padding-left: $spacer*2.5;
      padding-right: $spacer*2.5;
    }

    &:first-child {
      margin-bottom: $spacer*2;
    }
  }

  &-img {
    width: 112px;
    height: 112px;
    margin-bottom: $spacer*1.5;
  }

  &-description {
    text-align: center;
  }

  &-buttonblock {
    @include hover-btn-arrow($link-color, $link-hover-color);
  }
}

// app/local/blocks/1ci/15.1ci_newsroom_contact
.newsroom__contact {
  &-wrapper {
    padding-top: $spacer*3;
    padding-bottom: $spacer*3;
    @include media-breakpoint-up("md") {
      display: flex;
      justify-content: center;
    }
  }

  &-img {
    width: 112px;
    height: 112px;
    border-radius: 100%;
    @include media-breakpoint-down("sm") {
      margin-bottom: $spacer;
    }
    @include media-breakpoint-up("md") {
      margin-right: $spacer*2;
    }
  }

  &-author,
  &-position {
    margin: 0;
    line-height: 24px;
  }
}

// app/local/blocks/1ci/15.1ci_newsroom_subscribe
.newsroom__subscribe {
  overflow: hidden;

  &-title,
  &-description {
    color: $white;
  }

  &-description {
    margin-bottom: $spacer/2;
  }

  &-wrapper {
    @include media-breakpoint-up("lg") {
      display: flex;
    }
  }

  &-action {
    position: relative;
    padding-top: $spacer*4;
    padding-bottom: $spacer*4;
    @include media-breakpoint-up("lg") {
      padding-right: $spacer*4.5;
      width: 50%;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 200%;
      height: 100%;
      background-color: $blue;
      z-index: -1;
      @include media-breakpoint-down("md") {
        right: -26%;
      }
    }
  }

  &-social {
    position: relative;
    padding-top: $spacer*4;
    padding-bottom: $spacer*4;
    @include media-breakpoint-up("lg") {
      padding-left: $spacer*3.5;
      width: 50%;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 200%;
      height: 100%;
      background-image: $form-gradient;
      z-index: -1;
      @include media-breakpoint-down("md") {
        left: -26%;
      }
    }
  }

  &-form {
    &.forms--blue {
      padding: 0;
    }

    .grid__form {
      margin-top: $spacer*1.5;
      @include media-breakpoint-down("sm") {
        display: flex;
        flex-direction: column;
      }
      @include media-breakpoint-up("md") {
        display: grid;
      }

      .grid__input {
        grid-area: input;
        margin-bottom: $spacer*1.5;
        @include media-breakpoint-down("sm") {
          width: 100%;
        }
      }

      .grid__button {
        grid-area: button;
        @include media-breakpoint-down("sm") {
          margin-top: $spacer*2;
        }
      }

      .custom-control {
        min-height: auto;
        // stylelint-disable selector-max-compound-selectors
        label {
          color: $white;
          line-height: 16px;
          font-size: $font-size-sm;

          a {
            color: currentColor;
            text-decoration: underline;
          }
        }

        // stylelint-enable selector-max-compound-selectors
      }

      .grid__checkbox {
        &-0 {
          grid-area: checkbox-0;
        }

        &-1 {
          grid-area: checkbox-1;
        }
      }

      @include media-breakpoint-up("md") {
        grid-template: "input button" "checkbox-0 checkbox-0" "checkbox-1 checkbox-1";
        grid-template-columns: 350px auto;
        column-gap: 16px;
      }
      @include media-breakpoint-up("lg") {
        grid-template-columns: minmax(260px, 100%) auto;
      }
      @include media-breakpoint-up("xl") {
        grid-template-columns: minmax(350px, 100%) auto;
      }
    }
  }

  &-icons {
    display: flex;
    flex-wrap: wrap;
  }

  &-link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 100%;
    background-color: $white;
    margin-right: $spacer/2;
    margin-top: $spacer;
  }
}

// app/local/blocks/1ci/15.1ci_newsroom_textblock
.newsroom__textblock {
  &-card {
    margin-bottom: $spacer;
  }

  &-text {
    margin-bottom: 0;
  }

  &-title {
    margin-top: $spacer*3;
    margin-bottom: $spacer;
  }
}

// app/15.1ci_newsroom_textblock
.newsroom__about {
  &-bg {
    padding-left: $spacer;
    padding-right: $spacer;
    @include media-breakpoint-up("md") {
      padding-left: $spacer*3;
      padding-right: $spacer*3;
    }
  }

  &-title {
    margin-bottom: $spacer*1.5;
  }

  &-wrapper:not(:last-child) {
    @include media-breakpoint-down("md") {
      margin-bottom: $spacer*2;
    }
  }
}

// app/local/blocks/1ci/15.1ci_newsroom_partners
.newsroom__partners {
  &-card {
    padding: $spacer*1.5;
    border-radius: $border-radius/2;
    border: 1px solid $gray-600;
    height: 100%;
    display: flex;
    flex-direction: column;

    &_wrapper {
      margin-bottom: $spacer*2;
    }

    &_logo {
      height: 32px;
    }
  }

  &-activity {
    display: flex;
    align-items: center;

    &:first-child {
      margin-bottom: $spacer/2;
    }

    &_site,
    &_date {
      font-size: $font-size-sm;
      line-height: 16px;
      margin-bottom: 0;
      color: $dark-indigo;

      &--image {
        height: 16px;
        width: 16px;
        margin-right: $spacer/2;
      }
    }
  }

  &-textblock {
    margin: $spacer*1.5 0 $spacer;
    padding: $spacer*1.5 0;
    border-top: 1px solid $gray-600;
    border-bottom: 1px solid $gray-600;
    height: 100%;
    display: flex;
    flex-direction: column;

    &_title {
      font-family: $headings-font-family;
      font-size: $h6-font-size;
      line-height: 24px;
      margin: $spacer*1.5 0;
    }
  }

  &-buttonblock {
    margin-top: auto;
    @include hover-btn-arrow($link-color, $link-hover-color);
  }

  &-additional {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_wrapper {
      display: flex;
      align-items: center;
    }

    &_flag {
      height: 15px;
      width: 21px;
      margin-right: $spacer/2;
    }

    &_country {
      font-family: $headings-font-family;
      font-size: $font-size-xs;
      line-height: 16px;
      font-weight: $font-weight-semibold;
      letter-spacing: $spacer*.03;
      text-transform: uppercase;
      margin-bottom: 0;
    }

    &_type {
      font-family: $headings-font-family;
      font-size: $font-size-xs;
      line-height: 16px;
      font-weight: $font-weight-semibold;
      text-transform: uppercase;
      letter-spacing: $spacer*.03;
      color: $dark-gray;
      margin-bottom: 0;
    }
  }
}

// app/local/blocks/1ci/15.1ci_newsroom_download_logo
.newsroom__download__logo {
  &-card {
    margin-bottom: $spacer*2;
  }

  &-image {
    height: 72px;
  }

  &-wrapper {
    height: 232px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: $spacer*1.5;

    &.g-bg-white {
      border: 1px solid $gray-600;
    }

    &.g-bg-dark-indigo {
      border: 1px solid $dark-indigo;
    }
  }

  &-downloads {
    &_button {
      font-weight: $font-weight-bold;
      margin-left: $spacer/4;
      transition: $transition-base;

      &:hover + .button-icon {
        path {
          fill: $link-hover-color;
        }
      }
    }

    &_buttonblock {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;

      &:first-child {
        margin-bottom: $spacer/2;
      }

      .button-icon {
        width: 16px;
        height: 16px;

        path {
          transition: $transition-base;
        }
      }
    }
  }
}

// app/local/blocks/1ci/15.1ci_newsroom_warning
.newsroom__warning {
  &-wrapper {
    padding: $spacer*1.5 $spacer;
    @include media-breakpoint-up("md") {
      display: flex;
      padding: $spacer*1.5;
    }
  }

  &-icon {
    width: 16px;
    height: 16px;
    margin-bottom: $spacer/2;
    margin-right: $spacer/2;
  }

  &-text {
    font-size: $font-size-sm;
    line-height: 16px;
    margin-bottom: 0;
  }
}

// app/local/blocks/1ci/15.1ci_newsroom_download_speakers
.newsroom__download__speakers {
  &-card {
    margin-bottom: $spacer*2;
  }

  &-wrapper {
    margin-bottom: $spacer;
  }

  &-image {
    height: 184px;
    width: 100%;
    object-fit: cover;
  }

  &-speaker {
    &_position {
      margin-bottom: $spacer/2;
    }
  }

  &-buttonblock {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;

    .button-icon {
      width: 16px;
      height: 16px;

      path {
        transition: $transition-base;
      }
    }
  }

  &-button {
    margin-left: $spacer/4;
    font-weight: $font-weight-bold;
    transition: $transition-base;

    &:hover + .button-icon {
      path {
        fill: $link-hover-color;
      }
    }
  }
}

//app/local/blocks/1ci/16.1ci_career_image_text
.career__about {
  .header-w-description_header {
    margin-bottom: $spacer*4;
  }
  .text__image,
  .image__text {
    align-items: center;
    padding-bottom: $spacer*4;
    overflow: hidden;
  }

  .text__image {
    @include media-breakpoint-down("sm") {
      flex-direction: column-reverse;
    }

    .career__about-icon__bg {
      background-position: left;
      @include media-breakpoint-down("sm") {
        right: 20%;
      }
    }

    .image__wrapper {
      margin-left: auto;

      &::before {
        right: 25%;
        @include media-breakpoint-up("md") {
          right: $spacer*3.5;
        }
      }
    }
  }

  .image__text {
    .career__about-icon__bg {
      background-position: right;
      @include media-breakpoint-down("sm") {
        left: 20%;
      }
    }

    .image__wrapper {
      margin-right: auto;

      &::before {
        left: 25%;
        @include media-breakpoint-up("md") {
          left: $spacer*3.5;
        }
      }
    }
  }

  &-title {
    margin-bottom: $spacer;
    @include media-breakpoint-down("sm") {
      margin-top: $spacer*3.5;
    }
  }

  &-subtitle {
    margin-bottom: 0;
  }

  &-imageblock {
    position: relative;
  }

  .image__wrapper {
    position: relative;
    @include media-breakpoint-down("sm") {
      width: 75%;
      display: block;
    }
    @include media-breakpoint-up("md") {
      margin-bottom: $spacer*1.625;
      display: inline-block;
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: $spacer*2;
      background-color: $dark-indigo;
      border-radius: $border-radius/2;
    }
  }

  &-icon__bg {
    position: absolute;
    top: 0;
    height: calc(100% + 32px);
    background-repeat: no-repeat;
    background-size: cover;
    @include media-breakpoint-up("md") {
      right: 15px;
      left: 15px;
      width: calc(100% - 30px);
      background-size: auto 100%;
    }
    @include media-breakpoint-down("sm") {
      background-size: contain;
      width: 75%;
    }
  }

  &-image {
    position: relative;
    object-fit: cover;
    display: block;
    z-index: 1;
    width: 100%;
    @include media-breakpoint-up("md") {
      width: 250px;
      height: 288px;
    }
    @include media-breakpoint-up("lg") {
      width: 365px;
    }
    @include media-breakpoint-up("xl") {
      width: 445px;
    }
  }
}

//app/local/blocks/1ci/16.1ci_careers_tabs
.career__tabs {
  .nav-tabs {
    display: none;
    @include media-breakpoint-up("md") {
      display: flex;
    }
    position: relative;
    list-style: none;
    padding: 0;
    margin-bottom: $spacer*4;
    border-bottom: 2px solid $gray-500;
    @include media-breakpoint-down("md") {
      margin-top: $spacer*3;
    }
    .nav-link {
      padding: 0 0 $spacer*.875;
      color: $dark-gray;
      margin-right: $spacer*2;
      cursor: pointer;
      &:not(.active) {
        letter-spacing: $spacer*.015;
      }
      &:hover {
        border-color: transparent;
      }
      &.active {
        color: $dark-indigo;
        font-weight: 600;
        cursor: default;
        border-bottom: 6px solid $blue;
      }
    }
  }
  .nav-select {
    display: none;
    @include media-breakpoint-down("sm") {
      display: block;
      margin-bottom: $spacer*4;
      margin-top: $spacer*3.5;
    }
  }
  &-tab {
    &:first-child {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        width: 95px;
        height: 104px;
        bottom: 8px;
        left: 0;
        background-image: url("/local/templates/1ci/i/career/Mission-circle-1.svg");
      }

      &::after {
        content: "";
        position: absolute;
        width: 32px;
        height: 32px;
        bottom: 64px;
        right: 0;
        background-image: url("/local/templates/1ci/i/career/Mission-circle-2.svg");
        @include media-breakpoint-up("md") {
          bottom: auto;
          top: 0;
        }
      }
    }

    &_title,
    &_subtitle {
      text-align: center;
    }

    &_subtitle {
      margin-bottom: 0;
      padding-bottom: $spacer*8;
      font-size: $display5-size;
      line-height: 32px;
      @include media-breakpoint-up("md") {
        padding-bottom: $spacer*5;
      }
      @include media-breakpoint-up("lg") {
        padding-bottom: $spacer*2;
      }
    }

    &_vacancy {
      display: flex;
      flex-direction: column;
      padding: $spacer*1.5;
      border: 1px solid $gray-600;
      border-radius: $border-radius/2;
      height: 100%;

      &--wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
      }

      &--header {
        padding-bottom: $spacer*1.5;
        margin-bottom: $spacer*1.5;
        border-bottom: 1px solid $gray-600;
        @include media-breakpoint-up("md") {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      &--body {
        display: flex;
        flex-direction: column;
        flex-grow: 3;
      }

      &--title {
        letter-spacing: .05em;
        font-size: $font-size-xs;
        text-transform: uppercase;
        font-weight: 600;
        line-height: 16px;
        height: 16px;
        color: $dark-gray;
        margin-bottom: $spacer/2;
        @include media-breakpoint-up("md") {
          margin-bottom: 0;
        }
      }

      &--place {
        display: flex;
        align-items: center;

        .icon {
          height: 16px;
          width: 16px;
          margin-right: $spacer/4;
        }

        .place {
          font-size: $font-size-sm;
          margin-bottom: 0;
        }
      }

      &--btnblock {
        margin-top: auto;
        &.internal__buttonblock {
          margin-bottom: $spacer*3;

          .button-icon {
            transition: $transition-base;
            path {
              transition: $transition-base;
            }
          }

          &:hover {
            .button-icon {
              transform: translateX(-3px);
              path {
                fill: $link-hover-color;
              }
            }
          }
        }

        @include hover-btn-arrow($link-color, $link-hover-color);
      }
    }
  }

  .internal {
    display: none;
    width: 0;
    padding-bottom: $spacer*4;

    &__reverse {
      @include media-breakpoint-down("sm") {
        flex-direction: column-reverse;
      }
    }

    &-titleblock {
      margin-bottom: $spacer*4;

      &_title {
        margin-bottom: $spacer;
      }

      &_place {
        margin-bottom: $spacer/2;
        @include media-breakpoint-up("md") {
          display: flex;
          align-items: center;
          margin-bottom: 0;
          .career__tabs-tab_vacancy--title {
            margin-right: $spacer*2;
          }
        }
      }
    }

    &-listblock {
      &_title {
        margin-bottom: $spacer*1.5;
      }

      &_list {
        margin-bottom: $spacer*3.5;
        padding: 0;

        li {
          display: flex;
          margin-bottom: $spacer/2;
          list-style-type: none;

          &::before {
            content: "";
            background-image: url("/local/templates/1ci/i/icons/Common/020.svg");
            display: block;
            position: relative;
            height: 8px;
            width: 8px;
            min-width: 8px;
            margin-right: $spacer/2;
            margin-top: $spacer/2;
          }
        }
      }

      &__grey {
        padding: $spacer*4 $spacer*3;
        background-color: $gray-300;
        border-radius: $border-radius/2;

        @include media-breakpoint-down("sm") {
          padding: $spacer*4 $spacer;
        }

        &_title {
          margin-bottom: $spacer*2;
        }

        &_list {
          margin-bottom: 0;

          li {
            margin-bottom: $spacer;
            align-items: center;

            &::before {
              content: "";
              background-image: url("/local/templates/1ci/i/icons/features/Blue/023-26x26.svg");
              background-size: cover;
              height: 24px;
              width: 24px;
              min-width: 24px;
              margin-top: $spacer*.375;
              margin-right: $spacer;
            }
          }
        }
      }
    }

    &-styckyblock {
      @include media-breakpoint-down("sm") {
        margin-bottom: $spacer*11;
        margin-right: $spacer*5;
      }
      @include media-breakpoint-only("md") {
        margin-right: $spacer*3.75;
      }
      @include media-breakpoint-up("md") {
        position: sticky;
        top: $spacer*2;
      }

      &_wrapper {
        position: relative;
        padding: $spacer*3 $spacer*1.5;
        background-color: $gray-300;
        border-radius: $border-radius/2;
        @include media-breakpoint-up("lg") {
          padding: $spacer*3 $spacer*2.5;
        }

        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          top: $spacer*2;
          left: $spacer*3.5;
          background-color: $blue;
          border-radius: $border-radius/2;
          z-index: -2;
        }

        &::after {
          content: "";
          position: absolute;
          width: 264px;
          height: 192px;
          bottom: -$spacer*7;
          right: -$spacer*5;
          z-index: -1;
          background: url("/local/templates/1ci/i/career/Vacancies-circle.svg") center no-repeat;
          background-size: cover;
          @include media-breakpoint-up("lg") {
            right: -$spacer*6;
            bottom: -$spacer*7.5;
          }
        }
      }

      &_text {
        margin-bottom: 0;
        text-align: center;
      }
    }
  }
}

//app/local/blocks/1ci/16.1ci_career_contact
.career__contact {
  display: none;

  &-wrapper {
    @include media-breakpoint-up("md") {
      display: flex;
    }
  }

  &-img {
    height: 48px;
    width: 48px;
    margin-right: $spacer*1.5;
    @include media-breakpoint-down("sm") {
      margin-bottom: $spacer/2;
    }
    @include media-breakpoint-up("md") {
      margin-right: $spacer*1.5;
    }
  }

  &-text {
    margin-bottom: 0;
  }
}

.forms__social {
  .container {
    @include media-breakpoint-up("md") {
      display: flex;
      justify-content: center;
    }
  }

  &-border {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-top: 1px solid $white;
    @include media-breakpoint-up("md") {
      max-width: 570px;
    }
    @include media-breakpoint-up("lg") {
      max-width: 540px;
    }
  }

  .email__wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: $spacer 0;
  }

  &-text {
    margin-bottom: 0;
    color: $white;
    text-align: center;
  }

  &-link {
    display: flex;
    align-items: center;

    &_href {
      color: $white;
      position: relative;
      margin-left: $spacer/4;
      @include hover-footer-links;

      &:hover {
        color: $white;
      }
    }
  }

  &-icons {
    margin-top: $spacer/2;
  }

  &-icon {
    display: inline-flex;
    align-items: center;
    width: 30px;
    height: 30px;
  }
}

.image__block {
  &-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

//app/local/blocks/1ci/1.1ci_event_banner
.event-banner {
  display: inline-block;

  &::after {
    height: 136px;
  }

  &_subtitle {
    font-weight: $font-weight-semibold;
    font-size: $font-size-xs;
    line-height: 16px;
    font-family: $headings-font-family;
    letter-spacing: .05em;
    text-transform: uppercase;
    margin-top: 1.5rem;
    margin-bottom: $spacer/2;
    color: $white;

    @include media-breakpoint-up("sm") {
      margin-top: $spacer*2;
    }
    @include media-breakpoint-up("md") {
      margin-top: $spacer*3.25;
    }
  }

  &_title {
    margin-bottom: 1rem;
    color: $white;
  }

  &_locate {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: $spacer*1.5 0 $spacer*2.5;

    @include media-breakpoint-up("md") {
      flex-direction: row;
      align-items: center;
    }

    &--item {
      display: flex;
      align-items: center;
      margin-right: $spacer*2.125;
      margin-bottom: $spacer/2;

      .inline {
        margin-right: $spacer/2;
      }

      .text {
        font-size: $font-size-base;
        color: $white;
        line-height: 24px;
        margin-bottom: 0;
      }
    }
  }

  &_imageblock {
    display: flex;
    align-items: flex-end;
    @include media-breakpoint-down("xs") {
      padding: 0;
    }

    &--img {
      margin-top: 0;
      @include media-breakpoint-only("md") {
        width: 540px;
        height: 440px;
      }
    }
  }
}

//app/local/blocks/1ci/4.1ci_event_block/block.php
.event {

  //this tag includes a smaller version
  &--little {
    .event-card {
      border: none;
    }

    .event-buttons_action {
      display: none;
    }

    .event-background {
      height: auto;
      background-image: none;

      &_title {
        color: $dark-gray;
      }
    }
  }

  &-wrapper {
    margin-bottom: $spacer*2;
    display: flex;
    flex-direction: column;
  }

  &-background {
    display: flex;
    padding: $spacer*1.5 $spacer*1.5 0;
    height: 256px;
    background-image: url("/local/templates/1ci/i/events/event.png");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 2px 2px 0 0;
    background-color: $white;

    &_title {
      font-family: $headings-font-family;
      color: $white;
      font-size: $font-size-xs;
      font-weight: $font-weight-semibold;
      text-transform: uppercase;
      letter-spacing: .05em;
      margin-bottom: 0;
    }
  }

  &-card {
    border-radius: 0 0 2px 2px;
    border: 1px solid $gray-600;
    padding: $spacer*1.5;
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: $white;
  }

  &-locate {
    @include media-breakpoint-up("lg") {
      display: flex;
      flex-wrap: wrap;
      div:first-child {
        margin-right: $spacer*4;
      }
    }

    div:not(.event-locate_additional) {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
    }

    &_additional {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 0;

      p {
        margin-right: $spacer*1.5;
        line-height: 16px;
        font-size: $font-size-sm;
        margin-bottom: 0;
      }
    }

    .inline {
      margin-right: $spacer/2;

      path {
        fill: $dark-indigo;
      }
    }

    &_place,
    &_date {
      margin-bottom: 0;
      line-height: 16px;
      font-size: $font-size-sm;
    }
  }

  &-textblock {
    margin: $spacer*1.5 0;

    &_text {
      font-size: $font-size-base;
      color: $dark-gray;
      line-height: 1.5;
      margin-bottom: 0;
    }
  }

  &-buttons {
    margin-top: auto;

    .inline path {
      transition: $transition-base;
    }

    &_action {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: $spacer/2;
      line-height: 24px;

      @include media-breakpoint-up("md") {
        margin-bottom: 0;
      }

      .inline path {
        fill: $link-color;
      }

      &--text {
        color: $blue;
        font-size: $font-size-base;
        font-weight: $font-weight-bold;
        margin-left: $spacer/2;
        margin-bottom: 0;
        transition: $transition-base;

        &:hover + .inline path {
          fill: $link-hover-color;
        }
      }
    }

    &_buttonblock {
      @include hover-btn-arrow($link-color, $link-hover-color);

      .action_button {
        line-height: 24px;

        &:hover + .inline path {
          fill: $link-hover-color;
        }
      }
    }

    @include media-breakpoint-up("md") {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }
  }
}

//app/local/blocks/1ci/4.1ci_event_subscribe
.subscribe {
  &-block {
    padding: $spacer*2.5 $spacer;
    background-color: $gray-300;
    border-radius: $border-radius/2;

    @include media-breakpoint-up("md") {
      display: flex;
      align-items: center;
      padding: $spacer*2.5 $spacer*1.5;
    }
    @include media-breakpoint-up("lg") {
      padding: $spacer*2.5 $spacer*6;
    }

    &_text {
      margin-bottom: 0;
      text-align: center;
      font-size: $font-size-base;
      line-height: 24px;

      @include media-breakpoint-up("md") {
        min-width: max-content;
      }
    }

    &_input {
      padding: $spacer/2 $spacer;
      display: block;
      height: 40px;
      border-radius: $border-radius;
      width: 100%;
      margin: $spacer/2 auto $spacer*2;
      background: $white;
      border: 1px solid $gray-600;

      @include media-breakpoint-up("md") {
        margin: 0 $spacer;
      }

      &::placeholder {
        font-size: $font-size-base;
        line-height: 1.3;
      }
    }

    &_button {
      display: block;
      margin: 0 auto;
    }
  }
}

//app/local/blocks/1ci/4.1ci_event_textblock_w_form
.event__form {
  .video-w-bg-block_bg {
    margin-bottom: $spacer*3.5;
  }

  .register__button {
    padding: $spacer*2;
    background-color: $blue;
    box-shadow: 130px 0 0 0 $blue, -130px 0 0 0 $blue;
    margin-bottom: $spacer*4;
  }

  &-maintitle {
    margin-bottom: $spacer*2;
  }

  &-text {
    &_main {
      font-size: $font-size-base;
      line-height: 24px;
      margin-bottom: $spacer*2;
    }

    &__list {
      list-style: none;
      padding: 0;
      margin-bottom: $spacer*2;

      @include media-breakpoint-up("lg") {
        column-count: 2;
        break-inside: avoid;
        margin-bottom: $spacer*4;
      }

      &_item {
        font-size: $font-size-base;
        line-height: 24px;
        margin-bottom: $spacer;
        position: relative;
        padding-left: $spacer;
        word-wrap: anywhere;

        &::before {
          position: absolute;
          height: 8px;
          width: 8px;
          content: "";
          background-image: url("/local/templates/1ci/i/icons/Common/020.svg");
          background-size: cover;
          left: 0;
          top: 8px;
        }
      }
    }
  }

  &-list {
    position: relative;
    margin-bottom: $spacer*2;
    padding: 0;

    @include media-breakpoint-up("md") {
      margin-bottom: $spacer*4;
    }

    &__container {
      padding-left: $spacer*2.5;
    }

    &__item {
      margin-bottom: $spacer*2;
      list-style-type: none;

      &_title {
        margin-bottom: $spacer;
      }

      &::before {
        content: "";
        background-image: url("/local/templates/1ci/i/icons/features/Blue/023-26x26.svg");
        background-size: cover;
        display: block;
        position: absolute;
        left: 0;
        height: 24px;
        width: 24px;
        margin-right: $spacer;
      }
    }
  }

  &-speakers {
    margin-bottom: $spacer*2;

    &_item {
      display: flex;
      margin-bottom: $spacer*2;

      &--image {
        height: 96px;
        width: 96px;
        object-fit: cover;
        border-radius: 100%;
        margin-right: $spacer;
      }

      &--text {
        margin-top: $spacer/2;

        p {
          margin-bottom: 0;
        }
      }

      &--speaker {
        font-weight: 600;
      }
    }
  }

  &-tabs {
    .nav-tabs {
      margin-bottom: $spacer*3;
      border-bottom: 2px solid $gray-500;
      .nav-link {
        padding: 0 0 $spacer;
        margin-right: $spacer*2.25;
        color: $dark-gray;
        border-bottom: 2px solid $gray-500;
        &.active {
          color: $dark-indigo;
          border-bottom: 2px solid $blue;
        }
      }
    }
    .tab-content {
      margin-bottom: $spacer*4;
    }
    .tab-pane {
      border-bottom: 1px solid $gray-500;
      &_item {
        padding: $spacer*2 0 $spacer*1.5;
        border-top: 1px solid $gray-500;
        @include media-breakpoint-up("lg") {
          display: flex;
        }
      }
      &_time,
      &_title,
      &_subtitle,
      &_text {
        margin-bottom: $spacer/2;
      }
      &_subtitle {
        font-weight: $font-weight-bold;
      }
      &_time {
        min-width: 160px;
        margin-right: $spacer*1.875;
      }
      &_text {
        color: $dark-gray;
        margin-top: $spacer;
      }
      &_column {
        @include media-breakpoint-up("lg") {
          display: flex;
          &-item:not(:last-child) {
            margin-right: $spacer*1.875;
          }
        }
      }
      &_list {
        list-style: none;
        padding: 0;
        margin-top: $spacer;
        margin-bottom: $spacer/2;
        &-item {
          color: $dark-gray;
          margin-bottom: $spacer/2;
          padding-left: $spacer;
          position: relative;
          &::before {
            position: absolute;
            height: 8px;
            width: 8px;
            content: "";
            background-image: url("/local/templates/1ci/i/icons/Common/020.svg");
            background-size: cover;
            left: 0;
            top: 8px;
          }
        }
      }
    }
  }

  &-location {
    &_imageblock {
      position: relative;
      @include media-breakpoint-up("lg") {
        padding-right: $spacer*6;
      }
    }

    &_text {
      position: absolute;
      background-color: $dark-grey-blue;
      border-radius: $border-radius/2;
      padding: $spacer*2.5;
      color: $white;
      @include media-breakpoint-down("xs") {
        width: 232px;
        bottom: -$spacer*6;
        right: $spacer*3.5;
      }
      @include media-breakpoint-only("sm") {
        width: 270px;
        bottom: -$spacer*6;
        left: 0;
      }
      @include media-breakpoint-only("md") {
        width: 270px;
        left: 0;
        bottom: -$spacer*4.5;
      }
      @include media-breakpoint-up("lg") {
        top: $spacer*3.5;
        right: 0;
        width: 350px;
      }
    }

    &_image {
      width: 80%;
      margin-left: auto;
      display: block;
      object-fit: cover;
      @include media-breakpoint-down("xs") {
        width: 232px;
        height: 160px;
        margin-bottom: $spacer*5;
      }
      @include media-breakpoint-only("sm") {
        width: 438px;
        height: 296px;
        margin-bottom: $spacer*5;
        margin-left: $spacer*4.5;
      }
      @include media-breakpoint-only("md") {
        height: 186px;
        width: 270px;
        margin-bottom: $spacer*3.5;
      }
      @include media-breakpoint-up("lg") {
        width: 100%;
        height: 398px;
      }
    }
  }

  &-form {
    background-color: $blue;
    width: 100%;
    max-width: 320px;
    @include media-breakpoint-down("sm") {
      box-shadow: 130px 0 0 0 $blue, -130px 0 0 0 $blue;
      margin-bottom: $spacer*4;
    }
    @include media-breakpoint-up("md") {
      position: sticky;
      top: $spacer*2;
      border-radius: $border-radius/2;
    }

    &_title {
      color: $white;
      margin-bottom: $spacer*1.5;
      @include media-breakpoint-down("sm") {
        text-align: center;
      }
    }
    &.forms--blue {
      height: max-content;
      .next__step {
        color: $white;
        &-social {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: $spacer;
        }
        &-text {
          margin-top: $spacer*1.5;
          font-size: $h6-font-size;
        }
        &_buttonblock {
          display: flex;
          align-items: center;
          @include hover-btn-arrow($white, $white);
        }
        .event__form-form_title,
        &_buttonblock {
          border-bottom: 1px solid $white;
          padding-bottom: $spacer*1.5;
        }
        &-calendar__btn,
        &-more__btn {
          color: $white;
          margin-right: $spacer/2;
        }
        &-calendar__btn {
          margin-left: $spacer/2;
        }
        &-calendar__icon {
          fill: $white;
        }
      }
      .hidden {
        display: none;
      }
      .form-group {
        .select2-selection__rendered,
        label,
        input {
          font-size: $font-size-sm;
          line-height: 16px;
        }
      }
      .form-button {
        display: flex;
        .btn {
          margin-top: auto;
        }
        @include media-breakpoint-down("sm") {
          justify-content: center;
        }
      }
      @include media-breakpoint-down("sm") {
        padding: $spacer*2 0 $spacer;
        width: 100%;
      }
      @include media-breakpoint-up("md") {
        padding: $spacer*2 $spacer*2 $spacer;
      }
    }
  }

  &-duration {
    &_wrapper {
      display: flex;
      flex-direction: column;
      @include media-breakpoint-up("lg") {
        align-items: center;
        flex-direction: row;
      }
    }
    &_plus {
      position: relative;
      display: flex;
      justify-content: center;
      height: 16px;
      width: 16px;
      margin: $spacer 0;
      @include media-breakpoint-up("lg") {
        margin: 0 $spacer*1.5;
      }
      &::before,
      &::after {
        content: "";
        position: absolute;
        height: 16px;
        width: 2px;
        background-color: $light-gray-blue;
        border-radius: $border-radius/2;
      }
      &::after {
        transform: rotate(90deg);
      }
    }
    &_item {
      display: flex;
      width: max-content;
      padding: $spacer*1.5 $spacer*1.5 $spacer*1.5 $spacer*1;
      background-color: $gray-300;
    }
    &_img {
      margin-right: $spacer;
      width: 16px;
      height: 16px;
    }
    &_text {
      margin-bottom: 0;
      line-height: 16px;
      &.text-bold {
        font-weight: 600;
      }
    }
  }
}

//app/local/blocks/1ci/4.1ci_event_question
.event__question {
  padding-top: $spacer*3;
  padding-bottom: $spacer*3;
  background-color: $gray-300;

  &-block {
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up("md") {
      flex-direction: row;
    }
  }

  &-avatar {
    border-radius: 100%;
    height: 96px;
    width: 96px;
    margin-right: $spacer*2;
    @include media-breakpoint-down("sm") {
      margin-bottom: 16px;
    }
  }

  &-textblock {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &_text {
      margin-bottom: 0;
    }
  }
}

//js cards link
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: transparent;
}
