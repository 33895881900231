// stylelint-disable declaration-no-important, selector-list-comma-newline-after

//
// Headings
//

h1, h2, h3, h4, h5, h6 {
  margin-bottom: $headings-margin-bottom;
  font-family: $headings-font-family;
  font-weight: $font-weight-normal;
  line-height: $headings-line-height-mobile;
  color: $headings-color;
}

.h1 {
  font-size: $h1-font-size;
  font-weight: $font-weight-semibold;
  @include media-breakpoint-up("md") {
    font-size: $h1-font-size-desktop;
    line-height: $headings-line-height-desktop;
  }
}

.h2 {
  font-size: $h2-font-size;
  @include media-breakpoint-up("md") {
    font-size: $h2-font-size-desktop;
    line-height: $headings-line-height-desktop;
  }
}

.h3 {
  font-size: $h3-font-size;
  @include media-breakpoint-up("md") {
    font-size: $h3-font-size-desktop;
  }
}

.h4 {
  font-size: $h4-font-size;
  line-height: $headings-line-height-mobile-small;
  @include media-breakpoint-up("md") {
    font-size: $h4-font-size-desktop;
    line-height: $headings-line-height-mobile;
  }
}

.h5 {
  font-size: $h5-font-size;
  line-height: $headings-line-height-mobile-small;
}

.h6 {
  font-size: $h6-font-size;
  line-height: $headings-line-height-mobile-small;
}

//
// Horizontal rules
//

hr {
  margin-top: $hr-margin-y;
  margin-bottom: $hr-margin-y;
  border: 0;
  border-top: $hr-border-width solid $hr-border-color;
}

//
// Emphasis
//

small,
.small {
  font-size: $small-font-size;
  font-weight: $font-weight-normal;
}

mark,
.mark {
  padding: $mark-padding;
  background-color: $mark-bg;
}


//
// Lists
//

.list-unstyled {
  @include list-unstyled;
}

// Inline turns list items into inline-block
.list-inline {
  @include list-unstyled;
}

.list-inline-item {
  display: inline-block;

  &:not(:last-child) {
    margin-right: $list-inline-padding;
  }
}


//
// Misc
//

// Builds on `abbr`
.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

// Blockquotes
.blockquote {
  margin-bottom: $spacer;
  font-size: $blockquote-font-size;
}

.blockquote-footer {
  display: block;
  font-size: 80%; // back to default font-size
  color: $blockquote-small-color;

  &::before {
    content: "\2014 \00A0"; // em dash, nbsp
  }
}

//List variant
@each $type, $value in $list-variants {
  .list__type-#{$type} {
    @include list-variant($value);
  }
}

//Height variant
@each $index, $values in $height-values {
  .hg-#{$index} {
    height: $values;
  }
}
