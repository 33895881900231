// stylelint-disable declaration-no-important

@each $color, $value in $gradient-colors {
  .gr-color-#{$color} {
    background-image: $value !important;
  }
}

@each $color, $value in $gradient-colors {
  .gr-bg-#{$color} {
    background-image: $value !important;
  }
}

@each $color, $value in $colors {
  .g-color-#{$color} {
    color: $value !important;
  }
}

@each $color, $value in $colors {
  .g-bg-#{$color} {
    background-color: $value !important;
  }
}

.g-color-inherit {
  color: inherit !important;
}

.g-color-black-opacity-0_1 {
  color: rgba(0, 0, 0, .1) !important;
}

.g-color-black-opacity-0_1 * {
  color: rgba(0, 0, 0, .1);
}

.g-color-black-opacity-0_1 p {
  color: rgba(0, 0, 0, .1);
}

.g-color-black-opacity-0_1--hover:active,
.g-color-black-opacity-0_1--hover:focus,
.g-color-black-opacity-0_1--hover.active {
  color: rgba(0, 0, 0, .1) !important;
}

.g-color-black-opacity-0_1--hover:hover {
  color: rgba(0, 0, 0, .1) !important;
}

.g-color-black-opacity-0_1--hover:hover * {
  color: rgba(0, 0, 0, .1) !important;
}

.g-color-black-opacity-0_3 {
  color: rgba(0, 0, 0, .3) !important;
}

.g-color-black-opacity-0_3 * {
  color: rgba(0, 0, 0, .3);
}

.g-color-black-opacity-0_3 p {
  color: rgba(0, 0, 0, .3);
}

.g-color-black-opacity-0_3--hover:active,
.g-color-black-opacity-0_3--hover:focus,
.g-color-black-opacity-0_3--hover.active {
  color: rgba(0, 0, 0, .3) !important;
}

.g-color-black-opacity-0_3--hover:hover {
  color: rgba(0, 0, 0, .3) !important;
}

.g-color-black-opacity-0_3--hover:hover * {
  color: rgba(0, 0, 0, .3) !important;
}

.g-color-black-opacity-0_5 {
  color: rgba(0, 0, 0, .5) !important;
}

.g-color-black-opacity-0_5 * {
  color: rgba(0, 0, 0, .5);
}

.g-color-black-opacity-0_5 p {
  color: rgba(0, 0, 0, .5);
}

.g-color-black-opacity-0_5--hover:active,
.g-color-black-opacity-0_5--hover:focus,
.g-color-black-opacity-0_5--hover.active {
  color: rgba(0, 0, 0, .5) !important;
}

.g-color-black-opacity-0_5--hover:hover {
  color: rgba(0, 0, 0, .5) !important;
}

.g-color-black-opacity-0_5--hover:hover * {
  color: rgba(0, 0, 0, .5) !important;
}

.g-color-black-opacity-0_6 {
  color: rgba(0, 0, 0, .6) !important;
}

.g-color-black-opacity-0_6 * {
  color: rgba(0, 0, 0, .6);
}

.g-color-black-opacity-0_6 p {
  color: rgba(0, 0, 0, .6);
}

.g-color-black-opacity-0_6--hover:active,
.g-color-black-opacity-0_6--hover:focus,
.g-color-black-opacity-0_6--hover.active {
  color: rgba(0, 0, 0, .6) !important;
}

.g-color-black-opacity-0_6--hover:hover {
  color: rgba(0, 0, 0, .6) !important;
}

.g-color-black-opacity-0_6--hover:hover * {
  color: rgba(0, 0, 0, .6) !important;
}

.g-color-black-opacity-0_7 {
  color: rgba(0, 0, 0, .7) !important;
}

.g-color-black-opacity-0_7 * {
  color: rgba(0, 0, 0, .7);
}

.g-color-black-opacity-0_7 p {
  color: rgba(0, 0, 0, .7);
}

.g-color-black-opacity-0_7--hover:active,
.g-color-black-opacity-0_7--hover:focus,
.g-color-black-opacity-0_7--hover.active {
  color: rgba(0, 0, 0, .7) !important;
}

.g-color-black-opacity-0_7--hover:hover {
  color: rgba(0, 0, 0, .7) !important;
}

.g-color-black-opacity-0_7--hover:hover * {
  color: rgba(0, 0, 0, .7) !important;
}

.g-color-black-opacity-0_8 {
  color: rgba(0, 0, 0, .8) !important;
}

.g-color-black-opacity-0_8 * {
  color: rgba(0, 0, 0, .8);
}

.g-color-black-opacity-0_8 p {
  color: rgba(0, 0, 0, .8);
}

.g-color-black-opacity-0_8--hover:active,
.g-color-black-opacity-0_8--hover:focus,
.g-color-black-opacity-0_8--hover.active {
  color: rgba(0, 0, 0, .8) !important;
}

.g-color-black-opacity-0_8--hover:hover {
  color: rgba(0, 0, 0, .8) !important;
}

.g-color-black-opacity-0_8--hover:hover * {
  color: rgba(0, 0, 0, .8) !important;
}

.g-color-black-opacity-0_8--child * {
  color: rgba(0, 0, 0, .8) !important;
}

.g-color-black-opacity-0_9 {
  color: rgba(0, 0, 0, .9) !important;
}

.g-color-black-opacity-0_9 * {
  color: rgba(0, 0, 0, .9);
}

.g-color-black-opacity-0_9 p {
  color: rgba(0, 0, 0, .9);
}

.g-color-black-opacity-0_9--hover:active,
.g-color-black-opacity-0_9--hover:focus,
.g-color-black-opacity-0_9--hover.active {
  color: rgba(0, 0, 0, .9) !important;
}

.g-color-black-opacity-0_9--hover:hover {
  color: rgba(0, 0, 0, .9) !important;
}

.g-color-black-opacity-0_9--hover:hover * {
  color: rgba(0, 0, 0, .9) !important;
}


.g-color-white--active.active {
  color: #fff !important;
}

.active .g-color-white--parent-active {
  color: #fff !important;
}

.g-color-white--child * {
  color: #fff !important;
}

.g-color-white-opacity-0_1 {
  color: rgba(255, 255, 255, .1) !important;
}

.g-color-white-opacity-0_1 * {
  color: rgba(255, 255, 255, .1);
}

.g-color-white-opacity-0_1 p {
  color: rgba(255, 255, 255, .1);
}

.g-color-white-opacity-0_1--hover:active,
.g-color-white-opacity-0_1--hover:focus,
.g-color-white-opacity-0_1--hover.active {
  color: rgba(255, 255, 255, .1) !important;
}

.g-color-white-opacity-0_1--hover:hover {
  color: rgba(255, 255, 255, .1) !important;
}

.g-color-white-opacity-0_1--hover:hover * {
  color: rgba(255, 255, 255, .1) !important;
}

.g-color-white-opacity-0_2 {
  color: rgba(255, 255, 255, .2) !important;
}

.g-color-white-opacity-0_2 * {
  color: rgba(255, 255, 255, .2);
}

.g-color-white-opacity-0_2 p {
  color: rgba(255, 255, 255, .2);
}

.g-color-white-opacity-0_2--hover:active,
.g-color-white-opacity-0_2--hover:focus,
.g-color-white-opacity-0_2--hover.active {
  color: rgba(255, 255, 255, .2) !important;
}

.g-color-white-opacity-0_2--hover:hover {
  color: rgba(255, 255, 255, .2) !important;
}

.g-color-white-opacity-0_2--hover:hover * {
  color: rgba(255, 255, 255, .2) !important;
}

.g-color-white-opacity-0_3 {
  color: rgba(255, 255, 255, .3) !important;
}

.g-color-white-opacity-0_3 * {
  color: rgba(255, 255, 255, .3);
}

.g-color-white-opacity-0_3 p {
  color: rgba(255, 255, 255, .3);
}

.g-color-white-opacity-0_3--hover:active,
.g-color-white-opacity-0_3--hover:focus,
.g-color-white-opacity-0_3--hover.active {
  color: rgba(255, 255, 255, .3) !important;
}

.g-color-white-opacity-0_3--hover:hover {
  color: rgba(255, 255, 255, .3) !important;
}

.g-color-white-opacity-0_3--hover:hover * {
  color: rgba(255, 255, 255, .3) !important;
}

.g-color-white-opacity-0_5,
.g-color-white-opacity-0_5--hover:hover,
.g-color-white-opacity--hover:active,
.g-color-white-opacity--hover:focus,
.g-color-white-opacity--hover.active {
  color: rgba(255, 255, 255, .5) !important;
}

.g-color-white-opacity-0_5 *,
.g-color-white-opacity-0_5--hover:hover *,
.g-color-white-opacity--hover:active *,
.g-color-white-opacity--hover:focus *,
.g-color-white-opacity--hover.active * {
  color: rgba(255, 255, 255, .5);
}

.g-color-white-opacity-0_5 p,
.g-color-white-opacity-0_5--hover:hover p,
.g-color-white-opacity--hover:active p,
.g-color-white-opacity--hover:focus p,
.g-color-white-opacity--hover.active p {
  color: rgba(255, 255, 255, .5);
}

.g-color-white-opacity-0_6 {
  color: rgba(255, 255, 255, .6) !important;
}

.g-color-white-opacity-0_6 * {
  color: rgba(255, 255, 255, .6);
}

.g-color-white-opacity-0_6 p {
  color: rgba(255, 255, 255, .6);
}

.g-color-white-opacity-0_6--hover:active,
.g-color-white-opacity-0_6--hover:focus,
.g-color-white-opacity-0_6--hover.active {
  color: rgba(255, 255, 255, .6) !important;
}

.g-color-white-opacity-0_6--hover:hover {
  color: rgba(255, 255, 255, .6) !important;
}

.g-color-white-opacity-0_6--hover:hover * {
  color: rgba(255, 255, 255, .6) !important;
}

.g-parent:hover .g-color-white-opacity-0_6--parent-hover {
  color: rgba(255, 255, 255, .6) !important;
}

.g-color-white-opacity-0_7,
.g-color-white-opacity-0_7--hover:hover,
.g-color-white-opacity--hover:active,
.g-color-white-opacity--hover:focus,
.g-color-white-opacity--hover.active {
  color: rgba(255, 255, 255, .7) !important;
}

.g-color-white-opacity-0_7 *,
.g-color-white-opacity-0_7--hover:hover *,
.g-color-white-opacity--hover:active *,
.g-color-white-opacity--hover:focus *,
.g-color-white-opacity--hover.active * {
  color: rgba(255, 255, 255, .7);
}

.g-color-white-opacity-0_7 p,
.g-color-white-opacity-0_7--hover:hover p,
.g-color-white-opacity--hover:active p,
.g-color-white-opacity--hover:focus p,
.g-color-white-opacity--hover.active p {
  color: rgba(255, 255, 255, .7);
}

.g-color-white-opacity-0_75 {
  color: rgba(255, 255, 255, .75) !important;
}

.g-color-white-opacity-0_75 * {
  color: rgba(255, 255, 255, .75);
}

.g-color-white-opacity-0_75 p {
  color: rgba(255, 255, 255, .75);
}

.g-color-white-opacity-0_75--hover:hover {
  color: rgba(255, 255, 255, .75);
}

.g-color-white-opacity-0_75--hover:hover * {
  color: rgba(255, 255, 255, .75);
}

.g-color-white-opacity-0_8 {
  color: rgba(255, 255, 255, .8) !important;
}

.g-color-white-opacity-0_8 * {
  color: rgba(255, 255, 255, .8);
}

.g-color-white-opacity-0_8 p {
  color: rgba(255, 255, 255, .8);
}

.g-color-white-opacity-0_8--hover:active,
.g-color-white-opacity-0_8--hover:focus,
.g-color-white-opacity-0_8--hover.active {
  color: rgba(255, 255, 255, .8) !important;
}

.g-color-white-opacity-0_8--hover:hover {
  color: rgba(255, 255, 255, .8) !important;
}

.g-color-white-opacity-0_8--hover:hover * {
  color: rgba(255, 255, 255, .8) !important;
}

.g-color-white-opacity-0_8--child * {
  color: rgba(255, 255, 255, .8) !important;
}

.g-color-white-opacity-0_9,
.g-color-white-opacity-0_9--hover:hover,
.g-color-white-opacity--hover:active,
.g-color-white-opacity--hover:focus,
.g-color-white-opacity--hover.active {
  color: rgba(255, 255, 255, .9) !important;
}

.g-color-white-opacity-0_9 *,
.g-color-white-opacity-0_9--hover:hover *,
.g-color-white-opacity--hover:active *,
.g-color-white-opacity--hover:focus *,
.g-color-white-opacity--hover.active * {
  color: rgba(255, 255, 255, .9);
}

.g-color-white-opacity-0_9 p,
.g-color-white-opacity-0_9--hover:hover p,
.g-color-white-opacity--hover:active p,
.g-color-white-opacity--hover:focus p,
.g-color-white-opacity--hover.active p {
  color: rgba(255, 255, 255, .9);
}

/* Gray Colors */
.g-color-gray-light-v1 {
  color: $gray-300 !important;
}

.g-color-gray-light-v1--hover:active,
.g-color-gray-light-v1--hover:focus,
.g-color-gray-light-v1--hover.active {
  color: $gray-300 !important;
}

.g-color-gray-light-v1--hover:hover {
  color: $gray-300 !important;
}

.g-color-gray-light-v1--hover:hover * {
  color: $gray-300 !important;
}

.g-color-gray-light-v1 * {
  color: $gray-300;
}

.g-color-gray-light-v1 p {
  color: $gray-300;
}

.g-color-gray-light-v2 {
  color: $gray-500 !important;
}

.g-color-gray-light-v2 * {
  color: $gray-500;
}

.g-color-gray-light-v2 p {
  color: $gray-500;
}

.g-color-gray-light-v2--hover:active,
.g-color-gray-light-v2--hover:focus,
.g-color-gray-light-v2--hover.active {
  color: $gray-500 !important;
}

.g-color-gray-light-v2--hover:hover {
  color: $gray-500 !important;
}

.g-color-gray-light-v2--hover:hover * {
  color: $gray-500 !important;
}

.g-color-gray-light-v3 {
  color: $gray-600 !important;
}

.g-color-gray-light-v3 * {
  color: $gray-600;
}

.g-color-gray-light-v3 p {
  color: $gray-600;
}

.g-color-gray-light-v3--hover:active,
.g-color-gray-light-v3--hover:focus,
.g-color-gray-light-v3--hover.active {
  color: $gray-600 !important;
}

.g-color-gray-light-v3--hover:hover {
  color: $gray-600 !important;
}

.g-color-gray-light-v3--hover:hover * {
  color: $gray-600 !important;
}

.g-color-gray-light-v4 {
  color: $gray-700 !important;
}

.g-color-gray-light-v4 * {
  color: $gray-700;
}

.g-color-gray-light-v4 p {
  color: $gray-700;
}

.g-color-gray-light-v4--hover:active,
.g-color-gray-light-v4--hover:focus,
.g-color-gray-light-v4--hover.active {
  color: $gray-700 !important;
}

.g-color-gray-light-v4--hover:hover {
  color: $gray-700 !important;
}

.g-color-gray-light-v4--hover:hover * {
  color: $gray-700 !important;
}

.g-color-gray-light-v4-opacity-0_6 {
  color: rgba(238, 238, 238, .6) !important;
}

.g-color-gray-light-v5 {
  color: $gray-800 !important;
}

.g-color-gray-light-v5 * {
  color: $gray-800;
}

.g-color-gray-light-v5 p {
  color: $gray-800;
}

.g-color-gray-light-v5--hover:active,
.g-color-gray-light-v5--hover:focus,
.g-color-gray-light-v5--hover.active {
  color: $gray-800 !important;
}

.g-color-gray-light-v5--hover:hover {
  color: $gray-800 !important;
}

.g-color-gray-light-v5--hover:hover * {
  color: $gray-800 !important;
}

.g-color-gray-dark-v1 {
  color: $gray-900 !important;
}

.g-color-gray-dark-v1 * {
  color: $gray-900;
}

.g-color-gray-dark-v1 p {
  color: $gray-900;
}

.g-color-gray-dark-v1--hover:active,
.g-color-gray-dark-v1--hover:focus,
.g-color-gray-dark-v1--hover.active {
  color: $gray-900 !important;
}

.g-color-gray-dark-v1--hover:hover {
  color: $gray-900 !important;
}

.g-color-gray-dark-v1--hover:hover * {
  color: $gray-900 !important;
}

.g-color-gray-dark-v2 {
  color: $gray-100 !important;
}

.g-color-gray-dark-v2 * {
  color: $gray-100;
}

.g-color-gray-dark-v2 p {
  color: $gray-100;
}

.g-color-gray-dark-v2--hover:active,
.g-color-gray-dark-v2--hover:focus,
.g-color-gray-dark-v2--hover.active {
  color: $gray-100 !important;
}

.g-color-gray-dark-v2--hover:hover {
  color: $gray-100 !important;
}

.g-color-gray-dark-v2--hover:hover * {
  color: $gray-100 !important;
}

.g-color-gray-dark-v2-opacity-0_75 {
  color: rgba(51, 51, 51, .75) !important;
}

.g-color-gray-dark-v3 {
  color: #555 !important;
}

.g-color-gray-dark-v3 * {
  color: #555;
}

.g-color-gray-dark-v3 p {
  color: #555;
}

.g-color-gray-dark-v3--hover:active,
.g-color-gray-dark-v3--hover:focus,
.g-color-gray-dark-v3--hover.active {
  color: #555 !important;
}

.g-color-gray-dark-v3--hover:hover {
  color: #555 !important;
}

.g-color-gray-dark-v3--hover:hover * {
  color: #555 !important;
}

.g-color-gray-dark-v4 {
  color: #777 !important;
}

.g-color-gray-dark-v4 * {
  color: #777;
}

.g-color-gray-dark-v4 p {
  color: #777;
}

.g-color-gray-dark-v4--hover:active,
.g-color-gray-dark-v4--hover:focus,
.g-color-gray-dark-v4--hover.active {
  color: #777 !important;
}

.g-color-gray-dark-v4--hover:hover {
  color: #777 !important;
}

.g-color-gray-dark-v4--hover:hover * {
  color: #777 !important;
}

.g-color-gray-dark-v5 {
  color: #999 !important;
}

.g-color-gray-dark-v5 * {
  color: #999;
}

.g-color-gray-dark-v5 p {
  color: #999;
}

.g-color-gray-dark-v5--hover:active,
.g-color-gray-dark-v5--hover:focus,
.g-color-gray-dark-v5--hover.active {
  color: #999 !important;
}

.g-color-gray-dark-v5--hover:hover {
  color: #999 !important;
}

.g-color-gray-dark-v5--hover:hover * {
  color: #999 !important;
}

.g-parent:hover .g-color-gray-dark-v5--parent-hover {
  color: #999 !important;
}

.g-color-blue-dark-v1 {
  color: #175a88 !important;
}

.g-color-blue-dark-v1 * {
  color: #175a88;
}

.g-color-blue-dark-v1 p {
  color: #175a88;
}

.g-color-blue-dark-v1--hover:hover {
  color: #175a88 !important;
}

.g-color-blue-dark-v1--hover:hover * {
  color: #175a88 !important;
}

/* Color Light Blue */
.g-color-lightblue {
  color: $blue !important;
}

.g-color-lightblue * {
  color: $blue;
}

.g-color-lightblue p {
  color: $blue;
}

.g-color-lightblue--hover:active,
.g-color-lightblue--hover:focus,
.g-color-lightblue--hover.active {
  color: $blue !important;
}

.g-color-lightblue--hover:hover {
  color: $blue !important;
}

.g-color-lightblue--hover:hover * {
  color: $blue !important;
}

.g-color-lightblue-v1 {
  color: #d6e2ee !important;
}

.g-color-lightblue-v1 * {
  color: #d6e2ee;
}

.g-color-lightblue-v1 p {
  color: #d6e2ee;
}

.g-color-lightblue-v1--hover:active,
.g-color-lightblue-v1--hover:focus,
.g-color-lightblue-v1--hover.active {
  color: #d6e2ee !important;
}

.g-color-lightblue-v1--hover:hover {
  color: #d6e2ee !important;
}

.g-color-lightblue-v1--hover:hover * {
  color: #d6e2ee !important;
}

/* Color Dark Blue */
.g-color-darkblue {
  color: $dark-blue !important;
}

.g-color-darkblue * {
  color: $dark-blue;
}

.g-color-darkblue p {
  color: $dark-blue;
}

.g-color-darkblue--hover:active,
.g-color-darkblue--hover:focus,
.g-color-darkblue--hover.active {
  color: $dark-blue !important;
}

.g-color-darkblue--hover:hover {
  color: $dark-blue !important;
}

.g-color-darkblue--hover:hover * {
  color: $dark-blue !important;
}

/* Color Indigo */
.g-color-indigo {
  color: $dark-indigo !important;
}

.g-color-indigo * {
  color: $dark-indigo;
}

.g-color-indigo p {
  color: $dark-indigo;
}

.g-color-indigo--hover:active,
.g-color-indigo--hover:focus,
.g-color-indigo--hover.active {
  color: $dark-indigo !important;
}

.g-color-indigo--hover:hover {
  color: $dark-indigo !important;
}

.g-color-indigo--hover:hover * {
  color: $dark-indigo !important;
}

/* Color Red */
.g-color-red {
  color: $accent-red !important;
}

.g-color-red * {
  color: $accent-red;
}

.g-color-red p {
  color: $accent-red;
}

.g-color-red--hover:active,
.g-color-red--hover:focus,
.g-color-red--hover.active {
  color: $accent-red !important;
}

.g-color-red--hover:hover {
  color: $accent-red !important;
}

.g-color-red--hover:hover * {
  color: $accent-red !important;
}

*:hover > .g-color-red--parent-hover {
  color: $accent-red !important;
}

/* Color Light Red */
.g-color-lightred {
  color: $red !important;
}

.g-color-lightred * {
  color: $red;
}

.g-color-lightred p {
  color: $red;
}

.g-color-lightred--hover:active,
.g-color-lightred--hover:focus,
.g-color-lightred--hover.active {
  color: $red !important;
}

.g-color-lightred--hover:hover {
  color: $red !important;
}

.g-color-lightred--hover:hover * {
  color: $red !important;
}

/* Color Dark Red */
.g-color-darkred {
  color: #a10f2b !important;
}

.g-color-darkred * {
  color: #a10f2b;
}

.g-color-darkred p {
  color: #a10f2b;
}

.g-color-darkred--hover:active,
.g-color-darkred--hover:focus,
.g-color-darkred--hover.active {
  color: #a10f2b !important;
}

.g-color-darkred--hover:hover {
  color: #a10f2b !important;
}

.g-color-darkred--hover:hover * {
  color: #a10f2b !important;
}

/* Color Purple */
.g-color-purple {
  color: #9a69cb !important;
}

.g-color-purple * {
  color: #9a69cb;
}

.g-color-purple p {
  color: #9a69cb;
}

.g-color-purple--hover:active,
.g-color-purple--hover:focus,
.g-color-purple--hover.active {
  color: #9a69cb !important;
}

.g-color-purple--hover:hover {
  color: #9a69cb !important;
}

.g-color-purple--hover:hover * {
  color: #9a69cb !important;
}

.g-color-purple-dark-v1 {
  color: #552c7e !important;
}

/* Color Dark Purple */
.g-color-darkpurple {
  color: #6639b6 !important;
}

.g-color-darkpurple * {
  color: #6639b6;
}

.g-color-darkpurple p {
  color: #6639b6;
}

.g-color-darkpurple--hover:active,
.g-color-darkpurple--hover:focus,
.g-color-darkpurple--hover.active {
  color: #6639b6 !important;
}

.g-color-darkpurple--hover:hover {
  color: #6639b6 !important;
}

.g-color-darkpurple--hover:hover * {
  color: #6639b6 !important;
}

/* Color Pink */
.g-color-pink {
  color: $pink !important;
}

.g-color-pink * {
  color: $pink;
}

.g-color-pink p {
  color: $pink;
}

.g-color-pink--hover:active,
.g-color-pink--hover:focus,
.g-color-pink--hover.active {
  color: $pink !important;
}

.g-color-pink--hover:hover {
  color: $pink !important;
}

.g-color-pink--hover:hover * {
  color: $pink !important;
}

.g-color-pink-dark-v1 {
  color: #6f0b2d !important;
}

.g-color-pink-dark-v1 * {
  color: #6f0b2d;
}

.g-color-pink-dark-v1 p {
  color: #6f0b2d;
}

.g-color-pink-dark-v1--hover:hover {
  color: #6f0b2d !important;
}

/* Color Orange */
.g-color-orange {
  color: $dark-yellow !important;
}

.g-color-orange * {
  color: $dark-yellow;
}

.g-color-orange p {
  color: $dark-yellow;
}

.g-color-orange--hover:active,
.g-color-orange--hover:focus,
.g-color-orange--hover.active {
  color: $dark-yellow !important;
}

.g-color-orange--hover:hover {
  color: $dark-yellow !important;
}

.g-color-orange--hover:hover * {
  color: $dark-yellow !important;
}

/* Color Deep Orange */
.g-color-deeporange {
  color: #fe541e !important;
}

.g-color-deeporange * {
  color: #fe541e;
}

.g-color-deeporange p {
  color: #fe541e;
}

.g-color-deeporange--hover:active,
.g-color-deeporange--hover:focus,
.g-color-deeporange--hover.active {
  color: #fe541e !important;
}

.g-color-deeporange--hover:hover {
  color: #fe541e !important;
}

.g-color-deeporange--hover:hover * {
  color: #fe541e !important;
}

/* Color Yellow */
.g-color-yellow {
  color: $yellow !important;
}

.g-color-yellow * {
  color: $yellow;
}

.g-color-yellow p {
  color: $yellow;
}

.g-color-yellow--hover:active,
.g-color-yellow--hover:focus,
.g-color-yellow--hover.active {
  color: $yellow !important;
}

.g-color-yellow--hover:hover {
  color: $yellow !important;
}

.g-color-yellow--hover:hover * {
  color: $yellow !important;
}

/* Color Aqua */
.g-color-aqua {
  color: $light-bg-blue !important;
}

.g-color-aqua * {
  color: $light-bg-blue;
}

.g-color-aqua p {
  color: $light-bg-blue;
}

.g-color-aqua--hover:active,
.g-color-aqua--hover:focus,
.g-color-aqua--hover.active {
  color: $light-bg-blue !important;
}

.g-color-aqua--hover:hover {
  color: $light-bg-blue !important;
}

.g-color-aqua--hover:hover * {
  color: $light-bg-blue !important;
}

.g-color-aqua-dark-v1 {
  color: #11848e !important;
}

.g-color-aqua-dark-v1 * {
  color: #11848e;
}

.g-color-aqua-dark-v1 p {
  color: #11848e;
}

.g-color-aqua-dark-v1--hover:active,
.g-color-aqua-dark-v1--hover:focus,
.g-color-aqua-dark-v1--hover.active {
  color: #11848e !important;
}

.g-color-aqua-dark-v1--hover:hover {
  color: #11848e !important;
}

.g-color-aqua-dark-v1--hover:hover * {
  color: #11848e !important;
}

/* Color Cyan */
.g-color-cyan {
  color: #00bed6 !important;
}

.g-color-cyan * {
  color: #00bed6;
}

.g-color-cyan p {
  color: #00bed6;
}

.g-color-cyan--hover:active,
.g-color-cyan--hover:focus,
.g-color-cyan--hover.active {
  color: #00bed6 !important;
}

.g-color-cyan--hover:hover {
  color: #00bed6 !important;
}

.g-color-cyan--hover:hover * {
  color: #00bed6 !important;
}

/* Color Teal */
.g-color-teal {
  color: #18ba9b !important;
}

.g-color-teal * {
  color: #18ba9b;
}

.g-color-teal p {
  color: #18ba9b;
}

.g-color-teal--hover:active,
.g-color-teal--hover:focus,
.g-color-teal--hover.active {
  color: #18ba9b !important;
}

.g-color-teal--hover:hover {
  color: #18ba9b !important;
}

.g-color-teal--hover:hover * {
  color: #18ba9b !important;
}

/* Color Brown */
.g-color-brown {
  color: #9c8061 !important;
}

.g-color-brown * {
  color: #9c8061;
}

.g-color-brown p {
  color: #9c8061;
}

.g-color-brown--hover:active,
.g-color-brown--hover:focus,
.g-color-brown--hover.active {
  color: #9c8061 !important;
}

.g-color-brown--hover:hover {
  color: #9c8061 !important;
}

.g-color-brown--hover:hover * {
  color: #9c8061 !important;
}

/* Color Blue Gray */
.g-color-bluegray {
  color: $dark-grey-blue !important;
}

.g-color-bluegray * {
  color: $dark-grey-blue;
}

.g-color-bluegray p {
  color: $dark-grey-blue;
}

.g-color-bluegray--hover:active,
.g-color-bluegray--hover:focus,
.g-color-bluegray--hover.active {
  color: $dark-grey-blue !important;
}

.g-color-bluegray--hover:hover {
  color: $dark-grey-blue !important;
}

.g-color-bluegray--hover:hover * {
  color: $dark-grey-blue !important;
}
