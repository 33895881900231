// app/local/blocks/1ci/10.1ci_application_image_list_block
// app/local/blocks/1ci/10.1ci_application_list_image_block
[data-card-preset="text"]:not(:last-child) {
  margin-bottom: $spacer*2;
}

.image-and-text-block,
.text-and-image-block {
  background-color: transparent;
  padding: $spacer*3 0;

  &_mobile-reverse {
    @include media-breakpoint-down("sm") {
      flex-direction: column-reverse;
    }
  }

  &_text {
    color: $dark-gray;
    @include media-breakpoint-down("xs") {
      margin-bottom: $spacer*1.5;
    }
  }

  &_image {
    width: 100%;
  }

  &_list {
    padding-left: 0;
  }

  &_list-item,
  li {
    padding-left: $spacer*2.5;
    list-style: none;
    position: relative;
    margin: $spacer*1.5 0;

    &::before {
      content: "";
      position: absolute;
      background: url("/local/templates/1ci/i/icons/dot2.svg") no-repeat 0 0;
      left: 0;
      top: 0;
      width: 24px;
      height: 24px;
    }
  }
}

// app/local/blocks/1ci/10.1ci_application_benefits_block
.product-benefits-block {
  background-color: transparent;
  padding: $spacer*1.5 0 $spacer*2;

  &_header {
    text-align: center;
  }

  &_image {
    padding: $spacer;
    height: 136px;
  }

  &_title {
    margin-bottom: $spacer*1.5;
  }

  &_item {
    padding: $spacer;
  }

  &_icon {
    width: 56px;
    height: auto;
    margin-bottom: $spacer/2;
  }

  &_text {
    font-size: $display50-size;
    line-height: 1.26;
  }

  &_text_m {
    font-size: $font-size-m;
    color: $dark-gray;
    line-height: 1.41;
  }
}

// app/local/blocks/1ci/10.1ci_all_products_block
.all-products-block {
  padding: $spacer 0 $spacer*2;
  background-color: transparent;

  &_block {
    margin-bottom: $spacer*2;
  }

  &_item {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: $spacer*1.5;
    padding-bottom: $spacer*2;
    @include border-radius($border-radius);
    @include box-shadow-overwrite($box-shadow-sm);
    @include transition($transition-base);

    &:hover {
      box-shadow: $box-shadow;
    }

    .all-products-block_textblock-usa + .all-products-block_buttonblock {
      margin-top: 0;
    }
  }

  &_titleblock {
    padding: $spacer 0 0;
  }

  &_textblock {
    color: $dark-gray;
    margin-bottom: $spacer;

    &-usa {
      font-family: $headings-font-family;
      font-weight: $headings-font-weight;
      font-size: $font-size-xs;
      padding-bottom: $spacer*1.5;
      margin-top: auto;
      color: $dark-gray;

      img {
        padding-right: $spacer/2;
        vertical-align: top;
      }
    }
  }

  &_buttonblock {
    margin-top: auto;

    @include media-breakpoint-up("sm") {
      flex-direction: row;
    }
  }

  @include hover-btn-arrow($link-color, $link-hover-color);

  &_request-button {
    font-weight: $font-weight-bold;
    padding: $spacer*.4 $spacer;
    margin-right: $spacer;
    border: solid 1px $btn-yellow-border;
  }

  &_request-button-wa {
    font-weight: $font-weight-bold;
    padding: $spacer*.4 0;
  }
}

// app/local/blocks/1ci/10.1ci_application_value_block/
.application-value-block {
  background-color: transparent;
  margin: $spacer*2.5 0;
  @include media-breakpoint-up("sm") {
    background-color: $gray-300;
  }
  @include media-breakpoint-up("lg") {
    background-color: transparent;
    .disable-lg-row {
      margin: 0;
    }
  }

  &_block {
    background-color: $gray-300;
    padding-top: $spacer*1.5;
    padding-bottom: $spacer*1.5;
    @include media-breakpoint-up("lg") {
      padding: $spacer*2 $spacer*3.375;
    }
  }

  &_base {
    position: relative;
    background-color: $dark-gray;
    @include media-breakpoint-up("lg") {
      align-items: flex-end;
      padding-bottom: $spacer/2;
    }
  }

  &_progress {
    position: relative;
    background-color: $progress_yellow-bg;
    @include media-breakpoint-up("lg") {
      align-items: center;
    }
  }

  &_item:not(:last-child) &_text {
    margin-bottom: $spacer*2;
    @include media-breakpoint-up("lg") {
      margin-bottom: 0;
    }
  }

  &_text {
    margin-top: $spacer/2;
    @include media-breakpoint-up("lg") {
      margin-top: $spacer/2;
      max-width: 164px;
    }
  }

  &_description {

    &--big {
      font-size: $display4-size;
      font-weight: $font-weight-bold;
      bottom: ($spacer - .2);
    }
  }

  $bar-values: (
    1: (base: 35%, progress: 10%),
    2: (base: 35%, progress: 30%),
    3: (base: 26.5%, progress: 8.5%),
    4: (base: 35%, progress: 14%),
    5: (base: 35%, progress: 35%)
  );

  @each $index, $values in $bar-values {
    .bar-value-#{$index} {
      .application-value-block_base {
        width: map-get($values, base);
        height: inherit;
        padding-left: $spacer/4;

        @include media-breakpoint-up("md") {
          width: map-get($values, base) *.86;
          height: inherit;
        }
        @include media-breakpoint-up("lg") {
          height: map-get($values, base) *1.3;
          padding-left: 0;
          width: 100%;
        }
      }

      .application-value-block_progress {
        width: map-get($values, progress);
        height: inherit;
        justify-content: center;

        @include media-breakpoint-up("md") {
          height: inherit;
          width: map-get($values, progress) * .86;
        }
        @include media-breakpoint-up("lg") {
          height: map-get($values, progress) *1.3;
          width: 100%;
        }
      }
    }
  }

}

//app/local/blocks/1ci/0.disclaimer
.disclaimer {
  padding: $spacer/2 0 $spacer*3;

  &-text {
    padding-top: $spacer;
    border-top: 1px solid $gray-600;
    margin-bottom: 0;
    text-align: center;
    font-size: $font-size-sm;
    color: $dark-gray;
  }
}

// app/local/blocks/1ci/10.1ci_application_usa_only
.usa-only {
  padding: $spacer 0 $spacer*2;
  background-color: transparent;

  &_row {
    justify-content: center;
    border-bottom: 1px solid $gray-500;
  }

  &_block {
    display: flex;
    align-items: center;
    background: transparent url("/local/templates/1ci/i/products/Earth.svg") bottom center no-repeat;
    text-align: center;
    min-height: $spacer*4;
    padding-top: $spacer;
    padding-bottom: $spacer;
  }

  &_image {
    padding-right: $spacer/2;
  }

  &_text {
    font-family: $headings-font-family;
    font-size: $font-size-xs;
    font-weight: $headings-font-weight;
    white-space: nowrap;
    margin: 0;
  }
}

// app/local/blocks/1ci/10.1ci_application_testimonials
.testimonial-w-quote-2 {
  margin-bottom: $spacer;

  &_block {
    background-color: $gray-300;
    padding: $spacer*3 $spacer*4 $spacer*3 $spacer*3;
  }

  &_quote {
    color: $yellow;
    font-family: Arial, sans-serif;
    font-size: 114px;
    line-height: 1;
    float: left;
    margin-top: -2rem;
    height: 100%;
  }

  &_text {
    font-size: $font-size-base*1.125;
  }

  &_author {
    color: $dark-gray;
    font-size: $font-size-sm;
    font-style: italic;
  }
}

// app/local/blocks/1ci/10.1ci_application_brochure
.brochure {
  background-color: $gray-300;
  @include media-breakpoint-up("md") {
    background-color: transparent;
  }

  &-row {
    display: flex;
    @include media-breakpoint-up("md") {
      padding-top: $spacer*3;
      & > div:first-child {
        padding-right: 0;
      }
      & > div:last-child {
        padding-left: 0;
      }
    }
    @include media-breakpoint-up("xl") {
      padding-top: $spacer*6;
    }
  }

  &_block,
  &_image-block {
    position: relative;
    background-color: $gray-300;
  }

  &_block {
    padding-top: $spacer*1.875;
    padding-bottom: $spacer*2.125;
    @include media-breakpoint-up("md") {
      padding: $spacer*2 0 $spacer*2.25 $spacer*2.625;
      border-radius: 4px 0 0 4px;
    }
    @include media-breakpoint-up("lg") {
      padding: $spacer*2.5 0 $spacer*3.5 $spacer*3;
    }
  }

  &_image-block {
    display: none;
    @include media-breakpoint-up("md") {
      display: block;
      height: 100%;
      border-radius: 0 4px 4px 0;
    }
  }

  &_image {
    position: absolute;
    bottom: 10px;
    width: 100%;
    @include media-breakpoint-up("xl") {
      bottom: -10px;
      right: 32px;
    }
  }

  &_type {
    text-transform: uppercase;
    font-size: $font-size-xs;
    font-weight: $font-weight-bold;
    color: $dark-gray;
    margin-bottom: $spacer;
  }

  &_text {
    color: $dark-gray;
    margin-bottom: $spacer * 1.5;

    @include media-breakpoint-up("xl") {
      max-width: 624px;
    }
  }

  &_btnblock {
    margin-bottom: 0;
  }
}

// app/local/blocks/1ci/7.1ci_solution_professional
.professionals {
  padding: $spacer*2.5 0 $spacer/2;

  &_block {
    padding: $spacer*2 $spacer;
    height: 100%;
    border: 1px solid $gray-500;
    @include border-radius($border-radius);

    &-wrapper {
      margin-bottom: $spacer*2;
    }

    @include media-breakpoint-up("lg") {
      padding: $spacer*2;
    }
  }

  &_header {
    margin-bottom: $spacer*2;
  }

  &_item {
    position: relative;
    margin-left: $spacer*2.5;
    margin-bottom: $spacer;

    &::before {
      content: "";
      position: absolute;
      background: url("/local/templates/1ci/i/icons/dot.svg") no-repeat 0 0;
      left: -40px;
      top: 0;
      width: 24px;
      height: 24px;
    }

    &-header {
      margin-bottom: 0;
    }

    &-description {
      color: $dark-gray;
    }
  }
}
