
.select2-selection--single {
  color: #fff;
  background-color: #172b4d;
  height: calc(3.5rem + 2px);
  border-radius: $border-radius;

  .select2-selection__rendered {
    color: #fff;
    background-color: #172b4d;
    border: 1px solid #172b4d;
    display: flex;
    align-items: center;
    font-weight: 700;
    height: 100%;
    padding: .6rem 1.5rem;
  }

  .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
  }

  .select2-selection__placeholder {
    color: #c1c7d0;
  }

  .select2-selection__arrow {
    position: absolute;
    top: 0;
    right: 1.5rem;
    width: 7px;
    height: 90%;
    display: flex;
    align-items: center;

    b {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 7px solid #fff;
      }
    }
  }
}

&[dir="rtl"] {
  .select2-selection--single {
    .select2-selection__clear {
      float: left;
    }

    .select2-selection__arrow {
      left: 1px;
      right: auto;
    }
  }
}

&.select2-container--disabled {
  .select2-selection--single {
    background-color: #eee;
    cursor: default;

    .select2-selection__clear {
      display: none;
    }
  }
}

&.select2-container--open {
  .select2-selection--single {
    .select2-selection__arrow {
      b {
        border-color: transparent transparent #091e42 transparent;
        border-width: 0 4px 5px 4px;
      }
    }
  }
}
