.select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: calc(2rem + 2px);
  user-select: none;
  outline: none;
  -webkit-user-select: none;

  .select2-selection__rendered {
    display: flex;
    align-items: center;
    height: calc(2rem + 8px);
    padding: .6rem 1rem;
    font-size: 1rem;
    line-height: 0;
    color: #091e42;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #c1c7d0;
    border-radius: 0.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    outline: none;
  }

  .select2-selection__clear {
    position: relative;
  }
}

&[dir="rtl"] {
  .select2-selection--single {
    .select2-selection__rendered {
      padding-right: 8px;
      padding-left: 20px;
    }
  }
}
