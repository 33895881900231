.select2-container--filter {
  @import "single";
  @import "multiple";

  input::placeholder {
    opacity: 1;
  }

  #select2-country-results {
    .select2-results__option {
      padding-left: 16px;
    }
  }

  .select2-dropdown.select2-dropdown--below {
    top: -3px;
  }
  .select2-search--dropdown {
    padding: 8px 8px 0;
    .select2-search__field {
      min-width: 100%;
      min-height: 40px;
      border: 2px solid #158AFF;
      border-radius: 4px;
      box-shadow: none;
      padding: 0 16px;
    }
  }

  .select2-search--inline {
    .select2-search__field {
      margin-top: 7px;
      background: transparent;
      border: none;
      outline: 0;
      box-shadow: none;
      -webkit-appearance: textfield;
      &::placeholder {
        color: #8993A4;
        &:focus {
          color: #C1C7D0;
        }
      }
    }
  }

  .select2-results > .select2-results__options {
    max-height: 200px;
    overflow-y: auto;
  }

  .select2-results__option {
    &[role="treeitem"] {
      margin: 8px;
      padding: 8px 40px;
      min-height: 40px;
      border-radius: 4px;
      //transition: all .2s;
      background-color: #f4f5f7;

      &:hover {
        background-color: #158AFF;
        color: #fff;
        .custom-checkbox .custom-control-label::before {
          border: solid 1px #fff;
        }
      }

      .select2-results__group {
        padding-left: 0;
      }
    }

    &[role=group] {
      //padding: 0;
    }

    &[aria-disabled=true] {
      color: #999;
    }

    &[aria-selected=true] {
      background-color: #f4f5f7;
      color: #091e42;
    }

    .select2-results__option {

      .select2-results__option {
        margin-left: -1em;
        padding-left: 2em;

        .select2-results__option {
          margin-left: -2em;
          padding-left: 3em;

          .select2-results__option {
            margin-left: -3em;
            padding-left: 4em;

            .select2-results__option {
              margin-left: -4em;
              padding-left: 5em;

              .select2-results__option {
                margin-left: -5em;
                padding-left: 6em;
              }
            }
          }
        }
      }
    }
  }

  .select2-results__option--highlighted[aria-selected] {
    //box-shadow: 0 2px 4px 0 rgba(23, 43, 77, 0.3);

    &:last-of-type {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .select2-results__group {
    display: block;
    padding: 8px 16px;
    margin: 8px;
    height: 40px;
    border-radius: 4px;
    background-color: #f4f5f7;
    font-weight: 400;
    position: relative;
    //transition: all .2s;

    .custom-checkbox .custom-control-label::before {
      z-index: 0;
    }

    &:hover {
      background-color: #158AFF;
      color: #fff;
      .custom-checkbox .custom-control-label::before {
        border: solid 1px #fff;
      }
      .toggle {
        background-image: url(/local/templates/1ci/i/icons/Common/003w.svg);
      }
    }

    .toggle {
      content: "";
      position: absolute;
      height: 5px;
      width: 8px;
      background-image: url("/local/templates/1ci/i/icons/Common/003.svg");
      background-repeat: no-repeat;
      background-size: contain;
      right: 16px;
      top: 18px;
      cursor: pointer;

      &.open {
        transform: rotate(180deg);
      }
    }

    &[aria-selected=true] {
      background-color: #158aff !important;
      color: #fff !important;

      .toggle {
        background-image: url("/local/templates/1ci/i/icons/Common/003w.svg");
      }
    }
  }

  .show:after {
    transform: rotate(0);
  }
}
