.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: $breadcrumb-padding-y $breadcrumb-padding-x;
  margin-bottom: $breadcrumb-margin-bottom;
  list-style: none;
}

.main-banner {
  @include breadcrumb-variant($breadcrumb-color, $breadcrumb-divider-color, $breadcrumb-divider);
}

.solution-main-banner,
.main-banner-blue,
.partner-banner--blue,
.g-bg-dark-grey-blue,
.g-bg-dark-indigo,
.g-bg-blue,
.g-bg-dark-blue {
  @include breadcrumb-variant($white, $white, $breadcrumb-divider-white);
}
