%owl-dots {
  // stylelint-disable selector-max-class
  // stylelint-disable selector-max-compound-selectors
  .owl-dots {
    height: auto;
    justify-content: center;
    position: absolute;
    width: 100%;
    margin: 0 -15px;
    padding: 0 30px;
    bottom: $spacer;
    .owl-dot.active span {
      background: $blue;
    }

    .owl-dot span {
      margin: 4px;
      width: 8px;
      height: 8px;
    }
  }
  // stylelint-enable selector-max-class
  // stylelint-enable selector-max-compound-selectors
}

%owl-nav {
  // stylelint-disable selector-max-class
  // stylelint-disable selector-max-compound-selectors
  .owl-nav {
    justify-content: space-between;
    margin: 0 -15px;

    [class*="owl-"] {
      margin: 0;
      padding: 0;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: $dark-gray;
      }
    }

    .owl-prev,
    .owl-next {
      position: absolute;
      top: 50%;
      width: 48px;
      height: 48px;
      background-color: $blue-gray;
      border-radius: 100%;
      outline: none;
    }

    .owl-prev {
      transform: translateY(-50%) scale(-1, 1);
      left: -10px;
    }

    .owl-next {
      right: -10px;
      transform: translateY(-50%);
    }
  }
  // stylelint-enable selector-max-class
  // stylelint-enable selector-max-compound-selectors
}
