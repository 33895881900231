.video-w-bg-block {
  padding-top: $spacer;

  &_row {
    justify-content: center;
  }

  &_controls {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &_image {
    width: 100%;
  }

  &_bg {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: auto;
  }

  &_play {
    width: 50px;
    height: 50px;

    img {
      @include transition($transition-base);
    }

    &:hover img {
      transform: scale(1.4);
    }
  }

  //with text app/local/blocks/1ci/9.1ci_video_button_on_bgimg_popup_2_text
  &_w_textcard {
    padding: $spacer*2 0 $spacer*3;
    background-color: $gray-300;
  }
  &_before_text {
    margin-bottom: $spacer*2.25;
    @include media-breakpoint-up("md") {
      margin-bottom: 0;
    }
  }
  &_card {
    .card-wrapper .video-w-bg-block_card--wrapper:first-child {
      @include media-breakpoint-up("sm") {
        padding-top: 0;
      }
    }
    &--wrapper {
      padding: $spacer*.75 0;
    }
    &-item {
      position: relative;
      padding-left: $spacer*2;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 24px;
        height: 24px;
        background: url("/local/templates/1ci/i/icons/Common/005s.svg") no-repeat 0 0;
      }
    }
  }
  &_buttonblock {
    margin-top: $spacer*.75;
    @include hover-btn-arrow($link-color, $link-hover-color);
    .btn {
      padding-left: $spacer*2;
    }
  }
}
