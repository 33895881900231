.filter {
  &_title {
    text-transform: uppercase;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    font-size: $font-size-xs;
    letter-spacing: .7px;
    color: $dark-indigo;
  }

  &_item {
    margin-top: $spacer*1.25;

    &-label {
      display: block;
      font-family: $font-family-sans-serif;
      font-size: $font-size-sm;
      color: $dark-indigo;
    }

    &-input {
      width: 100%;
      height: 40px;
      border-radius: $border-radius;
      border: solid 1px $gray-600;
      padding: $spacer*.375 $spacer;
    }

    &-hidden {
      display: none;
      @include media-breakpoint-up("sm") {
        display: block;
      }
    }
  }

  &_buttonblock {
    margin: $spacer 0 0;

    &--button {
      margin-right: 4px;
    }

    @include hover-btn-arrow($link-color, $link-hover-color);
    @include media-breakpoint-up("sm") {
      display: none;
    }
  }
}
