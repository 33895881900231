// Base class
.tooltip {
  position: absolute;
  z-index: $zindex-tooltip;
  display: block;
  margin-top: $tooltip-margin-top;
  // Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
  // So reset our font and text properties to avoid inheriting weird values.
  @include reset-text();
  font-size: $tooltip-font-size;
  // Allow breaking very long words so they don't overflow the tooltip's bounds
  word-wrap: break-word;
  opacity: 0;

  &.show {
    opacity: $tooltip-opacity;
    margin-top: $tooltip-margin-top;
    margin-bottom: $tooltip-margin-top;
  }

  .arrow {
    position: absolute;
    display: block;
    width: $tooltip-arrow-width;
    height: $tooltip-arrow-height;

    &::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid;
    }
  }
}

.bs-tooltip-top {
  padding: $tooltip-arrow-height 0;

  .arrow {
    bottom: 0;
    width: 16px;

    &::before {
      bottom: 1px;
      left: 3px;
      content: "";
      position: absolute;
      background: $tooltip-bg;
      border: 5px solid transparent;
      transform: rotate(45deg);
      box-shadow: 1px 1px 5px 0 $tooltip-box-shadow-arrow;
      z-index: 1;
      width: 12px;
      height: 12px;
      @include border-radius($tooltip-border-radius);
    }
    &::after {
      content: "";
      position: absolute;
      z-index: 2;
      background: $tooltip-bg;
      width: 130%;
      height: 130%;
      top: -130%;
    }
  }
}

.bs-tooltip-right {
  padding: 0 $tooltip-arrow-height;

  .arrow {
    left: 0;
    width: $tooltip-arrow-height;
    height: $tooltip-arrow-width;

    &::before {
      right: 0;
      border-width: ($tooltip-arrow-width / 2) $tooltip-arrow-height ($tooltip-arrow-width / 2) 0;
      border-right-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-bottom {
  padding: $tooltip-arrow-height 0;

  .arrow {
    top: 0;
    width: 16px;

    &::before {
      top: 1px;
      left: 3px;
      content: "";
      position: absolute;
      background: $tooltip-bg;
      border: 5px solid transparent;
      transform: rotate(225deg);
      box-shadow: 1px 1px 5px 0 $tooltip-box-shadow-arrow;
      z-index: 1;
      width: 12px;
      height: 12px;
      @include border-radius($tooltip-border-radius);
    }
    &::after {
      content: "";
      position: absolute;
      z-index: 2;
      background: $tooltip-bg;
      width: 130%;
      height: 130%;
      bottom: -130%;
    }
  }
}

.bs-tooltip-left {
  padding: 0 $tooltip-arrow-height;

  .arrow {
    right: 0;
    width: $tooltip-arrow-height;
    height: $tooltip-arrow-width;

    &::before {
      left: 0;
      border-width: ($tooltip-arrow-width / 2) 0 ($tooltip-arrow-width / 2) $tooltip-arrow-height;
      border-left-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-auto {
  &[x-placement^="top"] {
    @extend .bs-tooltip-top;
  }
  &[x-placement^="right"] {
    @extend .bs-tooltip-right;
  }
  &[x-placement^="bottom"] {
    @extend .bs-tooltip-bottom;
  }
  &[x-placement^="left"] {
    @extend .bs-tooltip-left;
  }
}

// Wrapper for the tooltip content
.tooltip-inner {
  max-width: $tooltip-max-width;
  min-width: $tooltip-min-width;
  padding: $tooltip-padding;
  color: $tooltip-color;
  text-align: center;
  background-color: $tooltip-bg;
  line-height: 1;
  box-shadow: 0 0 5px 0 $tooltip-box-shadow;
  @include border-radius($tooltip-border-radius);
}
