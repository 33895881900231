// Hover mixin and `$enable-hover-media-query` are deprecated.
//
// Originally added during our alphas and maintained during betas, this mixin was
// designed to prevent `:hover` stickiness on iOS-an issue where hover styles
// would persist after initial touch.
//
// For backward compatibility, we've kept these mixins and updated them to
// always return their regular pseudo-classes instead of a shimmed media query.
//
// Issue: https://github.com/twbs/bootstrap/issues/25195

@mixin hover {
  &:hover {
    @content;
  }
}

@mixin hover-focus {
  &:hover,
  &:focus {
    @content;
  }
}

@mixin plain-hover-focus {
  &,
  &:hover,
  &:focus {
    @content;
  }
}

@mixin hover-focus-active {
  &:hover,
  &:focus,
  &:active {
    @content;
  }
}

@mixin hover-focus-after {
  &:hover,
  &:focus {
    @content;

    &::after {
      @content;
    }
  }
}

@mixin hover-footer-links {
  &::before {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: currentColor;
    opacity: 0;
    transition: opacity .2s;
  }

  &:hover::before {
    opacity: 1;
  }
}

@mixin hover-btn-arrow($fill-property, $fill-hover-property) {
  [class*="_button"],
  [class*="_btn"] {
    font-weight: $font-weight-bold;
    padding: 0;
  }

  [role="button"] + .button-icon {
    padding-left: 0;
    margin-left: -3px;
    margin-top: 2px;
    @include transition($transition-base);

    &.plus {
      transform: translateX(3px);
    }

    .arrow,
    .http,
    .plus {
      fill: $fill-property;
      @include transition($transition-base);
    }
  }

  [role="button"]:hover + .button-icon {
    transform: translateX(3px);

    .arrow,
    .http,
    .plus {
      fill: $fill-hover-property;
    }
  }
  [role="button"]:hover + .browser-link {
    transform: none;
  }
}
