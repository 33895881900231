// app/local/blocks/1ci/11.1ci_company_benefits_block
.company-benefits {
  background-color: transparent;
  padding: $spacer 0;

  &_card {
    margin-bottom: $spacer*2;
    @include media-breakpoint-up("md") {
      margin-bottom: 0;
    }
  }

  &_block {
    margin-left: $spacer*2;
    margin-bottom: 0;
    @include media-breakpoint-up("md") {
      margin-bottom: $spacer*2;
      &--partner {
        margin-bottom: $spacer;
      }
    }

    &::before {
      content: "";
      position: absolute;
      left: $spacer;
      top: 0;
      width: 24px;
      height: 24px;
      background: transparent url("/local/templates/1ci/i/icons/dot.svg") center no-repeat;
      @include media-breakpoint-up("sm") {
        left: $spacer/2;
      }
    }
  }

  &_text {
    color: $dark-gray;

    &--partner {
      font-size: $font-size-m;
      color: $dark-indigo;
    }
  }
}

// app/local/blocks/1ci/13.1ci_company_about_block_grid
.company-grid {
  background-color: $gray-300;

  &_container {
    display: grid;
    grid-gap: $spacer/2;
    grid-template: "text text text" "post-2 post-2 post-2" "post-3 post-3 post-4" "post-6 post-5 post-5" "post-7 post-7 post-8";
    grid-template-columns: 30% auto 30%;
    grid-template-rows: auto 240px repeat(3, 200px);

    //Iphone 5s
    @media screen and (max-width: 400px) {
      grid-template: "text text text" "post-2 post-2 post-2" "post-3 post-3 post-4" "post-6 post-5 post-5" "post-7 post-7 post-8";
      grid-template-columns: 88px auto 88px;
      grid-template-rows: auto 184px repeat(3, 128px);
    }
    //Iphone 5s

    @include media-breakpoint-up("sm") {
      grid-template-rows: auto 272px repeat(3, 224px);
    }
    @include media-breakpoint-up("md") {
      grid-template: "text text post-2 post-2 post-2" "post-3 post-3 post-4 post-5 post-5" "post-6 post-7 post-7 post-7 post-8";
      grid-template-columns: 160px 124px 160px auto 160px;
      grid-template-rows: 272px repeat(2, 224px);
    }
    @include media-breakpoint-up("lg") {
      grid-template-columns: repeat(3, 160px) auto 160px;
      grid-template-rows: 328px repeat(2, 224px);
    }
    @include media-breakpoint-up("xl") {
      grid-template-columns: 160px 204px 160px auto 160px;
    }
  }

  .grid-item {
    background-color: $white;
    &_img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    &_text {
      margin: 0;
      @include media-breakpoint-down("sm") {
        padding: $spacer/2 0 $spacer*1.5;
      }
      @include media-breakpoint-up("md") {
        width: min-content;
      }
    }

    &:nth-child(1) {
      grid-area: text;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      @include media-breakpoint-down("sm") {
        justify-content: flex-start;
      }
      @include media-breakpoint-up("md") {
        background-color: $white;
      }
    }

    &:nth-child(2) {
      grid-area: post-2;
      .grid-item_img {
        object-position: top;
      }
    }

    &:nth-child(3) {
      grid-area: post-3;
      .grid-item_img {
        object-position: left top;
      }
    }

    &:nth-child(4) {
      grid-area: post-4;
    }

    &:nth-child(5) {
      grid-area: post-5;
    }

    &:nth-child(6) {
      grid-area: post-6;
    }

    &_double {
      grid-area: post-7;
      display: grid;
      grid-gap: $spacer/2;
      grid-template: "image-1 image-3 image-3";
      @include media-breakpoint-down("xs") {
        grid-template-columns: 42.8% auto;
        grid-template-rows: 200px;
      }

      //Iphone 5s
      @media screen and (max-width: 400px) {
        grid-template-columns: 88px auto;
        grid-template-rows: 128px;
      }
      //Iphone 5s

      @include media-breakpoint-up("sm") {
        grid-template-columns: 42.8% auto;
        grid-template-rows: 224px;
      }

      @include media-breakpoint-up("md") {
        grid-template-columns: 160px auto auto;
        grid-template-rows: 224px;
      }

      .grid-item:first-child {
        grid-area: image-1;
      }

      .grid-item:last-child {
        grid-area: image-3;
        .grid-item_img {
          object-position: 90%;
        }
      }
    }

    &:nth-child(8) {
      grid-area: post-8;
    }
  }
}

// app/local/blocks/1ci/11.1ci_company_about_block
.company-about {
  margin: 0;
  padding: $spacer*3.5 0 $spacer*4;
  background-color: $gray-300;

  &_image {
    width: 100%;
  }
}

// app/local/blocks/1ci/11.1ci_company_contacts_block
.company-contacts {
  margin: 0 0 $spacer*2;
  background-color: transparent;

  &_icon-block {
    text-align: center;
  }

  &_icon {
    padding-bottom: $spacer/2;
  }

  &_header {
    text-align: center;
    font-size: $display5-size;
  }
}

// app/local/blocks/1ci/11.1ci_contacts_block_w_local
// app/local/blocks/1ci/11.1ci_contacts_block_wo_local
.contacts {
  padding: $spacer*1.875 0 $spacer;

  @include hover-btn-arrow($link-color, $link-hover-color);

  &_header {
    text-align: center;
    margin-bottom: $spacer;
  }

  &_block {
    display: flex;
    flex-direction: column;
    margin-bottom: $spacer*2;
  }

//  &_value-phone a {
//    color: $dark-indigo;
//  }

  &_block-title {
    display: flex;
    align-items: center;
    padding: $spacer*1.5;
    border-top: 1px solid $gray-500;
    border-left: 1px solid $gray-500;
    border-right: 1px solid $gray-500;
    @include border-top-radius($border-radius);
  }

  &_local {
    margin-bottom: $spacer*2;

    &-row {
      padding: $spacer $spacer*1.5;
      @include media-breakpoint-down("md") {
        flex-direction: column;
        align-items: flex-start;
        max-height: 81px;
      }
      @include media-breakpoint-up("lg") {
        display: flex;
        align-items: center;
      }
    }
  }

  &_block-body {
    flex: 1;
    padding: 0 $spacer*1.5 $spacer*1.5;
    border: 1px solid $gray-500;
    @include border-bottom-radius($border-radius);
  }

  &_block-body--row {
    @include media-breakpoint-up("lg") {
      display: flex;
      &_item {
        margin-right: $spacer*3.5;
      }
    }
  }

  &_local-block {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid $gray-500;
    padding: $spacer/2 0 $spacer;
    @include border-radius($border-radius);

    @include media-breakpoint-up("lg") {
      padding: $spacer*1.5 0;
    }

    &-item {
      padding: 0 $spacer*1.5;
      @include media-breakpoint-down("md") {
        &:first-child {
          border-bottom: 1px solid $gray-500;
          padding-bottom: $spacer;
        }
      }
    }
  }

  &_icon {
    float: left;
    width: 32px;
    height: 32px;
  }

  &_local-title {
    margin: 0 $spacer 0 0;
    @include media-breakpoint-up("lg") {
      display: flex;
      align-items: center;
    }
  }

  &_title {
    margin-bottom: 0;
    margin-left: $spacer;
  }

  &_label,
  &_local-label,
  &_label-email,
  &_label-address,
  &_label-phone,
  &_local-label-email,
  &_local-label-address,
  &_local-label-phone {
    margin-top: $spacer;
    color: $dark-gray;
    font-size: $font-size-m;
    line-height: 1.4;
  }

  &_value,
  &_local-value,
  &_value-email,
  &_value-address,
  &_value-phone,
  &_local-value-email,
  &_local-value-address,
  &_local-value-phone {
    color: $dark-indigo;
    font-size: $font-size-m;
    line-height: 1.4;
  }
}

/**
 * career
 */
.career {
  .mission-info {
    position: relative;
    min-height: 104px;
    padding: 0 40px 0 100px;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background: transparent url("/local/templates/1ci/i/company/careers/tab-bg-left.png") 0 0 no-repeat;
      width: 95px;
      height: 104px;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      background: transparent url("/local/templates/1ci/i/company/careers/tab-bg-right.png") 0 0 no-repeat;
      width: 32px;
      height: 32px;
    }
  }

  .item {
    border: solid 1px $gray-500;
    padding: 24px;

    .top-info {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: solid 1px $gray-500;

      .section {
        text-transform: uppercase;
        color: $dark-gray;

        font-size: 12px;
        line-height: 16px;
      }
      .location {
        background: transparent url("/local/templates/1ci/i/icons/location.svg") left center no-repeat;

        text-align: right;
        font-size: 14px;
        line-height: 16px;
        padding-left: 20px;
      }
    }

    .title {
      font-size: 21px;
      line-height: 24px;
      padding-bottom: 8px;
    }
    .link {
      a {
        display: inline-block;

        background: transparent url("/local/templates/1ci/i/icons/arrow.svg") right center no-repeat;
        padding-right: 20px;

        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
