// Base class
//
// Requires one of the contextual, color modifier classes for `color` and
// `background-color`.
.badge {
  display: inline-block;
  padding: $spacer*.25 $spacer*.5;
  font-size: $font-size-sm;
  font-weight: 400;
  line-height: .8;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  text-transform: uppercase;
  border-radius: $border-radius;
  border: solid 2px currentColor;

  // Empty badges collapse automatically
  &:empty {
    display: none;
  }
}

.badge_new {
  display: inline-block;
  font-size: $badge-font-size;
  color: $dark-gray;
  font-weight: $badge-font-weight;
  line-height: 1.14;
  vertical-align: baseline;
  text-transform: uppercase;

  // Empty badges collapse automatically
  &:empty {
    display: none;
  }
}

// Quick fix for badges in buttons
.btn .badge {
  position: relative;
  top: -1px;
}

// Pill badges
//
// Make them extra rounded with a modifier to replace v3's badges.

.badge-pill {
  padding-right: $badge-pill-padding-x;
  padding-left: $badge-pill-padding-x;
  @include border-radius($badge-pill-border-radius);
}

// Colors
//
// Contextual variations (linked badges get darker on :hover).

@each $color, $value in $badge-variants {
  .badge-color-#{$color} {
    @include badge-variant($value);
  }
}

