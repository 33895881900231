@keyframes progress-bar-stripes {
  from {
    background-position: $progress-height 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: $progress-width;
  overflow: hidden;
  font-size: $progress-font-size;
  background-color: transparent;

  @include media-breakpoint-up("lg") {
    width: $progress-width;
    height: $progress-height;
    flex-direction: column-reverse;
  }
}

.progress-bar {
  height: $progress-width;
  display: flex;
  justify-content: left;
  color: $progress-bar-color;
  white-space: nowrap;
  background-color: $progress-bar-bg;
  line-height: 1.41;
  font-size: $font-size-m;
  font-weight: $font-weight-boldest;
  align-items: center;
  @include transition($progress-bar-transition);

  @include media-breakpoint-up("lg") {
    justify-content: center;
    width: 100%;
  }
}

.progress-bar-striped {
  @include gradient-striped();
  background-size: $progress-height $progress-height;
}

.progress-bar-animated {
  animation: progress-bar-stripes $progress-bar-animation-timing;
}
