//app/local/blocks/1ci/13.1ci_main_slider
.main-slider {
  background-size: cover;
  background-position: center;
  position: relative;
  background-color: $dark-grey-blue;
  padding-top: $spacer;

  .bg__image {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center;
  }

  @include media-breakpoint-up("sm") {
    padding-top: $spacer*2.5;
  }
  @include media-breakpoint-up("lg") {
    padding-top: $spacer*4;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 96px;
    width: 100%;
    background-color: $white;
    @include media-breakpoint-up("sm") {
      height: 128px;
    }
    @include media-breakpoint-up("lg") {
      height: 48px;
    }
  }

  .owl-carousel {
    .owl-stage {
      display: flex;

      .owl-item {
        flex-basis: auto;
        flex-shrink: 0;
      }
    }

    .container {
      height: 100%;

      .row {
        height: 100%;
      }
    }

    .slide {
      &-text {
        color: $white;
        @include media-breakpoint-up("lg") {
          padding-bottom: $spacer*4;
        }
      }

      &-headline {
        color: $white;
        @include media-breakpoint-up("md") {
          margin-bottom: $spacer;
        }

        &_suptext {
          font-family: $headings-font-family;
          font-size: $font-size-xs;
          font-weight: $display1-weight;
          line-height: 1.33;
          letter-spacing: .7px;
          margin-top: $spacer;
          margin-bottom: $spacer/2;

          &--bulb {
            position: relative;

            &::before {
              content: "";
              position: relative;
              display: inline-block;
              width: 8px;
              height: 8px;
              border-radius: 100%;
              box-shadow: 0 0 8px 0 $yellow;
              background-color: $yellow;
              margin-right: $spacer/2;
            }
          }
        }

        &_subtext {
          font-size: $display5-size;
          line-height: 1.45;
          margin-bottom: $spacer*1.5;
          @include media-breakpoint-up("lg") {
            margin-bottom: $spacer;
          }
        }
      }

      &-date,
      &-time,
      &-lang {
        display: flex;
        align-items: center;
        @include media-breakpoint-down("xs") {
          margin-bottom: $spacer/2;
        }

        &_wrapper {
          margin: $spacer*1.5 0;
          @include media-breakpoint-down("xs") {
            display: block;
          }
          @include media-breakpoint-up("sm") {
            display: flex;
          }
        }

        &_text {
          display: flex;
          align-items: center;
          font-size: $font-size-m;
          line-height: 1.41;
          margin-right: $spacer*1.5;
          margin-bottom: 0;
        }

        &_icon {
          height: 16px;
          width: 16px;
          margin-right: $spacer/2;
        }
      }

      &-button {
        margin-bottom: $spacer*4.5;
      }

      &-image {
        position: relative;
        margin: 0 auto;
        max-height: 440px;
        bottom: 0;
        z-index: 2;

        &_wrapper {
          padding: 0;
          display: flex;
          align-items: flex-end;
          @include media-breakpoint-up("sm") {
            padding: 0 15px;
          }
          @include media-breakpoint-only("md") {
            margin: 0 auto;
            padding: 0;
          }
          @include media-breakpoint-up("lg") {
            min-height: 440px;
            padding: 0 15px;
          }
        }
      }
    }
  }

  // stylelint-disable selector-max-compound-selectors
  .owl-dots {
    position: absolute;
    display: flex;
    height: 8px;
    padding: 0 15px;
    transform: translateY(-24px);
    @include media-breakpoint-up("sm") {
      width: 540px;
      left: 50%;
      transform: translateX(-50%);
      bottom: $spacer*27;
    }
    @include media-breakpoint-up("md") {
      width: 720px;
      justify-content: center;
      bottom: $spacer*29;
    }
    @include media-breakpoint-up("lg") {
      width: 960px;
      justify-content: flex-start;
      transform: translateX(-50%) translateY(-40px);
      bottom: $spacer*4.5;
    }
    @include media-breakpoint-up("xl") {
      width: 1140px;
    }

    .owl-dot {
      &.active span {
        background-color: $white;
      }

      span {
        display: block;
        height: 8px;
        width: 8px;
        border: 1px solid $white;
        border-radius: 100%;
        margin: 0 2px;
        background-color: transparent;
      }
    }
  }
}

// app/local/blocks/1ci/12.1ci_blog_slider
.blog-slider {
  padding: $spacer*3 0 $spacer*4.5;

  .owl-carousel {
    .owl-stage {
      display: flex;
    }

    .owl-item {
      flex-basis: auto;
      flex-shrink: 0;
    }

    .owl-nav {
      display: none;
    }

    .owl-dots {
      margin-top: $spacer/2;
    }

    .owl-dot {
      outline: none;

      span {
        margin: 0 $spacer/4;
        height: 8px;
        width: 8px;
        background-color: $gray-500;
      }

      &.active span {
        background-color: $blue;
      }
    }

    // stylelint-enable selector-max-compound-selectors

    .slide {
      height: 100%;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      @include media-breakpoint-up("sm") {
        width: 330px;
      }

      &_head {
        position: relative;
        margin-bottom: $spacer;
        @include media-breakpoint-only("xs") {
          display: flex;
          flex-direction: column;
        }
        @include media-breakpoint-up("sm") {
          width: 330px;
          height: 296px;
        }
        @include media-breakpoint-up("lg") {
          width: 350px;
          height: 272px;
        }
      }

      &_picture {
        width: 88%;

        &--img {
          width: 100%;
        }

        @include media-breakpoint-only("xs") {
          align-self: flex-end;
          margin-bottom: 70px;
          &--img {
            object-fit: cover;
          }
        }
        @include media-breakpoint-up("sm") {
          width: 300px;
          height: 224px;
          float: right;
        }
        @include media-breakpoint-up("lg") {
          width: 310px;
          height: 224px;
        }
      }

      &_subtext {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: $spacer $spacer*1.5;
        border-radius: $border-radius;
        width: 258px;
        height: 128px;
        color: $white;
        margin-bottom: 0;
        opacity: .9;
        @include media-breakpoint-only("xs") {
          width: 90%;
        }
        @include media-breakpoint-up("sm") {
          width: 300px;
        }
        @include media-breakpoint-up("lg") {
          width: 310px;
          height: 104px;
        }
      }

      &_text {
        color: $dark-gray;
        font-family: $font-family-sans-serif;
        font-size: $font-size-m;
        line-height: 1.41;
        margin-bottom: $spacer/2;
      }

      &_action {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: auto;

        &--time {
          color: $dark-gray;
          font-family: $headings-font-family;
          font-size: $font-size-xs;
          font-weight: $font-weight-semibold;
          line-height: 1.33;
          letter-spacing: .7px;
          margin-bottom: 0;
        }
      }

      &_buttonblock {
        display: flex;
        align-items: center;

        &--link {
          font-family: $font-family-sans-serif;
          font-size: $font-size-base;
          line-height: 1.5;
          margin-right: 4px;
        }

        &--img {
          width: 16px;
          height: 16px;
        }

        @include hover-btn-arrow($link-color, $link-hover-color);
      }
    }
  }
}

.comments-slider,
.comment-static,
.testimonial-slider {

  .owl-carousel .owl-stage,
  .owl-carousel.owl-drag .owl-item {
    touch-action: auto;
  }

  .container {
    margin-top: $spacer*1.5;
    margin-bottom: $spacer*1.125;
    @include media-breakpoint-down("sm") {
      background-color: $gray-300;
      margin-bottom: 0;
    }
  }

  .slide {
    min-height: 256px;
    display: flex;
    background-color: $gray-300;
    flex-direction: column;
    padding: $spacer*2 $spacer;
    border-radius: 4px;
    @include media-breakpoint-up("sm") {
      border-radius: 4px;
      padding: $spacer*2.5 $spacer*2.625 $spacer*2.375;
    }
    @include media-breakpoint-up("md") {
      padding: $spacer*2 $spacer*4;
    }

    h3,
    p {
      width: 100%;
      text-align: left;
      word-break: break-word;
    }

    &_title {
      opacity: .9;
      font-family: $headings-font-family;
      font-size: $font-size-xs;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      color: $dark-indigo;
      text-transform: uppercase;
      margin-bottom: 0;
    }

    &_comment {
      font-family: $font-family-sans-serif;
      font-size: $font-size-xxs * 2;
      line-height: 1.45;
      color: #091e42;
      margin: 24px 0;
    }

    &_author {
      width: max-content;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      p {
        font-family: $headings-font-family;
        font-size: $font-size-xs;
        font-style: italic;
        line-height: 1.33;
        color: $dark-indigo;
        margin-bottom: 0;
      }
    }
  }

  .wrapper {
    &::before {
      content: "";
      position: absolute;
      top: -24px;
      left: 0;
      height: 48px;
      width: 59px;
      background: url("/local/templates/1ci/i/icons/Quote.svg") center center no-repeat;
      background-size: contain;
      z-index: 1;
      @include media-breakpoint-up("sm") {
        left: 26px;
      }
      @include media-breakpoint-up("md") {
        left: 48px;
      }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: -18px;
      right: 48px;
      height: 72px;
      width: 73px;
      background: url("/local/templates/1ci/i/icons/Bubble.svg") center center no-repeat;
      background-size: contain;
      z-index: 1;
      display: none;
      @include media-breakpoint-up("md") {
        display: block;
      }
    }
  }
}

//app/local/blocks/1ci/12.1ci_comment_slider_new
.testimonial-slider {
  .wrapper {
    &::before {
      left: auto;
      right: 16px;
      @include media-breakpoint-up("md") {
        right: 48px;
      }
      @include media-breakpoint-up("lg") {
        right: 56px;
      }
    }

    &::after {
      left: 16px;
      height: 48px;
      width: 59px;
      background: url("/local/templates/1ci/i/icons/Quote.svg") center center no-repeat;
      background-size: contain;
      display: block;
      @include media-breakpoint-up("md") {
        left: 48px;
      }
      @include media-breakpoint-up("lg") {
        left: 56px;
      }
    }
  }

  .owl-carousel {
    .owl-stage {
      display: flex;
    }

    .owl-item {
      flex-basis: auto;
      flex-shrink: 0;
    }

    @extend %owl-dots;
    @extend %owl-nav;

    .slide {
      padding: $spacer*3 $spacer*0 $spacer*4;
      flex-direction: column-reverse;
      align-items: flex-start;
      justify-content: flex-end;
      min-height: 216px;
      height: 100%;
      @include media-breakpoint-up("md") {
        flex-direction: row;
        align-items: stretch;
        align-content: stretch;
        padding: $spacer*3 $spacer*2 $spacer*4;
      }
      @include media-breakpoint-up("lg") {
        padding: $spacer*3 $spacer*6 $spacer*4;
      }

      &_comment {
        margin: 0;
        font-size: $font-size-base;
        width: 100%;
        line-height: 24px;
        @include media-breakpoint-up("md") {
          border-right: 1px solid $gray-600;
          padding-right: 30px;
        }
        @include media-breakpoint-only("md") {
          display: flex;
          align-items: center;
        }
      }

      &_author {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding-bottom: 24px;
        min-width: 254px;
        @include media-breakpoint-up("md") {
          padding: 16px 0;
          align-self: center;
        }

        &__about {
          display: flex;
          flex-direction: row;
        }

        &__avatar {
          width: 72px;
          height: 72px;
          border-radius: 100%;
          margin-right: 24px;
          overflow: hidden;
          @include media-breakpoint-up("md") {
            margin: 0 24px;
          }

          &--image {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }

        &__country {
          text-transform: uppercase;
          font-style: normal;
          font-weight: $font-weight-semibold;

          &--flag {
            height: 15px;
            width: 21px;
            min-height: 15px;
            min-width: 21px;
            margin-right: $spacer/2;
            margin-bottom: $spacer/2;
          }
        }

        &__work {
          white-space: nowrap;
        }
      }
    }
  }
}

// app/local/blocks/1ci/12.1ci_comment_slider
.comments-slider {

  .owl-carousel {
    @extend %owl-dots;
    @extend %owl-nav;
  }
}

//app/local/blocks/1ci/15.1ci_newsroom
.newsroom__slider {
  .owl-carousel {
    .owl-stage {
      display: flex;
      height: 100%;
    }

    .owl-item {
      flex-basis: auto;
      flex-shrink: 0;

      img {
        height: 16px;
        width: 16px;
      }
    }

    @extend %owl-dots;
    @extend %owl-nav;

    .slide {
      padding-bottom: $spacer*4;
      min-height: 184px;
      @include media-breakpoint-down("sm") {
        display: flex;
        flex-direction: column;
      }

      &-info {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
  }

  .slide {
    padding: $spacer*3 $spacer;
    height: 100%;
    @include media-breakpoint-up("md") {
      padding-left: $spacer*2;
      padding-right: $spacer*2;
      display: flex;
    }
    @include media-breakpoint-up("lg") {
      padding-left: 95px;
      padding-right: 95px;
    }

    &-additional {
      @include media-breakpoint-up("md") {
        padding-top: $spacer/2;
        margin-right: 30px;
        min-width: 206px;
      }
      @include media-breakpoint-up("lg") {
        margin-right: 46px;
      }

      &_wrapper {
        display: flex;
        align-items: center;

        &:first-child {
          margin-bottom: $spacer;
          @include media-breakpoint-up("md") {
            margin-bottom: $spacer*1.5;
          }
        }

        &:last-child {
          @include media-breakpoint-down("sm") {
            margin-bottom: $spacer*1.5;
          }
        }
      }

      &_img {
        height: 16px;
        width: 16px;
        margin-right: $spacer/2;
      }

      &_text {
        font-family: $headings-font-family;
        font-size: $font-size-xs;
        font-weight: $font-weight-semibold;
        line-height: 16px;
        letter-spacing: $spacer*.03;
        text-transform: uppercase;
        margin-bottom: 0;
      }
    }

    &-info {
      @include media-breakpoint-up("md") {
        padding-left: 30px;
        padding-bottom: $spacer*.25;
        border-left: 1px solid $gray-600;
      }
    }

    &-title {
      margin: $spacer/2 0;
      @include media-breakpoint-up("md") {
        margin-top: 0;
      }
    }

    &-btnblock {
      margin-top: auto;
      @include hover-btn-arrow($link-color, $link-hover-color);
    }
  }
}

//app/local/blocks/1ci/16.1ci_career_slider
.career__slider {
  background-size: cover;
  background-position: center;
  overflow: hidden;

  .owl-carousel {
    margin-bottom: $spacer*2;

    &::after {
      content: "";
      width: calc(100% - 30px);
      height: 100%;
      position: absolute;
      top: $spacer*2;
      left: $spacer*4.5;
      background-color: $dark-indigo;
      border-radius: $border-radius/2;
      z-index: -1;
    }
    &::before {
      content: "";
      position: absolute;
      background-image: url("/local/templates/1ci/i/career/Quotes-circle.svg");
      width: 319px;
      height: 319px;
      z-index: 2;
      bottom: -250px;
      left: 60%;
      @include media-breakpoint-up("md") {
        left: auto;
        right: -36px;
      }
      @include media-breakpoint-up("lg") {
        left: 80px;
        right: auto;
      }
      @include media-breakpoint-up("xl") {
        left: 110px;
      }
    }

    .owl-stage {
      display: flex;
    }
    .owl-item {
      flex-basis: auto;
      flex-shrink: 0;
    }
    .owl-nav {
      @include media-breakpoint-down("sm") {
        position: absolute;
        top: 150px;
        left: 30px;
        width: calc(100% - 30px);
      }
      @include media-breakpoint-down("xs") {
        top: 124px;
      }
    }

    @extend %owl-nav;
    @extend %owl-dots;
  }

  .slide__shadow {
    position: relative;

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: $spacer*2;
      left: $spacer*3.5;
      background-color: $dark-indigo;
      z-index: -1;
    }
  }

  .slide {
    padding: $spacer*1.5 $spacer $spacer*4;
    background-color: $white;
    border-radius: $border-radius/2;
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    @include media-breakpoint-up("md") {
      padding: $spacer*2.5 $spacer*2.5 $spacer*4;
      justify-content: space-between;
      flex-direction: row;
    }
    @include media-breakpoint-up("lg") {
      padding: $spacer*2.5 $spacer*3.5 $spacer*4;
    }

    &-text {
      &_quote {
        margin-bottom: 0;
      }

      &_icon {
        max-width: 56px;
        min-width: 56px;
        height: 56px;
        margin-bottom: $spacer;
      }
    }

    &-author {
      @include media-breakpoint-up("md") {
        margin-left: $spacer*1.5;
      }
      @include media-breakpoint-up("lg") {
        margin-left: $spacer*2;
      }

      &_image {
        object-fit: cover;
        margin-bottom: $spacer;
        height: 160px;
        width: 100%;
        @include media-breakpoint-down("xs") {
          height: 200px;
        }
        @include media-breakpoint-only("sm") {
          height: 250px;
        }
        @include media-breakpoint-up("md") {
          height: 160px;
          max-width: 169px;
          min-width: 169px;
        }
        @include media-breakpoint-up("lg") {
          height: 256px;
          max-width: 294px;
          min-width: 294px;
        }
      }

      &_text {
        font-size: $font-size-sm;
      }
    }
  }
}
