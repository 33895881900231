// app/local/blocks/1ci/9_1ci_partners_list_block_white
.partners__new {

  &-text_integrator {
    margin-bottom: $spacer/2;
    color: $badge-orange;
    font-family: $headings-font-family;
    font-size: $font-size-xs;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: $spacer*.05;
    font-weight: 500;
  }

  &-item {
    padding: $spacer*2 $spacer;
    margin-bottom: $spacer*2.5;
    border-radius: $border-radius/2;
    border: 1px solid $gray-600;
    @include media-breakpoint-up("md") {
      padding: $spacer*2 $spacer*2.5;
    }
  }

  &-hidesection {
    display: none;
  }

  &-wrapper {
    display: grid;
    grid-template: "text" "footer" "additional";
    @include media-breakpoint-up("lg") {
      grid-template: "text additional" "footer footer";
      grid-template-columns: auto 350px;
    }
  }

  &-text {
    grid-area: text;
    height: 100%;
    @include media-breakpoint-up("lg") {
      border-right: 1px solid $gray-600;
      padding-right: $spacer*2;
      margin-right: $spacer*2;
    }

    &_title {
      margin-bottom: $spacer;
    }

    &_suptitle {
      font-family: $font-family-sans-serif;
      font-size: $font-size-base;
      line-height: 24px;
      color: $dark-gray;
      margin-bottom: 0;
    }
  }

  &-additional {
    margin-top: $spacer;
    grid-area: additional;

    @include media-breakpoint-down("md") {
      padding-top: $spacer*2;
      margin-top: $spacer*2;
      border-top: 1px solid $gray-600;
    }

    &_title {
      font-family: $headings-font-family;
      font-weight: 500;
      font-size: $font-size-xs;
      line-height: 16px;
      letter-spacing: .05em;
      text-transform: uppercase;
    }

    &_props {
      margin-bottom: 0;
      display: flex;

      &-left,
      &-right {
        font-family: $font-family-sans-serif;
        font-size: $font-size-base;
        line-height: 24px;
        margin-bottom: 0;
      }

      &-left {
        margin-right: $spacer;
        min-width: 58px;
      }

      &-right {
        color: $dark-gray;

        a {
          word-break: break-all;
        }

        a:hover::after {
          filter: brightness(80%);
        }

        [href^="http"]::after {
          content: "";
          display: inline-block;
          width: 1rem;
          height: .75rem;
          text-indent: 1rem;
          white-space: nowrap;
          overflow: hidden;
          background-image: url("/local/templates/1ci/i/icons/Common/002-1.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 100%;
          margin-left: .2rem;
          transform: translateY(1px);
        }
      }
    }
  }

  &-emptyblock {
    margin-bottom: $spacer*2;
    &.hidden {
      display: none;
    }
  }

  &-buttonblock {
    @include hover-btn-arrow($link-color, $link-hover-color);

    &_button {
      padding: 0;
      margin-right: 4px;
      font-size: $font-size-m;
      font-weight: $font-weight-bold;

      &.more {
        @include plus;
        //@include plus-hover;
        &:hover {
          &::after,
          &::before {
            background-color: currentColor;
          }
        }
      }

      &.less {
        @include minus;
        &:hover {
          &::after,
          &::before {
            background-color: currentColor;
          }
        }
      }
    }
  }

  .footer-section {
    grid-area: footer;
    margin-top: $spacer*2;
  }

  &-footer {
    display: flex;
    flex-direction: column;

    &_text {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-top: $spacer*2;
      padding-bottom: $spacer;
      border-top: 1px solid $gray-600;
      @include media-breakpoint-up("md") {
        padding-bottom: 0;
      }
    }

    &_partners {
      display: flex;
      flex-direction: column;
      padding-top: $spacer*2;
      padding-bottom: $spacer*2;
      border-top: 1px solid $gray-600;

      &--list {
        margin-top: $spacer/2;
      }

      &-image {
        width: 100px;
        height: 88px;
        object-fit: contain;
        margin-right: $spacer*1.5;
      }
    }

    &_item {
      width: 100%;
      margin-bottom: $spacer;
      @include media-breakpoint-up("md") {
        width: 50%;
        margin-bottom: $spacer*2;
        &:not(:last-child) {
          padding-right: $spacer;
        }
      }
      @include media-breakpoint-up("md") {
        width: 25%;
      }
    }

    &_title {
      font-family: $headings-font-family;
      font-weight: 500;
      font-size: $font-size-xs;
      line-height: 16px;
      letter-spacing: .05em;
      text-transform: uppercase;
      margin-bottom: $spacer/2;
    }

    &_suptitle {
      font-family: $font-family-sans-serif;
      font-size: $font-size-base;
      line-height: 24px;
      color: $dark-gray;
      margin-bottom: 0;
    }
  }
}

// app/local/blocks/1ci/9_1ci_partners_list_block
.partners-list {
  margin: $spacer 0;

  &_item {
    background-color: $gray-300;
    padding: $spacer;
    margin-bottom: $spacer*2;
    @include media-breakpoint-up("sm") {
      padding: $spacer*3;
    }
  }

  &_title {
    font-size: $display4-size;
    padding-bottom: $spacer;
  }

  &_contactstitle {
    font-family: $headings-font-family;
    font-size: $display5-size;
    font-weight: $display5-weight;
    margin: 0;
    padding: 0;
  }

  &_props {
    padding: 0;
    margin: 0;

    strong {
      color: $dark-gray;
      font-weight: $font-weight-base;
    }
  }
}

// app/local/blocks/1ci/9.1ci_partners_menu
.partners-menu {

  display: none;

  &_mobile {
    display: block;

    @include media-breakpoint-up("sm") {
      display: none;
    }
  }

  @include media-breakpoint-up("sm") {
    display: flex;
  }

  &_item {
    flex: 100%;

    @include media-breakpoint-only("sm") {
      &:first-child &-inner {
        @include border-top-radius(4px);
      }
      &:last-child &-inner {
        @include border-bottom-radius(4px);
      }
    }

    @include media-breakpoint-up("md") {
      flex: auto;
      &:first-child &-inner {
        @include border-left-radius(4px);
      }
      &:last-child &-inner {
        @include border-right-radius(4px);
      }
    }

    &:last-of-type {
      .partners-menu_item-inner {
        border-right: $border-width solid $dark-indigo;
      }
    }

    &-inner {
      background-color: $white;
      border-left: $border-width solid $dark-indigo;
      border-top: $border-width solid $dark-indigo;
      border-bottom: $border-width solid $dark-indigo;
      border-right: $border-width solid $dark-indigo;
      text-align: center;

      @include media-breakpoint-up("md") {
        border-right: none;
      }

      .partners-menu_item-link {
        color: $dark-indigo;
        font-weight: $font-weight-base;
      }

      &.active {
        color: $white;
        background-color: $dark-indigo;

        .partners-menu_item-link {
          color: $white;
        }
      }
    }

    &-title {
      font-size: $font-size-sm;
      margin: $spacer/2 0;
    }

    &-link {
      color: $dark-indigo;
      padding-bottom: $spacer * .5;
    }
  }

}

// app/local/blocks/1ci/9.1ci_partners_3_cols_block
.partners-benefits-block {
  margin: 0 0 $spacer*2;
  padding-top: $spacer;
  background-color: $gray-300;

  &_header {
    text-align: center;
    font-size: $display3-size;
    font-weight: $display3-weight;
  }

  &_description {
    text-align: center;
    font-size: $display5-size;
    font-weight: $display5-weight;
    padding-bottom: $spacer*2;
  }

  &_item {
    text-align: center;
  }

  &_img {
    width: 112px;
    height: 112px;
    margin-bottom: $spacer;
  }

  &_title {
    margin-top: $spacer;
    margin-bottom: $spacer*2;
  }
}

// app/local/blocks/1ci/9.1ci_partners_solution_block
.partners-solutions-block {
  padding-bottom: $spacer*3;

  @include media-breakpoint-only("md") {
    .col-lg-4.partners-solutions-block_item .partners-solutions-block_list {
      display: flex;

      &-item:not(:last-child) {
        margin-right: $spacer * 1.5;
      }
    }
  }
  @include media-breakpoint-down("sm") {
    .col-lg-8.partners-solutions-block_item .partners-solutions-block_card:first-child {
      margin-bottom: $spacer * 2.5;
    }
  }

  &_card {
    &--titleblock {
      display: flex;
      align-items: center;
      margin-bottom: $spacer/2;

      &_img {
        margin-right: $spacer;
        width: 40px;
        height: 40px;
      }

      &_title {
        margin-bottom: 0;
      }
    }
  }

  &_item {
    padding: $spacer;
  }

  @include media-breakpoint-up("lg") {
    &_card {

      &:first-child {
        padding-right: $spacer*2.5;
      }

      &:only-child {
        padding-right: 15px;
      }

      & + & {
        &:last-child {
          padding-left: $spacer*2.5;
        }
      }
    }
  }

  &_text-block {
    padding: $spacer*2 $spacer*1.5 $spacer*1.5;
    border: 1px solid $gray-500;
    @include border-radius($border-radius);
    height: 100%;
  }

  &_description {
    margin-bottom: $spacer*2;
  }

  &_subdescription {
    margin-bottom: $spacer * 1.5;
  }

  &_list {
    list-style: none;
    padding-left: 0;

    &-item {
      position: relative;
      margin-left: $spacer*2;
      margin-bottom: $spacer*1.5;

      &::before {
        content: "";
        position: absolute;
        background: url("/local/templates/1ci/i/icons/dot2.svg") no-repeat 0 0;
        left: -34px;
        top: 0;
        width: 24px;
        height: 24px;
      }
    }
  }

  &_buttonblock {
    @include hover-btn-arrow($link-color, $link-hover-color);
  }
}

//app/local/blocks/1ci/9.1ci_partners_catalogue_list_block
.partners-catalogue {
  padding: $spacer*2.5 0 $spacer*2;

  //&_header {
  //  margin-top: $spacer*2;
  //  margin-bottom: $spacer*2.5;
  //  text-align: center;
  //
  //  &.hidden {
  //    display: none;
  //  }
  //}

  &_block--wrapper {
    margin-bottom: $spacer*2;
    @include make-col-ready();
    @include make-col(12);
    @include media-breakpoint-up("lg") {
      @include make-col(6);
    }
  }

  &_item {
    display: flex;
    flex-direction: column;
    padding: $spacer*1.5;
    border-radius: $border-radius;
    box-shadow: $box-shadow-sm;
    height: 100%;
  }

  &_badges {
    display: flex;
    flex-wrap: wrap;
    //padding-top: $spacer/2;
    //border-top: solid 1px $dark-gray;

    &--tag {
      margin-right: $spacer/2;
      margin-bottom: $spacer/2;
    }
  }

  //&_logoblock {
  //  display: flex;
  //  justify-content: space-between;
  //}

  &_logo {
    max-height: 32px;
    margin-top: $spacer;
  }

  //&_text {
  //  text-transform: uppercase;
  //  line-height: 1.33;
  //  letter-spacing: .7px;
  //  color: $badge-orange;
  //  font-family: $headings-font-family;
  //  font-size: $font-size-xs;
  //  font-weight: $font-weight-semibold;
  //}

  &_headline {
    padding: $spacer*2 0;

    &--subtitle {
      font-size: $font-size-m;
      line-height: 1.41;
      margin-bottom: 0;
    }
  }

  &_list {
    &--feature {
      margin-top: $spacer*2;
      margin-bottom: 0;
    }

    &--company {
      margin-top: $spacer;
      @include media-breakpoint-up("sm") {
        margin-top: 0;
      }
    }

    &--title {
      font-family: $headings-font-family;
      font-size: $font-size-xs;
      font-weight: $font-weight-semibold;
      text-transform: uppercase;
      color: $dark-gray;
      letter-spacing: .7px;
      line-height: 1.33;
      margin-bottom: 0;
    }

    &--subtitle {
      font-size: $font-size-m;
      line-height: 1.41;
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }

    &--flag {
      height: 15px;
      margin-right: $spacer/2;
    }

    //&--about {
    //  display: flex;
    //  margin-left: $spacer/2;
    //  width: $spacer;
    //  height: $spacer;
    //  cursor: pointer;
    //
    //  .inline {
    //    path,
    //    rect {
    //      transition: $transition-base;
    //    }
    //  }
    //
    //  &:hover {
    //    .inline {
    //      path,
    //      rect {
    //        fill: $blue;
    //      }
    //    }
    //  }
    //}

    //&--subtitle,
    //&--features__item {
    //  font-size: $font-size-m;
    //  line-height: 1.41;
    //  margin-bottom: 0;
    //  display: flex;
    //  break-inside: avoid-column;
    //}

    &--features {
      margin-bottom: $spacer*2.5;
    }

    &--features__item {
      font-size: $font-size-m;
      line-height: 1.41;
      margin-bottom: 0;
      display: flex;
      align-items: center;

      &::before {
        content: "";
        display: block;
        position: relative;
        height: 4px;
        min-width: 4px;
        margin: $spacer*.6 $spacer auto 0;
        border-radius: $border-radius;
        background-color: $dark-indigo;
      }
    }
  }

  &_column {
    //margin-bottom: $spacer*2.5;
    @include media-breakpoint-up("sm") {
      column-count: 2;
      column-gap: 28px;
    }
  }

  &_buttonblock {
    margin-top: auto;
    //margin-bottom: $spacer*2.5;
    @include hover-btn-arrow($link-color, $link-hover-color);

    &--button {
      margin-right: 3px;
    }
  }

  // app/local/blocks/1ci/9.1ci_partners_catalogue_little_list_block
  //&_little {
  //
  //  .partners-catalogue_block--wrapper {
  //    margin-bottom: $spacer*2;
  //    @include media-breakpoint-up("sm") {
  //      @include make-col(6);
  //    }
  //    @include media-breakpoint-up("lg") {
  //      @include make-col(4);
  //    }
  //  }
  //
  //  .partners-catalogue_list {
  //    display: none;
  //  }
  //
  //  .partners-catalogue_headline {
  //    padding-bottom: $spacer*1.5;
  //  }
  //
  //  .partners-catalogue_buttonblock {
  //    margin-bottom: $spacer*1.5;
  //  }
  //}
  //
  //.blur {
  //  filter: blur(5px);
  //}
}

//9.1ci_partners_catalogue_list_block_new
.partners-catalogue__new {
  padding: $spacer*2.5 0 $spacer*2;

  [href="#form"] {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-image: linear-gradient(90deg, currentColor, currentColor 50%, transparent 50%, transparent 100%);
      background-size: 6px 1px;
    }
  }

  &_header {
    margin-top: $spacer*2;
    margin-bottom: $spacer*2.5;
    text-align: center;

    &.hidden {
      display: none;
    }
  }

  &_block--wrapper {
    margin-bottom: $spacer*2;
    @include make-col-ready();
    @include make-col(12);
    @include media-breakpoint-up("lg") {
      @include make-col(6);
    }
  }

  &_item {
    display: flex;
    flex-direction: column;
    padding: $spacer*1.5;
    border: solid 1px $gray-600;
    border-radius: $border-radius/2;
    height: 100%;
    overflow: hidden;
    @include media-breakpoint-up("md") {
      padding-bottom: $spacer*3;
    }
  }

  &_badges {
    align-items: center;

    @include media-breakpoint-up("md") {
      position: absolute;
      background-color: $white;
      width: 100%;
      padding-top: $spacer*1.5;
      padding-right: $spacer*1.5;
      bottom: -$spacer*1.5;
      height: $spacer*3;
      overflow: hidden;
      &::before {
        content: "";
        position: absolute;
        top: 16px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $dark-gray;
      }

      &.popup {
        display: flex;
        flex-wrap: wrap;
        &:hover {
          cursor: pointer;
        }
      }
    }

    &__wrapper {
      display: flex;
      justify-content: space-between;
      position: relative;
      @include media-breakpoint-down("sm") {
        border-top: 1px solid $dark-gray;
        padding-top: $spacer/2;
      }
      &:hover {
        .popup {
          cursor: pointer;
          height: auto;
        }
        .open {
          transform: rotate(180deg);
          path {
            fill: $blue;
          }
        }
      }
    }

    &--tag {
      letter-spacing: .7px;
      opacity: 1;
      transition: $transition-base;
      margin-right: $spacer*1.5;
      margin-top: $spacer/2;

      .hidden {
        opacity: 0;
      }
    }

    &--icon {
      display: none;
      @include media-breakpoint-up("md") {
        position: absolute;
        align-items: center;
        justify-content: center;
        height: 16px;
        width: 16px;
        margin-top: $spacer/2;
        right: 0;
        z-index: 1;
        display: flex;
      }
    }
  }

  &_controls {
    display: flex;
    justify-content: space-between;
    height: 16px;
    min-width: 36px;
    margin-left: $spacer;

    .prev__element,
    .next__element {
      height: 16px;
      width: 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      .inline {
        fill: $gray-600;

        &.active {
          fill: $blue-gray;
          cursor: pointer;

          &:hover {
            fill: $dark-gray;
          }
        }
      }
    }

    .next__element {
      transform: rotate(180deg);
    }
  }

  &_logoblock {
    height: 88px;
  }

  &_logo {
    max-height: 32px;
  }

  &_text {
    text-transform: uppercase;
    line-height: 1.33;
    letter-spacing: .7px;
    color: $badge-orange;
    font-family: $headings-font-family;
    font-size: $font-size-xs;
    font-weight: $font-weight-semibold;
    margin-top: $spacer*2;
    margin-bottom: $spacer/2;
  }

  &_headline {
    padding: 0 0 $spacer*2.5;

    &--subtitle {
      line-height: 1.41;
      margin-bottom: 0;
    }
  }

  &_list {
    &--feature {
      margin-top: $spacer*1.5;
      margin-bottom: 0;
    }

    &--company {
      margin-top: $spacer*1.5;
    }

    &--title {
      font-family: $headings-font-family;
      font-size: $font-size-xs;
      font-weight: $font-weight-semibold;
      text-transform: uppercase;
      color: $dark-gray;
      letter-spacing: .7px;
      line-height: 1.33;
      margin-bottom: 0;
    }

    &--flag {
      height: 15px;
      margin-right: $spacer/2;
    }

    &--about {
      display: flex;
      margin-left: $spacer/2;
      width: $spacer;
      height: $spacer;
      cursor: pointer;

      .inline {
        overflow: visible;

        path,
        rect {
          transition: $transition-base;
        }
      }

      &:hover {
        .inline {
          path,
          rect {
            fill: $blue;
          }
        }
      }
    }

    &--subtitle,
    &--features__item {
      font-size: $font-size-m;
      line-height: 1.41;
      margin-bottom: 0;
      display: flex;
      break-inside: avoid-column;
    }

    &--subtitle {
      align-items: center;
    }

    &--features__item {
      &::before {
        content: "";
        background-image: url("/local/templates/1ci/i/icons/Common/020.svg");
        display: block;
        position: relative;
        height: 8px;
        min-width: 8px;
        margin-right: $spacer/2;
        margin-top: $spacer/2;
      }
    }
  }

  &_column {
    margin-bottom: $spacer*2.5;
    @include media-breakpoint-up("sm") {
      column-count: 2;
      column-gap: 28px;
    }
  }

  &_buttonblock {
    margin-top: auto;
    margin-bottom: $spacer*2.5;
    @include media-breakpoint-up("md") {
      margin-bottom: $spacer*3;
    }
    @include hover-btn-arrow($link-color, $link-hover-color);

    &--button {
      margin-right: 3px;
    }
  }

  // app/local/blocks/1ci/9.1ci_partners_catalogue_little_list_block
  &_little {

    .partners-catalogue__new_block--wrapper {
      margin-bottom: $spacer*2;
      @include media-breakpoint-up("sm") {
        @include make-col(6);
      }
      @include media-breakpoint-up("lg") {
        @include make-col(4);
      }
    }

    .partners-catalogue__new {
      @include media-breakpoint-only("sm") {
        &_item {
          padding-bottom: $spacer*3;
        }
        &_badges {
          position: absolute;
          background-color: $white;
          width: 100%;
          padding-top: $spacer*1.5;
          padding-right: $spacer*1.5;
          bottom: -$spacer*1.5;
          overflow: hidden;
          height: $spacer*3;
          &::before {
            content: "";
            position: absolute;
            top: 16px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: $dark-gray;
          }

          &__wrapper {
            border-top: 1px solid $dark-gray;
            padding-top: $spacer/2;
          }

          &--icon {
            display: flex;
            position: absolute;
            align-items: center;
            justify-content: center;
            height: 16px;
            width: 16px;
            margin-top: $spacer/2;
            right: 0;
            z-index: 1;
            .icon-arrow {
              transition: $transition-base;
              fill: $blue-gray;
            }
          }
        }
      }

      &_logoblock {
        height: $spacer*5;
      }

      &_text {
        margin-top: $spacer*1.5;
        margin-bottom: $spacer/2;
      }

      &_list {
        display: none;
      }

      &_headline {
        padding-bottom: $spacer*1.5;
      }

      &_buttonblock {
        margin-bottom: $spacer*2;
      }
    }
  }

  .blur {
    filter: blur(5px);
  }

  &_empty {

    &.hidden {
      display: none;
    }

    &-header {
      font-size: $h3-font-size;
      text-align: center;
      margin-bottom: $spacer*1.5;
    }

    &-block {
      font-size: $font-size-m;
      line-height: 1.41;
      background-color: $gray-300;
      padding: $spacer*2.5 $spacer;
      text-align: center;
      margin-bottom: $spacer*2;

      @include media-breakpoint-up("md") {
        padding: $spacer*2.5 60px;
      }

      @include media-breakpoint-up("lg") {
        padding: $spacer*2.5 95px;
      }
    }

  }
}

// app/local/blocks/1ci/9.1ci_partners_video_block
.partners-video-block {
  background-color: transparent;
  padding-bottom: $spacer*3.5;

  &_video:first-child {
    @include media-breakpoint-down("sm") {
      margin-bottom: $spacer*2.25;
    }
  }

  &_description {
    font-family: $headings-font-family;
    font-weight: $font-weight-base;
    font-size: $font-size-m;
    margin-top: $spacer*1.375;
  }
}

//app/local/blocks/1ci/14.1ci_filter_badges
.filter {
  &__badges {
    margin: $spacer * 2 0 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;

    &.hidden {
      display: none;
    }

    &-tag {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      background-color: $blue;
      border-radius: $border-radius;
      cursor: default;
      float: left;
      margin-right: $spacer/2;
      margin-bottom: $spacer/2;
      padding: 4px 8px;
      font-size: $font-size-m;
      line-height: 1.4;
      color: $white;

      &.hidden {
        display: none;
      }

      &--remove {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 16px;
        width: 16px;
        margin-left: 4px;
        color: $white;
        cursor: pointer;
        font-size: $h1-font-size;
        transform: translateY(-1px);
      }
    }

    &-button {
      font-weight: $font-weight-bold;
      padding: 4px 8px;
      white-space: nowrap;

      &.hidden {
        display: none;
      }
    }
  }
}
