@mixin caret-down {
  transform: rotate(0deg);
}

@mixin caret-up {
  transform: rotate(180deg);
  path {
    fill: $blue;
  }
}

@mixin caret-right {
  border-top: $caret-width solid transparent;
  border-right: 0;
  border-bottom: $caret-width solid transparent;
  border-left: $caret-width solid;
}

@mixin caret-left {
  border-top: $caret-width solid transparent;
  border-right: $caret-width solid;
  border-bottom: $caret-width solid transparent;
}

@mixin caret($direction: down) {
  @if $enable-caret {
    .caret {
      width: 6px;
      height: 4px;
      margin-left: $caret-width * .85;
      vertical-align: $caret-width * .85;
      & path {
        transition: $transition-base;
      }
      @if $direction == down {
        @include caret-down;
      } @else if $direction == up {
        @include caret-up;
      } @else if $direction == right {
        @include caret-right;
      }
    }

    @if $direction == left {
      &::after {
        display: none;
      }

      &::before {
        display: inline-block;
        width: 0;
        height: 0;
        margin-right: 0;
        position: absolute;
        top: $spacer/2;
        left: $spacer;
        content: "";
        @include caret-right;
      }
    }

    @if $direction == left-show {
      &::after {
        display: none;
      }

      &::before {
        display: inline-block;
        width: 0;
        height: 0;
        margin-right: 0;
        position: absolute;
        top: $spacer/2;
        left: $spacer;
        content: "";
        @include caret-down;
      }
    }

    &:empty::after {
      margin-left: 0;
    }
  }
}
