// app/local/blocks/1ci/12.1ci_form_developers
.forms {
  width: 100%;
  margin: 0;
  padding: $spacer/2 0 $spacer*2.5;

  &--banner {
    margin: $spacer*3 auto 0;
    position: relative;
    padding: $spacer*2.5 0 $spacer*3;
    border-radius: $border-radius;
    box-shadow: 50px 0 0 0 $blue, -50px 0 0 0 $blue;
    background-color: $blue;
    z-index: 1;
    @include media-breakpoint-up("sm") {
      padding: $spacer*2.5 $spacer*3.75 $spacer*2;
      box-shadow: none;
    }
    @include media-breakpoint-up("md") {
      margin-top: $spacer*2;
    }
    @include media-breakpoint-up("lg") {
      max-width: 352px;
      bottom: -$spacer;
      margin-top: 0;
      padding: $spacer*2.5 $spacer*3.25 $spacer*3;
    }
    //@include form-developer();
  }

  &--banner_new {
    position: relative;
    border-radius: $border-radius/2;
    background-color: $blue;
    z-index: 1;
    @include media-breakpoint-down("md") {
      padding: $spacer*2 0;
      margin-top: $spacer*3;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: $blue;
        z-index: -1;
        transform: scaleX(10);
      }
    }
    @include media-breakpoint-up("lg") {
      padding: $spacer*2;
      .row {
        margin-left: -$spacer/2;
        margin-right: -$spacer/2;
      }
      .form-group {
        padding-left: $spacer/2;
        padding-right: $spacer/2;
      }
    }
    @include form-developer();
  }

  &--blue {
    background-color: $blue;
    padding: $spacer*2 0 $spacer;

    .select2-container .select2-selection--single .select2-selection__rendered {
      border-color: $white;
    }
  }

  &--blue &_title {
    color: $white;
  }

  &_title {
    text-align: center;
    color: $dark-indigo;
    margin-bottom: $spacer*1.5;
  }

  &--blue &_description {
    color: $white;
  }

  &_description {
    text-align: center;
    color: $dark-indigo;
    font-size: $display5-size;
    font-weight: $display5-weight;
  }

  // stylelint-disable declaration-no-important
  &--blue label {
    color: $white !important;
  }

  // stylelint-enable declaration-no-important

  label {
    font-size: $font-size-sm;
    font-weight: $font-weight-normal;
    color: $dark-indigo;
    line-height: 16px;
  }

  &_row {
    align-items: flex-start;
  }

  &--blue .errortext {
    color: $white;
  }

  .errortext {
    font-size: $font-size-sm;
    font-weight: $font-weight-normal;
    color: $accent-red;
  }

  &_section {
    &:last-of-type {
      display: none;
    }
  }

  &_switch {
    &--company,
    &--individual {
      user-select: none;

      &.active {
        color: $blue;
      }
    }
  }
}

.form-developer {
  .container {
    display: flex;
    justify-content: center;
  }
  .forms_row {
    @include media-breakpoint-up("lg") {
      max-width: 570px;
    }
  }
  @include form-developer();
}

.form-become__partner {
  .container {
    display: flex;
    justify-content: center;
  }
  .forms_row {
    @include media-breakpoint-up("md") {
      max-width: 600px;
    }
    @include media-breakpoint-up("lg") {
      max-width: 570px;
    }
  }
  .form-group {
    min-height: auto;
    .custom-control {
      min-height: $spacer;
    }
    &_checkbox a {
      color: $white;
      text-decoration: underline;
    }
  }
  .form-button {
    margin-top: $spacer*2;
    margin-bottom: 0;
  }
}

.form-description {
  text-align: center;
  color: $white;
  margin: $spacer 0;

  [href^="mailto:"] {
    color: currentColor;
    position: relative;
    @include hover-footer-links;
  }
}

.form-social {
  background-color: $blue;
  margin: 0;
  padding-bottom: $spacer*2;

  &_col {
    display: flex;
    border-top: 1px solid $white;
    margin: 0;
    padding-top: $spacer*1.75;
  }

  &_text {
    color: $white;
    font-family: $font-family-sans-serif;
    font-size: $font-size-m;
    font-weight: $font-weight-base;
    line-height: 1.41;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &_description {
    margin-right: $spacer;
    margin-bottom: $spacer;
    width: 100%;
    @include media-breakpoint-up("md") {
      width: max-content;
      margin-bottom: 0;
    }
  }

  &_item {
    margin-right: $spacer/2;
  }
}
