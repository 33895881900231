// The dropdown wrapper (`<div>`)
.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  // Generate the caret automatically
  @include caret;

}

.show > .dropdown-toggle {
  @include caret("up");
}

// The dropdown menu
.dropdown-menu {
  position: absolute;
  top: calc(100% + #{$nav-link-padding-y * 2 - .1});
  left: 50%;
  right: 0;
  z-index: $zindex-dropdown;
  display: none; // none by default, but block on "open" of the menu
  float: left;
  min-width: auto;
  padding: $dropdown-padding-y/2 0 0 $dropdown-item-padding-x;
  margin: $dropdown-spacer 0 0; // override default ul
  font-size: $navbar-menu-font-size; // Redeclare because nesting can cause inheritance issues
  color: $body-color;
  text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
  list-style: none;
  background-color: transparent;
  background-clip: padding-box;
  @include media-breakpoint-between("sm", "lg") {
    padding: 0 0 $dropdown-padding-y;
    background-color: $dropdown-bg;
  }
  @include media-breakpoint-up("lg") {
    transform: translateX(-50%);
    padding: $dropdown-padding-y 0 0;
    background-color: $dropdown-bg;
    @include border-radius($dropdown-border-radius);
    @include box-shadow-overwrite($dropdown-box-shadow);

    &_wrapper {
      display: flex;
      flex-direction: row;
      margin-bottom: $spacer/2;
    }

    &_container {
      flex-direction: row;
    }

    .arrow {
      position: absolute;
      display: block;
      width: $popover-arrow-width;
      height: $popover-arrow-height;
      margin: 0 $border-radius-lg;
      top: calc((#{$popover-arrow-height} + #{$popover-border-width}) * -1);
      left: calc(50% - (#{$popover-arrow-width} + #{$popover-border-width}));

      &::before,
      &::after {
        position: absolute;
        display: block;
        content: "";
        border-color: transparent;
        border-style: solid;
        border-width: 0 ($popover-arrow-width / 2) $popover-arrow-height ($popover-arrow-width / 2);
      }

      &::before {
        top: 0;
        border-bottom-color: $popover-arrow-outer-color;
      }

      &::after {
        top: $popover-border-width;
        border-bottom-color: $popover-arrow-color;
      }

    }

    .arrow-r {
      position: absolute;
      display: block;
      width: $popover-arrow-width/2;
      height: $popover-arrow-height;
      margin: 0;
      top: calc((#{$popover-arrow-height} + #{$popover-border-width}) * -1);
      right: 0;

      &::before,
      &::after {
        position: absolute;
        display: block;
        content: "";
        border-color: transparent;
        border-style: solid;
        border-width: 0 0 $popover-arrow-height ($popover-arrow-width / 2);
      }

      &::before {
        top: 0;
        border-bottom-color: $popover-arrow-outer-color;
      }

      &::after {
        top: $popover-border-width;
        border-bottom-color: $popover-arrow-color;
      }
    }
  }

  &_gray {
    border-top: solid 1px $gray-500;
    margin-left: 0;
    margin-top: $spacer/2;
    @include media-breakpoint-between("sm", "lg") {
      margin-left: $spacer*2;
    }
    @include media-breakpoint-up("lg") {
      margin-left: 0;
      margin-top: 0;
      border: none;
      background-color: $gray-300;
      @include border-bottom-radius($dropdown-border-radius);
    }

    .dropdown-item {
      padding: 0 0 $dropdown-item-padding-y;
      @include media-breakpoint-between("sm", "lg") {
        padding: $dropdown-item-padding-y/2 0;
      }
      @include media-breakpoint-up("lg") {
        padding: $dropdown-item-padding-y - .1 $dropdown-item-padding-x - 1;
      }

      &::after {
        display: inline-block;
        padding-left: $spacer/5;
        vertical-align: text-bottom;
        content: $breadcrumb-divider;
      }

      &:hover::after {
        content: $breadcrumb-divider_blue;
      }
    }

    &.gray-center {
      @include media-breakpoint-up("lg") {
        text-align: center;
      }
    }
  }

  &.js-language-dropdown {
    left: unset;
    right: 0;
    transform: unset;
    margin: $dropdown-spacer $spacer*.75 0 0;
    padding-bottom: $spacer/2;

    @include media-breakpoint-up("lg") {
      @include border-corner-radius($dropdown-border-radius);
    }

    .lang {
      &.selected {
        font-weight: $font-weight-bold;
      }
    }
  }
}

@include media-breakpoint-up("lg") {
  .show > .dropdown-menu {
    animation-name: topnavshow;
    animation-duration: .5s;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
  }
}

@keyframes topnavshow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

// Allow for dropdowns to go bottom up (aka, dropup-menu)
// Just add .dropup after the standard .dropdown class and you're set.
.dropup {
  .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: $dropdown-spacer;
  }

  .dropdown-toggle {
    @include caret(up);
  }
}

.dropright {
  .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: $dropdown-spacer;
  }

  .dropdown-toggle {
    @include caret(right);

    &::after {
      vertical-align: 0;
    }
  }
}

.dropleft {
  .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: $dropdown-spacer;
  }

  .dropdown-toggle {
    @include caret(left);

    &::before {
      vertical-align: 0;
    }
  }
}

// When enabled Popper.js, reset basic dropdown position
// stylelint-disable no-duplicate-selectors
.dropdown-menu {
  &[x-placement^="top"],
  &[x-placement^="right"],
  &[x-placement^="bottom"],
  &[x-placement^="left"] {
    right: auto;
    bottom: auto;
  }
}

// stylelint-enable no-duplicate-selectors

// Dividers (basically an `<hr>`) within the dropdown
.dropdown-divider {
  @include nav-divider($dropdown-divider-bg);
}

// Links, buttons, and more within the dropdown menu
//
// `<button>`-specific styles are denoted with `// For <button>s`
.dropdown-item {
  display: block;
  width: 100%; // For `<button>`s
  padding: 0 0 $dropdown-item-padding-y;
  clear: both;
  font-weight: $font-weight-normal;
  color: $dropdown-link-color;
  text-align: inherit; // For `<button>`s
  white-space: nowrap; // prevent links from randomly breaking onto new lines
  background-color: transparent; // For `<button>`s
  border: 0; // For `<button>`s
  &:last-child {
    margin-bottom: $spacer/2;
    @include media-breakpoint-up("lg") {
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-between("sm", "lg") {
    padding: $dropdown-item-padding-y/2 $dropdown-item-padding-x;
  }
  @include media-breakpoint-up("lg") {
    padding: $dropdown-item-padding-y - .35 $dropdown-item-padding-x - 1;
  }
  @include hover-focus {
    color: $dropdown-link-hover-color;
    text-decoration: none;
  }

  &.active {
    font-weight: $font-weight-bold;
    text-decoration: none;
  }

  &.disabled,
  &:disabled {
    color: $dropdown-link-disabled-color;
    background-color: transparent;
    // Remove CSS gradients if they're enabled
    @if $enable-gradients {
      background-image: none;
    }
  }
}

.dropdown-menu.show {
  display: block;
  @include media-breakpoint-up("lg") {
    display: table;
  }
}

// Dropdown section headers
.dropdown-header {
  display: block;
  padding: $dropdown-padding-y $dropdown-item-padding-x;
  margin-bottom: 0; // for use with heading elements
  font-size: $font-size-sm;
  color: $dropdown-header-color;
  white-space: nowrap; // as with > li > a
}

// Dropdown text
.dropdown-item-text {
  display: block;
  padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  color: $dropdown-link-color;
}

.dropdown-title {
  font-size: $font-size-xs;
  font-weight: $font-weight-bold;
  color: $blue-gray;
  border-bottom: solid 1px $gray-500;
  text-transform: uppercase;
  line-height: 2;
  letter-spacing: .4px;
  width: calc(100% - #{$dropdown-item-padding-x});
  margin-bottom: $spacer/2;
  display: block;
  padding: 0 0 $dropdown-item-padding-y;
  clear: both;
  text-align: inherit; // For `<button>`s
  white-space: nowrap; // prevent links from randomly breaking onto new lines
  background-color: transparent; // For `<button>`s

  @include media-breakpoint-between("sm", "lg") {
    margin-left: $spacer*2;
    padding: $dropdown-item-padding-y/2 0;
  }

  @include media-breakpoint-up("lg") {
    margin-left: $spacer;
    margin-right: $spacer;
    padding: $dropdown-item-padding-y - .3 0;
  }
}
