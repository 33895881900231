/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * 1Ci customization v1.0.0 (https://gitlab.1cinternational.com/)
 * Copyright 2018-2019 1Ci Developers
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@import url("https://fonts.googleapis.com/css?family=Rubik:300,400,500,700|Source+Sans+Pro:300,300i,400,400i,600,600i,700,700i&display=swap");
:root {
  --blue: #158aff;
  --dark-blue: #172b4d;
  --light-blue: #88c4ff;
  --red: #ff788c;
  --pink: #fd9caa;
  --yellow: #ffdd2d;
  --light-yellow: #faffbd;
  --green: #8be2ad;
  --accent-green: #16c65d;
  --white: #fff;
  --gray: #f4f5f7;
  --dark-gray: #c1c7d0;
  --dark-indigo: #091e42;
  --blue-gray: #8993a4;
  --accent-red: #fd3a57;
  --black: #000;
  --dark-grey-blue: #344563;
  --dark-grey-light: #6c798f;
  --primary: #ffdd2d;
  --links: #158aff;
  --secondary: #fff;
  --success: #8be2ad;
  --info: #158aff;
  --warning: #ffcd33;
  --danger: #ff788c;
  --dark: #091e42;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --font-family-headers: "Rubik", sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #091e42;
  text-align: left;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

p:focus {
  outline: none;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 400;
  color: #6c798f;
}

dd {
  margin-bottom: 0;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #158aff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #0064c8;
  text-decoration: none;
}

a:focus {
  outline: none;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #c1c7d0;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: none;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0.5rem;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 32px;
  color: #091e42;
}

.h1 {
  font-size: 1.875rem;
  font-weight: 500;
}

@media (min-width: 768px) {
  .h1 {
    font-size: 2.5rem;
    line-height: 40px;
  }
}

.h2 {
  font-size: 1.875rem;
}

@media (min-width: 768px) {
  .h2 {
    font-size: 2.25rem;
    line-height: 40px;
  }
}

.h3 {
  font-size: 1.625rem;
}

@media (min-width: 768px) {
  .h3 {
    font-size: 1.875rem;
  }
}

.h4 {
  font-size: 1.5rem;
  line-height: 24px;
}

@media (min-width: 768px) {
  .h4 {
    font-size: 1.625rem;
    line-height: 32px;
  }
}

.h5 {
  font-size: 1.3125rem;
  line-height: 24px;
}

.h6 {
  font-size: 1.1875rem;
  line-height: 24px;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 90%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #c1c7d0;
}

.blockquote-footer::before {
  content: "\2014 \00A0";
}

.list__type-decimal {
  list-style-type: decimal;
}

.list__type-circle {
  list-style-type: circle;
}

.list__type-disc {
  list-style-type: disc;
}

.list__type-alpha {
  list-style-type: lower-alpha;
}

.list__type-roman {
  list-style-type: upper-roman;
}

.list__type-none {
  list-style-type: none;
}

.hg-1 {
  height: 128px;
}

.hg-2 {
  height: 152px;
}

.hg-3 {
  height: 176px;
}

.hg-4 {
  height: 200px;
}

.hg-5 {
  height: 224px;
}

.hg-6 {
  height: 248px;
}

.hg-7 {
  height: 272px;
}

.hg-8 {
  height: 296px;
}

.hg-9 {
  height: 320px;
}

.hg-10 {
  height: 344px;
}

.hg-11 {
  height: 368px;
}

.hg-12 {
  height: 392px;
}

.hg-13 {
  height: 416px;
}

.hg-14 {
  height: 440px;
}

.hg-15 {
  height: 464px;
}

.hg-16 {
  height: 488px;
}

.hg-17 {
  height: 512px;
}

.hg-18 {
  height: 536px;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #f4f5f7;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #c1c7d0;
}

.icon-bar {
  width: 24px;
  height: 3px;
  border-radius: 1px;
  background-color: #42526e;
  display: block;
  margin-top: 4px;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .icon-bar {
    transition: none;
  }
}

.obj-contain {
  -o-object-fit: contain;
  object-fit: contain;
}

.obj-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dfe1e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dfe1e6;
}

.table tbody + tbody {
  border-top: 2px solid #dfe1e6;
}

.table .table {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dfe1e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dfe1e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: #f4f5f7;
}

.table-hover tbody tr:hover {
  background-color: #f4faff;
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #fff5c4;
}

.table-hover .table-primary:hover {
  background-color: #fff1ab;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #fff1ab;
}

.table-links,
.table-links > th,
.table-links > td {
  background-color: #bddeff;
}

.table-hover .table-links:hover {
  background-color: #a4d1ff;
}

.table-hover .table-links:hover > td,
.table-hover .table-links:hover > th {
  background-color: #a4d1ff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: white;
}

.table-hover .table-secondary:hover {
  background-color: #f2f2f2;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #f2f2f2;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #dff7e8;
}

.table-hover .table-success:hover {
  background-color: #cbf2d9;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #cbf2d9;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bddeff;
}

.table-hover .table-info:hover {
  background-color: #a4d1ff;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #a4d1ff;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fff1c6;
}

.table-hover .table-warning:hover {
  background-color: #ffebad;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffebad;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #ffd9df;
}

.table-hover .table-danger:hover {
  background-color: #ffc0ca;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #ffc0ca;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #bac0ca;
}

.table-hover .table-dark:hover {
  background-color: #acb3bf;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #acb3bf;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: #f4faff;
}

.table-hover .table-active:hover {
  background-color: #dbeeff;
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: #dbeeff;
}

.table .thead-dark th {
  color: #fff;
  background-color: #97a0af;
  border-color: #adb4c0;
}

.table .thead-light th {
  color: #b3bac5;
  background-color: #fafbfc;
  border-color: #dfe1e6;
}

.table-dark {
  color: #fff;
  background-color: #97a0af;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #adb4c0;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive > .table-bordered {
  border: 0;
}

.forms--blue .form-control:not(.phone__mask-input),
.forms--banner .form-control,
.forms--banner_new .form-control {
  border-color: transparent;
}

.newsroom__subscribe-form .form-control {
  border-color: #fff;
}

.forms--blue .phone__mask-input {
  border-color: #fff;
  border-left-color: #c1c7d0;
}

.form-control,
.inputtext,
.inputselect {
  display: block;
  width: 100%;
  height: calc(2rem + 8px);
  padding: 0.375rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #091e42;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #c1c7d0;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-control,
  .inputtext,
  .inputselect {
    transition: none;
  }
}

.form-control::-ms-expand,
.inputtext::-ms-expand,
.inputselect::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus,
.inputtext:focus,
.inputselect:focus {
  color: #091e42;
  background-color: #fff;
  border-color: #88c4ff;
  outline: 0;
  box-shadow: 0 0 0 1px #88c4ff;
}

.form-control:focus::-webkit-input-placeholder,
.inputtext:focus::-webkit-input-placeholder,
.inputselect:focus::-webkit-input-placeholder {
  color: #c1c7d0;
}

.form-control:focus::-moz-placeholder,
.inputtext:focus::-moz-placeholder,
.inputselect:focus::-moz-placeholder {
  color: #c1c7d0;
}

.form-control:focus::-ms-input-placeholder,
.inputtext:focus::-ms-input-placeholder,
.inputselect:focus::-ms-input-placeholder {
  color: #c1c7d0;
}

.form-control:focus::placeholder,
.inputtext:focus::placeholder,
.inputselect:focus::placeholder {
  color: #c1c7d0;
}

.form-control::-webkit-input-placeholder,
.inputtext::-webkit-input-placeholder,
.inputselect::-webkit-input-placeholder {
  color: #8993a4;
  transition: all 0.2s ease-in-out;
  opacity: 1;
}

.form-control::-moz-placeholder,
.inputtext::-moz-placeholder,
.inputselect::-moz-placeholder {
  color: #8993a4;
  transition: all 0.2s ease-in-out;
  opacity: 1;
}

.form-control::-ms-input-placeholder,
.inputtext::-ms-input-placeholder,
.inputselect::-ms-input-placeholder {
  color: #8993a4;
  transition: all 0.2s ease-in-out;
  opacity: 1;
}

.form-control::placeholder,
.inputtext::placeholder,
.inputselect::placeholder {
  color: #8993a4;
  transition: all 0.2s ease-in-out;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly],
.inputtext:disabled,
.inputtext[readonly],
.inputselect:disabled,
.inputselect[readonly] {
  background-color: #fafbfc;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #091e42;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.157rem + 1px);
  padding-bottom: calc(0.157rem + 1px);
  font-size: 0.875rem;
  line-height: 1.33;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #091e42;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.47775rem + 8px);
  padding: 0.157rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.33;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(2.875rem + 8px);
  padding: 0.5rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
  min-height: 4.25rem;
}

.form-group > .btn {
  white-space: normal;
  word-break: break-word;
}

.form-group.col-btn {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.form-group > .forms--banner_label {
  color: #fff;
}

.form-group.wo_min-height--sm {
  min-height: 56px;
}

@media (min-width: 768px) {
  .form-group.wo_min-height--sm {
    min-height: 68px;
  }
  .form-group.wo_min-height--sm .btn {
    margin-bottom: 0.3125rem;
    width: 100%;
  }
}

.form-group.partner-btn {
  min-height: 56px;
}

@media (min-width: 576px) {
  .form-group.partner-btn {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .form-group.partner-btn {
    min-height: 68px;
  }
  .form-group.partner-btn .btn {
    margin-bottom: 0.25rem;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
}

.form-group.form--banner_btnblock {
  margin: 2rem 0 0;
  text-align: center;
}

.form-group_checkbox {
  margin: 0.5rem 0 0;
}

.form-group.text-center {
  margin-top: 1.5rem;
}

.form-group.form--banner_btnblock, .form-group.text-center, .form-group_checkbox, .form-group.wo_min-height {
  min-height: 0;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: -3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #c1c7d0;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 90%;
  color: #8be2ad;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  max-width: 100%;
  padding: 1rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #091e42;
  background-color: #8be2ad;
  border-radius: 4px;
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid, .was-validated
.inputselect:valid,
.inputselect.is-valid, .was-validated
.custom-control-label:valid,
.custom-control-label.is-valid {
  border-color: #8be2ad !important;
}

.was-validated .form-control:valid + .select2-container .select2-selection__rendered, .form-control.is-valid + .select2-container .select2-selection__rendered, .was-validated
.custom-select:valid + .select2-container .select2-selection__rendered,
.custom-select.is-valid + .select2-container .select2-selection__rendered, .was-validated
.inputselect:valid + .select2-container .select2-selection__rendered,
.inputselect.is-valid + .select2-container .select2-selection__rendered, .was-validated
.custom-control-label:valid + .select2-container .select2-selection__rendered,
.custom-control-label.is-valid + .select2-container .select2-selection__rendered {
  border-color: #8be2ad !important;
}

.was-validated .form-control:valid.custom-control-label::before, .form-control.is-valid.custom-control-label::before, .was-validated
.custom-select:valid.custom-control-label::before,
.custom-select.is-valid.custom-control-label::before, .was-validated
.inputselect:valid.custom-control-label::before,
.inputselect.is-valid.custom-control-label::before, .was-validated
.custom-control-label:valid.custom-control-label::before,
.custom-control-label.is-valid.custom-control-label::before {
  border-color: #8be2ad !important;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
.custom-select:valid:focus,
.custom-select.is-valid:focus, .was-validated
.inputselect:valid:focus,
.inputselect.is-valid:focus, .was-validated
.custom-control-label:valid:focus,
.custom-control-label.is-valid:focus {
  border-color: #8be2ad !important;
  box-shadow: 0 0 0 0.05rem rgba(139, 226, 173, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip, .was-validated
.custom-select:valid ~ .valid-feedback,
.was-validated
.custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip, .was-validated
.inputselect:valid ~ .valid-feedback,
.was-validated
.inputselect:valid ~ .valid-tooltip,
.inputselect.is-valid ~ .valid-feedback,
.inputselect.is-valid ~ .valid-tooltip, .was-validated
.custom-control-label:valid ~ .valid-feedback,
.was-validated
.custom-control-label:valid ~ .valid-tooltip,
.custom-control-label.is-valid ~ .valid-feedback,
.custom-control-label.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #8be2ad;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #8be2ad;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #f1fcf5;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #b4ecca;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.05rem rgba(139, 226, 173, 0.25);
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #8be2ad;
}

.was-validated .custom-file-input:valid ~ .custom-file-label::after, .custom-file-input.is-valid ~ .custom-file-label::after {
  border-color: inherit;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.05rem rgba(139, 226, 173, 0.25);
}

.invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 90%;
  color: #ff788c;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  max-width: 100%;
  padding: 1rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #091e42;
  background-color: #ff788c;
  border-radius: 4px;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid, .was-validated
.inputselect:invalid,
.inputselect.is-invalid, .was-validated
.custom-control-label:invalid,
.custom-control-label.is-invalid {
  border-color: #ff788c !important;
}

.was-validated .form-control:invalid + .select2-container .select2-selection__rendered, .form-control.is-invalid + .select2-container .select2-selection__rendered, .was-validated
.custom-select:invalid + .select2-container .select2-selection__rendered,
.custom-select.is-invalid + .select2-container .select2-selection__rendered, .was-validated
.inputselect:invalid + .select2-container .select2-selection__rendered,
.inputselect.is-invalid + .select2-container .select2-selection__rendered, .was-validated
.custom-control-label:invalid + .select2-container .select2-selection__rendered,
.custom-control-label.is-invalid + .select2-container .select2-selection__rendered {
  border-color: #ff788c !important;
}

.was-validated .form-control:invalid.custom-control-label::before, .form-control.is-invalid.custom-control-label::before, .was-validated
.custom-select:invalid.custom-control-label::before,
.custom-select.is-invalid.custom-control-label::before, .was-validated
.inputselect:invalid.custom-control-label::before,
.inputselect.is-invalid.custom-control-label::before, .was-validated
.custom-control-label:invalid.custom-control-label::before,
.custom-control-label.is-invalid.custom-control-label::before {
  border-color: #ff788c !important;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
.custom-select:invalid:focus,
.custom-select.is-invalid:focus, .was-validated
.inputselect:invalid:focus,
.inputselect.is-invalid:focus, .was-validated
.custom-control-label:invalid:focus,
.custom-control-label.is-invalid:focus {
  border-color: #ff788c !important;
  box-shadow: 0 0 0 0.05rem rgba(255, 120, 140, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip, .was-validated
.custom-select:invalid ~ .invalid-feedback,
.was-validated
.custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip, .was-validated
.inputselect:invalid ~ .invalid-feedback,
.was-validated
.inputselect:invalid ~ .invalid-tooltip,
.inputselect.is-invalid ~ .invalid-feedback,
.inputselect.is-invalid ~ .invalid-tooltip, .was-validated
.custom-control-label:invalid ~ .invalid-feedback,
.was-validated
.custom-control-label:invalid ~ .invalid-tooltip,
.custom-control-label.is-invalid ~ .invalid-feedback,
.custom-control-label.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #ff788c;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #ff788c;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #fff8f9;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #ffabb7;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.05rem rgba(255, 120, 140, 0.25);
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #ff788c;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label::after, .custom-file-input.is-invalid ~ .custom-file-label::after {
  border-color: inherit;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.05rem rgba(255, 120, 140, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
    line-height: 1;
    display: flex;
  }
  .form-inline .custom-control label {
    margin-bottom: 0;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: none;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover, .btn:focus {
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #091e42;
  background-color: #ffdd2d;
  border-color: #ffdd2d;
}

.btn-primary:hover {
  color: #091e42;
  background-color: #ffd707;
  border-color: #ffd707;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.05rem rgba(255, 221, 45, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #091e42;
  background-color: #ffdd2d;
  border-color: #ffdd2d;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #091e42;
  background-color: #f9d100;
  border-color: #f9d100;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.05rem rgba(255, 221, 45, 0.5);
}

.btn-links {
  color: #fff;
  background-color: #158aff;
  border-color: #158aff;
}

.btn-links:hover {
  color: #fff;
  background-color: #0077ee;
  border-color: #0077ee;
}

.btn-links:focus, .btn-links.focus {
  box-shadow: 0 0 0 0.05rem rgba(21, 138, 255, 0.5);
}

.btn-links.disabled, .btn-links:disabled {
  color: #fff;
  background-color: #158aff;
  border-color: #158aff;
}

.btn-links:not(:disabled):not(.disabled):active, .btn-links:not(:disabled):not(.disabled).active,
.show > .btn-links.dropdown-toggle {
  color: #fff;
  background-color: #0071e1;
  border-color: #0071e1;
}

.btn-links:not(:disabled):not(.disabled):active:focus, .btn-links:not(:disabled):not(.disabled).active:focus,
.show > .btn-links.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.05rem rgba(21, 138, 255, 0.5);
}

.btn-secondary {
  color: #091e42;
  background-color: #fff;
  border-color: #fff;
}

.btn-secondary:hover {
  color: #091e42;
  background-color: #ececec;
  border-color: #ececec;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.05rem rgba(255, 255, 255, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #091e42;
  background-color: #fff;
  border-color: #fff;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #091e42;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.05rem rgba(255, 255, 255, 0.5);
}

.btn-success {
  color: #091e42;
  background-color: #8be2ad;
  border-color: #8be2ad;
}

.btn-success:hover {
  color: #091e42;
  background-color: #6cda97;
  border-color: #6cda97;
}

.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.05rem rgba(139, 226, 173, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #091e42;
  background-color: #8be2ad;
  border-color: #8be2ad;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #091e42;
  background-color: #62d890;
  border-color: #62d890;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.05rem rgba(139, 226, 173, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #158aff;
  border-color: #158aff;
}

.btn-info:hover {
  color: #fff;
  background-color: #0077ee;
  border-color: #0077ee;
}

.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.05rem rgba(21, 138, 255, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #158aff;
  border-color: #158aff;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #0071e1;
  border-color: #0071e1;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.05rem rgba(21, 138, 255, 0.5);
}

.btn-warning {
  color: #091e42;
  background-color: #ffcd33;
  border-color: #ffcd33;
}

.btn-warning:hover {
  color: #091e42;
  background-color: #ffc40d;
  border-color: #ffc40d;
}

.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.05rem rgba(255, 205, 51, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #091e42;
  background-color: #ffcd33;
  border-color: #ffcd33;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #091e42;
  background-color: #ffc100;
  border-color: #ffc100;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.05rem rgba(255, 205, 51, 0.5);
}

.btn-danger {
  color: #091e42;
  background-color: #ff788c;
  border-color: #ff788c;
}

.btn-danger:hover {
  color: #fff;
  background-color: #ff526b;
  border-color: #ff526b;
}

.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.05rem rgba(255, 120, 140, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #091e42;
  background-color: #ff788c;
  border-color: #ff788c;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ff4561;
  border-color: #ff4561;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.05rem rgba(255, 120, 140, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #091e42;
  border-color: #091e42;
}

.btn-dark:hover {
  color: #fff;
  background-color: #040f20;
  border-color: #040f20;
}

.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.05rem rgba(9, 30, 66, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #091e42;
  border-color: #091e42;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #030a15;
  border-color: #030a15;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.05rem rgba(9, 30, 66, 0.5);
}

.btn-outline-primary {
  color: #000;
  background-color: transparent;
  background-image: none;
  border-color: #ffdd2d;
}

.btn-outline-primary:hover {
  color: #091e42;
  background-color: #ffdd2d;
  border-color: #ffdd2d;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.05rem rgba(255, 221, 45, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #ffdd2d;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #091e42;
  background-color: #ffdd2d;
  border-color: #ffdd2d;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.05rem rgba(255, 221, 45, 0.5);
}

.btn-outline-links {
  color: #000;
  background-color: transparent;
  background-image: none;
  border-color: #158aff;
}

.btn-outline-links:hover {
  color: #fff;
  background-color: #158aff;
  border-color: #158aff;
}

.btn-outline-links:focus, .btn-outline-links.focus {
  box-shadow: 0 0 0 0.05rem rgba(21, 138, 255, 0.5);
}

.btn-outline-links.disabled, .btn-outline-links:disabled {
  color: #158aff;
  background-color: transparent;
}

.btn-outline-links:not(:disabled):not(.disabled):active, .btn-outline-links:not(:disabled):not(.disabled).active,
.show > .btn-outline-links.dropdown-toggle {
  color: #fff;
  background-color: #158aff;
  border-color: #158aff;
}

.btn-outline-links:not(:disabled):not(.disabled):active:focus, .btn-outline-links:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-links.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.05rem rgba(21, 138, 255, 0.5);
}

.btn-outline-secondary {
  color: #000;
  background-color: transparent;
  background-image: none;
  border-color: #fff;
}

.btn-outline-secondary:hover {
  color: #091e42;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.05rem rgba(255, 255, 255, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #fff;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #091e42;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.05rem rgba(255, 255, 255, 0.5);
}

.btn-outline-success {
  color: #000;
  background-color: transparent;
  background-image: none;
  border-color: #8be2ad;
}

.btn-outline-success:hover {
  color: #091e42;
  background-color: #8be2ad;
  border-color: #8be2ad;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.05rem rgba(139, 226, 173, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #8be2ad;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #091e42;
  background-color: #8be2ad;
  border-color: #8be2ad;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.05rem rgba(139, 226, 173, 0.5);
}

.btn-outline-info {
  color: #000;
  background-color: transparent;
  background-image: none;
  border-color: #158aff;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #158aff;
  border-color: #158aff;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.05rem rgba(21, 138, 255, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #158aff;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #158aff;
  border-color: #158aff;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.05rem rgba(21, 138, 255, 0.5);
}

.btn-outline-warning {
  color: #000;
  background-color: transparent;
  background-image: none;
  border-color: #ffcd33;
}

.btn-outline-warning:hover {
  color: #091e42;
  background-color: #ffcd33;
  border-color: #ffcd33;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.05rem rgba(255, 205, 51, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffcd33;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #091e42;
  background-color: #ffcd33;
  border-color: #ffcd33;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.05rem rgba(255, 205, 51, 0.5);
}

.btn-outline-danger {
  color: #000;
  background-color: transparent;
  background-image: none;
  border-color: #ff788c;
}

.btn-outline-danger:hover {
  color: #091e42;
  background-color: #ff788c;
  border-color: #ff788c;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.05rem rgba(255, 120, 140, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #ff788c;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #091e42;
  background-color: #ff788c;
  border-color: #ff788c;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.05rem rgba(255, 120, 140, 0.5);
}

.btn-outline-dark {
  color: #000;
  background-color: transparent;
  background-image: none;
  border-color: #091e42;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #091e42;
  border-color: #091e42;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.05rem rgba(9, 30, 66, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #091e42;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #091e42;
  border-color: #091e42;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.05rem rgba(9, 30, 66, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #158aff;
  background-color: transparent;
  white-space: normal;
}

.btn-link:hover {
  color: #0064c8;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: none;
  border-color: transparent;
  box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #c1c7d0;
  pointer-events: none;
}

.btn-link.main_link {
  font-weight: 600;
  padding: 0;
  margin-right: 0.333333rem;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.157rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.33;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle .caret {
  width: 6px;
  height: 4px;
  margin-left: 0.1615em;
  vertical-align: 0.1615em;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.dropdown-toggle .caret path {
  transition: all 0.2s ease-in-out;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.show > .dropdown-toggle .caret {
  width: 6px;
  height: 4px;
  margin-left: 0.1615em;
  vertical-align: 0.1615em;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.show > .dropdown-toggle .caret path {
  transition: all 0.2s ease-in-out;
}

.show > .dropdown-toggle .caret path {
  fill: #158aff;
}

.show > .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: calc(100% + 0.9rem);
  left: 50%;
  right: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: auto;
  padding: 0.25rem 0 0 2rem;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #091e42;
  text-align: left;
  list-style: none;
  background-color: transparent;
  background-clip: padding-box;
}

@media (min-width: 576px) and (max-width: 1199.98px) {
  .dropdown-menu {
    padding: 0 0 0.5rem;
    background-color: #fff;
  }
}

@media (min-width: 992px) {
  .dropdown-menu {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    padding: 0.5rem 0 0;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }
  .dropdown-menu_wrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5rem;
  }
  .dropdown-menu_container {
    flex-direction: row;
  }
  .dropdown-menu .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;
    top: calc((0.5rem + 1px) * -1);
    left: calc(50% - (1rem + 1px));
  }
  .dropdown-menu .arrow::before, .dropdown-menu .arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
    border-width: 0 0.5rem 0.5rem 0.5rem;
  }
  .dropdown-menu .arrow::before {
    top: 0;
    border-bottom-color: #f4f5f7;
  }
  .dropdown-menu .arrow::after {
    top: 1px;
    border-bottom-color: #fff;
  }
  .dropdown-menu .arrow-r {
    position: absolute;
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    margin: 0;
    top: calc((0.5rem + 1px) * -1);
    right: 0;
  }
  .dropdown-menu .arrow-r::before, .dropdown-menu .arrow-r::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
    border-width: 0 0 0.5rem 0.5rem;
  }
  .dropdown-menu .arrow-r::before {
    top: 0;
    border-bottom-color: #f4f5f7;
  }
  .dropdown-menu .arrow-r::after {
    top: 1px;
    border-bottom-color: #fff;
  }
}

.dropdown-menu_gray {
  border-top: solid 1px #dfe1e6;
  margin-left: 0;
  margin-top: 0.5rem;
}

@media (min-width: 576px) and (max-width: 1199.98px) {
  .dropdown-menu_gray {
    margin-left: 2rem;
  }
}

@media (min-width: 992px) {
  .dropdown-menu_gray {
    margin-left: 0;
    margin-top: 0;
    border: none;
    background-color: #f4f5f7;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}

.dropdown-menu_gray .dropdown-item {
  padding: 0 0 0.5rem;
}

@media (min-width: 576px) and (max-width: 1199.98px) {
  .dropdown-menu_gray .dropdown-item {
    padding: 0.25rem 0;
  }
}

@media (min-width: 992px) {
  .dropdown-menu_gray .dropdown-item {
    padding: 0.4rem 1rem;
  }
}

.dropdown-menu_gray .dropdown-item::after {
  display: inline-block;
  padding-left: 0.2rem;
  vertical-align: text-bottom;
  content: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIzcHgiIGhlaWdodD0iNnB4IiB2aWV3Qm94PSIwIDAgMyA2IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPiAgICA8ZyBpZD0iU3ltYm9scyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+ICAgICAgICA8ZyBpZD0i4oC6IiBmaWxsPSIjMDkxRTQyIiBmaWxsLXJ1bGU9Im5vbnplcm8iPiAgICAgICAgICAgIDxwb2x5Z29uIHBvaW50cz0iMC42MTA0NjUxMTYgNiAwIDUuNTQ4Mzg3MSAyLjA1ODEzOTUzIDMgMCAwLjQ4Mzg3MDk2OCAwLjYxMDQ2NTExNiAwIDMgMi41IDMgMy41Ij48L3BvbHlnb24+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=");
}

.dropdown-menu_gray .dropdown-item:hover::after {
  content: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIzcHgiIGhlaWdodD0iNnB4IiB2aWV3Qm94PSIwIDAgMyA2IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+ICAgIDxnIGlkPSJTeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxnIGlkPSLigLoiIGZpbGw9IiMxNThBRkYiIGZpbGwtcnVsZT0ibm9uemVybyI+ICAgICAgICAgICAgPHBvbHlnb24gcG9pbnRzPSIwLjYxMDQ2NTExNiA2IDAgNS41NDgzODcxIDIuMDU4MTM5NTMgMyAwIDAuNDgzODcwOTY4IDAuNjEwNDY1MTE2IDAgMyAyLjUgMyAzLjUiPjwvcG9seWdvbj4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==");
}

@media (min-width: 992px) {
  .dropdown-menu_gray.gray-center {
    text-align: center;
  }
}

.dropdown-menu.js-language-dropdown {
  left: unset;
  right: 0;
  -webkit-transform: unset;
  transform: unset;
  margin: 0.125rem 0.75rem 0 0;
  padding-bottom: 0.5rem;
}

@media (min-width: 992px) {
  .dropdown-menu.js-language-dropdown {
    border-top-left-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}

.dropdown-menu.js-language-dropdown .lang.selected {
  font-weight: 600;
}

@media (min-width: 992px) {
  .show > .dropdown-menu {
    -webkit-animation-name: topnavshow;
    animation-name: topnavshow;
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
}

@-webkit-keyframes topnavshow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes topnavshow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle .caret {
  width: 6px;
  height: 4px;
  margin-left: 0.1615em;
  vertical-align: 0.1615em;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.dropup .dropdown-toggle .caret path {
  transition: all 0.2s ease-in-out;
}

.dropup .dropdown-toggle .caret path {
  fill: #158aff;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle .caret {
  width: 6px;
  height: 4px;
  margin-left: 0.1615em;
  vertical-align: 0.1615em;
  border-top: 0.19em solid transparent;
  border-right: 0;
  border-bottom: 0.19em solid transparent;
  border-left: 0.19em solid;
}

.dropright .dropdown-toggle .caret path {
  transition: all 0.2s ease-in-out;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle .caret {
  width: 6px;
  height: 4px;
  margin-left: 0.1615em;
  vertical-align: 0.1615em;
}

.dropleft .dropdown-toggle .caret path {
  transition: all 0.2s ease-in-out;
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0;
  position: absolute;
  top: 0.5rem;
  left: 1rem;
  content: "";
  border-top: 0.19em solid transparent;
  border-right: 0;
  border-bottom: 0.19em solid transparent;
  border-left: 0.19em solid;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #dfe1e6;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0 0 0.5rem;
  clear: both;
  font-weight: 400;
  color: #091e42;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:last-child {
  margin-bottom: 0.5rem;
}

@media (min-width: 992px) {
  .dropdown-item:last-child {
    margin-bottom: 0;
  }
}

@media (min-width: 576px) and (max-width: 1199.98px) {
  .dropdown-item {
    padding: 0.25rem 2rem;
  }
}

@media (min-width: 992px) {
  .dropdown-item {
    padding: 0.15rem 1rem;
  }
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #158aff;
  text-decoration: none;
}

.dropdown-item.active {
  font-weight: 600;
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #c1c7d0;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

@media (min-width: 992px) {
  .dropdown-menu.show {
    display: table;
  }
}

.dropdown-header {
  display: block;
  padding: 0.5rem 2rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #091e42;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.5rem 2rem;
  color: #091e42;
}

.dropdown-title {
  font-size: 0.75rem;
  font-weight: 600;
  color: #8993a4;
  border-bottom: solid 1px #dfe1e6;
  text-transform: uppercase;
  line-height: 2;
  letter-spacing: .4px;
  width: calc(100% - 2rem);
  margin-bottom: 0.5rem;
  display: block;
  padding: 0 0 0.5rem;
  clear: both;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
}

@media (min-width: 576px) and (max-width: 1199.98px) {
  .dropdown-title {
    margin-left: 2rem;
    padding: 0.25rem 0;
  }
}

@media (min-width: 992px) {
  .dropdown-title {
    margin-left: 1rem;
    margin-right: 1rem;
    padding: 0.2rem 0;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin-top: 5px;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 1;
  margin-top: 5px;
  margin-bottom: 5px;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
  width: 16px;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  bottom: 1px;
  left: 3px;
  content: "";
  position: absolute;
  background: #fff;
  border: 5px solid transparent;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  box-shadow: 1px 1px 5px 0 rgba(23, 43, 77, 0.2);
  z-index: 1;
  width: 12px;
  height: 12px;
  border-radius: 4px;
}

.bs-tooltip-top .arrow::after, .bs-tooltip-auto[x-placement^="top"] .arrow::after {
  content: "";
  position: absolute;
  z-index: 2;
  background: #fff;
  width: 130%;
  height: 130%;
  top: -130%;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #fff;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
  width: 16px;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  top: 1px;
  left: 3px;
  content: "";
  position: absolute;
  background: #fff;
  border: 5px solid transparent;
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
  box-shadow: 1px 1px 5px 0 rgba(23, 43, 77, 0.2);
  z-index: 1;
  width: 12px;
  height: 12px;
  border-radius: 4px;
}

.bs-tooltip-bottom .arrow::after, .bs-tooltip-auto[x-placement^="bottom"] .arrow::after {
  content: "";
  position: absolute;
  z-index: 2;
  background: #fff;
  width: 130%;
  height: 130%;
  bottom: -130%;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #fff;
}

.tooltip-inner {
  max-width: 150px;
  min-width: 148px;
  padding: 1rem;
  color: #091e42;
  text-align: center;
  background-color: #fff;
  line-height: 1;
  box-shadow: 0 0 5px 0 rgba(23, 43, 77, 0.3);
  border-radius: 4px;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 0 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #091e42;
  text-align: center;
  white-space: nowrap;
  background-color: #fafbfc;
  border: 1px solid #c1c7d0;
  border-radius: 0.25rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  height: calc(2.875rem + 8px);
  padding: 0.5rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  height: calc(1.47775rem + 8px);
  padding: 0.157rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.33;
  border-radius: 0.2rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #158aff;
}

.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #c8e3ff;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #c1c7d0;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #fff;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
}

.custom-control-label::before {
  position: absolute;
  top: 0;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #c1c7d0;
}

.custom-control-label::after {
  position: absolute;
  top: 0;
  left: -1.5rem;
  display: block;
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 0.25rem;
}

.solution-main-banner--form .custom-checkbox .custom-control-label::before,
.forms--blue .custom-checkbox .custom-control-label::before {
  border-color: transparent;
}

.solution-main-banner--form .custom-checkbox .custom-control-label.is-invalid::before,
.forms--blue .custom-checkbox .custom-control-label.is-invalid::before {
  border-color: #ff788c !important;
}

.custom-checkbox:hover .custom-control-label::before {
  border: solid 2px #88c4ff;
}

.custom-checkbox:hover .custom-control-label--filter__countries::before,
.custom-checkbox:hover .custom-control-label--filter__categories::before {
  border: solid 1px #c1c7d0;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
  background-color: #fff;
  border: solid 1px #c1c7d0;
}

.custom-checkbox .custom-control-label--filter__countries {
  position: absolute;
  line-height: 1;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.custom-checkbox .custom-control-label--filter__countries::before, .custom-checkbox .custom-control-label--filter__countries::after {
  border-radius: 100%;
  top: 0;
}

.custom-checkbox .custom-control-label--filter__categories {
  position: absolute;
  line-height: 1;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.custom-checkbox .custom-control-label--filter__categories::before, .custom-checkbox .custom-control-label--filter__categories::after {
  top: -7px;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: transparent;
  border: solid 2px #158aff;
  -webkit-transform: none;
  transform: none;
  z-index: 1;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("/local/templates/1ci/i/icons/Common/input.svg");
  background-size: 50%;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
}

.custom-checkbox .custom-control-input--filter__countries:checked ~ .custom-control-label--filter__countries::before {
  border: none;
  z-index: 0;
}

.custom-checkbox .custom-control-input--filter__countries:checked ~ .custom-control-label--filter__countries::after {
  background-image: url("/local/templates/1ci/i/icons/Common/input-filter.svg");
}

.custom-checkbox .custom-control-input--filter__categories:checked ~ .custom-control-label--filter__categories::before {
  background-color: transparent;
  border: solid 2px #158aff;
}

.custom-checkbox .custom-control-input--filter__categories:checked ~ .custom-control-label--filter__categories::after {
  background-image: url("/local/templates/1ci/i/icons/Common/input.svg");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #158aff;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-radio:indeterminate ~ .custom-control-label::before {
  background-color: #fff;
}

.custom-checkbox .custom-control-radio:indeterminate ~ .custom-control-label::after {
  background-image: none;
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #fff;
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: #fff;
}

.forms--blue .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.forms--banner_new .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  border: solid 2px #fff;
}

.select2-results__option[aria-selected="true"] .custom-control-label--filter__countries::before {
  z-index: 0;
  background-color: transparent;
  border: solid 2px #158aff;
  -webkit-transform: none;
  transform: none;
}

.select2-results__option[aria-selected="true"] .custom-control-label--filter__countries::after {
  background-image: url("/local/templates/1ci/i/icons/Common/input-filter.svg");
  background-size: 50%;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
}

.select2-results__option[aria-selected="true"] .custom-control-label--filter__categories::before {
  z-index: 1;
  background-color: transparent;
  border: solid 2px #158aff;
  -webkit-transform: none;
  transform: none;
}

.select2-results__option[aria-selected="true"] .custom-control-label--filter__categories::after {
  background-image: url("/local/templates/1ci/i/icons/Common/input.svg");
  background-size: 50%;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #158aff;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E %3Csvg viewBox='2 .9 10.5 9' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E %3Cdefs%3E %3Ccircle id='path-1' cx='8' cy='8' r='3'%3E%3C/circle%3E %3Cfilter x='-116.7%' y='-83.3%' width='333.3%' height='333.3%' filterUnits='objectBoundingBox' id='filter-2'%3E %3CfeOffset dx='0' dy='2' in='SourceAlpha' result='shadowOffsetOuter1'%3E%3C/feOffset%3E %3CfeGaussianBlur stdDeviation='2' in='shadowOffsetOuter1' result='shadowBlurOuter1'%3E%3C/feGaussianBlur%3E %3CfeColorMatrix values='0 0 0 0 0.203921569   0 0 0 0 0.270588235   0 0 0 0 0.388235294  0 0 0 0.6 0' type='matrix' in='shadowBlurOuter1'%3E%3C/feColorMatrix%3E %3C/filter%3E %3C/defs%3E %3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E %3Cg transform='translate(-1.000000, -3.000000)' fill-rule='nonzero'%3E %3Cg%3E %3Cuse fill='black' fill-opacity='1' filter='url(%23filter-2)' xlink:href='%23path-1'%3E%3C/use%3E %3Cuse fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #fff;
}

.custom-switch {
  padding-left: 3rem;
}

.custom-switch .custom-control-label::before {
  left: -3rem;
  width: 3rem;
  height: 1.5rem;
  pointer-events: all;
  border: solid 1px #c1c7d0;
  border-radius: 1rem;
  background-color: #fff;
}

.custom-switch .custom-control-label::after {
  top: calc(0.125rem + 2px);
  left: calc(-3rem + 4px);
  width: 1rem;
  height: 1rem;
  background-color: #158aff;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #fff;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #158aff;
  -webkit-transform: translateX(1.5rem);
  transform: translateX(1.5rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #fff;
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2rem + 8px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #091e42;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23d0d4dd' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #c1c7d0;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-select:focus {
  border-color: #88c4ff;
  outline: 0;
  box-shadow: 0 0 0 0.05rem rgba(136, 196, 255, 0.5);
}

.custom-select:focus::-ms-value {
  color: #091e42;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #c1c7d0;
  background-color: #fafbfc;
}

.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: calc(1.47775rem + 8px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}

.custom-select-lg {
  height: calc(2.875rem + 8px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2rem + 8px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2rem + 8px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #88c4ff;
  box-shadow: 0 0 0 0.05rem #88c4ff;
}

.custom-file-input:focus ~ .custom-file-label::after {
  border-color: #88c4ff;
}

.custom-file-input:disabled ~ .custom-file-label {
  background-color: #fafbfc;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Select File";
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2rem + 8px);
  padding: 0.4rem 1rem;
  line-height: 1.5;
  color: #091e42;
  background-color: #fff;
  border: 1px solid #c1c7d0;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2rem;
  padding: 0.4rem 1rem;
  line-height: 1.5;
  color: #091e42;
  content: "Browse";
  background-color: #ffdd2d;
  border-left: 1px solid #c1c7d0;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.05rem #88c4ff;
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.05rem #88c4ff;
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.05rem #88c4ff;
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #158aff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #c8e3ff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #f4f5f7;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #158aff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #c8e3ff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #f4f5f7;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.05rem;
  margin-left: 0.05rem;
  background-color: #158aff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #c8e3ff;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #f4f5f7;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #f4f5f7;
  border-radius: 1rem;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.phone__mask-container {
  display: flex;
  position: relative;
  width: 100%;
}

.phone__mask-placeholder {
  cursor: text;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin-left: 16px;
  color: #091e42;
  margin-right: 6px;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
}

.phone__mask-placeholder__container {
  position: relative;
  width: 100%;
}

.phone__mask-input {
  border-radius: 0 0.25rem 0.25rem 0;
  padding: 8px 0 8px 50px;
}

.phone__mask-input::-webkit-input-placeholder {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  color: #8993a4;
}

.phone__mask-input::-moz-placeholder {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  color: #8993a4;
}

.phone__mask-input::-ms-input-placeholder {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  color: #8993a4;
}

.phone__mask-input::placeholder {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  color: #8993a4;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 0.875rem;
}

@media (min-width: 992px) {
  .nav-link {
    padding: 0.5rem 0.875rem 0.125rem;
  }
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #c1c7d0;
}

.nav-tabs {
  border-bottom: 2px solid #f4f5f7;
}

.nav-tabs .nav-item {
  margin-bottom: -2px;
}

.nav-tabs .nav-link {
  color: #6c798f;
  font-size: 16px;
  line-height: 24px;
  border-bottom: 4px solid transparent;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #fafbfc #fafbfc #f4f5f7;
}

.nav-tabs .nav-link.disabled {
  color: #c1c7d0;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #091e42;
  background-color: #fff;
  border-bottom-color: #158aff;
}

.nav-tabs .dropdown-menu {
  margin-top: -2px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #158aff;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.95rem 1rem;
}

.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 1rem;
  font-size: 0.7rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin: 2rem 0 2.5rem auto;
  list-style: none;
}

@media (min-width: 992px) {
  .navbar-nav {
    margin: 0 0 0 auto;
  }
}

.navbar-nav .nav-item {
  border-bottom: 1px solid #d0d4dd;
}

.navbar-nav .nav-item:last-of-type {
  border-bottom: none;
}

@media (min-width: 992px) {
  .navbar-nav .nav-item {
    padding-right: 0.875rem;
    border-bottom: none;
  }
}

.navbar-nav .nav-item .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .nav-item .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .navbar-collapse {
    position: fixed;
    top: 0;
    width: 322px;
    height: 100vh;
    background-color: #fff;
    z-index: 1060;
    padding: 0 2.5rem 0 2rem;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }
  .navbar-collapse.collapsing {
    transition: right 0.3s ease;
    right: -100%;
  }
}

@media screen and (min-width: 576px) and (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .navbar-collapse.collapsing {
    transition: none;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .navbar-collapse.show {
    right: 0;
    transition: right 0.3s ease;
  }
}

@media screen and (min-width: 576px) and (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .navbar-collapse.show {
    transition: none;
  }
}

.navbar-toggler {
  padding: 0.25rem 0;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 0;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.tablet-toggler {
  display: none;
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .tablet-toggler {
    display: inline-block;
    margin-left: 240px;
    margin-top: 20px;
  }
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: #158aff;
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: #158aff;
}

.navbar-light .navbar-nav .nav-link {
  color: #172b4d;
}

.navbar-light .navbar-nav .nav-link::after {
  color: #42526e;
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: #158aff;
}

.navbar-light .navbar-nav .nav-link:hover .caret path, .navbar-light .navbar-nav .nav-link:focus .caret path {
  fill: #158aff;
}

.navbar-light .navbar-nav .nav-link:hover::after, .navbar-light .navbar-nav .nav-link:focus::after {
  color: #158aff;
}

.navbar-light .navbar-nav .nav-link:hover::after .caret path, .navbar-light .navbar-nav .nav-link:focus::after .caret path {
  fill: #158aff;
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.show {
  color: #158aff;
}

@media (max-width: 1199.98px) {
  .navbar-light .navbar-nav .show > .nav-link,
  .navbar-light .navbar-nav .nav-link.show {
    color: #158aff;
  }
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: #158aff;
}

.navbar-light .navbar-toggler {
  color: #172b4d;
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23172b4d' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: #172b4d;
}

.navbar-light .navbar-text a {
  color: #158aff;
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: #158aff;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
  transform: translate(0, -25%);
}

@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (0.5rem * 2));
  content: "";
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.5rem;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.modal-header .close {
  padding: 0.5rem;
  margin: -1rem -0.1rem -0.5rem 0;
}

.modal-header .close img {
  margin-top: 0;
}

.modal-header img {
  padding-left: 32px;
  margin-top: 0.5rem;
  margin-left: auto;
  margin-right: auto;
}

.modal-title {
  color: #172b4d;
  margin: 0 1rem;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0.5rem 2rem 1.5rem;
}

.modal-body h2 {
  text-align: center;
  font-size: 1.6875rem;
  font-weight: 400;
}

.modal-body p {
  color: #6c798f;
  text-align: center;
  font-size: 1.0625rem;
  margin-bottom: 0;
  margin-top: 0.5rem;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 2rem;
  border-top: 1px solid #dfe1e6;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 416px;
    margin: 1.75rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 732px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.close {
  float: right;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1;
  color: #42526e;
}

.close path {
  transition: all 0.2s ease-in-out;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  color: #42526e;
  text-decoration: none;
  outline: none;
}

.close:not(:disabled):not(.disabled):hover path, .close:not(:disabled):not(.disabled):focus path {
  fill: #158aff;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem 0;
  margin-bottom: 0.75rem;
  list-style: none;
}

.main-banner .breadcrumb-item {
  font-size: 0.9375rem;
  padding-right: 0.5rem;
  color: #091e42;
}

.main-banner .breadcrumb-item a {
  color: #091e42;
  position: relative;
}

.main-banner .breadcrumb-item a::before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: currentColor;
  opacity: 0;
  transition: opacity .2s;
}

.main-banner .breadcrumb-item a:hover::before {
  opacity: 1;
}

.main-banner .breadcrumb-item:not(:last-child)::after {
  display: inline-block;
  padding-left: 0.4rem;
  color: #091e42;
  content: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIzcHgiIGhlaWdodD0iNnB4IiB2aWV3Qm94PSIwIDAgMyA2IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPiAgICA8ZyBpZD0iU3ltYm9scyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+ICAgICAgICA8ZyBpZD0i4oC6IiBmaWxsPSIjMDkxRTQyIiBmaWxsLXJ1bGU9Im5vbnplcm8iPiAgICAgICAgICAgIDxwb2x5Z29uIHBvaW50cz0iMC42MTA0NjUxMTYgNiAwIDUuNTQ4Mzg3MSAyLjA1ODEzOTUzIDMgMCAwLjQ4Mzg3MDk2OCAwLjYxMDQ2NTExNiAwIDMgMi41IDMgMy41Ij48L3BvbHlnb24+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=");
}

.main-banner .breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.main-banner .breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.main-banner .breadcrumb-item.active {
  color: #091e42;
}

.solution-main-banner .breadcrumb-item,
.main-banner-blue .breadcrumb-item,
.partner-banner--blue .breadcrumb-item,
.g-bg-dark-grey-blue .breadcrumb-item,
.g-bg-dark-indigo .breadcrumb-item,
.g-bg-blue .breadcrumb-item,
.g-bg-dark-blue .breadcrumb-item {
  font-size: 0.9375rem;
  padding-right: 0.5rem;
  color: #fff;
}

.solution-main-banner .breadcrumb-item a,
.main-banner-blue .breadcrumb-item a,
.partner-banner--blue .breadcrumb-item a,
.g-bg-dark-grey-blue .breadcrumb-item a,
.g-bg-dark-indigo .breadcrumb-item a,
.g-bg-blue .breadcrumb-item a,
.g-bg-dark-blue .breadcrumb-item a {
  color: #fff;
  position: relative;
}

.solution-main-banner .breadcrumb-item a::before,
.main-banner-blue .breadcrumb-item a::before,
.partner-banner--blue .breadcrumb-item a::before,
.g-bg-dark-grey-blue .breadcrumb-item a::before,
.g-bg-dark-indigo .breadcrumb-item a::before,
.g-bg-blue .breadcrumb-item a::before,
.g-bg-dark-blue .breadcrumb-item a::before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: currentColor;
  opacity: 0;
  transition: opacity .2s;
}

.solution-main-banner .breadcrumb-item a:hover::before,
.main-banner-blue .breadcrumb-item a:hover::before,
.partner-banner--blue .breadcrumb-item a:hover::before,
.g-bg-dark-grey-blue .breadcrumb-item a:hover::before,
.g-bg-dark-indigo .breadcrumb-item a:hover::before,
.g-bg-blue .breadcrumb-item a:hover::before,
.g-bg-dark-blue .breadcrumb-item a:hover::before {
  opacity: 1;
}

.solution-main-banner .breadcrumb-item:not(:last-child)::after,
.main-banner-blue .breadcrumb-item:not(:last-child)::after,
.partner-banner--blue .breadcrumb-item:not(:last-child)::after,
.g-bg-dark-grey-blue .breadcrumb-item:not(:last-child)::after,
.g-bg-dark-indigo .breadcrumb-item:not(:last-child)::after,
.g-bg-blue .breadcrumb-item:not(:last-child)::after,
.g-bg-dark-blue .breadcrumb-item:not(:last-child)::after {
  display: inline-block;
  padding-left: 0.4rem;
  color: #fff;
  content: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIzcHgiIGhlaWdodD0iNnB4IiB2aWV3Qm94PSIwIDAgMyA2IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+ICAgIDxnIGlkPSJTeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxnIGlkPSLigLoiIGZpbGw9IiNmZmYiIGZpbGwtcnVsZT0ibm9uemVybyI+ICAgICAgICAgICAgPHBvbHlnb24gcG9pbnRzPSIwLjYxMDQ2NTExNiA2IDAgNS41NDgzODcxIDIuMDU4MTM5NTMgMyAwIDAuNDgzODcwOTY4IDAuNjEwNDY1MTE2IDAgMyAyLjUgMyAzLjUiPjwvcG9seWdvbj4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==");
}

.solution-main-banner .breadcrumb-item + .breadcrumb-item:hover::before,
.main-banner-blue .breadcrumb-item + .breadcrumb-item:hover::before,
.partner-banner--blue .breadcrumb-item + .breadcrumb-item:hover::before,
.g-bg-dark-grey-blue .breadcrumb-item + .breadcrumb-item:hover::before,
.g-bg-dark-indigo .breadcrumb-item + .breadcrumb-item:hover::before,
.g-bg-blue .breadcrumb-item + .breadcrumb-item:hover::before,
.g-bg-dark-blue .breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.solution-main-banner .breadcrumb-item + .breadcrumb-item:hover::before,
.main-banner-blue .breadcrumb-item + .breadcrumb-item:hover::before,
.partner-banner--blue .breadcrumb-item + .breadcrumb-item:hover::before,
.g-bg-dark-grey-blue .breadcrumb-item + .breadcrumb-item:hover::before,
.g-bg-dark-indigo .breadcrumb-item + .breadcrumb-item:hover::before,
.g-bg-blue .breadcrumb-item + .breadcrumb-item:hover::before,
.g-bg-dark-blue .breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.solution-main-banner .breadcrumb-item.active,
.main-banner-blue .breadcrumb-item.active,
.partner-banner--blue .breadcrumb-item.active,
.g-bg-dark-grey-blue .breadcrumb-item.active,
.g-bg-dark-indigo .breadcrumb-item.active,
.g-bg-blue .breadcrumb-item.active,
.g-bg-dark-blue .breadcrumb-item.active {
  color: #091e42;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #ffdd2d !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #f9d100 !important;
}

.bg-links {
  background-color: #158aff !important;
}

a.bg-links:hover, a.bg-links:focus,
button.bg-links:hover,
button.bg-links:focus {
  background-color: #0071e1 !important;
}

.bg-secondary {
  background-color: #fff !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #e6e6e6 !important;
}

.bg-success {
  background-color: #8be2ad !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #62d890 !important;
}

.bg-info {
  background-color: #158aff !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #0071e1 !important;
}

.bg-warning {
  background-color: #ffcd33 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ffc100 !important;
}

.bg-danger {
  background-color: #ff788c !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ff4561 !important;
}

.bg-dark {
  background-color: #091e42 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #030a15 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dfe1e6 !important;
}

.border-top {
  border-top: 1px solid #dfe1e6 !important;
}

.border-right {
  border-right: 1px solid #dfe1e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dfe1e6 !important;
}

.border-left {
  border-left: 1px solid #dfe1e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #ffdd2d !important;
}

.border-links {
  border-color: #158aff !important;
}

.border-secondary {
  border-color: #fff !important;
}

.border-success {
  border-color: #8be2ad !important;
}

.border-info {
  border-color: #158aff !important;
}

.border-warning {
  border-color: #ffcd33 !important;
}

.border-danger {
  border-color: #ff788c !important;
}

.border-dark {
  border-color: #091e42 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none, .only-mobile, .only-mobile.only-desktop, .only-mobile.only-tablet, .only-mobile.only-desktop.only-tablet {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block, .only-tablet, .only-tablet.only-desktop {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none, .only-tablet, .only-tablet.only-desktop {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block, .only-mobile, .only-mobile.only-desktop {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none, .only-desktop, .only-mobile.only-desktop {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block, .only-tablet, .only-mobile.only-tablet {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .landing-public-mode .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem 1px rgba(0, 0, 0, 0.1) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem 1px rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem 1px rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-11 {
  margin: 0.15rem !important;
}

.mt-11,
.my-11 {
  margin-top: 0.15rem !important;
}

.mr-11,
.mx-11 {
  margin-right: 0.15rem !important;
}

.mb-11,
.my-11 {
  margin-bottom: 0.15rem !important;
}

.ml-11,
.mx-11 {
  margin-left: 0.15rem !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-25 {
  margin: 0.75rem !important;
}

.mt-25,
.my-25 {
  margin-top: 0.75rem !important;
}

.mr-25,
.mx-25 {
  margin-right: 0.75rem !important;
}

.mb-25,
.my-25 {
  margin-bottom: 0.75rem !important;
}

.ml-25,
.mx-25 {
  margin-left: 0.75rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-42 {
  margin: 2rem !important;
}

.mt-42,
.my-42 {
  margin-top: 2rem !important;
}

.mr-42,
.mx-42 {
  margin-right: 2rem !important;
}

.mb-42,
.my-42 {
  margin-bottom: 2rem !important;
}

.ml-42,
.mx-42 {
  margin-left: 2rem !important;
}

.m-425 {
  margin: 2.5rem !important;
}

.mt-425,
.my-425 {
  margin-top: 2.5rem !important;
}

.mr-425,
.mx-425 {
  margin-right: 2.5rem !important;
}

.mb-425,
.my-425 {
  margin-bottom: 2.5rem !important;
}

.ml-425,
.mx-425 {
  margin-left: 2.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.m-55 {
  margin: 3.5rem !important;
}

.mt-55,
.my-55 {
  margin-top: 3.5rem !important;
}

.mr-55,
.mx-55 {
  margin-right: 3.5rem !important;
}

.mb-55,
.my-55 {
  margin-bottom: 3.5rem !important;
}

.ml-55,
.mx-55 {
  margin-left: 3.5rem !important;
}

.m-6 {
  margin: 4rem !important;
}

.mt-6,
.my-6 {
  margin-top: 4rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 4rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 4rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 4rem !important;
}

.m-65 {
  margin: 4.5rem !important;
}

.mt-65,
.my-65 {
  margin-top: 4.5rem !important;
}

.mr-65,
.mx-65 {
  margin-right: 4.5rem !important;
}

.mb-65,
.my-65 {
  margin-bottom: 4.5rem !important;
}

.ml-65,
.mx-65 {
  margin-left: 4.5rem !important;
}

.m-7 {
  margin: 5rem !important;
}

.mt-7,
.my-7 {
  margin-top: 5rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 5rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 5rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 5rem !important;
}

.m-75 {
  margin: 5.5rem !important;
}

.mt-75,
.my-75 {
  margin-top: 5.5rem !important;
}

.mr-75,
.mx-75 {
  margin-right: 5.5rem !important;
}

.mb-75,
.my-75 {
  margin-bottom: 5.5rem !important;
}

.ml-75,
.mx-75 {
  margin-left: 5.5rem !important;
}

.m-8 {
  margin: 6rem !important;
}

.mt-8,
.my-8 {
  margin-top: 6rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 6rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 6rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 6rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-11 {
  padding: 0.15rem !important;
}

.pt-11,
.py-11 {
  padding-top: 0.15rem !important;
}

.pr-11,
.px-11 {
  padding-right: 0.15rem !important;
}

.pb-11,
.py-11 {
  padding-bottom: 0.15rem !important;
}

.pl-11,
.px-11 {
  padding-left: 0.15rem !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-25 {
  padding: 0.75rem !important;
}

.pt-25,
.py-25 {
  padding-top: 0.75rem !important;
}

.pr-25,
.px-25 {
  padding-right: 0.75rem !important;
}

.pb-25,
.py-25 {
  padding-bottom: 0.75rem !important;
}

.pl-25,
.px-25 {
  padding-left: 0.75rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-42 {
  padding: 2rem !important;
}

.pt-42,
.py-42 {
  padding-top: 2rem !important;
}

.pr-42,
.px-42 {
  padding-right: 2rem !important;
}

.pb-42,
.py-42 {
  padding-bottom: 2rem !important;
}

.pl-42,
.px-42 {
  padding-left: 2rem !important;
}

.p-425 {
  padding: 2.5rem !important;
}

.pt-425,
.py-425 {
  padding-top: 2.5rem !important;
}

.pr-425,
.px-425 {
  padding-right: 2.5rem !important;
}

.pb-425,
.py-425 {
  padding-bottom: 2.5rem !important;
}

.pl-425,
.px-425 {
  padding-left: 2.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.p-55 {
  padding: 3.5rem !important;
}

.pt-55,
.py-55 {
  padding-top: 3.5rem !important;
}

.pr-55,
.px-55 {
  padding-right: 3.5rem !important;
}

.pb-55,
.py-55 {
  padding-bottom: 3.5rem !important;
}

.pl-55,
.px-55 {
  padding-left: 3.5rem !important;
}

.p-6 {
  padding: 4rem !important;
}

.pt-6,
.py-6 {
  padding-top: 4rem !important;
}

.pr-6,
.px-6 {
  padding-right: 4rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 4rem !important;
}

.pl-6,
.px-6 {
  padding-left: 4rem !important;
}

.p-65 {
  padding: 4.5rem !important;
}

.pt-65,
.py-65 {
  padding-top: 4.5rem !important;
}

.pr-65,
.px-65 {
  padding-right: 4.5rem !important;
}

.pb-65,
.py-65 {
  padding-bottom: 4.5rem !important;
}

.pl-65,
.px-65 {
  padding-left: 4.5rem !important;
}

.p-7 {
  padding: 5rem !important;
}

.pt-7,
.py-7 {
  padding-top: 5rem !important;
}

.pr-7,
.px-7 {
  padding-right: 5rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 5rem !important;
}

.pl-7,
.px-7 {
  padding-left: 5rem !important;
}

.p-75 {
  padding: 5.5rem !important;
}

.pt-75,
.py-75 {
  padding-top: 5.5rem !important;
}

.pr-75,
.px-75 {
  padding-right: 5.5rem !important;
}

.pb-75,
.py-75 {
  padding-bottom: 5.5rem !important;
}

.pl-75,
.px-75 {
  padding-left: 5.5rem !important;
}

.p-8 {
  padding: 6rem !important;
}

.pt-8,
.py-8 {
  padding-top: 6rem !important;
}

.pr-8,
.px-8 {
  padding-right: 6rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 6rem !important;
}

.pl-8,
.px-8 {
  padding-left: 6rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-11 {
    margin: 0.15rem !important;
  }
  .mt-sm-11,
  .my-sm-11 {
    margin-top: 0.15rem !important;
  }
  .mr-sm-11,
  .mx-sm-11 {
    margin-right: 0.15rem !important;
  }
  .mb-sm-11,
  .my-sm-11 {
    margin-bottom: 0.15rem !important;
  }
  .ml-sm-11,
  .mx-sm-11 {
    margin-left: 0.15rem !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-25 {
    margin: 0.75rem !important;
  }
  .mt-sm-25,
  .my-sm-25 {
    margin-top: 0.75rem !important;
  }
  .mr-sm-25,
  .mx-sm-25 {
    margin-right: 0.75rem !important;
  }
  .mb-sm-25,
  .my-sm-25 {
    margin-bottom: 0.75rem !important;
  }
  .ml-sm-25,
  .mx-sm-25 {
    margin-left: 0.75rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-42 {
    margin: 2rem !important;
  }
  .mt-sm-42,
  .my-sm-42 {
    margin-top: 2rem !important;
  }
  .mr-sm-42,
  .mx-sm-42 {
    margin-right: 2rem !important;
  }
  .mb-sm-42,
  .my-sm-42 {
    margin-bottom: 2rem !important;
  }
  .ml-sm-42,
  .mx-sm-42 {
    margin-left: 2rem !important;
  }
  .m-sm-425 {
    margin: 2.5rem !important;
  }
  .mt-sm-425,
  .my-sm-425 {
    margin-top: 2.5rem !important;
  }
  .mr-sm-425,
  .mx-sm-425 {
    margin-right: 2.5rem !important;
  }
  .mb-sm-425,
  .my-sm-425 {
    margin-bottom: 2.5rem !important;
  }
  .ml-sm-425,
  .mx-sm-425 {
    margin-left: 2.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .m-sm-55 {
    margin: 3.5rem !important;
  }
  .mt-sm-55,
  .my-sm-55 {
    margin-top: 3.5rem !important;
  }
  .mr-sm-55,
  .mx-sm-55 {
    margin-right: 3.5rem !important;
  }
  .mb-sm-55,
  .my-sm-55 {
    margin-bottom: 3.5rem !important;
  }
  .ml-sm-55,
  .mx-sm-55 {
    margin-left: 3.5rem !important;
  }
  .m-sm-6 {
    margin: 4rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 4rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 4rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 4rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 4rem !important;
  }
  .m-sm-65 {
    margin: 4.5rem !important;
  }
  .mt-sm-65,
  .my-sm-65 {
    margin-top: 4.5rem !important;
  }
  .mr-sm-65,
  .mx-sm-65 {
    margin-right: 4.5rem !important;
  }
  .mb-sm-65,
  .my-sm-65 {
    margin-bottom: 4.5rem !important;
  }
  .ml-sm-65,
  .mx-sm-65 {
    margin-left: 4.5rem !important;
  }
  .m-sm-7 {
    margin: 5rem !important;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 5rem !important;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 5rem !important;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 5rem !important;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 5rem !important;
  }
  .m-sm-75 {
    margin: 5.5rem !important;
  }
  .mt-sm-75,
  .my-sm-75 {
    margin-top: 5.5rem !important;
  }
  .mr-sm-75,
  .mx-sm-75 {
    margin-right: 5.5rem !important;
  }
  .mb-sm-75,
  .my-sm-75 {
    margin-bottom: 5.5rem !important;
  }
  .ml-sm-75,
  .mx-sm-75 {
    margin-left: 5.5rem !important;
  }
  .m-sm-8 {
    margin: 6rem !important;
  }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 6rem !important;
  }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 6rem !important;
  }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 6rem !important;
  }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 6rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-11 {
    padding: 0.15rem !important;
  }
  .pt-sm-11,
  .py-sm-11 {
    padding-top: 0.15rem !important;
  }
  .pr-sm-11,
  .px-sm-11 {
    padding-right: 0.15rem !important;
  }
  .pb-sm-11,
  .py-sm-11 {
    padding-bottom: 0.15rem !important;
  }
  .pl-sm-11,
  .px-sm-11 {
    padding-left: 0.15rem !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-25 {
    padding: 0.75rem !important;
  }
  .pt-sm-25,
  .py-sm-25 {
    padding-top: 0.75rem !important;
  }
  .pr-sm-25,
  .px-sm-25 {
    padding-right: 0.75rem !important;
  }
  .pb-sm-25,
  .py-sm-25 {
    padding-bottom: 0.75rem !important;
  }
  .pl-sm-25,
  .px-sm-25 {
    padding-left: 0.75rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-42 {
    padding: 2rem !important;
  }
  .pt-sm-42,
  .py-sm-42 {
    padding-top: 2rem !important;
  }
  .pr-sm-42,
  .px-sm-42 {
    padding-right: 2rem !important;
  }
  .pb-sm-42,
  .py-sm-42 {
    padding-bottom: 2rem !important;
  }
  .pl-sm-42,
  .px-sm-42 {
    padding-left: 2rem !important;
  }
  .p-sm-425 {
    padding: 2.5rem !important;
  }
  .pt-sm-425,
  .py-sm-425 {
    padding-top: 2.5rem !important;
  }
  .pr-sm-425,
  .px-sm-425 {
    padding-right: 2.5rem !important;
  }
  .pb-sm-425,
  .py-sm-425 {
    padding-bottom: 2.5rem !important;
  }
  .pl-sm-425,
  .px-sm-425 {
    padding-left: 2.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .p-sm-55 {
    padding: 3.5rem !important;
  }
  .pt-sm-55,
  .py-sm-55 {
    padding-top: 3.5rem !important;
  }
  .pr-sm-55,
  .px-sm-55 {
    padding-right: 3.5rem !important;
  }
  .pb-sm-55,
  .py-sm-55 {
    padding-bottom: 3.5rem !important;
  }
  .pl-sm-55,
  .px-sm-55 {
    padding-left: 3.5rem !important;
  }
  .p-sm-6 {
    padding: 4rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 4rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 4rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 4rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 4rem !important;
  }
  .p-sm-65 {
    padding: 4.5rem !important;
  }
  .pt-sm-65,
  .py-sm-65 {
    padding-top: 4.5rem !important;
  }
  .pr-sm-65,
  .px-sm-65 {
    padding-right: 4.5rem !important;
  }
  .pb-sm-65,
  .py-sm-65 {
    padding-bottom: 4.5rem !important;
  }
  .pl-sm-65,
  .px-sm-65 {
    padding-left: 4.5rem !important;
  }
  .p-sm-7 {
    padding: 5rem !important;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 5rem !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 5rem !important;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 5rem !important;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 5rem !important;
  }
  .p-sm-75 {
    padding: 5.5rem !important;
  }
  .pt-sm-75,
  .py-sm-75 {
    padding-top: 5.5rem !important;
  }
  .pr-sm-75,
  .px-sm-75 {
    padding-right: 5.5rem !important;
  }
  .pb-sm-75,
  .py-sm-75 {
    padding-bottom: 5.5rem !important;
  }
  .pl-sm-75,
  .px-sm-75 {
    padding-left: 5.5rem !important;
  }
  .p-sm-8 {
    padding: 6rem !important;
  }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 6rem !important;
  }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 6rem !important;
  }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 6rem !important;
  }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 6rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-11 {
    margin: 0.15rem !important;
  }
  .mt-md-11,
  .my-md-11 {
    margin-top: 0.15rem !important;
  }
  .mr-md-11,
  .mx-md-11 {
    margin-right: 0.15rem !important;
  }
  .mb-md-11,
  .my-md-11 {
    margin-bottom: 0.15rem !important;
  }
  .ml-md-11,
  .mx-md-11 {
    margin-left: 0.15rem !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-25 {
    margin: 0.75rem !important;
  }
  .mt-md-25,
  .my-md-25 {
    margin-top: 0.75rem !important;
  }
  .mr-md-25,
  .mx-md-25 {
    margin-right: 0.75rem !important;
  }
  .mb-md-25,
  .my-md-25 {
    margin-bottom: 0.75rem !important;
  }
  .ml-md-25,
  .mx-md-25 {
    margin-left: 0.75rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-42 {
    margin: 2rem !important;
  }
  .mt-md-42,
  .my-md-42 {
    margin-top: 2rem !important;
  }
  .mr-md-42,
  .mx-md-42 {
    margin-right: 2rem !important;
  }
  .mb-md-42,
  .my-md-42 {
    margin-bottom: 2rem !important;
  }
  .ml-md-42,
  .mx-md-42 {
    margin-left: 2rem !important;
  }
  .m-md-425 {
    margin: 2.5rem !important;
  }
  .mt-md-425,
  .my-md-425 {
    margin-top: 2.5rem !important;
  }
  .mr-md-425,
  .mx-md-425 {
    margin-right: 2.5rem !important;
  }
  .mb-md-425,
  .my-md-425 {
    margin-bottom: 2.5rem !important;
  }
  .ml-md-425,
  .mx-md-425 {
    margin-left: 2.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .m-md-55 {
    margin: 3.5rem !important;
  }
  .mt-md-55,
  .my-md-55 {
    margin-top: 3.5rem !important;
  }
  .mr-md-55,
  .mx-md-55 {
    margin-right: 3.5rem !important;
  }
  .mb-md-55,
  .my-md-55 {
    margin-bottom: 3.5rem !important;
  }
  .ml-md-55,
  .mx-md-55 {
    margin-left: 3.5rem !important;
  }
  .m-md-6 {
    margin: 4rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 4rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 4rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 4rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 4rem !important;
  }
  .m-md-65 {
    margin: 4.5rem !important;
  }
  .mt-md-65,
  .my-md-65 {
    margin-top: 4.5rem !important;
  }
  .mr-md-65,
  .mx-md-65 {
    margin-right: 4.5rem !important;
  }
  .mb-md-65,
  .my-md-65 {
    margin-bottom: 4.5rem !important;
  }
  .ml-md-65,
  .mx-md-65 {
    margin-left: 4.5rem !important;
  }
  .m-md-7 {
    margin: 5rem !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 5rem !important;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 5rem !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 5rem !important;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 5rem !important;
  }
  .m-md-75 {
    margin: 5.5rem !important;
  }
  .mt-md-75,
  .my-md-75 {
    margin-top: 5.5rem !important;
  }
  .mr-md-75,
  .mx-md-75 {
    margin-right: 5.5rem !important;
  }
  .mb-md-75,
  .my-md-75 {
    margin-bottom: 5.5rem !important;
  }
  .ml-md-75,
  .mx-md-75 {
    margin-left: 5.5rem !important;
  }
  .m-md-8 {
    margin: 6rem !important;
  }
  .mt-md-8,
  .my-md-8 {
    margin-top: 6rem !important;
  }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 6rem !important;
  }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 6rem !important;
  }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 6rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-11 {
    padding: 0.15rem !important;
  }
  .pt-md-11,
  .py-md-11 {
    padding-top: 0.15rem !important;
  }
  .pr-md-11,
  .px-md-11 {
    padding-right: 0.15rem !important;
  }
  .pb-md-11,
  .py-md-11 {
    padding-bottom: 0.15rem !important;
  }
  .pl-md-11,
  .px-md-11 {
    padding-left: 0.15rem !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-25 {
    padding: 0.75rem !important;
  }
  .pt-md-25,
  .py-md-25 {
    padding-top: 0.75rem !important;
  }
  .pr-md-25,
  .px-md-25 {
    padding-right: 0.75rem !important;
  }
  .pb-md-25,
  .py-md-25 {
    padding-bottom: 0.75rem !important;
  }
  .pl-md-25,
  .px-md-25 {
    padding-left: 0.75rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-42 {
    padding: 2rem !important;
  }
  .pt-md-42,
  .py-md-42 {
    padding-top: 2rem !important;
  }
  .pr-md-42,
  .px-md-42 {
    padding-right: 2rem !important;
  }
  .pb-md-42,
  .py-md-42 {
    padding-bottom: 2rem !important;
  }
  .pl-md-42,
  .px-md-42 {
    padding-left: 2rem !important;
  }
  .p-md-425 {
    padding: 2.5rem !important;
  }
  .pt-md-425,
  .py-md-425 {
    padding-top: 2.5rem !important;
  }
  .pr-md-425,
  .px-md-425 {
    padding-right: 2.5rem !important;
  }
  .pb-md-425,
  .py-md-425 {
    padding-bottom: 2.5rem !important;
  }
  .pl-md-425,
  .px-md-425 {
    padding-left: 2.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .p-md-55 {
    padding: 3.5rem !important;
  }
  .pt-md-55,
  .py-md-55 {
    padding-top: 3.5rem !important;
  }
  .pr-md-55,
  .px-md-55 {
    padding-right: 3.5rem !important;
  }
  .pb-md-55,
  .py-md-55 {
    padding-bottom: 3.5rem !important;
  }
  .pl-md-55,
  .px-md-55 {
    padding-left: 3.5rem !important;
  }
  .p-md-6 {
    padding: 4rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 4rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 4rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 4rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 4rem !important;
  }
  .p-md-65 {
    padding: 4.5rem !important;
  }
  .pt-md-65,
  .py-md-65 {
    padding-top: 4.5rem !important;
  }
  .pr-md-65,
  .px-md-65 {
    padding-right: 4.5rem !important;
  }
  .pb-md-65,
  .py-md-65 {
    padding-bottom: 4.5rem !important;
  }
  .pl-md-65,
  .px-md-65 {
    padding-left: 4.5rem !important;
  }
  .p-md-7 {
    padding: 5rem !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 5rem !important;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 5rem !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 5rem !important;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 5rem !important;
  }
  .p-md-75 {
    padding: 5.5rem !important;
  }
  .pt-md-75,
  .py-md-75 {
    padding-top: 5.5rem !important;
  }
  .pr-md-75,
  .px-md-75 {
    padding-right: 5.5rem !important;
  }
  .pb-md-75,
  .py-md-75 {
    padding-bottom: 5.5rem !important;
  }
  .pl-md-75,
  .px-md-75 {
    padding-left: 5.5rem !important;
  }
  .p-md-8 {
    padding: 6rem !important;
  }
  .pt-md-8,
  .py-md-8 {
    padding-top: 6rem !important;
  }
  .pr-md-8,
  .px-md-8 {
    padding-right: 6rem !important;
  }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 6rem !important;
  }
  .pl-md-8,
  .px-md-8 {
    padding-left: 6rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-11 {
    margin: 0.15rem !important;
  }
  .mt-lg-11,
  .my-lg-11 {
    margin-top: 0.15rem !important;
  }
  .mr-lg-11,
  .mx-lg-11 {
    margin-right: 0.15rem !important;
  }
  .mb-lg-11,
  .my-lg-11 {
    margin-bottom: 0.15rem !important;
  }
  .ml-lg-11,
  .mx-lg-11 {
    margin-left: 0.15rem !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-25 {
    margin: 0.75rem !important;
  }
  .mt-lg-25,
  .my-lg-25 {
    margin-top: 0.75rem !important;
  }
  .mr-lg-25,
  .mx-lg-25 {
    margin-right: 0.75rem !important;
  }
  .mb-lg-25,
  .my-lg-25 {
    margin-bottom: 0.75rem !important;
  }
  .ml-lg-25,
  .mx-lg-25 {
    margin-left: 0.75rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-42 {
    margin: 2rem !important;
  }
  .mt-lg-42,
  .my-lg-42 {
    margin-top: 2rem !important;
  }
  .mr-lg-42,
  .mx-lg-42 {
    margin-right: 2rem !important;
  }
  .mb-lg-42,
  .my-lg-42 {
    margin-bottom: 2rem !important;
  }
  .ml-lg-42,
  .mx-lg-42 {
    margin-left: 2rem !important;
  }
  .m-lg-425 {
    margin: 2.5rem !important;
  }
  .mt-lg-425,
  .my-lg-425 {
    margin-top: 2.5rem !important;
  }
  .mr-lg-425,
  .mx-lg-425 {
    margin-right: 2.5rem !important;
  }
  .mb-lg-425,
  .my-lg-425 {
    margin-bottom: 2.5rem !important;
  }
  .ml-lg-425,
  .mx-lg-425 {
    margin-left: 2.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .m-lg-55 {
    margin: 3.5rem !important;
  }
  .mt-lg-55,
  .my-lg-55 {
    margin-top: 3.5rem !important;
  }
  .mr-lg-55,
  .mx-lg-55 {
    margin-right: 3.5rem !important;
  }
  .mb-lg-55,
  .my-lg-55 {
    margin-bottom: 3.5rem !important;
  }
  .ml-lg-55,
  .mx-lg-55 {
    margin-left: 3.5rem !important;
  }
  .m-lg-6 {
    margin: 4rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 4rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 4rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 4rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 4rem !important;
  }
  .m-lg-65 {
    margin: 4.5rem !important;
  }
  .mt-lg-65,
  .my-lg-65 {
    margin-top: 4.5rem !important;
  }
  .mr-lg-65,
  .mx-lg-65 {
    margin-right: 4.5rem !important;
  }
  .mb-lg-65,
  .my-lg-65 {
    margin-bottom: 4.5rem !important;
  }
  .ml-lg-65,
  .mx-lg-65 {
    margin-left: 4.5rem !important;
  }
  .m-lg-7 {
    margin: 5rem !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 5rem !important;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 5rem !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 5rem !important;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 5rem !important;
  }
  .m-lg-75 {
    margin: 5.5rem !important;
  }
  .mt-lg-75,
  .my-lg-75 {
    margin-top: 5.5rem !important;
  }
  .mr-lg-75,
  .mx-lg-75 {
    margin-right: 5.5rem !important;
  }
  .mb-lg-75,
  .my-lg-75 {
    margin-bottom: 5.5rem !important;
  }
  .ml-lg-75,
  .mx-lg-75 {
    margin-left: 5.5rem !important;
  }
  .m-lg-8 {
    margin: 6rem !important;
  }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 6rem !important;
  }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 6rem !important;
  }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 6rem !important;
  }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 6rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-11 {
    padding: 0.15rem !important;
  }
  .pt-lg-11,
  .py-lg-11 {
    padding-top: 0.15rem !important;
  }
  .pr-lg-11,
  .px-lg-11 {
    padding-right: 0.15rem !important;
  }
  .pb-lg-11,
  .py-lg-11 {
    padding-bottom: 0.15rem !important;
  }
  .pl-lg-11,
  .px-lg-11 {
    padding-left: 0.15rem !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-25 {
    padding: 0.75rem !important;
  }
  .pt-lg-25,
  .py-lg-25 {
    padding-top: 0.75rem !important;
  }
  .pr-lg-25,
  .px-lg-25 {
    padding-right: 0.75rem !important;
  }
  .pb-lg-25,
  .py-lg-25 {
    padding-bottom: 0.75rem !important;
  }
  .pl-lg-25,
  .px-lg-25 {
    padding-left: 0.75rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-42 {
    padding: 2rem !important;
  }
  .pt-lg-42,
  .py-lg-42 {
    padding-top: 2rem !important;
  }
  .pr-lg-42,
  .px-lg-42 {
    padding-right: 2rem !important;
  }
  .pb-lg-42,
  .py-lg-42 {
    padding-bottom: 2rem !important;
  }
  .pl-lg-42,
  .px-lg-42 {
    padding-left: 2rem !important;
  }
  .p-lg-425 {
    padding: 2.5rem !important;
  }
  .pt-lg-425,
  .py-lg-425 {
    padding-top: 2.5rem !important;
  }
  .pr-lg-425,
  .px-lg-425 {
    padding-right: 2.5rem !important;
  }
  .pb-lg-425,
  .py-lg-425 {
    padding-bottom: 2.5rem !important;
  }
  .pl-lg-425,
  .px-lg-425 {
    padding-left: 2.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .p-lg-55 {
    padding: 3.5rem !important;
  }
  .pt-lg-55,
  .py-lg-55 {
    padding-top: 3.5rem !important;
  }
  .pr-lg-55,
  .px-lg-55 {
    padding-right: 3.5rem !important;
  }
  .pb-lg-55,
  .py-lg-55 {
    padding-bottom: 3.5rem !important;
  }
  .pl-lg-55,
  .px-lg-55 {
    padding-left: 3.5rem !important;
  }
  .p-lg-6 {
    padding: 4rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 4rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 4rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 4rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 4rem !important;
  }
  .p-lg-65 {
    padding: 4.5rem !important;
  }
  .pt-lg-65,
  .py-lg-65 {
    padding-top: 4.5rem !important;
  }
  .pr-lg-65,
  .px-lg-65 {
    padding-right: 4.5rem !important;
  }
  .pb-lg-65,
  .py-lg-65 {
    padding-bottom: 4.5rem !important;
  }
  .pl-lg-65,
  .px-lg-65 {
    padding-left: 4.5rem !important;
  }
  .p-lg-7 {
    padding: 5rem !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 5rem !important;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 5rem !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 5rem !important;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 5rem !important;
  }
  .p-lg-75 {
    padding: 5.5rem !important;
  }
  .pt-lg-75,
  .py-lg-75 {
    padding-top: 5.5rem !important;
  }
  .pr-lg-75,
  .px-lg-75 {
    padding-right: 5.5rem !important;
  }
  .pb-lg-75,
  .py-lg-75 {
    padding-bottom: 5.5rem !important;
  }
  .pl-lg-75,
  .px-lg-75 {
    padding-left: 5.5rem !important;
  }
  .p-lg-8 {
    padding: 6rem !important;
  }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 6rem !important;
  }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 6rem !important;
  }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 6rem !important;
  }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 6rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-11 {
    margin: 0.15rem !important;
  }
  .mt-xl-11,
  .my-xl-11 {
    margin-top: 0.15rem !important;
  }
  .mr-xl-11,
  .mx-xl-11 {
    margin-right: 0.15rem !important;
  }
  .mb-xl-11,
  .my-xl-11 {
    margin-bottom: 0.15rem !important;
  }
  .ml-xl-11,
  .mx-xl-11 {
    margin-left: 0.15rem !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-25 {
    margin: 0.75rem !important;
  }
  .mt-xl-25,
  .my-xl-25 {
    margin-top: 0.75rem !important;
  }
  .mr-xl-25,
  .mx-xl-25 {
    margin-right: 0.75rem !important;
  }
  .mb-xl-25,
  .my-xl-25 {
    margin-bottom: 0.75rem !important;
  }
  .ml-xl-25,
  .mx-xl-25 {
    margin-left: 0.75rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-42 {
    margin: 2rem !important;
  }
  .mt-xl-42,
  .my-xl-42 {
    margin-top: 2rem !important;
  }
  .mr-xl-42,
  .mx-xl-42 {
    margin-right: 2rem !important;
  }
  .mb-xl-42,
  .my-xl-42 {
    margin-bottom: 2rem !important;
  }
  .ml-xl-42,
  .mx-xl-42 {
    margin-left: 2rem !important;
  }
  .m-xl-425 {
    margin: 2.5rem !important;
  }
  .mt-xl-425,
  .my-xl-425 {
    margin-top: 2.5rem !important;
  }
  .mr-xl-425,
  .mx-xl-425 {
    margin-right: 2.5rem !important;
  }
  .mb-xl-425,
  .my-xl-425 {
    margin-bottom: 2.5rem !important;
  }
  .ml-xl-425,
  .mx-xl-425 {
    margin-left: 2.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .m-xl-55 {
    margin: 3.5rem !important;
  }
  .mt-xl-55,
  .my-xl-55 {
    margin-top: 3.5rem !important;
  }
  .mr-xl-55,
  .mx-xl-55 {
    margin-right: 3.5rem !important;
  }
  .mb-xl-55,
  .my-xl-55 {
    margin-bottom: 3.5rem !important;
  }
  .ml-xl-55,
  .mx-xl-55 {
    margin-left: 3.5rem !important;
  }
  .m-xl-6 {
    margin: 4rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 4rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 4rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 4rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 4rem !important;
  }
  .m-xl-65 {
    margin: 4.5rem !important;
  }
  .mt-xl-65,
  .my-xl-65 {
    margin-top: 4.5rem !important;
  }
  .mr-xl-65,
  .mx-xl-65 {
    margin-right: 4.5rem !important;
  }
  .mb-xl-65,
  .my-xl-65 {
    margin-bottom: 4.5rem !important;
  }
  .ml-xl-65,
  .mx-xl-65 {
    margin-left: 4.5rem !important;
  }
  .m-xl-7 {
    margin: 5rem !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 5rem !important;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 5rem !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 5rem !important;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 5rem !important;
  }
  .m-xl-75 {
    margin: 5.5rem !important;
  }
  .mt-xl-75,
  .my-xl-75 {
    margin-top: 5.5rem !important;
  }
  .mr-xl-75,
  .mx-xl-75 {
    margin-right: 5.5rem !important;
  }
  .mb-xl-75,
  .my-xl-75 {
    margin-bottom: 5.5rem !important;
  }
  .ml-xl-75,
  .mx-xl-75 {
    margin-left: 5.5rem !important;
  }
  .m-xl-8 {
    margin: 6rem !important;
  }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 6rem !important;
  }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 6rem !important;
  }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 6rem !important;
  }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 6rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-11 {
    padding: 0.15rem !important;
  }
  .pt-xl-11,
  .py-xl-11 {
    padding-top: 0.15rem !important;
  }
  .pr-xl-11,
  .px-xl-11 {
    padding-right: 0.15rem !important;
  }
  .pb-xl-11,
  .py-xl-11 {
    padding-bottom: 0.15rem !important;
  }
  .pl-xl-11,
  .px-xl-11 {
    padding-left: 0.15rem !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-25 {
    padding: 0.75rem !important;
  }
  .pt-xl-25,
  .py-xl-25 {
    padding-top: 0.75rem !important;
  }
  .pr-xl-25,
  .px-xl-25 {
    padding-right: 0.75rem !important;
  }
  .pb-xl-25,
  .py-xl-25 {
    padding-bottom: 0.75rem !important;
  }
  .pl-xl-25,
  .px-xl-25 {
    padding-left: 0.75rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-42 {
    padding: 2rem !important;
  }
  .pt-xl-42,
  .py-xl-42 {
    padding-top: 2rem !important;
  }
  .pr-xl-42,
  .px-xl-42 {
    padding-right: 2rem !important;
  }
  .pb-xl-42,
  .py-xl-42 {
    padding-bottom: 2rem !important;
  }
  .pl-xl-42,
  .px-xl-42 {
    padding-left: 2rem !important;
  }
  .p-xl-425 {
    padding: 2.5rem !important;
  }
  .pt-xl-425,
  .py-xl-425 {
    padding-top: 2.5rem !important;
  }
  .pr-xl-425,
  .px-xl-425 {
    padding-right: 2.5rem !important;
  }
  .pb-xl-425,
  .py-xl-425 {
    padding-bottom: 2.5rem !important;
  }
  .pl-xl-425,
  .px-xl-425 {
    padding-left: 2.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .p-xl-55 {
    padding: 3.5rem !important;
  }
  .pt-xl-55,
  .py-xl-55 {
    padding-top: 3.5rem !important;
  }
  .pr-xl-55,
  .px-xl-55 {
    padding-right: 3.5rem !important;
  }
  .pb-xl-55,
  .py-xl-55 {
    padding-bottom: 3.5rem !important;
  }
  .pl-xl-55,
  .px-xl-55 {
    padding-left: 3.5rem !important;
  }
  .p-xl-6 {
    padding: 4rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 4rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 4rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 4rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 4rem !important;
  }
  .p-xl-65 {
    padding: 4.5rem !important;
  }
  .pt-xl-65,
  .py-xl-65 {
    padding-top: 4.5rem !important;
  }
  .pr-xl-65,
  .px-xl-65 {
    padding-right: 4.5rem !important;
  }
  .pb-xl-65,
  .py-xl-65 {
    padding-bottom: 4.5rem !important;
  }
  .pl-xl-65,
  .px-xl-65 {
    padding-left: 4.5rem !important;
  }
  .p-xl-7 {
    padding: 5rem !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 5rem !important;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 5rem !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 5rem !important;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 5rem !important;
  }
  .p-xl-75 {
    padding: 5.5rem !important;
  }
  .pt-xl-75,
  .py-xl-75 {
    padding-top: 5.5rem !important;
  }
  .pr-xl-75,
  .px-xl-75 {
    padding-right: 5.5rem !important;
  }
  .pb-xl-75,
  .py-xl-75 {
    padding-bottom: 5.5rem !important;
  }
  .pl-xl-75,
  .px-xl-75 {
    padding-left: 5.5rem !important;
  }
  .p-xl-8 {
    padding: 6rem !important;
  }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 6rem !important;
  }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 6rem !important;
  }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 6rem !important;
  }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 6rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 600 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #ffdd2d !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #f9d100 !important;
}

.text-links {
  color: #158aff !important;
}

a.text-links:hover, a.text-links:focus {
  color: #0071e1 !important;
}

.text-secondary {
  color: #fff !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #e6e6e6 !important;
}

.text-success {
  color: #8be2ad !important;
}

a.text-success:hover, a.text-success:focus {
  color: #62d890 !important;
}

.text-info {
  color: #158aff !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0071e1 !important;
}

.text-warning {
  color: #ffcd33 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ffc100 !important;
}

.text-danger {
  color: #ff788c !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #ff4561 !important;
}

.text-dark {
  color: #091e42 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #030a15 !important;
}

.text-body {
  color: #091e42 !important;
}

.text-muted {
  color: #c1c7d0 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.line-height-lg {
  line-height: 1.5;
}

.line-height-sm {
  line-height: 1.33;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 13rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 13rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 4rem;
  overflow: hidden;
  font-size: 1rem;
  background-color: transparent;
}

@media (min-width: 992px) {
  .progress {
    width: 4rem;
    height: 13rem;
    flex-direction: column-reverse;
  }
}

.progress-bar {
  height: 4rem;
  display: flex;
  justify-content: left;
  color: #fff;
  white-space: nowrap;
  background-color: #6c798f;
  line-height: 1.41;
  font-size: 1.0625rem;
  font-weight: 900;
  align-items: center;
  transition: width 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

@media (min-width: 992px) {
  .progress-bar {
    justify-content: center;
    width: 100%;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 13rem 13rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

.badge {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: .8;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  text-transform: uppercase;
  border-radius: 0.25rem;
  border: solid 2px currentColor;
}

.badge:empty {
  display: none;
}

.badge_new {
  display: inline-block;
  font-size: 14px;
  color: #6c798f;
  font-weight: 400;
  line-height: 1.14;
  vertical-align: baseline;
  text-transform: uppercase;
}

.badge_new:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-color-orange {
  color: #ffae1f;
}

.badge-color-orange[href]:hover, .badge-color-orange[href]:focus {
  color: #091e42;
  text-decoration: none;
  background-color: #eb9600;
}

.badge-color-blue {
  color: #005ab4;
}

.badge-color-blue[href]:hover, .badge-color-blue[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #004181;
}

.badge-color-green {
  color: #00b047;
}

.badge-color-green[href]:hover, .badge-color-green[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #007d32;
}

.badge-color-dark-green {
  color: #006e2c;
}

.badge-color-dark-green[href]:hover, .badge-color-dark-green[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #003b18;
}

.badge-color-brown {
  color: #a80019;
}

.badge-color-brown[href]:hover, .badge-color-brown[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #750011;
}

.badge-color-red {
  color: #e40022;
}

.badge-color-red[href]:hover, .badge-color-red[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #b1001a;
}

.badge-color-purple {
  color: #9a01e1;
}

.badge-color-purple[href]:hover, .badge-color-purple[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #7701ae;
}

.card {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  box-shadow: 0 2px 5px 0 rgba(23, 43, 77, 0.5);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-date, .card-country, .card-event {
  margin-bottom: 0;
}

.card-date {
  font-size: 1.375rem;
  line-height: 1.45;
}

.card-country {
  font-size: 0.75rem;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  letter-spacing: .7px;
  text-transform: uppercase;
  color: #6c798f;
}

.card-event {
  font-size: 0.75rem;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  letter-spacing: .7px;
  text-transform: uppercase;
}

.card-title {
  font-size: 26px;
  font-weight: 400;
  line-height: 1.23;
  margin: 0.875rem 0 0.5rem;
}

.card-subtitle {
  font-size: 1.0625rem;
  line-height: 1.41;
  margin-bottom: 1rem;
}

.card-buttonblock {
  margin-top: auto;
  display: flex;
  align-items: center;
}

.card-buttonblock [class*="_button"],
.card-buttonblock [class*="_btn"] {
  font-weight: 600;
  padding: 0;
}

.card-buttonblock [role="button"] + .button-icon {
  padding-left: 0;
  margin-left: -3px;
  margin-top: 2px;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .card-buttonblock [role="button"] + .button-icon {
    transition: none;
  }
}

.card-buttonblock [role="button"] + .button-icon.plus {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.card-buttonblock [role="button"] + .button-icon .arrow,
.card-buttonblock [role="button"] + .button-icon .http,
.card-buttonblock [role="button"] + .button-icon .plus {
  fill: #158aff;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .card-buttonblock [role="button"] + .button-icon .arrow,
  .card-buttonblock [role="button"] + .button-icon .http,
  .card-buttonblock [role="button"] + .button-icon .plus {
    transition: none;
  }
}

.card-buttonblock [role="button"]:hover + .button-icon {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.card-buttonblock [role="button"]:hover + .button-icon .arrow,
.card-buttonblock [role="button"]:hover + .button-icon .http,
.card-buttonblock [role="button"]:hover + .button-icon .plus {
  fill: #0064c8;
}

.card-buttonblock [role="button"]:hover + .browser-link {
  -webkit-transform: none;
  transform: none;
}

.card-buttonblock [class$="-button"] {
  margin-right: 0.25rem;
  font-weight: 600;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-img {
  min-height: 206px;
  background-size: cover;
  background-position: center;
}

.card-header {
  padding: 0.875rem 1.25rem 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-footer {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 1.125rem 1.25rem 1rem;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: calc(2rem + 2px);
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  display: flex;
  align-items: center;
  height: calc(2rem + 8px);
  padding: .6rem 1rem;
  font-size: 1rem;
  line-height: 0;
  color: #091e42;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #c1c7d0;
  border-radius: 0.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  outline: none;
}

.select2-container .select2-selection--single .select2-selection__clear {
  position: relative;
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-search--inline {
  float: left;
}

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  background-color: white;
  border: 1px solid #c1c7d0;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 0.25rem 1rem;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
}

.select2-results__option[aria-selected] {
  cursor: pointer;
}

.select2-results__option i {
  font-style: normal;
  color: #97a0af;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  top: -1px;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}

.select2-search--dropdown .select2-search__field {
  font-size: 16px;
  padding: 2px 8px;
  width: 100%;
  box-sizing: border-box;
  outline: 0;
  box-shadow: 0 0 0 0.05rem #88c4ff;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border-radius: 4px;
  height: 38px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #091e42;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #8993A4;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 38px;
  position: absolute;
  top: 0;
  right: 10px;
  width: 7px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  background-image: url("/local/templates/1ci/i/icons/select.svg");
  background-repeat: no-repeat;
  height: 15px;
  left: 50%;
  margin-left: -4px;
  margin-top: -7px;
  position: absolute;
  top: 50%;
  width: 7px;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__placeholder {
  color: #C1C7D0;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #091e42 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
  color: #999;
  margin-top: 5px;
  float: left;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

.select2-container--default input::-webkit-input-placeholder {
  opacity: 1;
}

.select2-container--default input::-moz-placeholder {
  opacity: 1;
}

.select2-container--default input::-ms-input-placeholder {
  opacity: 1;
}

.select2-container--default input::placeholder {
  opacity: 1;
}

.select2-container--default .select2-search--dropdown {
  padding: 8px 8px 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 2px solid #158AFF;
  border-radius: 4px;
  box-shadow: none;
  min-width: 100%;
  min-height: 40px;
}

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option {
  margin: 8px;
  background: #F4F5F7;
  border-radius: 4px;
  min-height: 40px;
  display: flex;
  align-items: center;
}

.select2-container--default .select2-results__option[role=group] {
  padding: 0;
}

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  color: #158aff;
}

.select2-container--default .select2-results__option .select2-results__option {
  margin: 8px;
  padding: 8px 40px;
  height: 40px;
  border-radius: 4px;
  background-color: #f4f5f7;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #158aff;
  color: white;
}

.select2-container--default .select2-results__option--highlighted[aria-selected]:last-of-type {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.select2-container--default .select2-results__group {
  cursor: pointer;
  display: block;
  padding: 8px 16px;
  margin: 8px;
  height: 40px;
  border-radius: 4px;
  background-color: #f4f5f7;
  font-weight: 400;
}

.select2-container--blue .select2-selection--single {
  background-color: #172b4d;
  border-radius: 4px;
}

.select2-container--blue .select2-selection--single .select2-selection__rendered {
  color: #fff;
  background-color: #172b4d;
  border: 1px solid #c1c7d0;
}

.select2-container--blue .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}

.select2-container--blue .select2-selection--single .select2-selection__placeholder {
  color: #c1c7d0;
}

.select2-container--blue .select2-selection--single .select2-selection__arrow {
  height: 38px;
  position: absolute;
  top: 0;
  right: 10px;
  width: 7px;
}

.select2-container--blue .select2-selection--single .select2-selection__arrow b {
  background-image: url("/local/templates/1ci/i/icons/select_w.svg");
  background-repeat: no-repeat;
  height: 15px;
  left: 50%;
  margin-left: -4px;
  margin-top: -7px;
  position: absolute;
  top: 50%;
  width: 7px;
}

.select2-container--blue[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--blue[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--blue.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}

.select2-container--blue.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--blue.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #091e42 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--blue .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
}

.select2-container--blue .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}

.select2-container--blue .select2-selection--multiple .select2-selection__rendered li {
  list-style: none;
}

.select2-container--blue .select2-selection--multiple .select2-selection__placeholder {
  color: #999;
  margin-top: 5px;
  float: left;
}

.select2-container--blue .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
}

.select2-container--blue .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--blue .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--blue .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333;
}

.select2-container--blue[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--blue[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder, .select2-container--blue[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right;
}

.select2-container--blue[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--blue[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--blue.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}

.select2-container--blue.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--blue.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

.select2-container--blue.select2-container--open.select2-container--above .select2-selection--single, .select2-container--blue.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--blue.select2-container--open.select2-container--below .select2-selection--single, .select2-container--blue.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--blue .select2-search--dropdown .select2-search__field {
  border: 1px solid #c1c7d0;
}

.select2-container--blue .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}

.select2-container--blue .select2-results > .select2-results__options {
  max-height: 300px;
  overflow-y: auto;
}

.select2-container--blue .select2-results__option[role=group] {
  padding: 0;
}

.select2-container--blue .select2-results__option[aria-disabled=true] {
  color: #999;
}

.select2-container--blue .select2-results__option[aria-selected=true] {
  color: #158aff;
  background-color: #fff;
}

.select2-container--blue .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container--blue .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container--blue .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--blue .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--blue .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--blue .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--blue .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--blue .select2-results__option--highlighted[aria-selected] {
  background-color: #158aff;
  color: white;
}

.select2-container--blue .select2-results__option--highlighted[aria-selected]:last-of-type {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.select2-container--blue .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--blue .select2-dropdown {
  background-color: #172b4d;
  border: 1px solid #c1c7d0;
  border-radius: 4px;
  color: #fff;
}

.select2-container--below .select2-selection--single[aria-expanded="true"] {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--above .select2-selection--single[aria-expanded="true"] {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--mobile .select2-selection--single {
  color: #fff;
  background-color: #172b4d;
  height: calc(3.5rem + 2px);
  border-radius: 0.25rem;
}

.select2-container--mobile .select2-selection--single .select2-selection__rendered {
  color: #fff;
  background-color: #172b4d;
  border: 1px solid #172b4d;
  display: flex;
  align-items: center;
  font-weight: 700;
  height: 100%;
  padding: .6rem 1.5rem;
}

.select2-container--mobile .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}

.select2-container--mobile .select2-selection--single .select2-selection__placeholder {
  color: #c1c7d0;
}

.select2-container--mobile .select2-selection--single .select2-selection__arrow {
  position: absolute;
  top: 0;
  right: 1.5rem;
  width: 7px;
  height: 90%;
  display: flex;
  align-items: center;
}

.select2-container--mobile .select2-selection--single .select2-selection__arrow b {
  position: relative;
}

.select2-container--mobile .select2-selection--single .select2-selection__arrow b:after {
  content: "";
  position: absolute;
  width: 100%;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 7px solid #fff;
}

.select2-container--mobile[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--mobile[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--mobile.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}

.select2-container--mobile.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--mobile.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #091e42 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--mobile .select2-selection--multiple {
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: text;
}

.select2-container--mobile .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}

.select2-container--mobile .select2-selection--multiple .select2-selection__rendered li {
  list-style: none;
}

.select2-container--mobile .select2-selection--multiple .select2-selection__placeholder {
  color: #999;
  margin-top: 5px;
  float: left;
}

.select2-container--mobile .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
}

.select2-container--mobile .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--mobile .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--mobile .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333;
}

.select2-container--mobile[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--mobile[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder, .select2-container--mobile[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right;
}

.select2-container--mobile[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--mobile[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--mobile.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}

.select2-container--mobile.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--mobile.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

.select2-container--mobile.select2-container--open .select2-dropdown--below, .select2-container--mobile.select2-container--open .select2-dropdown--above {
  border: 1px solid #172b4d;
}

.select2-container--mobile .select2-search--dropdown .select2-search__field {
  border: none;
}

.select2-container--mobile .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  -webkit-appearance: textfield;
}

.select2-container--mobile .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
  color: #fff;
  background-color: #172b4d;
  border: none;
}

.select2-container--mobile .select2-results__option[role=group] {
  padding: 0;
}

.select2-container--mobile .select2-results__option[aria-disabled=true] {
  color: #999;
}

.select2-container--mobile .select2-results__option[aria-selected=true] {
  color: #158aff;
  background-color: transparent;
}

.select2-container--mobile .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container--mobile .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container--mobile .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--mobile .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--mobile .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--mobile .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--mobile .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--mobile .select2-results__option--highlighted[aria-selected] {
  background-color: #158aff;
  color: white;
  border-radius: 0.25rem;
}

.select2-container--mobile .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--filter .select2-selection--single {
  background-color: #fff;
  border-radius: 4px;
  min-height: 38px;
}

.select2-container--filter .select2-selection--single .select2-selection__rendered {
  color: #091e42;
}

.select2-container--filter .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}

.select2-container--filter .select2-selection--single .select2-selection__placeholder {
  color: #8993A4;
}

.select2-container--filter .select2-selection--single .select2-selection__arrow {
  height: 38px;
  position: absolute;
  top: 0;
  right: 10px;
  width: 7px;
}

.select2-container--filter .select2-selection--single .select2-selection__arrow b {
  background-image: url("/local/templates/1ci/i/icons/select.svg");
  background-repeat: no-repeat;
  height: 15px;
  left: 50%;
  margin-left: -4px;
  margin-top: -7px;
  position: absolute;
  top: 50%;
  width: 7px;
}

.select2-container--filter[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--filter[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--filter.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}

.select2-container--filter.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--filter.select2-container--open .select2-selection--single .select2-selection__placeholder {
  color: #C1C7D0;
}

.select2-container--filter.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #091e42 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--filter .select2-selection--multiple {
  background-color: white;
  border: solid 1px #c1c7d0;
  border-radius: 4px;
  cursor: text;
  min-height: 40px;
}

.select2-container--filter .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 16px;
  width: 100%;
}

.select2-container--filter .select2-selection--multiple .select2-selection__rendered::after {
  content: "";
  background-image: url("/local/templates/1ci/i/icons/select.svg");
  background-repeat: no-repeat;
  height: 15px;
  right: 10px;
  margin-left: -4px;
  margin-top: -7px;
  position: absolute;
  top: 50%;
  width: 7px;
}

.select2-container--filter .select2-selection--multiple .select2-selection__rendered li {
  list-style: none;
}

.select2-container--filter .select2-selection--multiple .select2-selection__placeholder {
  color: #091e42;
  margin-top: 5px;
  float: left;
}

.select2-container--filter .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
  display: none;
}

.select2-container--filter .select2-selection--multiple .select2-selection__choice {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  background-color: #158aff;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 4px;
  margin-top: 4px;
  padding: 4px 8px 6px 16px;
  line-height: 1.2;
  color: #fff;
}

.select2-container--filter .select2-selection--multiple .select2-selection__choice__remove {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  margin-left: 4px;
  color: #fff;
  cursor: pointer;
  font-weight: 400;
  font-size: 30px;
}

.select2-container--filter[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--filter[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder, .select2-container--filter[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right;
}

.select2-container--filter[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--filter[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--filter.select2-container--focus .select2-selection--multiple {
  border: solid 1px #c1c7d0;
  outline: 0;
}

.select2-container--filter.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--filter.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

.select2-container--filter input::-webkit-input-placeholder {
  opacity: 1;
}

.select2-container--filter input::-moz-placeholder {
  opacity: 1;
}

.select2-container--filter input::-ms-input-placeholder {
  opacity: 1;
}

.select2-container--filter input::placeholder {
  opacity: 1;
}

.select2-container--filter #select2-country-results .select2-results__option {
  padding-left: 16px;
}

.select2-container--filter .select2-dropdown.select2-dropdown--below {
  top: -3px;
}

.select2-container--filter .select2-search--dropdown {
  padding: 8px 8px 0;
}

.select2-container--filter .select2-search--dropdown .select2-search__field {
  min-width: 100%;
  min-height: 40px;
  border: 2px solid #158AFF;
  border-radius: 4px;
  box-shadow: none;
  padding: 0 16px;
}

.select2-container--filter .select2-search--inline .select2-search__field {
  margin-top: 7px;
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}

.select2-container--filter .select2-search--inline .select2-search__field::-webkit-input-placeholder {
  color: #8993A4;
}

.select2-container--filter .select2-search--inline .select2-search__field::-moz-placeholder {
  color: #8993A4;
}

.select2-container--filter .select2-search--inline .select2-search__field::-ms-input-placeholder {
  color: #8993A4;
}

.select2-container--filter .select2-search--inline .select2-search__field::placeholder {
  color: #8993A4;
}

.select2-container--filter .select2-search--inline .select2-search__field::-webkit-input-placeholder:focus {
  color: #C1C7D0;
}

.select2-container--filter .select2-search--inline .select2-search__field::-moz-placeholder:focus {
  color: #C1C7D0;
}

.select2-container--filter .select2-search--inline .select2-search__field::-ms-input-placeholder:focus {
  color: #C1C7D0;
}

.select2-container--filter .select2-search--inline .select2-search__field::placeholder:focus {
  color: #C1C7D0;
}

.select2-container--filter .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--filter .select2-results__option[role="treeitem"] {
  margin: 8px;
  padding: 8px 40px;
  min-height: 40px;
  border-radius: 4px;
  background-color: #f4f5f7;
}

.select2-container--filter .select2-results__option[role="treeitem"]:hover {
  background-color: #158AFF;
  color: #fff;
}

.select2-container--filter .select2-results__option[role="treeitem"]:hover .custom-checkbox .custom-control-label::before {
  border: solid 1px #fff;
}

.select2-container--filter .select2-results__option[role="treeitem"] .select2-results__group {
  padding-left: 0;
}

.select2-container--filter .select2-results__option[aria-disabled=true] {
  color: #999;
}

.select2-container--filter .select2-results__option[aria-selected=true] {
  background-color: #f4f5f7;
  color: #091e42;
}

.select2-container--filter .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--filter .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--filter .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--filter .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--filter .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--filter .select2-results__option--highlighted[aria-selected]:last-of-type {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.select2-container--filter .select2-results__group {
  display: block;
  padding: 8px 16px;
  margin: 8px;
  height: 40px;
  border-radius: 4px;
  background-color: #f4f5f7;
  font-weight: 400;
  position: relative;
}

.select2-container--filter .select2-results__group .custom-checkbox .custom-control-label::before {
  z-index: 0;
}

.select2-container--filter .select2-results__group:hover {
  background-color: #158AFF;
  color: #fff;
}

.select2-container--filter .select2-results__group:hover .custom-checkbox .custom-control-label::before {
  border: solid 1px #fff;
}

.select2-container--filter .select2-results__group:hover .toggle {
  background-image: url(/local/templates/1ci/i/icons/Common/003w.svg);
}

.select2-container--filter .select2-results__group .toggle {
  content: "";
  position: absolute;
  height: 5px;
  width: 8px;
  background-image: url("/local/templates/1ci/i/icons/Common/003.svg");
  background-repeat: no-repeat;
  background-size: contain;
  right: 16px;
  top: 18px;
  cursor: pointer;
}

.select2-container--filter .select2-results__group .toggle.open {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.select2-container--filter .select2-results__group[aria-selected=true] {
  background-color: #158aff !important;
  color: #fff !important;
}

.select2-container--filter .select2-results__group[aria-selected=true] .toggle {
  background-image: url("/local/templates/1ci/i/icons/Common/003w.svg");
}

.select2-container--filter .show:after {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.select2-container--mask {
  min-width: 64px;
  width: 100%;
}

.select2-container--mask .select2-selection--single {
  background-color: #fff;
  border-radius: 4px;
}

.select2-container--mask .select2-selection--single .select2-selection__rendered {
  color: #091e42;
  border-right: none;
  border-radius: 4px 0 0 4px;
}

.select2-container--mask .select2-selection--single .select2-selection__rendered .country_text, .select2-container--mask .select2-selection--single .select2-selection__rendered .country_index {
  display: none;
}

.select2-container--mask .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}

.select2-container--mask .select2-selection--single .select2-selection__placeholder {
  color: #091e42;
}

.select2-container--mask .select2-selection--single .select2-selection__arrow {
  height: 38px;
  position: absolute;
  top: 0;
  right: 10px;
  width: 7px;
}

.select2-container--mask .select2-selection--single .select2-selection__arrow b {
  background-image: url("/local/templates/1ci/i/icons/Common/003.svg");
  background-repeat: no-repeat;
  background-position: center;
  height: 15px;
  left: -2px;
  margin-top: -7px;
  position: absolute;
  top: 50%;
  width: 7px;
}

.select2-container--mask[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--mask[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--mask.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}

.select2-container--mask.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--mask.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #091e42 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--mask .select2-selection--multiple {
  background-color: white;
  border: solid 1px #c1c7d0;
  border-radius: 4px;
  cursor: text;
  min-height: 40px;
}

.select2-container--mask .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 16px;
  width: 100%;
}

.select2-container--mask .select2-selection--multiple .select2-selection__rendered::after {
  content: "";
  background-image: url("/local/templates/1ci/i/icons/select.svg");
  background-repeat: no-repeat;
  height: 15px;
  right: 10px;
  margin-left: -4px;
  margin-top: -7px;
  position: absolute;
  top: 50%;
  width: 7px;
}

.select2-container--mask .select2-selection--multiple .select2-selection__rendered li {
  list-style: none;
}

.select2-container--mask .select2-selection--multiple .select2-selection__placeholder {
  color: #091e42;
  margin-top: 5px;
  float: left;
}

.select2-container--mask .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
  display: none;
}

.select2-container--mask .select2-selection--multiple .select2-selection__choice {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  background-color: #158aff;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 4px;
  margin-top: 4px;
  padding: 4px 8px 6px 16px;
  line-height: 1.2;
  color: #fff;
}

.select2-container--mask .select2-selection--multiple .select2-selection__choice__remove {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  margin-left: 4px;
  color: #fff;
  cursor: pointer;
  font-weight: 400;
  font-size: 30px;
}

.select2-container--mask[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--mask[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder, .select2-container--mask[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right;
}

.select2-container--mask[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--mask[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--mask.select2-container--focus .select2-selection--multiple {
  border: solid 1px #c1c7d0;
  outline: 0;
}

.select2-container--mask.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--mask.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

.select2-container--mask .select2-dropdown {
  width: 100% !important;
}

.select2-container--mask .select2-dropdown--above {
  top: 2px;
  border: 1px solid #c1c7d0;
}

.select2-container--mask .select2-dropdown--below {
  top: -10px;
}

.select2-container--mask.select2-container--open.select2-container--above .select2-selection--single, .select2-container--mask.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--mask.select2-container--open.select2-container--below .select2-selection--single, .select2-container--mask.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--mask .select2-search--dropdown {
  padding: 8px 8px 0;
}

.select2-container--mask .select2-search--dropdown .select2-search__field {
  border: 2px solid #158AFF;
  border-radius: 4px;
  box-shadow: none;
  height: 40px;
  width: 100% !important;
}

.select2-container--mask .select2-search--inline .select2-search__field {
  margin-top: 7px;
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}

.select2-container--mask .select2-search--inline .select2-search__field::-webkit-input-placeholder, .select2-container--mask .select2-search--inline .select2-search__field::-webkit-input-placeholder {
  color: #091e42;
  opacity: 1;
}

.select2-container--mask .select2-search--inline .select2-search__field::-webkit-input-placeholder, .select2-container--mask .select2-search--inline .select2-search__field::placeholder {
  color: #091e42;
  opacity: 1;
}

.select2-container--mask .select2-results > .select2-results__options {
  max-height: 295px;
  overflow-y: auto;
}

.select2-container--mask .select2-results__option[role="treeitem"] {
  margin: 8px;
  padding: 8px 16px 8px 8px;
  min-height: 40px;
  border-radius: 4px;
  background-color: #f4f5f7;
}

.select2-container--mask .select2-results__option[role="treeitem"] .country {
  display: flex;
  align-items: center;
}

.select2-container--mask .select2-results__option[role="treeitem"] .country_flag {
  margin-right: 8px;
}

.select2-container--mask .select2-results__option[role="treeitem"] .country_text, .select2-container--mask .select2-results__option[role="treeitem"] .country_index {
  margin: 0;
  line-height: 24px;
}

.select2-container--mask .select2-results__option[role="treeitem"] .country_index {
  margin-left: auto;
}

.select2-container--mask .select2-results__option[role="treeitem"] .select2-results__group {
  padding-left: 0;
}

.select2-container--mask .select2-results__option[role="treeitem"] .select2-results__group:hover {
  box-shadow: 0 2px 4px 0 rgba(23, 43, 77, 0.3);
}

.select2-container--mask .select2-results__option[aria-disabled=true] {
  color: #999;
}

.select2-container--mask .select2-results__option[aria-selected=true] {
  color: #158aff;
}

.select2-container--mask .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--mask .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--mask .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--mask .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--mask .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--mask .select2-results__option--highlighted[aria-selected] {
  background-color: #158AFF;
  color: #fff;
}

.select2-container--mask .select2-results__option--highlighted[aria-selected]:last-of-type {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.select2-container--mask .select2-results__group {
  display: block;
  padding: 8px 16px;
  margin: 8px;
  height: 40px;
  border-radius: 4px;
  background-color: #f4f5f7;
  font-weight: 400;
  position: relative;
}

.select2-container--mask .select2-results__group:hover {
  box-shadow: 0 2px 4px 0 rgba(23, 43, 77, 0.3);
}

.select2-container--mask .select2-results__group .toggle {
  content: "";
  position: absolute;
  height: 5px;
  width: 8px;
  background-image: url("/local/templates/1ci/i/icons/Common/003.svg");
  background-repeat: no-repeat;
  background-size: contain;
  right: 16px;
  top: 18px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  cursor: pointer;
}

.select2-container--mask .select2-results__group .toggle.open {
  -webkit-transform: none;
  transform: none;
}

.select2-container--mask .select2-results__group[aria-selected=true] {
  color: #158AFF;
}

.select2-container--mask .select2-results__group[aria-selected=true] .select2-results__option--highlighted {
  color: #fff;
}

.select2-container--mask .select2-results__group[aria-selected=true] .toggle {
  background-image: url("/local/templates/1ci/i/icons/Common/003w.svg");
}

.select2-container--mask .show:after {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}
/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
.owl-theme .owl-dots, .owl-theme .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-nav {
  margin-top: 10px;
}

.owl-theme .owl-nav [class*=owl-] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}

.owl-theme .owl-nav [class*=owl-]:hover {
  background: #869791;
  color: #FFF;
  text-decoration: none;
}

.owl-theme .owl-nav .disabled {
  opacity: .5;
  cursor: default;
}

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
}

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity .2s ease;
  border-radius: 30px;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}
.landing-public-mode .block-wrapper {
  display: initial;
}

.landing-public-mode #career_hidden,
.landing-public-mode #hidden_form,
.landing-public-mode .newsroom__media-button {
  display: none;
}

.landing-edit-mode .d-none, .landing-edit-mode .only-mobile, .landing-edit-mode .only-mobile.only-desktop.only-tablet,
.landing-edit-mode .only-mobile,
.landing-edit-mode .only-mobile.only-desktop,
.landing-edit-mode .only-mobile.only-tablet,
.landing-edit-mode .only-mobile.only-desktop.only-tablet,
.landing-edit-mode .d-lg-block,
.landing-edit-mode .only-tablet,
.landing-edit-mode .only-tablet,
.landing-edit-mode .d-lg-none,
.landing-edit-mode .only-desktop,
.landing-edit-mode .only-desktop {
  display: inherit !important;
  opacity: .5;
}

.landing-edit-mode .landing-ui-field-input .event__form-list__item::before {
  display: none;
}

.landing-edit-mode .event__form-list__item {
  position: inherit !important;
}

.landing-edit-mode .event__form-location_text {
  position: absolute !important;
}

.landing-edit-mode .only-mobile,
.landing-edit-mode .only-tablet,
.landing-edit-mode .only-desktop {
  position: relative;
}

.landing-edit-mode .only-mobile::after,
.landing-edit-mode .only-tablet::after,
.landing-edit-mode .only-desktop::after {
  position: absolute;
  content: "";
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  height: 1px;
  width: 100%;
  background-color: #091e42;
}

.landing-edit-mode #career_hidden,
.landing-edit-mode #hidden_form,
.landing-edit-mode .newsroom__media-button,
.landing-edit-mode .career__contact,
.landing-edit-mode .event__form-form.forms--blue .hidden,
.landing-edit-mode .register__button {
  display: block !important;
}

.landing-edit-mode .event__form .register__button {
  box-shadow: none;
}

.landing-edit-mode .event__form-form {
  position: relative;
  top: 0;
  width: 100%;
}

.landing-edit-mode .owl-carousel {
  display: flex;
  flex-wrap: wrap;
}

.landing-edit-mode .owl-carousel .slide {
  padding-bottom: 1rem;
  height: -webkit-max-content !important;
  height: -moz-max-content !important;
  height: max-content !important;
}

.landing-edit-mode .brochure_image,
.landing-edit-mode .slide_subtext {
  position: absolute !important;
}

.landing-edit-mode .stretched-link::after {
  display: none;
}

.landing-edit-mode .partners-catalogue__new_header.hidden, .landing-edit-mode .partners-catalogue__new_empty.hidden {
  display: block !important;
}

.landing-edit-mode .partners__new-emptyblock.hidden {
  display: block !important;
}

.landing-edit-mode .filter__badges.hidden {
  display: flex !important;
}

.landing-edit-mode .filter__badges-tag.hidden {
  display: flex !important;
}

.landing-edit-mode .filter__badges-button.hidden {
  display: flex !important;
}

.site-header {
  background-color: #fff;
}

.site-header a {
  font-size: 1rem;
  transition: ease-in-out color 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .site-header a {
    transition: none;
  }
}

.site-header .navbar-brand {
  width: 40px;
  height: 32px;
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .site-header .navbar-brand {
    padding-left: 15px;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .site-header .navbar-toggler {
    padding-right: 15px;
  }
}

.site-header .nav-link[href^="http"]:not(.lang)::after,
.site-header .dropdown-item[href^="http"]:not(.lang)::after {
  content: "(external link)";
  display: inline-block;
  width: 1rem;
  height: .75rem;
  text-indent: 1rem;
  white-space: nowrap;
  overflow: hidden;
  background-image: url("/local/templates/1ci/i/icons/Common/002.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  margin-left: .2rem;
}

.site-header .nav-link:hover[href^="http"]:not(.lang)::after,
.site-header .dropdown-item:hover[href^="http"]:not(.lang)::after {
  background-image: url("/local/templates/1ci/i/icons/Common/002-1.svg");
}

.site-header .language-select {
  display: none;
}

@media (max-width: 991.98px) {
  .site-header .language-select {
    display: block;
    margin-bottom: 2.5rem;
  }
}

.site-header .language-select_flag {
  padding: .1rem .5rem .3rem 0;
}

.site-header .language-dropdown {
  display: none;
}

@media (min-width: 992px) {
  .site-header .language-dropdown {
    display: block;
    padding-right: 0;
  }
  .site-header .language-dropdown .nav-link {
    padding-right: 0;
  }
}

.site-header .language-dropdown .nav-link {
  padding-top: 0.3rem;
}

.site-header .language-dropdown .lang i {
  font-style: normal;
  color: #97a0af;
}

.site-header .language-dropdown_flag {
  padding: .1rem .5rem .3rem 0;
}

.site-header .language-dropdown_icon path {
  transition: all 0.2s ease-in-out;
}

.site-header .language-dropdown_icon:hover, .site-header .language-dropdown_icon:active, .site-header .language-dropdown_icon.active {
  fill: #158aff;
}

.site-header .language-dropdown_icon:hover path, .site-header .language-dropdown_icon:active path, .site-header .language-dropdown_icon.active path {
  fill: #158aff;
}

.navbar-toggler {
  border: none;
  background: transparent;
}

.navbar-toggler .top-bar {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 9% 10%;
  transform-origin: 9% 10%;
}

.navbar-toggler .middle-bar {
  opacity: 0;
}

.navbar-toggler .bottom-bar {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 7% 90%;
  transform-origin: 7% 90%;
}

.navbar-toggler.collapsed .top-bar {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
}

.navbar-toggler.collapsed .bottom-bar {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.footer {
  position: relative;
  color: #fff;
  background-color: #172b4d;
  padding-top: 2rem;
}

@media (max-width: 767.98px) {
  .footer {
    padding-top: 2.5rem;
  }
}

@media (min-width: 768px) {
  .footer {
    padding-top: 3rem;
  }
}

.footer-menu {
  position: relative;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .footer-menu .col-md-9 {
    margin-bottom: 0.5rem;
  }
}

.footer-menu [href^="http"]::after {
  content: "(external link)";
  display: inline-block;
  width: 1rem;
  height: .75rem;
  text-indent: 1rem;
  white-space: nowrap;
  overflow: hidden;
  background-image: url("/local/templates/1ci/i/icons/Common/002w.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  margin-left: .2rem;
  -webkit-transform: translateY(0.125rem);
  transform: translateY(0.125rem);
}

.footer-menu_container {
  padding-left: 0;
  list-style: none;
  flex-direction: row;
  width: 100%;
  margin-bottom: 1rem;
}

@media (min-width: 576px) {
  .footer-menu_container {
    width: 33.333333%;
  }
  .footer-menu_container--row {
    padding-left: 22.5px;
  }
  .footer-menu_container + .footer-menu_container:not(:last-child) {
    padding-left: 7.5px;
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  .footer-menu_container {
    width: 50%;
  }
  .footer-menu_container + .footer-menu_container {
    padding-left: 0;
    padding-right: 0;
  }
  .footer-menu_container--row {
    width: 100%;
  }
  .footer-menu_container--row .footer_item:not(.footer_item--header) {
    display: inline-flex;
    padding-right: 1.5rem;
  }
  .footer-menu_container--col .footer_item {
    display: flex;
  }
}

.footer-menu_container--col {
  width: 100%;
}

.footer_wrapper {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 767.98px) {
  .footer_wrapper {
    padding-left: 1rem;
    display: none;
  }
}

.footer_title {
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: none;
  padding-left: 1rem;
  padding-bottom: 1rem;
}

@media (max-width: 767.98px) {
  .footer_title .caret {
    width: 6px;
    height: 4px;
    margin-left: 0.1615em;
    vertical-align: 0.1615em;
  }
  .footer_title .caret path {
    transition: all 0.2s ease-in-out;
  }
  .footer_title::after {
    display: none;
  }
  .footer_title::before {
    display: inline-block;
    width: 0;
    height: 0;
    margin-right: 0;
    position: absolute;
    top: 0.5rem;
    left: 1rem;
    content: "";
    border-top: 0.19em solid transparent;
    border-right: 0;
    border-bottom: 0.19em solid transparent;
    border-left: 0.19em solid;
  }
  .footer_title:empty::after {
    margin-left: 0;
  }
  .footer_title.show .caret {
    width: 6px;
    height: 4px;
    margin-left: 0.1615em;
    vertical-align: 0.1615em;
  }
  .footer_title.show .caret path {
    transition: all 0.2s ease-in-out;
  }
  .footer_title.show::after {
    display: none;
  }
  .footer_title.show::before {
    display: inline-block;
    width: 0;
    height: 0;
    margin-right: 0;
    position: absolute;
    top: 0.5rem;
    left: 1rem;
    content: "";
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .footer_title.show:empty::after {
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .footer_title {
    padding-left: 0;
    border-bottom: 1px solid #42526e;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
  }
}

.footer_item {
  display: flex;
  margin-bottom: 1rem;
}

.footer_item a {
  font-size: 0.875rem;
  line-height: 1.145;
  color: #fff;
  text-decoration: none;
  display: inline;
  position: relative;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.footer_item a::before {
  display: none;
}

.footer_item a:hover {
  text-decoration: underline;
}

.footer_item--arrow a::after {
  display: inline-block;
  padding-left: 0.2rem;
  content: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIzcHgiIGhlaWdodD0iNnB4IiB2aWV3Qm94PSIwIDAgMyA2IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+ICAgIDxnIGlkPSJTeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxnIGlkPSLigLoiIGZpbGw9IiNmZmYiIGZpbGwtcnVsZT0ibm9uemVybyI+ICAgICAgICAgICAgPHBvbHlnb24gcG9pbnRzPSIwLjYxMDQ2NTExNiA2IDAgNS41NDgzODcxIDIuMDU4MTM5NTMgMyAwIDAuNDgzODcwOTY4IDAuNjEwNDY1MTE2IDAgMyAyLjUgMyAzLjUiPjwvcG9seWdvbj4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==");
}

.footer_item--header {
  text-transform: uppercase;
  margin-bottom: 0.875rem;
  font-weight: 600;
  color: #97a0af;
  font-size: 0.75rem;
}

@media (max-width: 767.98px) {
  .footer_item--header {
    border-bottom: 1px solid #42526e;
    padding-bottom: 0.5rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}

.footer_copy {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 0.875rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.footer_copytext {
  text-transform: uppercase;
}

.footer_copyblock {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

@media (max-width: 767.98px) {
  .footer_copyblock {
    justify-content: flex-start;
    margin-top: 1rem;
  }
}

.footer_copylink a {
  color: #fff;
  margin-left: 1rem;
}

.footer_copylink a:hover {
  text-decoration: underline;
}

@media (max-width: 767.98px) {
  .footer_copylink a {
    margin-left: 0;
    margin-right: 1rem;
  }
}

.footer_logo {
  display: flex;
  justify-content: space-between;
  margin-bottom: 34px;
}

.footer_logoimg {
  width: 40px;
  height: 34px;
}

.footer .language-select-footer {
  display: none;
}

@media (min-width: 992px) {
  .footer .language-select-footer {
    display: block;
  }
}

.footer .language-select-footer .language-select_flag {
  padding: .1rem .5rem .3rem 0;
}

.footer_social {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  display: flex;
  align-items: center;
  flex-direction: row;
  z-index: 1;
}

@media (min-width: 992px) {
  .footer_social {
    position: absolute;
    bottom: 2rem;
    right: 0;
    margin: 0;
  }
}

.footer_social__link {
  height: 32px;
  width: 32px;
  margin-right: 0.25rem;
}

@media (min-width: 992px) {
  .footer_social__link {
    margin-right: auto;
    margin-left: 0.25rem;
  }
}

.footer_social__link[href^="http"]::after {
  display: none;
}

@media (max-width: 767.98px) {
  .footer_social {
    order: 1;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.footer_hr {
  border-top: 1px solid #42526e;
  margin-top: 1rem;
}

@media (min-width: 768px) {
  .footer_hr {
    margin-top: 2rem;
  }
}

@media (min-width: 992px) {
  .footer_hr {
    margin-top: 1rem;
  }
}

.cookie {
  display: none;
  position: fixed;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #158aff;
  box-shadow: 0 -1px 6px 0 rgba(165, 173, 186, 0.5);
  z-index: 10000;
  padding: 1rem 15px;
  width: 100%;
}

@media (min-width: 768px) {
  .cookie {
    padding: 1.5rem 0;
  }
}

@media (min-width: 992px) {
  .cookie {
    border-radius: 0.25rem 0.25rem 0 0;
    width: 978px;
  }
}

@media (min-width: 1200px) {
  .cookie {
    width: 1158px;
  }
}

.cookie-block {
  display: flex;
  color: #fff;
  font-size: 0.875rem;
  position: relative;
  margin: 0 auto;
}

@media (min-width: 576px) {
  .cookie-block {
    max-width: 540px;
    padding: 0 15px;
  }
}

@media (min-width: 768px) {
  .cookie-block {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .cookie-block {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .cookie-block {
    max-width: 1140px;
  }
}

.cookie-text {
  margin-bottom: 0;
  line-height: 1.14;
}

.cookie .close-policy {
  width: 16px;
  height: 16px;
  padding: 3px;
  display: flex;
  margin-left: 0.5rem;
}

@media (min-width: 768px) {
  .cookie .close-policy {
    top: -8px;
    position: absolute;
    right: -3px;
  }
}

@media (min-width: 992px) {
  .cookie .close-policy {
    position: relative;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .cookie .close-policy {
    margin-left: 2rem;
  }
}

.cookie .icon path {
  transition: all 0.2s ease-in-out;
}

.cookie .icon:hover {
  cursor: pointer;
}

.cookie-link {
  color: #fff;
  text-decoration: underline;
}

.cookie-link:hover {
  color: #fff;
  text-decoration: underline;
}

.localization-shower {
  display: none;
  margin-bottom: 0.5rem;
}

.localization-shower_col {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (max-width: 1199.98px) {
  .localization-shower_col {
    padding-right: 0;
    padding-left: 0;
  }
}

.localization-shower_block {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background: #fff;
  box-shadow: 0 2px 4px rgba(23, 43, 77, 0.3);
  -webkit-box-shadow: 0 2px 4px rgba(23, 43, 77, 0.3);
  border-radius: 0;
}

@media (max-width: 767.98px) {
  .localization-shower_block {
    flex-direction: column;
    padding: 16px 16px 8px;
  }
}

@media (min-width: 576px) {
  .localization-shower_block {
    padding: 16px 39px 24px;
  }
}

@media (min-width: 992px) {
  .localization-shower_block {
    padding: 16px 24px 24px;
    border-bottom-left-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }
}

@media (max-width: 767.98px) {
  .localization-shower_close {
    position: absolute;
    right: 15px;
    top: 16px;
  }
}

@media (min-width: 768px) {
  .localization-shower_close {
    margin-left: 25px;
  }
}

@media (min-width: 992px) {
  .localization-shower_close {
    margin-left: 4rem;
  }
}

.localization-shower_close svg {
  vertical-align: top;
  width: 14px;
  height: 14px;
}

.localization-shower_close svg path {
  fill: #42526e;
  -webkit-transform: scale(1.4);
  transform: scale(1.4);
}

.localization-shower_image {
  margin-top: 8px;
  margin-right: 24px;
}

@media (max-width: 767.98px) {
  .localization-shower_image {
    margin-bottom: 1rem;
  }
}

.localization-shower_textblock {
  width: 100%;
  margin-top: 8px;
}

.localization-shower_textblock p {
  margin: 0;
}

.localization-shower_buttonblock {
  margin-top: 16px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.localization-shower_buttonblock .left-button {
  color: #000;
  background-color: #ffdd2d;
  margin-right: 24px;
  border: 1px solid #ffdd2d;
  border-radius: 4px;
}

@media (max-width: 767.98px) {
  .localization-shower_buttonblock .left-button {
    margin-bottom: 16px;
  }
}

.localization-shower_buttonblock .left-button:hover {
  background-color: #ffcd33;
  border: 1px solid #ffcd33;
}

.localization-shower_buttonblock .right-button {
  color: #000;
  border: 1px solid currentColor;
  background-color: #fff;
  border-radius: 4px;
}

@media (max-width: 767.98px) {
  .localization-shower_buttonblock .right-button {
    margin-bottom: 16px;
  }
}

.localization-shower_buttonblock .right-button:hover {
  border: 1px solid #ffcd33;
  background-color: #ffcd33;
}

.filter__delete {
  position: relative;
}

.filter__delete::after {
  content: "";
  display: inline-block;
  position: relative;
  left: 5px;
  height: 14px;
  width: 14px;
  background-image: url("/local/templates/1ci/i/icons/Common/close.svg");
}

.bg__image {
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.visible__bg {
  opacity: 1;
}

.gr-color-default-gr {
  background-image: linear-gradient(45deg, #091e42 0%, #158aff 50%, #fff 100%) !important;
}

.gr-color-blue-dark {
  background-image: linear-gradient(18.33deg, #0077ef 5.48%, #07346e 82.35%) !important;
}

.gr-color-none {
  background-image: none !important;
}

.gr-bg-default-gr {
  background-image: linear-gradient(45deg, #091e42 0%, #158aff 50%, #fff 100%) !important;
}

.gr-bg-blue-dark {
  background-image: linear-gradient(18.33deg, #0077ef 5.48%, #07346e 82.35%) !important;
}

.gr-bg-none {
  background-image: none !important;
}

.g-color-blue {
  color: #158aff !important;
}

.g-color-dark-blue {
  color: #172b4d !important;
}

.g-color-light-blue {
  color: #88c4ff !important;
}

.g-color-red {
  color: #ff788c !important;
}

.g-color-pink {
  color: #fd9caa !important;
}

.g-color-yellow {
  color: #ffdd2d !important;
}

.g-color-light-yellow {
  color: #faffbd !important;
}

.g-color-green {
  color: #8be2ad !important;
}

.g-color-accent-green {
  color: #16c65d !important;
}

.g-color-white {
  color: #fff !important;
}

.g-color-gray {
  color: #f4f5f7 !important;
}

.g-color-dark-gray {
  color: #c1c7d0 !important;
}

.g-color-dark-indigo {
  color: #091e42 !important;
}

.g-color-blue-gray {
  color: #8993a4 !important;
}

.g-color-accent-red {
  color: #fd3a57 !important;
}

.g-color-black {
  color: #000 !important;
}

.g-color-dark-grey-blue {
  color: #344563 !important;
}

.g-color-dark-grey-light {
  color: #6c798f !important;
}

.g-bg-blue {
  background-color: #158aff !important;
}

.g-bg-dark-blue {
  background-color: #172b4d !important;
}

.g-bg-light-blue {
  background-color: #88c4ff !important;
}

.g-bg-red {
  background-color: #ff788c !important;
}

.g-bg-pink {
  background-color: #fd9caa !important;
}

.g-bg-yellow {
  background-color: #ffdd2d !important;
}

.g-bg-light-yellow {
  background-color: #faffbd !important;
}

.g-bg-green {
  background-color: #8be2ad !important;
}

.g-bg-accent-green {
  background-color: #16c65d !important;
}

.g-bg-white {
  background-color: #fff !important;
}

.g-bg-gray {
  background-color: #f4f5f7 !important;
}

.g-bg-dark-gray {
  background-color: #c1c7d0 !important;
}

.g-bg-dark-indigo {
  background-color: #091e42 !important;
}

.g-bg-blue-gray {
  background-color: #8993a4 !important;
}

.g-bg-accent-red {
  background-color: #fd3a57 !important;
}

.g-bg-black {
  background-color: #000 !important;
}

.g-bg-dark-grey-blue {
  background-color: #344563 !important;
}

.g-bg-dark-grey-light {
  background-color: #6c798f !important;
}

.g-color-inherit {
  color: inherit !important;
}

.g-color-black-opacity-0_1 {
  color: rgba(0, 0, 0, 0.1) !important;
}

.g-color-black-opacity-0_1 * {
  color: rgba(0, 0, 0, 0.1);
}

.g-color-black-opacity-0_1 p {
  color: rgba(0, 0, 0, 0.1);
}

.g-color-black-opacity-0_1--hover:active,
.g-color-black-opacity-0_1--hover:focus,
.g-color-black-opacity-0_1--hover.active {
  color: rgba(0, 0, 0, 0.1) !important;
}

.g-color-black-opacity-0_1--hover:hover {
  color: rgba(0, 0, 0, 0.1) !important;
}

.g-color-black-opacity-0_1--hover:hover * {
  color: rgba(0, 0, 0, 0.1) !important;
}

.g-color-black-opacity-0_3 {
  color: rgba(0, 0, 0, 0.3) !important;
}

.g-color-black-opacity-0_3 * {
  color: rgba(0, 0, 0, 0.3);
}

.g-color-black-opacity-0_3 p {
  color: rgba(0, 0, 0, 0.3);
}

.g-color-black-opacity-0_3--hover:active,
.g-color-black-opacity-0_3--hover:focus,
.g-color-black-opacity-0_3--hover.active {
  color: rgba(0, 0, 0, 0.3) !important;
}

.g-color-black-opacity-0_3--hover:hover {
  color: rgba(0, 0, 0, 0.3) !important;
}

.g-color-black-opacity-0_3--hover:hover * {
  color: rgba(0, 0, 0, 0.3) !important;
}

.g-color-black-opacity-0_5 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.g-color-black-opacity-0_5 * {
  color: rgba(0, 0, 0, 0.5);
}

.g-color-black-opacity-0_5 p {
  color: rgba(0, 0, 0, 0.5);
}

.g-color-black-opacity-0_5--hover:active,
.g-color-black-opacity-0_5--hover:focus,
.g-color-black-opacity-0_5--hover.active {
  color: rgba(0, 0, 0, 0.5) !important;
}

.g-color-black-opacity-0_5--hover:hover {
  color: rgba(0, 0, 0, 0.5) !important;
}

.g-color-black-opacity-0_5--hover:hover * {
  color: rgba(0, 0, 0, 0.5) !important;
}

.g-color-black-opacity-0_6 {
  color: rgba(0, 0, 0, 0.6) !important;
}

.g-color-black-opacity-0_6 * {
  color: rgba(0, 0, 0, 0.6);
}

.g-color-black-opacity-0_6 p {
  color: rgba(0, 0, 0, 0.6);
}

.g-color-black-opacity-0_6--hover:active,
.g-color-black-opacity-0_6--hover:focus,
.g-color-black-opacity-0_6--hover.active {
  color: rgba(0, 0, 0, 0.6) !important;
}

.g-color-black-opacity-0_6--hover:hover {
  color: rgba(0, 0, 0, 0.6) !important;
}

.g-color-black-opacity-0_6--hover:hover * {
  color: rgba(0, 0, 0, 0.6) !important;
}

.g-color-black-opacity-0_7 {
  color: rgba(0, 0, 0, 0.7) !important;
}

.g-color-black-opacity-0_7 * {
  color: rgba(0, 0, 0, 0.7);
}

.g-color-black-opacity-0_7 p {
  color: rgba(0, 0, 0, 0.7);
}

.g-color-black-opacity-0_7--hover:active,
.g-color-black-opacity-0_7--hover:focus,
.g-color-black-opacity-0_7--hover.active {
  color: rgba(0, 0, 0, 0.7) !important;
}

.g-color-black-opacity-0_7--hover:hover {
  color: rgba(0, 0, 0, 0.7) !important;
}

.g-color-black-opacity-0_7--hover:hover * {
  color: rgba(0, 0, 0, 0.7) !important;
}

.g-color-black-opacity-0_8 {
  color: rgba(0, 0, 0, 0.8) !important;
}

.g-color-black-opacity-0_8 * {
  color: rgba(0, 0, 0, 0.8);
}

.g-color-black-opacity-0_8 p {
  color: rgba(0, 0, 0, 0.8);
}

.g-color-black-opacity-0_8--hover:active,
.g-color-black-opacity-0_8--hover:focus,
.g-color-black-opacity-0_8--hover.active {
  color: rgba(0, 0, 0, 0.8) !important;
}

.g-color-black-opacity-0_8--hover:hover {
  color: rgba(0, 0, 0, 0.8) !important;
}

.g-color-black-opacity-0_8--hover:hover * {
  color: rgba(0, 0, 0, 0.8) !important;
}

.g-color-black-opacity-0_8--child * {
  color: rgba(0, 0, 0, 0.8) !important;
}

.g-color-black-opacity-0_9 {
  color: rgba(0, 0, 0, 0.9) !important;
}

.g-color-black-opacity-0_9 * {
  color: rgba(0, 0, 0, 0.9);
}

.g-color-black-opacity-0_9 p {
  color: rgba(0, 0, 0, 0.9);
}

.g-color-black-opacity-0_9--hover:active,
.g-color-black-opacity-0_9--hover:focus,
.g-color-black-opacity-0_9--hover.active {
  color: rgba(0, 0, 0, 0.9) !important;
}

.g-color-black-opacity-0_9--hover:hover {
  color: rgba(0, 0, 0, 0.9) !important;
}

.g-color-black-opacity-0_9--hover:hover * {
  color: rgba(0, 0, 0, 0.9) !important;
}

.g-color-white--active.active {
  color: #fff !important;
}

.active .g-color-white--parent-active {
  color: #fff !important;
}

.g-color-white--child * {
  color: #fff !important;
}

.g-color-white-opacity-0_1 {
  color: rgba(255, 255, 255, 0.1) !important;
}

.g-color-white-opacity-0_1 * {
  color: rgba(255, 255, 255, 0.1);
}

.g-color-white-opacity-0_1 p {
  color: rgba(255, 255, 255, 0.1);
}

.g-color-white-opacity-0_1--hover:active,
.g-color-white-opacity-0_1--hover:focus,
.g-color-white-opacity-0_1--hover.active {
  color: rgba(255, 255, 255, 0.1) !important;
}

.g-color-white-opacity-0_1--hover:hover {
  color: rgba(255, 255, 255, 0.1) !important;
}

.g-color-white-opacity-0_1--hover:hover * {
  color: rgba(255, 255, 255, 0.1) !important;
}

.g-color-white-opacity-0_2 {
  color: rgba(255, 255, 255, 0.2) !important;
}

.g-color-white-opacity-0_2 * {
  color: rgba(255, 255, 255, 0.2);
}

.g-color-white-opacity-0_2 p {
  color: rgba(255, 255, 255, 0.2);
}

.g-color-white-opacity-0_2--hover:active,
.g-color-white-opacity-0_2--hover:focus,
.g-color-white-opacity-0_2--hover.active {
  color: rgba(255, 255, 255, 0.2) !important;
}

.g-color-white-opacity-0_2--hover:hover {
  color: rgba(255, 255, 255, 0.2) !important;
}

.g-color-white-opacity-0_2--hover:hover * {
  color: rgba(255, 255, 255, 0.2) !important;
}

.g-color-white-opacity-0_3 {
  color: rgba(255, 255, 255, 0.3) !important;
}

.g-color-white-opacity-0_3 * {
  color: rgba(255, 255, 255, 0.3);
}

.g-color-white-opacity-0_3 p {
  color: rgba(255, 255, 255, 0.3);
}

.g-color-white-opacity-0_3--hover:active,
.g-color-white-opacity-0_3--hover:focus,
.g-color-white-opacity-0_3--hover.active {
  color: rgba(255, 255, 255, 0.3) !important;
}

.g-color-white-opacity-0_3--hover:hover {
  color: rgba(255, 255, 255, 0.3) !important;
}

.g-color-white-opacity-0_3--hover:hover * {
  color: rgba(255, 255, 255, 0.3) !important;
}

.g-color-white-opacity-0_5,
.g-color-white-opacity-0_5--hover:hover,
.g-color-white-opacity--hover:active,
.g-color-white-opacity--hover:focus,
.g-color-white-opacity--hover.active {
  color: rgba(255, 255, 255, 0.5) !important;
}

.g-color-white-opacity-0_5 *,
.g-color-white-opacity-0_5--hover:hover *,
.g-color-white-opacity--hover:active *,
.g-color-white-opacity--hover:focus *,
.g-color-white-opacity--hover.active * {
  color: rgba(255, 255, 255, 0.5);
}

.g-color-white-opacity-0_5 p,
.g-color-white-opacity-0_5--hover:hover p,
.g-color-white-opacity--hover:active p,
.g-color-white-opacity--hover:focus p,
.g-color-white-opacity--hover.active p {
  color: rgba(255, 255, 255, 0.5);
}

.g-color-white-opacity-0_6 {
  color: rgba(255, 255, 255, 0.6) !important;
}

.g-color-white-opacity-0_6 * {
  color: rgba(255, 255, 255, 0.6);
}

.g-color-white-opacity-0_6 p {
  color: rgba(255, 255, 255, 0.6);
}

.g-color-white-opacity-0_6--hover:active,
.g-color-white-opacity-0_6--hover:focus,
.g-color-white-opacity-0_6--hover.active {
  color: rgba(255, 255, 255, 0.6) !important;
}

.g-color-white-opacity-0_6--hover:hover {
  color: rgba(255, 255, 255, 0.6) !important;
}

.g-color-white-opacity-0_6--hover:hover * {
  color: rgba(255, 255, 255, 0.6) !important;
}

.g-parent:hover .g-color-white-opacity-0_6--parent-hover {
  color: rgba(255, 255, 255, 0.6) !important;
}

.g-color-white-opacity-0_7,
.g-color-white-opacity-0_7--hover:hover,
.g-color-white-opacity--hover:active,
.g-color-white-opacity--hover:focus,
.g-color-white-opacity--hover.active {
  color: rgba(255, 255, 255, 0.7) !important;
}

.g-color-white-opacity-0_7 *,
.g-color-white-opacity-0_7--hover:hover *,
.g-color-white-opacity--hover:active *,
.g-color-white-opacity--hover:focus *,
.g-color-white-opacity--hover.active * {
  color: rgba(255, 255, 255, 0.7);
}

.g-color-white-opacity-0_7 p,
.g-color-white-opacity-0_7--hover:hover p,
.g-color-white-opacity--hover:active p,
.g-color-white-opacity--hover:focus p,
.g-color-white-opacity--hover.active p {
  color: rgba(255, 255, 255, 0.7);
}

.g-color-white-opacity-0_75 {
  color: rgba(255, 255, 255, 0.75) !important;
}

.g-color-white-opacity-0_75 * {
  color: rgba(255, 255, 255, 0.75);
}

.g-color-white-opacity-0_75 p {
  color: rgba(255, 255, 255, 0.75);
}

.g-color-white-opacity-0_75--hover:hover {
  color: rgba(255, 255, 255, 0.75);
}

.g-color-white-opacity-0_75--hover:hover * {
  color: rgba(255, 255, 255, 0.75);
}

.g-color-white-opacity-0_8 {
  color: rgba(255, 255, 255, 0.8) !important;
}

.g-color-white-opacity-0_8 * {
  color: rgba(255, 255, 255, 0.8);
}

.g-color-white-opacity-0_8 p {
  color: rgba(255, 255, 255, 0.8);
}

.g-color-white-opacity-0_8--hover:active,
.g-color-white-opacity-0_8--hover:focus,
.g-color-white-opacity-0_8--hover.active {
  color: rgba(255, 255, 255, 0.8) !important;
}

.g-color-white-opacity-0_8--hover:hover {
  color: rgba(255, 255, 255, 0.8) !important;
}

.g-color-white-opacity-0_8--hover:hover * {
  color: rgba(255, 255, 255, 0.8) !important;
}

.g-color-white-opacity-0_8--child * {
  color: rgba(255, 255, 255, 0.8) !important;
}

.g-color-white-opacity-0_9,
.g-color-white-opacity-0_9--hover:hover,
.g-color-white-opacity--hover:active,
.g-color-white-opacity--hover:focus,
.g-color-white-opacity--hover.active {
  color: rgba(255, 255, 255, 0.9) !important;
}

.g-color-white-opacity-0_9 *,
.g-color-white-opacity-0_9--hover:hover *,
.g-color-white-opacity--hover:active *,
.g-color-white-opacity--hover:focus *,
.g-color-white-opacity--hover.active * {
  color: rgba(255, 255, 255, 0.9);
}

.g-color-white-opacity-0_9 p,
.g-color-white-opacity-0_9--hover:hover p,
.g-color-white-opacity--hover:active p,
.g-color-white-opacity--hover:focus p,
.g-color-white-opacity--hover.active p {
  color: rgba(255, 255, 255, 0.9);
}

/* Gray Colors */
.g-color-gray-light-v1 {
  color: #f4f5f7 !important;
}

.g-color-gray-light-v1--hover:active,
.g-color-gray-light-v1--hover:focus,
.g-color-gray-light-v1--hover.active {
  color: #f4f5f7 !important;
}

.g-color-gray-light-v1--hover:hover {
  color: #f4f5f7 !important;
}

.g-color-gray-light-v1--hover:hover * {
  color: #f4f5f7 !important;
}

.g-color-gray-light-v1 * {
  color: #f4f5f7;
}

.g-color-gray-light-v1 p {
  color: #f4f5f7;
}

.g-color-gray-light-v2 {
  color: #dfe1e6 !important;
}

.g-color-gray-light-v2 * {
  color: #dfe1e6;
}

.g-color-gray-light-v2 p {
  color: #dfe1e6;
}

.g-color-gray-light-v2--hover:active,
.g-color-gray-light-v2--hover:focus,
.g-color-gray-light-v2--hover.active {
  color: #dfe1e6 !important;
}

.g-color-gray-light-v2--hover:hover {
  color: #dfe1e6 !important;
}

.g-color-gray-light-v2--hover:hover * {
  color: #dfe1e6 !important;
}

.g-color-gray-light-v3 {
  color: #c1c7d0 !important;
}

.g-color-gray-light-v3 * {
  color: #c1c7d0;
}

.g-color-gray-light-v3 p {
  color: #c1c7d0;
}

.g-color-gray-light-v3--hover:active,
.g-color-gray-light-v3--hover:focus,
.g-color-gray-light-v3--hover.active {
  color: #c1c7d0 !important;
}

.g-color-gray-light-v3--hover:hover {
  color: #c1c7d0 !important;
}

.g-color-gray-light-v3--hover:hover * {
  color: #c1c7d0 !important;
}

.g-color-gray-light-v4 {
  color: #b3bac5 !important;
}

.g-color-gray-light-v4 * {
  color: #b3bac5;
}

.g-color-gray-light-v4 p {
  color: #b3bac5;
}

.g-color-gray-light-v4--hover:active,
.g-color-gray-light-v4--hover:focus,
.g-color-gray-light-v4--hover.active {
  color: #b3bac5 !important;
}

.g-color-gray-light-v4--hover:hover {
  color: #b3bac5 !important;
}

.g-color-gray-light-v4--hover:hover * {
  color: #b3bac5 !important;
}

.g-color-gray-light-v4-opacity-0_6 {
  color: rgba(238, 238, 238, 0.6) !important;
}

.g-color-gray-light-v5 {
  color: #d0d4dd !important;
}

.g-color-gray-light-v5 * {
  color: #d0d4dd;
}

.g-color-gray-light-v5 p {
  color: #d0d4dd;
}

.g-color-gray-light-v5--hover:active,
.g-color-gray-light-v5--hover:focus,
.g-color-gray-light-v5--hover.active {
  color: #d0d4dd !important;
}

.g-color-gray-light-v5--hover:hover {
  color: #d0d4dd !important;
}

.g-color-gray-light-v5--hover:hover * {
  color: #d0d4dd !important;
}

.g-color-gray-dark-v1 {
  color: #97a0af !important;
}

.g-color-gray-dark-v1 * {
  color: #97a0af;
}

.g-color-gray-dark-v1 p {
  color: #97a0af;
}

.g-color-gray-dark-v1--hover:active,
.g-color-gray-dark-v1--hover:focus,
.g-color-gray-dark-v1--hover.active {
  color: #97a0af !important;
}

.g-color-gray-dark-v1--hover:hover {
  color: #97a0af !important;
}

.g-color-gray-dark-v1--hover:hover * {
  color: #97a0af !important;
}

.g-color-gray-dark-v2 {
  color: #f8f9fa !important;
}

.g-color-gray-dark-v2 * {
  color: #f8f9fa;
}

.g-color-gray-dark-v2 p {
  color: #f8f9fa;
}

.g-color-gray-dark-v2--hover:active,
.g-color-gray-dark-v2--hover:focus,
.g-color-gray-dark-v2--hover.active {
  color: #f8f9fa !important;
}

.g-color-gray-dark-v2--hover:hover {
  color: #f8f9fa !important;
}

.g-color-gray-dark-v2--hover:hover * {
  color: #f8f9fa !important;
}

.g-color-gray-dark-v2-opacity-0_75 {
  color: rgba(51, 51, 51, 0.75) !important;
}

.g-color-gray-dark-v3 {
  color: #555 !important;
}

.g-color-gray-dark-v3 * {
  color: #555;
}

.g-color-gray-dark-v3 p {
  color: #555;
}

.g-color-gray-dark-v3--hover:active,
.g-color-gray-dark-v3--hover:focus,
.g-color-gray-dark-v3--hover.active {
  color: #555 !important;
}

.g-color-gray-dark-v3--hover:hover {
  color: #555 !important;
}

.g-color-gray-dark-v3--hover:hover * {
  color: #555 !important;
}

.g-color-gray-dark-v4 {
  color: #777 !important;
}

.g-color-gray-dark-v4 * {
  color: #777;
}

.g-color-gray-dark-v4 p {
  color: #777;
}

.g-color-gray-dark-v4--hover:active,
.g-color-gray-dark-v4--hover:focus,
.g-color-gray-dark-v4--hover.active {
  color: #777 !important;
}

.g-color-gray-dark-v4--hover:hover {
  color: #777 !important;
}

.g-color-gray-dark-v4--hover:hover * {
  color: #777 !important;
}

.g-color-gray-dark-v5 {
  color: #999 !important;
}

.g-color-gray-dark-v5 * {
  color: #999;
}

.g-color-gray-dark-v5 p {
  color: #999;
}

.g-color-gray-dark-v5--hover:active,
.g-color-gray-dark-v5--hover:focus,
.g-color-gray-dark-v5--hover.active {
  color: #999 !important;
}

.g-color-gray-dark-v5--hover:hover {
  color: #999 !important;
}

.g-color-gray-dark-v5--hover:hover * {
  color: #999 !important;
}

.g-parent:hover .g-color-gray-dark-v5--parent-hover {
  color: #999 !important;
}

.g-color-blue-dark-v1 {
  color: #175a88 !important;
}

.g-color-blue-dark-v1 * {
  color: #175a88;
}

.g-color-blue-dark-v1 p {
  color: #175a88;
}

.g-color-blue-dark-v1--hover:hover {
  color: #175a88 !important;
}

.g-color-blue-dark-v1--hover:hover * {
  color: #175a88 !important;
}

/* Color Light Blue */
.g-color-lightblue {
  color: #158aff !important;
}

.g-color-lightblue * {
  color: #158aff;
}

.g-color-lightblue p {
  color: #158aff;
}

.g-color-lightblue--hover:active,
.g-color-lightblue--hover:focus,
.g-color-lightblue--hover.active {
  color: #158aff !important;
}

.g-color-lightblue--hover:hover {
  color: #158aff !important;
}

.g-color-lightblue--hover:hover * {
  color: #158aff !important;
}

.g-color-lightblue-v1 {
  color: #d6e2ee !important;
}

.g-color-lightblue-v1 * {
  color: #d6e2ee;
}

.g-color-lightblue-v1 p {
  color: #d6e2ee;
}

.g-color-lightblue-v1--hover:active,
.g-color-lightblue-v1--hover:focus,
.g-color-lightblue-v1--hover.active {
  color: #d6e2ee !important;
}

.g-color-lightblue-v1--hover:hover {
  color: #d6e2ee !important;
}

.g-color-lightblue-v1--hover:hover * {
  color: #d6e2ee !important;
}

/* Color Dark Blue */
.g-color-darkblue {
  color: #172b4d !important;
}

.g-color-darkblue * {
  color: #172b4d;
}

.g-color-darkblue p {
  color: #172b4d;
}

.g-color-darkblue--hover:active,
.g-color-darkblue--hover:focus,
.g-color-darkblue--hover.active {
  color: #172b4d !important;
}

.g-color-darkblue--hover:hover {
  color: #172b4d !important;
}

.g-color-darkblue--hover:hover * {
  color: #172b4d !important;
}

/* Color Indigo */
.g-color-indigo {
  color: #091e42 !important;
}

.g-color-indigo * {
  color: #091e42;
}

.g-color-indigo p {
  color: #091e42;
}

.g-color-indigo--hover:active,
.g-color-indigo--hover:focus,
.g-color-indigo--hover.active {
  color: #091e42 !important;
}

.g-color-indigo--hover:hover {
  color: #091e42 !important;
}

.g-color-indigo--hover:hover * {
  color: #091e42 !important;
}

/* Color Red */
.g-color-red {
  color: #fd3a57 !important;
}

.g-color-red * {
  color: #fd3a57;
}

.g-color-red p {
  color: #fd3a57;
}

.g-color-red--hover:active,
.g-color-red--hover:focus,
.g-color-red--hover.active {
  color: #fd3a57 !important;
}

.g-color-red--hover:hover {
  color: #fd3a57 !important;
}

.g-color-red--hover:hover * {
  color: #fd3a57 !important;
}

*:hover > .g-color-red--parent-hover {
  color: #fd3a57 !important;
}

/* Color Light Red */
.g-color-lightred {
  color: #ff788c !important;
}

.g-color-lightred * {
  color: #ff788c;
}

.g-color-lightred p {
  color: #ff788c;
}

.g-color-lightred--hover:active,
.g-color-lightred--hover:focus,
.g-color-lightred--hover.active {
  color: #ff788c !important;
}

.g-color-lightred--hover:hover {
  color: #ff788c !important;
}

.g-color-lightred--hover:hover * {
  color: #ff788c !important;
}

/* Color Dark Red */
.g-color-darkred {
  color: #a10f2b !important;
}

.g-color-darkred * {
  color: #a10f2b;
}

.g-color-darkred p {
  color: #a10f2b;
}

.g-color-darkred--hover:active,
.g-color-darkred--hover:focus,
.g-color-darkred--hover.active {
  color: #a10f2b !important;
}

.g-color-darkred--hover:hover {
  color: #a10f2b !important;
}

.g-color-darkred--hover:hover * {
  color: #a10f2b !important;
}

/* Color Purple */
.g-color-purple {
  color: #9a69cb !important;
}

.g-color-purple * {
  color: #9a69cb;
}

.g-color-purple p {
  color: #9a69cb;
}

.g-color-purple--hover:active,
.g-color-purple--hover:focus,
.g-color-purple--hover.active {
  color: #9a69cb !important;
}

.g-color-purple--hover:hover {
  color: #9a69cb !important;
}

.g-color-purple--hover:hover * {
  color: #9a69cb !important;
}

.g-color-purple-dark-v1 {
  color: #552c7e !important;
}

/* Color Dark Purple */
.g-color-darkpurple {
  color: #6639b6 !important;
}

.g-color-darkpurple * {
  color: #6639b6;
}

.g-color-darkpurple p {
  color: #6639b6;
}

.g-color-darkpurple--hover:active,
.g-color-darkpurple--hover:focus,
.g-color-darkpurple--hover.active {
  color: #6639b6 !important;
}

.g-color-darkpurple--hover:hover {
  color: #6639b6 !important;
}

.g-color-darkpurple--hover:hover * {
  color: #6639b6 !important;
}

/* Color Pink */
.g-color-pink {
  color: #fd9caa !important;
}

.g-color-pink * {
  color: #fd9caa;
}

.g-color-pink p {
  color: #fd9caa;
}

.g-color-pink--hover:active,
.g-color-pink--hover:focus,
.g-color-pink--hover.active {
  color: #fd9caa !important;
}

.g-color-pink--hover:hover {
  color: #fd9caa !important;
}

.g-color-pink--hover:hover * {
  color: #fd9caa !important;
}

.g-color-pink-dark-v1 {
  color: #6f0b2d !important;
}

.g-color-pink-dark-v1 * {
  color: #6f0b2d;
}

.g-color-pink-dark-v1 p {
  color: #6f0b2d;
}

.g-color-pink-dark-v1--hover:hover {
  color: #6f0b2d !important;
}

/* Color Orange */
.g-color-orange {
  color: #ffcd33 !important;
}

.g-color-orange * {
  color: #ffcd33;
}

.g-color-orange p {
  color: #ffcd33;
}

.g-color-orange--hover:active,
.g-color-orange--hover:focus,
.g-color-orange--hover.active {
  color: #ffcd33 !important;
}

.g-color-orange--hover:hover {
  color: #ffcd33 !important;
}

.g-color-orange--hover:hover * {
  color: #ffcd33 !important;
}

/* Color Deep Orange */
.g-color-deeporange {
  color: #fe541e !important;
}

.g-color-deeporange * {
  color: #fe541e;
}

.g-color-deeporange p {
  color: #fe541e;
}

.g-color-deeporange--hover:active,
.g-color-deeporange--hover:focus,
.g-color-deeporange--hover.active {
  color: #fe541e !important;
}

.g-color-deeporange--hover:hover {
  color: #fe541e !important;
}

.g-color-deeporange--hover:hover * {
  color: #fe541e !important;
}

/* Color Yellow */
.g-color-yellow {
  color: #ffdd2d !important;
}

.g-color-yellow * {
  color: #ffdd2d;
}

.g-color-yellow p {
  color: #ffdd2d;
}

.g-color-yellow--hover:active,
.g-color-yellow--hover:focus,
.g-color-yellow--hover.active {
  color: #ffdd2d !important;
}

.g-color-yellow--hover:hover {
  color: #ffdd2d !important;
}

.g-color-yellow--hover:hover * {
  color: #ffdd2d !important;
}

/* Color Aqua */
.g-color-aqua {
  color: #c2e0ff !important;
}

.g-color-aqua * {
  color: #c2e0ff;
}

.g-color-aqua p {
  color: #c2e0ff;
}

.g-color-aqua--hover:active,
.g-color-aqua--hover:focus,
.g-color-aqua--hover.active {
  color: #c2e0ff !important;
}

.g-color-aqua--hover:hover {
  color: #c2e0ff !important;
}

.g-color-aqua--hover:hover * {
  color: #c2e0ff !important;
}

.g-color-aqua-dark-v1 {
  color: #11848e !important;
}

.g-color-aqua-dark-v1 * {
  color: #11848e;
}

.g-color-aqua-dark-v1 p {
  color: #11848e;
}

.g-color-aqua-dark-v1--hover:active,
.g-color-aqua-dark-v1--hover:focus,
.g-color-aqua-dark-v1--hover.active {
  color: #11848e !important;
}

.g-color-aqua-dark-v1--hover:hover {
  color: #11848e !important;
}

.g-color-aqua-dark-v1--hover:hover * {
  color: #11848e !important;
}

/* Color Cyan */
.g-color-cyan {
  color: #00bed6 !important;
}

.g-color-cyan * {
  color: #00bed6;
}

.g-color-cyan p {
  color: #00bed6;
}

.g-color-cyan--hover:active,
.g-color-cyan--hover:focus,
.g-color-cyan--hover.active {
  color: #00bed6 !important;
}

.g-color-cyan--hover:hover {
  color: #00bed6 !important;
}

.g-color-cyan--hover:hover * {
  color: #00bed6 !important;
}

/* Color Teal */
.g-color-teal {
  color: #18ba9b !important;
}

.g-color-teal * {
  color: #18ba9b;
}

.g-color-teal p {
  color: #18ba9b;
}

.g-color-teal--hover:active,
.g-color-teal--hover:focus,
.g-color-teal--hover.active {
  color: #18ba9b !important;
}

.g-color-teal--hover:hover {
  color: #18ba9b !important;
}

.g-color-teal--hover:hover * {
  color: #18ba9b !important;
}

/* Color Brown */
.g-color-brown {
  color: #9c8061 !important;
}

.g-color-brown * {
  color: #9c8061;
}

.g-color-brown p {
  color: #9c8061;
}

.g-color-brown--hover:active,
.g-color-brown--hover:focus,
.g-color-brown--hover.active {
  color: #9c8061 !important;
}

.g-color-brown--hover:hover {
  color: #9c8061 !important;
}

.g-color-brown--hover:hover * {
  color: #9c8061 !important;
}

/* Color Blue Gray */
.g-color-bluegray {
  color: #344563 !important;
}

.g-color-bluegray * {
  color: #344563;
}

.g-color-bluegray p {
  color: #344563;
}

.g-color-bluegray--hover:active,
.g-color-bluegray--hover:focus,
.g-color-bluegray--hover.active {
  color: #344563 !important;
}

.g-color-bluegray--hover:hover {
  color: #344563 !important;
}

.g-color-bluegray--hover:hover * {
  color: #344563 !important;
}

.main-banner {
  position: relative;
  width: 100%;
  padding: 0;
  border: 0;
  background-position: center 0%;
  background-repeat: no-repeat;
  background-size: auto 90%;
}

.main-banner::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 32px;
  width: 100%;
  background-color: #fff;
}

@media (min-width: 576px) {
  .main-banner::after {
    height: 40px;
  }
}

.main-banner.g-bg-dark-blue .main-banner_title,
.main-banner.g-bg-dark-blue .main-banner_description, .main-banner.g-bg-dark-indigo .main-banner_title,
.main-banner.g-bg-dark-indigo .main-banner_description, .main-banner.g-bg-dark-grey-blue .main-banner_title,
.main-banner.g-bg-dark-grey-blue .main-banner_description, .main-banner.g-bg-blue .main-banner_title,
.main-banner.g-bg-blue .main-banner_description {
  color: #fff;
}

.main-banner-summit::after {
  content: none;
}

@media (min-width: 992px) {
  .main-banner-summit {
    padding-bottom: 3rem;
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .main-banner {
    min-height: 360px;
    margin-bottom: 3rem;
  }
  .main-banner::after {
    content: none;
  }
}

@media (min-width: 1200px) {
  .main-banner {
    min-height: 408px;
  }
}

.main-banner-blue {
  background-color: #158aff;
}

.main-banner-grey {
  background-color: #f4f5f7;
}

.main-banner_image {
  display: flex;
  align-items: flex-end;
}

.main-banner_img {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

@media (min-width: 768px) {
  .main-banner_img {
    margin-top: 1rem;
  }
}

@media (min-width: 992px) {
  .main-banner_img {
    bottom: -3rem;
  }
}

@media (min-width: 992px) {
  .main-banner_textblock {
    margin-bottom: 3rem;
  }
}

.main-banner_textblock--breadcrumb .breadcrumb-item {
  height: 24px;
  margin-top: 0.5rem;
}

@media (min-width: 768px) {
  .main-banner_textblock--breadcrumb .breadcrumb-item {
    margin-top: 1rem;
  }
}

.main-banner_title {
  margin-bottom: 1rem;
  margin-top: 1.5rem;
}

@media (min-width: 768px) {
  .main-banner_title {
    margin-top: 2rem;
  }
}

@media (min-width: 992px) {
  .main-banner_title {
    margin-top: 3rem;
  }
}

.main-banner_title:not(.crumbs) {
  margin-top: 2rem;
}

@media (min-width: 768px) {
  .main-banner_title:not(.crumbs) {
    margin-top: 2.5rem;
  }
}

@media (min-width: 992px) {
  .main-banner_title:not(.crumbs) {
    margin-top: 6.5rem;
  }
}

.main-banner_description {
  font-size: 1.375rem;
  font-weight: 400;
  color: #6c798f;
  margin-bottom: 0;
}

.main-banner_btnblock {
  margin-top: 1rem;
}

@media (min-width: 992px) {
  .main-banner_btnblock {
    margin-bottom: 0;
  }
}

.newsroom__banner {
  background-size: cover;
  position: relative;
  margin-bottom: 0;
}

.newsroom__banner::after {
  display: none;
}

.newsroom__banner .banner__variant {
  display: flex;
  flex-direction: column;
}

@media (max-width: 767.98px) {
  .newsroom__banner-textblock .main-banner_breadcrumb .breadcrumb-item {
    margin-top: 0;
    height: 16px;
    display: flex;
    align-items: center;
  }
}

.newsroom__banner-title {
  color: #fff;
  margin-bottom: 1rem;
}

@media (max-width: 767.98px) {
  .newsroom__banner-title {
    margin-top: 0.75rem;
  }
}

@media (min-width: 768px) {
  .newsroom__banner-title {
    margin-top: 1.375rem;
  }
}

@media (min-width: 992px) {
  .newsroom__banner-title {
    margin-top: 2.875rem;
  }
}

.newsroom__banner .main-banner_description {
  font-size: 1.375rem;
  line-height: 32px;
}

.newsroom__banner-subtitle {
  margin-bottom: 0;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 16px;
  letter-spacing: 0.03rem;
  text-transform: uppercase;
  color: #fff;
}

@media (max-width: 767.98px) {
  .newsroom__banner-subtitle {
    margin-top: 0.75rem;
  }
}

@media (min-width: 768px) {
  .newsroom__banner-subtitle {
    margin-top: 0.875rem;
  }
}

@media (min-width: 992px) {
  .newsroom__banner-subtitle {
    margin-top: 2.875rem;
  }
}

.newsroom__banner .bg-mobile {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  padding-bottom: 4.5rem;
  padding-top: 1rem;
}

@media (min-width: 768px) {
  .newsroom__banner .bg-mobile {
    min-height: 312px;
    padding-top: 0.25rem;
  }
}

@media (min-width: 992px) {
  .newsroom__banner .bg-mobile {
    background-size: 0;
    min-height: 456px;
    padding-top: 0.5rem;
  }
}

.newsroom__banner-description_left {
  font-size: 1.375rem;
}

@media (max-width: 991.98px) {
  .newsroom__banner.bg-desktop {
    background-size: 0;
  }
}

.newsroom__banner .banner-additional .newsroom__banner-title {
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}

.newsroom__banner .banner-additional .additional {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .newsroom__banner .banner-additional .additional {
    align-items: center;
    flex-direction: row;
  }
}

@media (max-width: 767.98px) {
  .newsroom__banner .banner-additional .additional-place {
    margin-top: 0.5rem;
  }
}

.newsroom__banner .banner-additional .additional-date, .newsroom__banner .banner-additional .additional-place {
  display: flex;
  align-items: center;
  margin-right: 2rem;
}

.newsroom__banner .banner-additional .additional-date_icon, .newsroom__banner .banner-additional .additional-place_icon {
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
}

.newsroom__banner .banner-additional .additional-date_text, .newsroom__banner .banner-additional .additional-place_text {
  margin-bottom: 0;
  color: #fff;
}

@media (min-width: 992px) {
  .newsroom__banner-center .newsroom__banner-title,
  .newsroom__banner-center .main-banner_description {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .newsroom__banner-center .newsroom__banner-title {
    margin-top: 1rem;
  }
}

@media (min-width: 992px) {
  .newsroom__banner-center .newsroom__banner-title {
    margin-top: 4.875rem;
  }
}

.banner-select {
  position: relative;
}

@media (min-width: 992px) {
  .banner-select::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 1;
  }
  .banner-select__4::after {
    height: 220px;
  }
  .banner-select__2::after {
    height: 46px;
  }
}

@media (min-width: 992px) {
  .banner-select--textblock {
    text-align: center;
  }
}

.banner-select .main-banner_description {
  margin-bottom: 3rem;
}

.banner-select--content {
  z-index: 2;
}

.banner-select_area {
  background-color: #f4f5f7;
  position: relative;
}

@media (max-width: 991.98px) {
  .banner-select_area::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #f4f5f7;
    box-shadow: 150px 0 0 0 #f4f5f7, -150px 0 0 0 #f4f5f7;
    z-index: -1;
  }
}

@media (min-width: 992px) {
  .banner-select_area {
    border-radius: 0.25rem;
  }
}

.banner-select_area--row {
  padding: 2rem 0 1.5rem;
}

.banner-select_area--title {
  text-transform: uppercase;
  font-family: "Rubik", sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: .7px;
  margin-bottom: 1.5rem;
}

.banner-select_area--label {
  font-size: 0.875rem;
  line-height: 1.14;
}

.banner-select_area--btnblock {
  margin-top: 1rem;
  text-align: center;
}

.banner-select__4 .main-banner_btnblock {
  margin-bottom: 1rem;
}

.banner-select__4::after {
  height: 524px;
}

@media (min-width: 768px) {
  .banner-select__4::after {
    height: 338px;
  }
}

@media (min-width: 992px) {
  .banner-select__4::after {
    height: 218px;
  }
}

.partner-banner {
  background-size: 0;
  padding-bottom: 2rem;
  position: relative;
  overflow: hidden;
}

.partner-banner_mobile-tablet {
  height: 100%;
  width: 100%;
  padding-left: 15px;
}

@media (min-width: 992px) {
  .partner-banner_mobile-tablet {
    display: none;
  }
}

.partner-banner_mobile-tablet--oval {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 95%;
}

@media (min-width: 576px) {
  .partner-banner_mobile-tablet--oval {
    height: 400px;
    width: auto;
    right: -150px;
  }
}

@media (min-width: 768px) {
  .partner-banner_mobile-tablet--oval {
    height: 440px;
    right: -100px;
  }
}

@media (min-width: 992px) {
  .partner-banner {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
}

.partner-banner--blue {
  background-color: #158aff;
}

.partner-banner--grey {
  background-color: #f4f5f7;
}

.partner-banner_img {
  width: 100%;
  height: auto;
  display: block;
  position: relative;
  z-index: 2;
}

.partner-banner_textblock {
  display: flex;
  flex-direction: column;
}

.partner-banner_textblock--breadcrumb {
  margin-top: 0.5rem;
}

@media (min-width: 576px) {
  .partner-banner_textblock--breadcrumb {
    margin-top: 1rem;
  }
}

.partner-banner_title {
  margin-bottom: 1rem;
  color: #fff;
  margin-top: 0.5rem;
}

.partner-banner_title--catalogue {
  margin-top: 1.5rem;
}

@media (min-width: 576px) {
  .partner-banner_title {
    margin-top: 1rem;
  }
  .partner-banner_title--catalogue {
    margin-top: 3rem;
  }
}

@media (min-width: 992px) {
  .partner-banner_title {
    margin-top: 2rem;
  }
  .partner-banner_title--catalogue {
    margin-top: 3.5rem;
  }
}

.partner-banner_description {
  font-size: 1.375rem;
  font-weight: 400;
  color: #fff;
  margin-bottom: 0;
}

@media (min-width: 576px) {
  .partner-banner_description {
    font-size: 1.5rem;
  }
}

.partner-banner_btnblock {
  margin-top: 1rem;
}

.partner-banner_link {
  position: relative;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  color: #fff;
  transition: all 0.2s ease-in-out;
  font-weight: 600;
}

.partner-banner_link--wrapper {
  width: 100%;
  margin-top: 0.5rem;
}

@media (min-width: 768px) {
  .partner-banner_link--wrapper {
    margin-top: 1rem;
  }
}

@media (min-width: 992px) {
  .partner-banner_link--wrapper {
    margin-top: auto;
  }
}

.partner-banner_link:hover {
  color: #fff;
}

.partner-banner_link::before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: currentColor;
  opacity: 0;
  transition: opacity .2s;
}

.partner-banner_link:hover::before {
  opacity: 1;
}

.partner-banner_company {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 4rem;
}

.partner-banner_company--logo {
  height: 32px;
  margin-bottom: 1.5rem;
  display: none;
}

@media (min-width: 992px) {
  .partner-banner_company--logo {
    display: block;
  }
}

.solution-main-banner {
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding: 0;
  border: 0;
}

.solution-main-banner_w__form {
  overflow: hidden;
  position: relative;
}

@media (min-width: 576px) {
  .solution-main-banner_w__form::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3rem;
    bottom: 0;
    left: 0;
    background-color: #fff;
  }
}

@media (min-width: 992px) {
  .solution-main-banner_w__form {
    padding-bottom: 1rem;
  }
}

.solution-main-banner_w__form_new {
  margin-bottom: 3rem;
}

@media (max-width: 991.98px) {
  .solution-main-banner_w__form_new {
    overflow: hidden;
  }
}

@media (min-width: 992px) {
  .solution-main-banner_w__form_new {
    max-height: 404px;
    padding-bottom: 3rem;
  }
}

.solution-main-banner_column-row {
  padding-bottom: 4.5rem;
}

@media (min-width: 992px) {
  .solution-main-banner_column-row {
    padding: 2rem 0 4.5rem;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.solution-main-banner--blue {
  background-color: #158aff;
}

.solution-main-banner--partner_title {
  text-align: left;
  color: #fff;
  margin-bottom: 1rem;
}

.solution-main-banner--partner_subtitle {
  text-align: left;
  font-size: 1.375rem;
  font-weight: 400;
  color: #fff;
}

.solution-main-banner--partner_subtitle [href^="mailto:"] {
  color: currentColor;
  position: relative;
}

.solution-main-banner--partner_subtitle [href^="mailto:"]::before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: currentColor;
  opacity: 0;
  transition: opacity .2s;
}

.solution-main-banner--partner_subtitle [href^="mailto:"]:hover::before {
  opacity: 1;
}

@media (min-width: 992px) {
  .solution-main-banner--partner {
    min-height: 408px;
  }
  .solution-main-banner--partner_title {
    text-align: center;
  }
  .solution-main-banner--partner_subtitle {
    text-align: center;
  }
}

.solution-main-banner--dark_blue {
  background-color: #172b4d;
}

.solution-main-banner--form_title {
  color: #fff;
  line-height: 1;
}

.solution-main-banner--form_subtitle {
  font-size: 1.375rem;
  font-weight: 400;
  color: #fff;
  margin-bottom: 1rem;
}

@media (min-width: 992px) {
  .solution-main-banner--form {
    min-height: 408px;
  }
}

.solution-main-banner_row {
  min-height: 376px;
}

@media (min-width: 576px) {
  .solution-main-banner_row {
    min-height: 344px;
  }
}

@media (min-width: 992px) {
  .solution-main-banner_row {
    min-height: 456px;
  }
}

.solution-main-banner_smallrow {
  min-height: 350px;
}

.solution-main-banner_textblock {
  align-self: center;
  overflow: hidden;
}

.solution-main-banner_textblock--breadcrumb {
  align-self: initial;
}

@media (min-width: 992px) {
  .solution-main-banner_textblock--breadcrumb {
    display: flex;
    flex-direction: column;
  }
}

.solution-main-banner_breadcrumb {
  margin: 1rem 0 1.5rem;
  color: #fff;
}

@media (min-width: 768px) {
  .solution-main-banner_breadcrumb {
    margin-bottom: 2rem;
  }
}

@media (min-width: 992px) {
  .solution-main-banner_breadcrumb {
    margin-bottom: 0;
  }
}

.solution-main-banner_breadcrumb a {
  color: #fff;
}

.solution-main-banner_title {
  text-align: left;
  color: #fff;
}

@media (min-width: 992px) {
  .solution-main-banner_title {
    text-align: center;
  }
}

.solution-main-banner_subtitle {
  text-align: left;
  font-size: 1.375rem;
  font-weight: 400;
  color: #fff;
  margin-bottom: 1rem;
}

.solution-main-banner_subtitle [href^="mailto:"] {
  color: currentColor;
  position: relative;
}

.solution-main-banner_subtitle [href^="mailto:"]::before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: currentColor;
  opacity: 0;
  transition: opacity .2s;
}

.solution-main-banner_subtitle [href^="mailto:"]:hover::before {
  opacity: 1;
}

@media (min-width: 992px) {
  .solution-main-banner_subtitle {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .solution-main-banner_subtitle.text-left {
    width: 50%;
    margin-bottom: 1rem;
  }
}

.solution-main-banner_button.hidden {
  display: none;
}

.solution-main-banner_btnblock {
  text-align: left;
}

@media (min-width: 992px) {
  .solution-main-banner_btnblock {
    text-align: center;
  }
}

.partners-support-banner {
  background-color: #158aff;
  background-position: 54% bottom;
  background-repeat: no-repeat;
  background-size: auto;
  width: 100%;
  padding: 2rem 1rem 3.125rem;
  border: 0;
  position: relative;
}

.partners-support-banner_item {
  text-align: center;
  margin-bottom: 1.5rem;
  color: #fff;
}

@media (min-width: 576px) {
  .partners-support-banner_item {
    text-align: left;
    margin-right: 1rem;
  }
}

@media (min-width: 768px) {
  .partners-support-banner_item {
    margin-right: 0;
    max-width: 170px;
  }
}

.partners-support-banner_image {
  display: block;
  position: relative;
  margin: 0 auto 0.5rem;
}

@media (min-width: 576px) {
  .partners-support-banner_image {
    margin: 0 0 0.5rem;
  }
}

.partners-support-banner_text {
  position: relative;
  margin-bottom: 0;
  word-break: break-word;
}

@media (min-width: 576px) {
  .partners-support-banner_text {
    width: 144px;
  }
}

.partners-support-banner_bg {
  position: absolute;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.partners-support-banner_bg--img {
  position: absolute;
  display: none;
}

@media (min-width: 768px) {
  .partners-support-banner_bg--img {
    display: block;
    right: -50px;
    bottom: 0;
    height: 70%;
  }
}

@media (min-width: 992px) {
  .partners-support-banner_bg--img {
    height: 100%;
    right: 0;
  }
}

@media (max-width: 767.98px) {
  .partners-support-banner {
    background-image: none !important;
  }
}

@media (min-width: 768px) {
  .partners-support-banner {
    padding: 4rem 0 9.25rem;
  }
}

@media (min-width: 992px) {
  .partners-support-banner {
    padding: 4rem 0 2.25rem;
    background-position: center center;
    background-size: cover;
  }
}

.partners-support-banner_textblock {
  align-self: center;
  display: flex;
  flex-direction: column;
}

.partners-support-banner_textblock--breadcrumb {
  align-self: initial;
}

.partners-support-banner_breadcrumb {
  margin: 0 0 1rem;
}

@media (min-width: 992px) {
  .partners-support-banner_breadcrumb {
    margin: 1rem 0 2rem;
  }
}

.partners-support-banner_title {
  text-align: center;
  margin: 0 1rem 2rem;
  color: #fff;
}

@media (min-width: 576px) {
  .partners-support-banner_title {
    text-align: left;
    margin: 0 0 2.75rem;
  }
}

.partners-support-banner_subtitle {
  text-align: center;
  margin-bottom: 1rem;
  color: #fff;
}

@media (min-width: 576px) {
  .partners-support-banner_subtitle {
    text-align: left;
    margin-bottom: 1.75rem;
  }
}

.partners-support-banner_btnblock {
  text-align: center;
  font-size: 0.75rem;
  line-height: 1;
  margin-top: 0.5rem;
  width: auto;
  padding: 0 25px;
}

.partners-support-banner_btnblock .button-icon {
  position: relative;
  top: -2px;
}

.partners-support-banner_btnblock [class*="_button"],
.partners-support-banner_btnblock [class*="_btn"] {
  font-weight: 600;
  padding: 0;
}

.partners-support-banner_btnblock [role="button"] + .button-icon {
  padding-left: 0;
  margin-left: -3px;
  margin-top: 2px;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .partners-support-banner_btnblock [role="button"] + .button-icon {
    transition: none;
  }
}

.partners-support-banner_btnblock [role="button"] + .button-icon.plus {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.partners-support-banner_btnblock [role="button"] + .button-icon .arrow,
.partners-support-banner_btnblock [role="button"] + .button-icon .http,
.partners-support-banner_btnblock [role="button"] + .button-icon .plus {
  fill: #fff;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .partners-support-banner_btnblock [role="button"] + .button-icon .arrow,
  .partners-support-banner_btnblock [role="button"] + .button-icon .http,
  .partners-support-banner_btnblock [role="button"] + .button-icon .plus {
    transition: none;
  }
}

.partners-support-banner_btnblock [role="button"]:hover + .button-icon {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.partners-support-banner_btnblock [role="button"]:hover + .button-icon .arrow,
.partners-support-banner_btnblock [role="button"]:hover + .button-icon .http,
.partners-support-banner_btnblock [role="button"]:hover + .button-icon .plus {
  fill: #fff;
}

.partners-support-banner_btnblock [role="button"]:hover + .browser-link {
  -webkit-transform: none;
  transform: none;
}

@media (min-width: 576px) {
  .partners-support-banner_btnblock {
    text-align: left;
    margin: 0;
    font-size: 1rem;
    padding: 0;
    max-width: 350px;
  }
}

@media (min-width: 576px) {
  .partners-support-banner_btnblock {
    margin-top: 1.875rem;
  }
}

.partners-support-banner_link {
  font-family: "Rubik", sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  color: #fff;
  padding: 0.5rem 0;
  text-transform: uppercase;
  line-height: 1.33;
  letter-spacing: .7px;
}

.partners-support-banner_link:hover {
  color: #fff;
}

.partners-support-banner_link:hover .button-icon {
  margin-left: 0.333333rem;
}

.partners-support-banner_link:hover .button-icon .arrow {
  fill: #fff;
}

.company-main-banner {
  background-color: transparent;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding: 0;
  margin: 0 0 3rem;
  border: 0;
}

.company-main-banner_row {
  min-height: 456px;
}

.company-main-banner_textblock {
  align-self: center;
}

.company-main-banner_title {
  font-size: 2.875rem;
  font-weight: 500;
  color: #172b4d;
}

.company-main-banner_subtitle {
  font-size: 1.375rem;
  font-weight: 400;
  color: #6c798f;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .company-main-banner_subtitle.text-left {
    width: 50%;
  }
}

.company-main-banner_btnblock {
  text-align: center;
}

.police-block {
  padding: 1rem 0 1.5rem;
}

.police-block_header {
  margin-top: 1.5rem;
}

.police-block_description {
  white-space: pre-wrap;
}

.police-block_list {
  padding-left: 1rem;
}

.police-block_list .additional__text {
  margin: 0.5rem 0;
}

.header-w-description_header {
  text-align: center;
}

.header-w-description_description {
  font-size: 1.375rem;
  font-weight: 400;
  text-align: center;
}

.header-w-description_description [href^="mailto:"] {
  color: currentColor;
  position: relative;
}

.header-w-description_description [href^="mailto:"]::before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: currentColor;
  opacity: 0;
  transition: opacity .2s;
}

.header-w-description_description [href^="mailto:"]:hover::before {
  opacity: 1;
}

.three-cols-block {
  padding: 2.5rem 0;
}

.three-cols-block_item {
  position: relative;
  min-height: 272px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  background-color: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem 1px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .three-cols-block_item {
    transition: none;
  }
}

.three-cols-block_item:hover {
  box-shadow: 0 0.5rem 1rem 1px rgba(0, 0, 0, 0.15);
}

.three-cols-block_item--fixedheight {
  min-height: 160px;
  background-color: #fff;
}

.three-cols-block_icon {
  width: 40px;
  height: 40px;
  margin-bottom: 1rem;
}

.three-cols-block_textblock {
  color: #6c798f;
  margin-bottom: 1rem;
}

.three-cols-block_buttonblock {
  margin-top: auto;
}

.three-cols-block_buttonblock .btn {
  text-align: left;
  display: inline;
  line-height: 1;
}

.three-cols-block [class*="_button"],
.three-cols-block [class*="_btn"] {
  font-weight: 600;
  padding: 0;
}

.three-cols-block [role="button"] + .button-icon {
  padding-left: 0;
  margin-left: -3px;
  margin-top: 2px;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .three-cols-block [role="button"] + .button-icon {
    transition: none;
  }
}

.three-cols-block [role="button"] + .button-icon.plus {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.three-cols-block [role="button"] + .button-icon .arrow,
.three-cols-block [role="button"] + .button-icon .http,
.three-cols-block [role="button"] + .button-icon .plus {
  fill: #158aff;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .three-cols-block [role="button"] + .button-icon .arrow,
  .three-cols-block [role="button"] + .button-icon .http,
  .three-cols-block [role="button"] + .button-icon .plus {
    transition: none;
  }
}

.three-cols-block [role="button"]:hover + .button-icon {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.three-cols-block [role="button"]:hover + .button-icon .arrow,
.three-cols-block [role="button"]:hover + .button-icon .http,
.three-cols-block [role="button"]:hover + .button-icon .plus {
  fill: #0064c8;
}

.three-cols-block [role="button"]:hover + .browser-link {
  -webkit-transform: none;
  transform: none;
}

.ready-started_title {
  color: #000;
}

.ready-started_item {
  display: flex;
  height: 100%;
  padding-bottom: 2rem;
}

@media (max-width: 767.98px) {
  .ready-started_item {
    flex-direction: column;
  }
}

.ready-started_textblock--text {
  color: #6c798f;
}

.ready-started_iconblock {
  min-width: 40px;
  width: 40px;
  height: 40px;
  margin-right: 16px;
}

@media (max-width: 767.98px) {
  .ready-started_iconblock {
    margin-bottom: 1rem;
  }
}

@media (min-width: 768px) {
  .ready-started_iconblock {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}

.ready-started_iconblock--icon {
  width: 100%;
  height: 100%;
}

.ready-started_buttonblock {
  margin-top: auto;
}

.ready-started [class*="_button"],
.ready-started [class*="_btn"] {
  font-weight: 600;
  padding: 0;
}

.ready-started [role="button"] + .button-icon {
  padding-left: 0;
  margin-left: -3px;
  margin-top: 2px;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .ready-started [role="button"] + .button-icon {
    transition: none;
  }
}

.ready-started [role="button"] + .button-icon.plus {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.ready-started [role="button"] + .button-icon .arrow,
.ready-started [role="button"] + .button-icon .http,
.ready-started [role="button"] + .button-icon .plus {
  fill: #158aff;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .ready-started [role="button"] + .button-icon .arrow,
  .ready-started [role="button"] + .button-icon .http,
  .ready-started [role="button"] + .button-icon .plus {
    transition: none;
  }
}

.ready-started [role="button"]:hover + .button-icon {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.ready-started [role="button"]:hover + .button-icon .arrow,
.ready-started [role="button"]:hover + .button-icon .http,
.ready-started [role="button"]:hover + .button-icon .plus {
  fill: #0064c8;
}

.ready-started [role="button"]:hover + .browser-link {
  -webkit-transform: none;
  transform: none;
}

.developers-block {
  width: 100%;
  background-color: #172b4d;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  padding: 4rem 0;
  background-size: cover;
}

@media (min-width: 768px) {
  .developers-block--fixed {
    background-attachment: fixed;
  }
}

.developers-block_title {
  text-align: center;
  color: #fff;
}

.developers-block_description {
  text-align: center;
  font-size: 1.375rem;
  font-weight: 400;
  color: #fff;
  padding: 0 2rem;
  line-height: 1.45;
}

.developers-block_buttonblock {
  text-align: center;
  margin: 0;
}

.developers-block [class*="_button"],
.developers-block [class*="_btn"] {
  font-weight: 600;
  padding: 0;
}

.developers-block [role="button"] + .button-icon {
  padding-left: 0;
  margin-left: -3px;
  margin-top: 2px;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .developers-block [role="button"] + .button-icon {
    transition: none;
  }
}

.developers-block [role="button"] + .button-icon.plus {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.developers-block [role="button"] + .button-icon .arrow,
.developers-block [role="button"] + .button-icon .http,
.developers-block [role="button"] + .button-icon .plus {
  fill: #fff;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .developers-block [role="button"] + .button-icon .arrow,
  .developers-block [role="button"] + .button-icon .http,
  .developers-block [role="button"] + .button-icon .plus {
    transition: none;
  }
}

.developers-block [role="button"]:hover + .button-icon {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.developers-block [role="button"]:hover + .button-icon .arrow,
.developers-block [role="button"]:hover + .button-icon .http,
.developers-block [role="button"]:hover + .button-icon .plus {
  fill: #fff;
}

.developers-block [role="button"]:hover + .browser-link {
  -webkit-transform: none;
  transform: none;
}

.developers-block_button {
  color: #fff;
}

.developers-block_button:hover {
  color: #fff;
}

.customer-stories {
  width: 100%;
  background-color: #f4f5f7;
  padding-top: 1rem;
}

.customer-stories_title {
  text-align: center;
}

.customer-stories_description {
  text-align: center;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.375rem;
  font-weight: 400;
  padding-bottom: 2rem;
}

.customer-stories_story .customer-story {
  min-height: 144px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 1.75rem 1rem;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

@media screen and (prefers-reduced-motion: reduce) {
  .customer-stories_story .customer-story {
    transition: none;
  }
}

.customer-stories_story .customer-story:hover {
  opacity: .9;
}

.customer-stories_story .customer-story_title {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.75rem;
}

.customer-stories_story .customer-story_description {
  color: #fff;
  text-align: center;
}

.customer-stories_story .customer-story_buttonblock {
  color: #fff;
  text-align: center;
  margin: 0;
}

.customer-stories_story .customer-story [class*="_button"],
.customer-stories_story .customer-story [class*="_btn"] {
  font-weight: 600;
  padding: 0;
}

.customer-stories_story .customer-story [role="button"] + .button-icon {
  padding-left: 0;
  margin-left: -3px;
  margin-top: 2px;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .customer-stories_story .customer-story [role="button"] + .button-icon {
    transition: none;
  }
}

.customer-stories_story .customer-story [role="button"] + .button-icon.plus {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.customer-stories_story .customer-story [role="button"] + .button-icon .arrow,
.customer-stories_story .customer-story [role="button"] + .button-icon .http,
.customer-stories_story .customer-story [role="button"] + .button-icon .plus {
  fill: #fff;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .customer-stories_story .customer-story [role="button"] + .button-icon .arrow,
  .customer-stories_story .customer-story [role="button"] + .button-icon .http,
  .customer-stories_story .customer-story [role="button"] + .button-icon .plus {
    transition: none;
  }
}

.customer-stories_story .customer-story [role="button"]:hover + .button-icon {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.customer-stories_story .customer-story [role="button"]:hover + .button-icon .arrow,
.customer-stories_story .customer-story [role="button"]:hover + .button-icon .http,
.customer-stories_story .customer-story [role="button"]:hover + .button-icon .plus {
  fill: #fff;
}

.customer-stories_story .customer-story [role="button"]:hover + .browser-link {
  -webkit-transform: none;
  transform: none;
}

.customer-stories_story .customer-story_button {
  color: #fff;
}

.customer-stories_wo-title .customer-stories_story .customer-story {
  border-radius: 0.125rem;
  margin-bottom: 2rem;
}

.testimonial {
  margin: 3rem 0 5rem 0;
}

.testimonial_img {
  position: absolute;
  top: 0;
  right: 20px;
  left: 20px;
  width: 100px;
  margin: 0 auto;
}

.testimonial_image {
  width: 100%;
  height: auto;
  border-radius: 100%;
}

.testimonial_textblock {
  text-align: center;
  background: #f4f5f7;
  padding: 4rem 2rem 0;
  margin-top: 3rem;
  min-height: 100%;
  box-shadow: none;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .testimonial_textblock {
    transition: none;
  }
}

.testimonial_title {
  font-size: 1.875rem;
}

.testimonial_subtitle {
  font-size: 1.625rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

.testimonial_text {
  color: #6c798f;
}

.testimonial-w-quote {
  padding: 3rem 0;
}

.testimonial-w-quote_block {
  background: #f4f5f7;
  padding: 3.375rem 2.5rem 0;
  min-height: 100%;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .testimonial-w-quote_block {
    transition: none;
  }
}

.testimonial-w-quote_block::before {
  content: "";
  position: absolute;
  top: -15px;
  right: 30px;
  width: 58px;
  height: 48px;
  background-image: url("/local/templates/1ci/i/icons/Quote.svg");
  background-repeat: no-repeat;
}

.testimonial-w-quote_block::after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 30px;
  width: 58px;
  height: 48px;
  background-image: url("/local/templates/1ci/i/icons/Quote.svg");
  background-repeat: no-repeat;
}

.testimonial-w-quote_small-block {
  padding-top: 1rem;
}

.testimonial-w-quote_small-block:first-child {
  padding-top: 3rem;
}

@media (min-width: 992px) {
  .testimonial-w-quote_small-block:first-child {
    padding-top: 2.5rem;
  }
}

.testimonial-w-quote_image {
  width: 72px;
  height: auto;
  border-radius: 100%;
}

.testimonial-w-quote_title-block {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.testimonial-w-quote_name-block {
  padding: 1rem;
  align-self: center;
}

.testimonial-w-quote_title, .testimonial-w-quote_subtitle {
  font-family: "Rubik", sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
  font-style: italic;
  line-height: 1.33;
  letter-spacing: .7px;
  margin-bottom: 0;
}

.testimonial-w-quote_text {
  font-size: 1.375rem;
  color: #091e42;
  padding: 2rem 0 4rem;
}

.testimonial-w-quote_small-text {
  font-size: 1.1875rem;
  color: #6c798f;
  padding: 1rem 0;
}

.partners-logos {
  padding: 1rem 0 2.5rem;
}

.partners-logos_item {
  text-align: center;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .partners-logos_item {
    transition: none;
  }
}

.partners-logos_item-erp {
  margin-bottom: 1.5rem;
}

.partners-logos_img {
  height: 94px;
  padding: 0 1px;
  display: block;
  margin: 0 auto;
}

.partners-logos_img-erp {
  height: 104px;
}

@media (max-width: 575.98px) {
  .partners-logos_img-erp {
    width: 124px;
    height: 94px;
  }
}

.become-partner {
  padding: 3.5rem 0 2.5rem;
  background-color: #f4f5f7;
}

.become-partner_title {
  text-align: center;
}

.become-partner_text {
  padding-bottom: 1rem;
}

.become-partner_subtitle {
  text-align: center;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.375rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
}

.become-partner_btnblock {
  padding-top: 1rem;
  text-align: center;
}

.become-partner_item {
  margin-bottom: 0;
}

.become-partner_dot {
  margin-right: 0.5rem;
  margin-bottom: 1.5rem;
  float: left;
}

.solution-3-cols-block {
  padding: 1rem 0 1.5rem;
}

.solution-3-cols-block_item {
  text-align: center;
}

.solution-3-cols-block_img {
  width: 112px;
  height: 112px;
}

.solution-3-cols-block_title {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.solution-3-cols-block_maintitle {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.solution-3-cols-block_subtitle {
  margin-bottom: 2rem;
}

.solution-3-cols-block-3-1c-drive_item {
  text-align: left;
}

.solution-3-cols-block-3-1c-drive_img {
  width: 56px;
  height: 56px;
}

.solution-3-cols-block-3-1c-drive_maintitle {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.solution-3-cols-block-3-1c-drive_subtitle {
  margin-bottom: 2rem;
  color: #6c798f;
}

.solution-4-cols-block-career .header-w-description_columns {
  margin-bottom: 1.5rem;
}

.solution-4-cols-block-career_image {
  width: 112px;
  height: 112px;
  margin-bottom: 1rem;
}

.solution-4-cols-block-career_subtitle {
  margin-bottom: 2rem;
  color: #6c798f;
}

.solution-6-cols-block {
  padding: 3.5rem 0 1.5rem;
  background-color: #f4f5f7;
}

.solution-6-cols-block_title {
  text-align: center;
  margin-bottom: 3rem;
}

.solution-6-cols-block_img {
  width: 48px;
  height: 48px;
  margin-bottom: 1rem;
}

.solution-6-cols-block_text {
  margin-bottom: 2rem;
}

.solution-4-cols-block {
  padding: 2.5rem 0 1rem;
  background-color: #f4f5f7;
}

.solution-4-cols-block_item {
  display: flex;
}

.solution-4-cols-block_img {
  width: 32px;
  height: 32px;
  margin-bottom: 1.5rem;
  margin-right: 0.5rem;
}

.solution-4-cols-block_text {
  margin-bottom: 1.5rem;
}

.solution-3-cols-partner {
  padding: 1.5rem 0;
  background-color: #f4f5f7;
}

.solution-3-cols-partner_row {
  display: flex;
  justify-content: center;
}

.solution-3-cols-partner_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 97px;
}

@media (min-width: 576px) {
  .solution-3-cols-partner_item {
    flex-direction: row;
    width: auto;
    margin: 0 35px;
  }
}

@media (min-width: 992px) {
  .solution-3-cols-partner_item {
    margin: 0 45px;
  }
}

.solution-3-cols-partner_img {
  width: 24px;
  height: 24px;
  margin-bottom: 0.5rem;
}

@media (min-width: 576px) {
  .solution-3-cols-partner_img {
    margin-bottom: 0;
    margin-right: 0.5rem;
  }
}

.solution-3-cols-partner_text {
  margin-bottom: 0;
  font-size: 1.0625rem;
}

.solutions-open_card {
  margin-bottom: 2.5rem;
}

.solutions-open_img {
  height: 48px;
  width: 48px;
  margin-bottom: 1rem;
}

.solutions-open_item {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.0625rem;
  padding-left: 0;
}

.solutions-open_item--edit {
  display: flex;
  line-height: 1.41;
  color: #6c798f;
  list-style: none;
  padding-top: 0.5rem;
}

.solutions-open_item--edit::before {
  content: "";
  position: relative;
  top: 10px;
  min-width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: #6c798f;
  margin-right: 20px;
}

.solutions-open_buttonblock {
  margin-top: 0.5rem;
}

.solutions-open_buttonblock > .btn {
  margin-right: 12px;
  line-height: 1.41;
}

.solutions-open_buttonblock > .btn.more {
  position: relative;
  /* Vertical line */
  /* horizontal line */
}

.solutions-open_buttonblock > .btn.more::before, .solutions-open_buttonblock > .btn.more::after {
  content: "";
  position: absolute;
  background-color: #158aff;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .solutions-open_buttonblock > .btn.more::before, .solutions-open_buttonblock > .btn.more::after {
    transition: none;
  }
}

.solutions-open_buttonblock > .btn.more::before {
  top: calc(50% - 0.125rem);
  right: -0.5625rem;
  width: 0.125rem;
  height: 0.5rem;
}

.solutions-open_buttonblock > .btn.more::after {
  top: calc(50% + 0.0625rem);
  right: -0.75rem;
  width: 0.5rem;
  height: 0.125rem;
}

@media (hover: hover) {
  .solutions-open_buttonblock > .btn.more:hover {
    cursor: pointer;
  }
  .solutions-open_buttonblock > .btn.more:hover::before {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    background-color: #0064c8;
  }
  .solutions-open_buttonblock > .btn.more:hover::after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    background-color: #0064c8;
  }
}

.solutions-open_buttonblock > .btn.less {
  position: relative;
  /* horizontal line */
}

.solutions-open_buttonblock > .btn.less::before, .solutions-open_buttonblock > .btn.less::after {
  content: "";
  position: absolute;
  background-color: #158aff;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .solutions-open_buttonblock > .btn.less::before, .solutions-open_buttonblock > .btn.less::after {
    transition: none;
  }
}

.solutions-open_buttonblock > .btn.less::after {
  top: calc(50% + 0.0625rem);
  right: -0.75rem;
  width: 0.5rem;
  height: 0.125rem;
}

.solution-cards {
  width: 100%;
  padding-bottom: 1rem;
}

.solution-cards .text-wrapper {
  position: relative;
}

.solution-cards_col {
  margin-bottom: 2rem;
}

.solution-cards_card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 264px;
  height: 100%;
  padding: 1rem 1.5rem;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .solution-cards_card {
    transition: none;
  }
}

.solution-cards_card--bg {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.solution-cards_card--bg-grey {
  background-color: #6c798f;
}

.solution-cards_card--bg-blue {
  background-color: #158aff;
}

.solution-cards_card--img {
  display: block;
  position: absolute;
  height: 100%;
  opacity: .25;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.solution-cards_card:hover {
  opacity: .8;
}

.solution-cards_category {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 0.75rem;
  color: #fff;
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 0.5rem;
  letter-spacing: 1px;
}

@media (min-width: 992px) {
  .solution-cards_category {
    margin-bottom: 0;
    text-align: right;
  }
}

.solution-cards_title {
  color: #fff;
  margin: 1rem 0;
  z-index: 1;
}

.solution-cards_title--wcategory {
  margin: 0 0 1rem;
}

.solution-cards_text {
  color: #fff;
  z-index: 1;
}

.solution-cards_btnblock {
  margin: auto 0 0;
  z-index: 1;
}

.solution-cards_btnblock [class*="_button"],
.solution-cards_btnblock [class*="_btn"] {
  font-weight: 600;
  padding: 0;
}

.solution-cards_btnblock [role="button"] + .button-icon {
  padding-left: 0;
  margin-left: -3px;
  margin-top: 2px;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .solution-cards_btnblock [role="button"] + .button-icon {
    transition: none;
  }
}

.solution-cards_btnblock [role="button"] + .button-icon.plus {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.solution-cards_btnblock [role="button"] + .button-icon .arrow,
.solution-cards_btnblock [role="button"] + .button-icon .http,
.solution-cards_btnblock [role="button"] + .button-icon .plus {
  fill: #fff;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .solution-cards_btnblock [role="button"] + .button-icon .arrow,
  .solution-cards_btnblock [role="button"] + .button-icon .http,
  .solution-cards_btnblock [role="button"] + .button-icon .plus {
    transition: none;
  }
}

.solution-cards_btnblock [role="button"]:hover + .button-icon {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.solution-cards_btnblock [role="button"]:hover + .button-icon .arrow,
.solution-cards_btnblock [role="button"]:hover + .button-icon .http,
.solution-cards_btnblock [role="button"]:hover + .button-icon .plus {
  fill: #fff;
}

.solution-cards_btnblock [role="button"]:hover + .browser-link {
  -webkit-transform: none;
  transform: none;
}

.solution-cards_btn {
  color: #fff;
}

.solution-cards_btn:hover {
  color: #fff;
}

.customer-stories-cards {
  padding-bottom: 1rem;
}

.customer-stories-cards_title {
  text-align: center;
  font-size: 2.25rem;
  font-weight: 400;
}

.customer-stories-cards_subtitle {
  text-align: center;
  font-size: 1.375rem;
  font-weight: 400;
  margin: 0 0 1rem;
}

.request-block {
  background-color: #158aff;
  width: 100%;
  margin: 0;
  padding: 2rem 1rem;
}

.request-block_title {
  text-align: center;
  color: #fff;
  margin-bottom: 0;
}

.request-block_subtitle {
  text-align: center;
  color: #fff;
}

.request-block_btnblock {
  text-align: center;
  margin-bottom: 0;
}

.request-block_horizontal__text {
  padding-bottom: 0;
}

@media (min-width: 576px) {
  .request-block_horizontal__text h2,
  .request-block_horizontal__text p {
    text-align: left;
    margin-bottom: 0;
  }
}

.request-block_horizontal__btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 576px) {
  .request-block_horizontal__btn {
    justify-content: flex-end;
  }
}

.developers-adv-block {
  background-color: #f4f5f7;
  padding: 3rem 0 1rem;
}

.developers-adv-block_img {
  width: 56px;
  height: 57px;
  padding: 0;
}

.developers-adv-block_desc {
  padding: 0.5rem 1rem 2rem 0;
  margin-bottom: 0;
  font-size: 1.5rem;
  font-weight: 400;
}

.developers-adv-block_itemtitle {
  font-size: 2.625rem;
  font-weight: 300;
  line-height: 2.625rem;
  margin: 0;
}

.developers-adv-block_itemdesc {
  color: #6c798f;
  margin-bottom: 1.5rem;
}

@media (min-width: 576px) {
  .developers-adv-block_itemdesc {
    margin-bottom: 2rem;
  }
}

.developers-6-cols-block {
  background-color: transparent;
  padding: 1.5rem 0 2rem;
}

.developers-6-cols-block_title {
  text-align: center;
  font-size: 2.25rem;
  font-weight: 400;
  margin: 2rem 0;
}

.developers-6-cols-block_img {
  width: 48px;
  height: 48px;
  margin-bottom: 1rem;
}

.developers-6-cols-block_text {
  margin-bottom: 2rem;
}

.developers-6-cols-block_link {
  justify-content: flex-start;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
}

.developers-6-cols-block_link .main_link .button-icon,
.developers-6-cols-block_link .main_link .arrow {
  transition: all 0.2s ease-in-out;
}

.developers-6-cols-block_link .main_link:hover .button-icon {
  margin-left: 3px;
}

.developers-6-cols-block_link .main_link:hover .button-icon .arrow {
  fill: #0064c8;
}

.developers-6-cols-block_buttonlink {
  position: relative;
  width: 14rem;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .developers-6-cols-block_buttonlink {
    width: auto;
    text-align: center;
  }
}

@media (min-width: 992px) {
  .developers-6-cols-block_buttonlink {
    margin-top: 3.75rem;
  }
}

@media (min-width: 1200px) {
  .developers-6-cols-block_buttonlink {
    margin-top: 3.875rem;
  }
}

.developers-6-cols-block_buttonlink [class*="_button"],
.developers-6-cols-block_buttonlink [class*="_btn"] {
  font-weight: 600;
  padding: 0;
}

.developers-6-cols-block_buttonlink [role="button"] + .button-icon {
  padding-left: 0;
  margin-left: -3px;
  margin-top: 2px;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .developers-6-cols-block_buttonlink [role="button"] + .button-icon {
    transition: none;
  }
}

.developers-6-cols-block_buttonlink [role="button"] + .button-icon.plus {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.developers-6-cols-block_buttonlink [role="button"] + .button-icon .arrow,
.developers-6-cols-block_buttonlink [role="button"] + .button-icon .http,
.developers-6-cols-block_buttonlink [role="button"] + .button-icon .plus {
  fill: #158aff;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .developers-6-cols-block_buttonlink [role="button"] + .button-icon .arrow,
  .developers-6-cols-block_buttonlink [role="button"] + .button-icon .http,
  .developers-6-cols-block_buttonlink [role="button"] + .button-icon .plus {
    transition: none;
  }
}

.developers-6-cols-block_buttonlink [role="button"]:hover + .button-icon {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.developers-6-cols-block_buttonlink [role="button"]:hover + .button-icon .arrow,
.developers-6-cols-block_buttonlink [role="button"]:hover + .button-icon .http,
.developers-6-cols-block_buttonlink [role="button"]:hover + .button-icon .plus {
  fill: #0064c8;
}

.developers-6-cols-block_buttonlink [role="button"]:hover + .browser-link {
  -webkit-transform: none;
  transform: none;
}

.developers-6-cols-block_buttonlink--btn {
  text-align: left;
  line-height: 1;
  display: inline;
  margin-right: 0.125rem;
}

@media (min-width: 768px) {
  .developers-6-cols-block_buttonlink--btn {
    text-align: center;
  }
}

@media (min-width: 992px) {
  .developers-6-cols-block_buttonlink--btn {
    text-align: left;
  }
}

.block-title-w-image {
  background-color: #f4f5f7;
  padding: 2.5rem 0 3rem;
}

.block-title-w-image_img {
  padding-top: 1.5rem;
  width: 100%;
  max-width: 710px;
  height: auto;
}

.join-block {
  position: relative;
  width: 100%;
  background: #172b4d url("/local/templates/1ci/i/developers/developers_bg.jpg") no-repeat center;
  padding: 4rem 0;
  margin: 0 0 1rem;
  background-size: cover;
}

.join-block::before {
  position: absolute;
  content: " ";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 0;
  background-color: rgba(23, 43, 77, 0.5);
}

.join-block_title {
  text-align: center;
  font-size: 1.6875rem;
  font-weight: 400;
  color: #fff;
}

.join-block_description {
  text-align: center;
  font-size: 1.375rem;
  font-weight: 400;
  color: #fff;
  padding: 0 2rem;
}

.join-block_buttonblock {
  text-align: center;
  margin: 0;
}

.join-block_buttonblock a {
  margin: 1rem;
}

.main-link_row {
  justify-content: center;
}

.main-link_block {
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.main-link_block [class*="_button"],
.main-link_block [class*="_btn"] {
  font-weight: 600;
  padding: 0;
}

.main-link_block [role="button"] + .button-icon {
  padding-left: 0;
  margin-left: -3px;
  margin-top: 2px;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .main-link_block [role="button"] + .button-icon {
    transition: none;
  }
}

.main-link_block [role="button"] + .button-icon.plus {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.main-link_block [role="button"] + .button-icon .arrow,
.main-link_block [role="button"] + .button-icon .http,
.main-link_block [role="button"] + .button-icon .plus {
  fill: #158aff;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .main-link_block [role="button"] + .button-icon .arrow,
  .main-link_block [role="button"] + .button-icon .http,
  .main-link_block [role="button"] + .button-icon .plus {
    transition: none;
  }
}

.main-link_block [role="button"]:hover + .button-icon {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.main-link_block [role="button"]:hover + .button-icon .arrow,
.main-link_block [role="button"]:hover + .button-icon .http,
.main-link_block [role="button"]:hover + .button-icon .plus {
  fill: #0064c8;
}

.main-link_block [role="button"]:hover + .browser-link {
  -webkit-transform: none;
  transform: none;
}

.timeline {
  padding: 2rem 0 0.5rem;
  background-color: #158aff;
}

.timeline_item {
  height: 98px;
  display: flex;
}

@media (min-width: 992px) {
  .timeline_item {
    flex-direction: column;
    height: auto;
    margin-bottom: 3rem;
  }
}

.timeline_item:not(:last-child) .timeline_item--number::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 49.9%;
  width: 1px;
  height: 70px;
  background-color: #fff;
}

@media (min-width: 992px) {
  .timeline_item:not(:last-child) .timeline_item--number::before {
    left: 100%;
    top: 50%;
    height: 1px;
    width: 164px;
  }
}

@media (min-width: 1200px) {
  .timeline_item:not(:last-child) .timeline_item--number::before {
    width: 200px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .timeline_item:nth-child(2n+1) .timeline_item--number {
    left: -15px;
  }
  .timeline_item:nth-child(2n+1) .timeline_item--text {
    padding-left: 0.5rem;
  }
  .timeline_item:nth-child(2n) {
    flex-direction: row-reverse;
  }
  .timeline_item:nth-child(2n) .timeline_item--number {
    right: -15px;
  }
  .timeline_item:nth-child(2n) .timeline_item--text {
    padding-right: 0.5rem;
    text-align: right;
  }
}

.timeline_item--number, .timeline_item--text {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.0625rem;
  color: #fff;
  line-height: 1.41;
}

.timeline_item--number {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  min-height: 30px;
  min-width: 30px;
  border-radius: 100%;
  border: 1px solid #fff;
}

@media (min-width: 992px) {
  .timeline_item--number {
    position: relative;
  }
}

.timeline_item--text {
  padding-left: 2.5rem;
  margin: 0;
}

@media (min-width: 992px) {
  .timeline_item--text {
    padding-left: 0;
    padding-top: 1rem;
  }
}

.promo__banner {
  position: relative;
}

@media (max-width: 767.98px) {
  .promo__banner {
    background-color: #f4f5f7;
  }
}

.promo__banner-bg {
  background-color: #f4f5f7;
}

@media (min-width: 768px) {
  .promo__banner-bg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 992px) {
  .promo__banner-bg {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

.promo__banner-bg > .row {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.promo__banner-type {
  font-family: "Rubik", sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  margin-bottom: 1rem;
}

.promo__banner-type_bulb {
  position: relative;
}

.promo__banner-type_bulb::before {
  content: "";
  position: relative;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  box-shadow: 0 0 8px 0 #ffdd2d;
  background-color: #ffdd2d;
  margin-right: 0.5rem;
  margin-top: 3px;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .promo__banner-type {
    width: 100%;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .promo__banner-type {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .promo__banner-type {
    margin-right: 2rem;
    margin-bottom: 0;
  }
}

.promo__banner-image__wrapper {
  height: 100%;
}

@media (max-width: 767.98px) {
  .promo__banner-image__wrapper {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 400px) and (max-width: 520px) {
  .promo__banner-image__wrapper {
    margin-bottom: 82px;
  }
}

@media screen and (min-width: 320px) and (max-width: 400px) {
  .promo__banner-image__wrapper {
    margin-bottom: 64px;
  }
}

@media screen and (max-width: 320px) {
  .promo__banner-image__wrapper {
    margin-bottom: 56px;
  }
}

.promo__banner-image__wrapper_image {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1;
  -o-object-fit: cover;
  object-fit: cover;
}

.promo__banner-image__wrapper_image--shadow {
  display: block;
  position: relative;
  width: 78%;
  height: 90%;
}

@media (min-width: 768px) {
  .promo__banner-image__wrapper_image--shadow {
    width: 180px;
    height: 176px;
    margin-bottom: 2rem;
  }
}

@media (min-width: 992px) {
  .promo__banner-image__wrapper_image--shadow {
    width: 230px;
  }
}

@media (min-width: 1200px) {
  .promo__banner-image__wrapper_image--shadow {
    width: 290px;
  }
}

.promo__banner-image__wrapper_image--shadow::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  bottom: -18%;
  right: -28%;
  background-color: #091e42;
  border-radius: 0.125rem;
}

@media (min-width: 768px) {
  .promo__banner-image__wrapper_image--shadow::after {
    bottom: -2rem;
    right: -3.5rem;
  }
}

.promo__banner-additional {
  margin-bottom: 1.5rem;
}

@media (min-width: 768px) {
  .promo__banner-additional {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
}

.promo__banner-additional_date, .promo__banner-additional_time, .promo__banner-additional_place, .promo__banner-additional_lang {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

@media (min-width: 768px) {
  .promo__banner-additional_date, .promo__banner-additional_time, .promo__banner-additional_place, .promo__banner-additional_lang {
    margin-right: 2rem;
  }
}

.promo__banner-additional_date--icon, .promo__banner-additional_time--icon, .promo__banner-additional_place--icon, .promo__banner-additional_lang--icon {
  max-width: 16px;
  max-height: 16px;
  margin-right: 8px;
}

.promo__banner-additional_date--text, .promo__banner-additional_time--text, .promo__banner-additional_place--text, .promo__banner-additional_lang--text {
  margin: 0;
  font-size: 0.875rem;
  line-height: 16px;
}

.newsroom__media-block {
  padding: 6rem 1rem;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 768px) {
  .newsroom__media-block {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }
}

@media (min-width: 992px) {
  .newsroom__media-block {
    padding-left: 11.875rem;
    padding-right: 11.875rem;
  }
}

.newsroom__media-block_title {
  color: #fff;
}

.newsroom__media-block_description {
  color: #fff;
  margin-bottom: 1.5rem;
  text-align: center;
  font-size: 1.375rem;
  line-height: 32px;
}

.newsroom__media-btn {
  margin: 0 auto 2rem;
}

.newsroom__cards-card {
  padding: 2.5rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 0.125rem;
}

@media (min-width: 768px) {
  .newsroom__cards-card {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

.newsroom__cards-card:first-child {
  margin-bottom: 2rem;
}

.newsroom__cards-img {
  width: 112px;
  height: 112px;
  margin-bottom: 1.5rem;
}

.newsroom__cards-description {
  text-align: center;
}

.newsroom__cards-buttonblock [class*="_button"],
.newsroom__cards-buttonblock [class*="_btn"] {
  font-weight: 600;
  padding: 0;
}

.newsroom__cards-buttonblock [role="button"] + .button-icon {
  padding-left: 0;
  margin-left: -3px;
  margin-top: 2px;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .newsroom__cards-buttonblock [role="button"] + .button-icon {
    transition: none;
  }
}

.newsroom__cards-buttonblock [role="button"] + .button-icon.plus {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.newsroom__cards-buttonblock [role="button"] + .button-icon .arrow,
.newsroom__cards-buttonblock [role="button"] + .button-icon .http,
.newsroom__cards-buttonblock [role="button"] + .button-icon .plus {
  fill: #158aff;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .newsroom__cards-buttonblock [role="button"] + .button-icon .arrow,
  .newsroom__cards-buttonblock [role="button"] + .button-icon .http,
  .newsroom__cards-buttonblock [role="button"] + .button-icon .plus {
    transition: none;
  }
}

.newsroom__cards-buttonblock [role="button"]:hover + .button-icon {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.newsroom__cards-buttonblock [role="button"]:hover + .button-icon .arrow,
.newsroom__cards-buttonblock [role="button"]:hover + .button-icon .http,
.newsroom__cards-buttonblock [role="button"]:hover + .button-icon .plus {
  fill: #0064c8;
}

.newsroom__cards-buttonblock [role="button"]:hover + .browser-link {
  -webkit-transform: none;
  transform: none;
}

.newsroom__contact-wrapper {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

@media (min-width: 768px) {
  .newsroom__contact-wrapper {
    display: flex;
    justify-content: center;
  }
}

.newsroom__contact-img {
  width: 112px;
  height: 112px;
  border-radius: 100%;
}

@media (max-width: 767.98px) {
  .newsroom__contact-img {
    margin-bottom: 1rem;
  }
}

@media (min-width: 768px) {
  .newsroom__contact-img {
    margin-right: 2rem;
  }
}

.newsroom__contact-author, .newsroom__contact-position {
  margin: 0;
  line-height: 24px;
}

.newsroom__subscribe {
  overflow: hidden;
}

.newsroom__subscribe-title, .newsroom__subscribe-description {
  color: #fff;
}

.newsroom__subscribe-description {
  margin-bottom: 0.5rem;
}

@media (min-width: 992px) {
  .newsroom__subscribe-wrapper {
    display: flex;
  }
}

.newsroom__subscribe-action {
  position: relative;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

@media (min-width: 992px) {
  .newsroom__subscribe-action {
    padding-right: 4.5rem;
    width: 50%;
  }
}

.newsroom__subscribe-action::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 200%;
  height: 100%;
  background-color: #158aff;
  z-index: -1;
}

@media (max-width: 991.98px) {
  .newsroom__subscribe-action::before {
    right: -26%;
  }
}

.newsroom__subscribe-social {
  position: relative;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

@media (min-width: 992px) {
  .newsroom__subscribe-social {
    padding-left: 3.5rem;
    width: 50%;
  }
}

.newsroom__subscribe-social::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
  background-image: linear-gradient(90deg, #0064c9 0%, #158aff 70%);
  z-index: -1;
}

@media (max-width: 991.98px) {
  .newsroom__subscribe-social::before {
    left: -26%;
  }
}

.newsroom__subscribe-form.forms--blue {
  padding: 0;
}

.newsroom__subscribe-form .grid__form {
  margin-top: 1.5rem;
}

@media (max-width: 767.98px) {
  .newsroom__subscribe-form .grid__form {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 768px) {
  .newsroom__subscribe-form .grid__form {
    display: grid;
  }
}

.newsroom__subscribe-form .grid__form .grid__input {
  grid-area: input;
  margin-bottom: 1.5rem;
}

@media (max-width: 767.98px) {
  .newsroom__subscribe-form .grid__form .grid__input {
    width: 100%;
  }
}

.newsroom__subscribe-form .grid__form .grid__button {
  grid-area: button;
}

@media (max-width: 767.98px) {
  .newsroom__subscribe-form .grid__form .grid__button {
    margin-top: 2rem;
  }
}

.newsroom__subscribe-form .grid__form .custom-control {
  min-height: auto;
}

.newsroom__subscribe-form .grid__form .custom-control label {
  color: #fff;
  line-height: 16px;
  font-size: 0.875rem;
}

.newsroom__subscribe-form .grid__form .custom-control label a {
  color: currentColor;
  text-decoration: underline;
}

.newsroom__subscribe-form .grid__form .grid__checkbox-0 {
  grid-area: checkbox-0;
}

.newsroom__subscribe-form .grid__form .grid__checkbox-1 {
  grid-area: checkbox-1;
}

@media (min-width: 768px) {
  .newsroom__subscribe-form .grid__form {
    grid-template: "input button" "checkbox-0 checkbox-0" "checkbox-1 checkbox-1";
    grid-template-columns: 350px auto;
    -webkit-column-gap: 16px;
    -moz-column-gap: 16px;
    column-gap: 16px;
  }
}

@media (min-width: 992px) {
  .newsroom__subscribe-form .grid__form {
    grid-template-columns: minmax(260px, 100%) auto;
  }
}

@media (min-width: 1200px) {
  .newsroom__subscribe-form .grid__form {
    grid-template-columns: minmax(350px, 100%) auto;
  }
}

.newsroom__subscribe-icons {
  display: flex;
  flex-wrap: wrap;
}

.newsroom__subscribe-link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background-color: #fff;
  margin-right: 0.5rem;
  margin-top: 1rem;
}

.newsroom__textblock-card {
  margin-bottom: 1rem;
}

.newsroom__textblock-text {
  margin-bottom: 0;
}

.newsroom__textblock-title {
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.newsroom__about-bg {
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 768px) {
  .newsroom__about-bg {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

.newsroom__about-title {
  margin-bottom: 1.5rem;
}

@media (max-width: 991.98px) {
  .newsroom__about-wrapper:not(:last-child) {
    margin-bottom: 2rem;
  }
}

.newsroom__partners-card {
  padding: 1.5rem;
  border-radius: 0.125rem;
  border: 1px solid #c1c7d0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.newsroom__partners-card_wrapper {
  margin-bottom: 2rem;
}

.newsroom__partners-card_logo {
  height: 32px;
}

.newsroom__partners-activity {
  display: flex;
  align-items: center;
}

.newsroom__partners-activity:first-child {
  margin-bottom: 0.5rem;
}

.newsroom__partners-activity_site, .newsroom__partners-activity_date {
  font-size: 0.875rem;
  line-height: 16px;
  margin-bottom: 0;
  color: #091e42;
}

.newsroom__partners-activity_site--image, .newsroom__partners-activity_date--image {
  height: 16px;
  width: 16px;
  margin-right: 0.5rem;
}

.newsroom__partners-textblock {
  margin: 1.5rem 0 1rem;
  padding: 1.5rem 0;
  border-top: 1px solid #c1c7d0;
  border-bottom: 1px solid #c1c7d0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.newsroom__partners-textblock_title {
  font-family: "Rubik", sans-serif;
  font-size: 1.1875rem;
  line-height: 24px;
  margin: 1.5rem 0;
}

.newsroom__partners-buttonblock {
  margin-top: auto;
}

.newsroom__partners-buttonblock [class*="_button"],
.newsroom__partners-buttonblock [class*="_btn"] {
  font-weight: 600;
  padding: 0;
}

.newsroom__partners-buttonblock [role="button"] + .button-icon {
  padding-left: 0;
  margin-left: -3px;
  margin-top: 2px;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .newsroom__partners-buttonblock [role="button"] + .button-icon {
    transition: none;
  }
}

.newsroom__partners-buttonblock [role="button"] + .button-icon.plus {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.newsroom__partners-buttonblock [role="button"] + .button-icon .arrow,
.newsroom__partners-buttonblock [role="button"] + .button-icon .http,
.newsroom__partners-buttonblock [role="button"] + .button-icon .plus {
  fill: #158aff;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .newsroom__partners-buttonblock [role="button"] + .button-icon .arrow,
  .newsroom__partners-buttonblock [role="button"] + .button-icon .http,
  .newsroom__partners-buttonblock [role="button"] + .button-icon .plus {
    transition: none;
  }
}

.newsroom__partners-buttonblock [role="button"]:hover + .button-icon {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.newsroom__partners-buttonblock [role="button"]:hover + .button-icon .arrow,
.newsroom__partners-buttonblock [role="button"]:hover + .button-icon .http,
.newsroom__partners-buttonblock [role="button"]:hover + .button-icon .plus {
  fill: #0064c8;
}

.newsroom__partners-buttonblock [role="button"]:hover + .browser-link {
  -webkit-transform: none;
  transform: none;
}

.newsroom__partners-additional {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.newsroom__partners-additional_wrapper {
  display: flex;
  align-items: center;
}

.newsroom__partners-additional_flag {
  height: 15px;
  width: 21px;
  margin-right: 0.5rem;
}

.newsroom__partners-additional_country {
  font-family: "Rubik", sans-serif;
  font-size: 0.75rem;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 0.03rem;
  text-transform: uppercase;
  margin-bottom: 0;
}

.newsroom__partners-additional_type {
  font-family: "Rubik", sans-serif;
  font-size: 0.75rem;
  line-height: 16px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.03rem;
  color: #6c798f;
  margin-bottom: 0;
}

.newsroom__download__logo-card {
  margin-bottom: 2rem;
}

.newsroom__download__logo-image {
  height: 72px;
}

.newsroom__download__logo-wrapper {
  height: 232px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.newsroom__download__logo-wrapper.g-bg-white {
  border: 1px solid #c1c7d0;
}

.newsroom__download__logo-wrapper.g-bg-dark-indigo {
  border: 1px solid #091e42;
}

.newsroom__download__logo-downloads_button {
  font-weight: 600;
  margin-left: 0.25rem;
  transition: all 0.2s ease-in-out;
}

.newsroom__download__logo-downloads_button:hover + .button-icon path {
  fill: #0064c8;
}

.newsroom__download__logo-downloads_buttonblock {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
}

.newsroom__download__logo-downloads_buttonblock:first-child {
  margin-bottom: 0.5rem;
}

.newsroom__download__logo-downloads_buttonblock .button-icon {
  width: 16px;
  height: 16px;
}

.newsroom__download__logo-downloads_buttonblock .button-icon path {
  transition: all 0.2s ease-in-out;
}

.newsroom__warning-wrapper {
  padding: 1.5rem 1rem;
}

@media (min-width: 768px) {
  .newsroom__warning-wrapper {
    display: flex;
    padding: 1.5rem;
  }
}

.newsroom__warning-icon {
  width: 16px;
  height: 16px;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
}

.newsroom__warning-text {
  font-size: 0.875rem;
  line-height: 16px;
  margin-bottom: 0;
}

.newsroom__download__speakers-card {
  margin-bottom: 2rem;
}

.newsroom__download__speakers-wrapper {
  margin-bottom: 1rem;
}

.newsroom__download__speakers-image {
  height: 184px;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.newsroom__download__speakers-speaker_position {
  margin-bottom: 0.5rem;
}

.newsroom__download__speakers-buttonblock {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
}

.newsroom__download__speakers-buttonblock .button-icon {
  width: 16px;
  height: 16px;
}

.newsroom__download__speakers-buttonblock .button-icon path {
  transition: all 0.2s ease-in-out;
}

.newsroom__download__speakers-button {
  margin-left: 0.25rem;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
}

.newsroom__download__speakers-button:hover + .button-icon path {
  fill: #0064c8;
}

.career__about .header-w-description_header {
  margin-bottom: 4rem;
}

.career__about .text__image,
.career__about .image__text {
  align-items: center;
  padding-bottom: 4rem;
  overflow: hidden;
}

@media (max-width: 767.98px) {
  .career__about .text__image {
    flex-direction: column-reverse;
  }
}

.career__about .text__image .career__about-icon__bg {
  background-position: left;
}

@media (max-width: 767.98px) {
  .career__about .text__image .career__about-icon__bg {
    right: 20%;
  }
}

.career__about .text__image .image__wrapper {
  margin-left: auto;
}

.career__about .text__image .image__wrapper::before {
  right: 25%;
}

@media (min-width: 768px) {
  .career__about .text__image .image__wrapper::before {
    right: 3.5rem;
  }
}

.career__about .image__text .career__about-icon__bg {
  background-position: right;
}

@media (max-width: 767.98px) {
  .career__about .image__text .career__about-icon__bg {
    left: 20%;
  }
}

.career__about .image__text .image__wrapper {
  margin-right: auto;
}

.career__about .image__text .image__wrapper::before {
  left: 25%;
}

@media (min-width: 768px) {
  .career__about .image__text .image__wrapper::before {
    left: 3.5rem;
  }
}

.career__about-title {
  margin-bottom: 1rem;
}

@media (max-width: 767.98px) {
  .career__about-title {
    margin-top: 3.5rem;
  }
}

.career__about-subtitle {
  margin-bottom: 0;
}

.career__about-imageblock {
  position: relative;
}

.career__about .image__wrapper {
  position: relative;
}

@media (max-width: 767.98px) {
  .career__about .image__wrapper {
    width: 75%;
    display: block;
  }
}

@media (min-width: 768px) {
  .career__about .image__wrapper {
    margin-bottom: 1.625rem;
    display: inline-block;
  }
}

.career__about .image__wrapper::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 2rem;
  background-color: #091e42;
  border-radius: 0.125rem;
}

.career__about-icon__bg {
  position: absolute;
  top: 0;
  height: calc(100% + 32px);
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-width: 768px) {
  .career__about-icon__bg {
    right: 15px;
    left: 15px;
    width: calc(100% - 30px);
    background-size: auto 100%;
  }
}

@media (max-width: 767.98px) {
  .career__about-icon__bg {
    background-size: contain;
    width: 75%;
  }
}

.career__about-image {
  position: relative;
  -o-object-fit: cover;
  object-fit: cover;
  display: block;
  z-index: 1;
  width: 100%;
}

@media (min-width: 768px) {
  .career__about-image {
    width: 250px;
    height: 288px;
  }
}

@media (min-width: 992px) {
  .career__about-image {
    width: 365px;
  }
}

@media (min-width: 1200px) {
  .career__about-image {
    width: 445px;
  }
}

.career__tabs .nav-tabs {
  display: none;
  position: relative;
  list-style: none;
  padding: 0;
  margin-bottom: 4rem;
  border-bottom: 2px solid #dfe1e6;
}

@media (min-width: 768px) {
  .career__tabs .nav-tabs {
    display: flex;
  }
}

@media (max-width: 991.98px) {
  .career__tabs .nav-tabs {
    margin-top: 3rem;
  }
}

.career__tabs .nav-tabs .nav-link {
  padding: 0 0 0.875rem;
  color: #6c798f;
  margin-right: 2rem;
  cursor: pointer;
}

.career__tabs .nav-tabs .nav-link:not(.active) {
  letter-spacing: 0.015rem;
}

.career__tabs .nav-tabs .nav-link:hover {
  border-color: transparent;
}

.career__tabs .nav-tabs .nav-link.active {
  color: #091e42;
  font-weight: 600;
  cursor: default;
  border-bottom: 6px solid #158aff;
}

.career__tabs .nav-select {
  display: none;
}

@media (max-width: 767.98px) {
  .career__tabs .nav-select {
    display: block;
    margin-bottom: 4rem;
    margin-top: 3.5rem;
  }
}

.career__tabs-tab:first-child {
  position: relative;
}

.career__tabs-tab:first-child::before {
  content: "";
  position: absolute;
  width: 95px;
  height: 104px;
  bottom: 8px;
  left: 0;
  background-image: url("/local/templates/1ci/i/career/Mission-circle-1.svg");
}

.career__tabs-tab:first-child::after {
  content: "";
  position: absolute;
  width: 32px;
  height: 32px;
  bottom: 64px;
  right: 0;
  background-image: url("/local/templates/1ci/i/career/Mission-circle-2.svg");
}

@media (min-width: 768px) {
  .career__tabs-tab:first-child::after {
    bottom: auto;
    top: 0;
  }
}

.career__tabs-tab_title, .career__tabs-tab_subtitle {
  text-align: center;
}

.career__tabs-tab_subtitle {
  margin-bottom: 0;
  padding-bottom: 8rem;
  font-size: 1.375rem;
  line-height: 32px;
}

@media (min-width: 768px) {
  .career__tabs-tab_subtitle {
    padding-bottom: 5rem;
  }
}

@media (min-width: 992px) {
  .career__tabs-tab_subtitle {
    padding-bottom: 2rem;
  }
}

.career__tabs-tab_vacancy {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border: 1px solid #c1c7d0;
  border-radius: 0.125rem;
  height: 100%;
}

.career__tabs-tab_vacancy--wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.career__tabs-tab_vacancy--header {
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #c1c7d0;
}

@media (min-width: 768px) {
  .career__tabs-tab_vacancy--header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.career__tabs-tab_vacancy--body {
  display: flex;
  flex-direction: column;
  flex-grow: 3;
}

.career__tabs-tab_vacancy--title {
  letter-spacing: .05em;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 16px;
  height: 16px;
  color: #6c798f;
  margin-bottom: 0.5rem;
}

@media (min-width: 768px) {
  .career__tabs-tab_vacancy--title {
    margin-bottom: 0;
  }
}

.career__tabs-tab_vacancy--place {
  display: flex;
  align-items: center;
}

.career__tabs-tab_vacancy--place .icon {
  height: 16px;
  width: 16px;
  margin-right: 0.25rem;
}

.career__tabs-tab_vacancy--place .place {
  font-size: 0.875rem;
  margin-bottom: 0;
}

.career__tabs-tab_vacancy--btnblock {
  margin-top: auto;
}

.career__tabs-tab_vacancy--btnblock.internal__buttonblock {
  margin-bottom: 3rem;
}

.career__tabs-tab_vacancy--btnblock.internal__buttonblock .button-icon {
  transition: all 0.2s ease-in-out;
}

.career__tabs-tab_vacancy--btnblock.internal__buttonblock .button-icon path {
  transition: all 0.2s ease-in-out;
}

.career__tabs-tab_vacancy--btnblock.internal__buttonblock:hover .button-icon {
  -webkit-transform: translateX(-3px);
  transform: translateX(-3px);
}

.career__tabs-tab_vacancy--btnblock.internal__buttonblock:hover .button-icon path {
  fill: #0064c8;
}

.career__tabs-tab_vacancy--btnblock [class*="_button"],
.career__tabs-tab_vacancy--btnblock [class*="_btn"] {
  font-weight: 600;
  padding: 0;
}

.career__tabs-tab_vacancy--btnblock [role="button"] + .button-icon {
  padding-left: 0;
  margin-left: -3px;
  margin-top: 2px;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .career__tabs-tab_vacancy--btnblock [role="button"] + .button-icon {
    transition: none;
  }
}

.career__tabs-tab_vacancy--btnblock [role="button"] + .button-icon.plus {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.career__tabs-tab_vacancy--btnblock [role="button"] + .button-icon .arrow,
.career__tabs-tab_vacancy--btnblock [role="button"] + .button-icon .http,
.career__tabs-tab_vacancy--btnblock [role="button"] + .button-icon .plus {
  fill: #158aff;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .career__tabs-tab_vacancy--btnblock [role="button"] + .button-icon .arrow,
  .career__tabs-tab_vacancy--btnblock [role="button"] + .button-icon .http,
  .career__tabs-tab_vacancy--btnblock [role="button"] + .button-icon .plus {
    transition: none;
  }
}

.career__tabs-tab_vacancy--btnblock [role="button"]:hover + .button-icon {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.career__tabs-tab_vacancy--btnblock [role="button"]:hover + .button-icon .arrow,
.career__tabs-tab_vacancy--btnblock [role="button"]:hover + .button-icon .http,
.career__tabs-tab_vacancy--btnblock [role="button"]:hover + .button-icon .plus {
  fill: #0064c8;
}

.career__tabs-tab_vacancy--btnblock [role="button"]:hover + .browser-link {
  -webkit-transform: none;
  transform: none;
}

.career__tabs .internal {
  display: none;
  width: 0;
  padding-bottom: 4rem;
}

@media (max-width: 767.98px) {
  .career__tabs .internal__reverse {
    flex-direction: column-reverse;
  }
}

.career__tabs .internal-titleblock {
  margin-bottom: 4rem;
}

.career__tabs .internal-titleblock_title {
  margin-bottom: 1rem;
}

.career__tabs .internal-titleblock_place {
  margin-bottom: 0.5rem;
}

@media (min-width: 768px) {
  .career__tabs .internal-titleblock_place {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
  .career__tabs .internal-titleblock_place .career__tabs-tab_vacancy--title {
    margin-right: 2rem;
  }
}

.career__tabs .internal-listblock_title {
  margin-bottom: 1.5rem;
}

.career__tabs .internal-listblock_list {
  margin-bottom: 3.5rem;
  padding: 0;
}

.career__tabs .internal-listblock_list li {
  display: flex;
  margin-bottom: 0.5rem;
  list-style-type: none;
}

.career__tabs .internal-listblock_list li::before {
  content: "";
  background-image: url("/local/templates/1ci/i/icons/Common/020.svg");
  display: block;
  position: relative;
  height: 8px;
  width: 8px;
  min-width: 8px;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
}

.career__tabs .internal-listblock__grey {
  padding: 4rem 3rem;
  background-color: #f4f5f7;
  border-radius: 0.125rem;
}

@media (max-width: 767.98px) {
  .career__tabs .internal-listblock__grey {
    padding: 4rem 1rem;
  }
}

.career__tabs .internal-listblock__grey_title {
  margin-bottom: 2rem;
}

.career__tabs .internal-listblock__grey_list {
  margin-bottom: 0;
}

.career__tabs .internal-listblock__grey_list li {
  margin-bottom: 1rem;
  align-items: center;
}

.career__tabs .internal-listblock__grey_list li::before {
  content: "";
  background-image: url("/local/templates/1ci/i/icons/features/Blue/023-26x26.svg");
  background-size: cover;
  height: 24px;
  width: 24px;
  min-width: 24px;
  margin-top: 0.375rem;
  margin-right: 1rem;
}

@media (max-width: 767.98px) {
  .career__tabs .internal-styckyblock {
    margin-bottom: 11rem;
    margin-right: 5rem;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .career__tabs .internal-styckyblock {
    margin-right: 3.75rem;
  }
}

@media (min-width: 768px) {
  .career__tabs .internal-styckyblock {
    position: sticky;
    top: 2rem;
  }
}

.career__tabs .internal-styckyblock_wrapper {
  position: relative;
  padding: 3rem 1.5rem;
  background-color: #f4f5f7;
  border-radius: 0.125rem;
}

@media (min-width: 992px) {
  .career__tabs .internal-styckyblock_wrapper {
    padding: 3rem 2.5rem;
  }
}

.career__tabs .internal-styckyblock_wrapper::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 2rem;
  left: 3.5rem;
  background-color: #158aff;
  border-radius: 0.125rem;
  z-index: -2;
}

.career__tabs .internal-styckyblock_wrapper::after {
  content: "";
  position: absolute;
  width: 264px;
  height: 192px;
  bottom: -7rem;
  right: -5rem;
  z-index: -1;
  background: url("/local/templates/1ci/i/career/Vacancies-circle.svg") center no-repeat;
  background-size: cover;
}

@media (min-width: 992px) {
  .career__tabs .internal-styckyblock_wrapper::after {
    right: -6rem;
    bottom: -7.5rem;
  }
}

.career__tabs .internal-styckyblock_text {
  margin-bottom: 0;
  text-align: center;
}

.career__contact {
  display: none;
}

@media (min-width: 768px) {
  .career__contact-wrapper {
    display: flex;
  }
}

.career__contact-img {
  height: 48px;
  width: 48px;
  margin-right: 1.5rem;
}

@media (max-width: 767.98px) {
  .career__contact-img {
    margin-bottom: 0.5rem;
  }
}

@media (min-width: 768px) {
  .career__contact-img {
    margin-right: 1.5rem;
  }
}

.career__contact-text {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .forms__social .container {
    display: flex;
    justify-content: center;
  }
}

.forms__social-border {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-top: 1px solid #fff;
}

@media (min-width: 768px) {
  .forms__social-border {
    max-width: 570px;
  }
}

@media (min-width: 992px) {
  .forms__social-border {
    max-width: 540px;
  }
}

.forms__social .email__wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
}

.forms__social-text {
  margin-bottom: 0;
  color: #fff;
  text-align: center;
}

.forms__social-link {
  display: flex;
  align-items: center;
}

.forms__social-link_href {
  color: #fff;
  position: relative;
  margin-left: 0.25rem;
}

.forms__social-link_href::before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: currentColor;
  opacity: 0;
  transition: opacity .2s;
}

.forms__social-link_href:hover::before {
  opacity: 1;
}

.forms__social-link_href:hover {
  color: #fff;
}

.forms__social-icons {
  margin-top: 0.5rem;
}

.forms__social-icon {
  display: inline-flex;
  align-items: center;
  width: 30px;
  height: 30px;
}

.image__block-img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.event-banner {
  display: inline-block;
}

.event-banner::after {
  height: 136px;
}

.event-banner_subtitle {
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 16px;
  font-family: "Rubik", sans-serif;
  letter-spacing: .05em;
  text-transform: uppercase;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  color: #fff;
}

@media (min-width: 576px) {
  .event-banner_subtitle {
    margin-top: 2rem;
  }
}

@media (min-width: 768px) {
  .event-banner_subtitle {
    margin-top: 3.25rem;
  }
}

.event-banner_title {
  margin-bottom: 1rem;
  color: #fff;
}

.event-banner_locate {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 1.5rem 0 2.5rem;
}

@media (min-width: 768px) {
  .event-banner_locate {
    flex-direction: row;
    align-items: center;
  }
}

.event-banner_locate--item {
  display: flex;
  align-items: center;
  margin-right: 2.125rem;
  margin-bottom: 0.5rem;
}

.event-banner_locate--item .inline {
  margin-right: 0.5rem;
}

.event-banner_locate--item .text {
  font-size: 1rem;
  color: #fff;
  line-height: 24px;
  margin-bottom: 0;
}

.event-banner_imageblock {
  display: flex;
  align-items: flex-end;
}

@media (max-width: 575.98px) {
  .event-banner_imageblock {
    padding: 0;
  }
}

.event-banner_imageblock--img {
  margin-top: 0;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .event-banner_imageblock--img {
    width: 540px;
    height: 440px;
  }
}

.event--little .event-card {
  border: none;
}

.event--little .event-buttons_action {
  display: none;
}

.event--little .event-background {
  height: auto;
  background-image: none;
}

.event--little .event-background_title {
  color: #6c798f;
}

.event-wrapper {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
}

.event-background {
  display: flex;
  padding: 1.5rem 1.5rem 0;
  height: 256px;
  background-image: url("/local/templates/1ci/i/events/event.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 2px 2px 0 0;
  background-color: #fff;
}

.event-background_title {
  font-family: "Rubik", sans-serif;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: .05em;
  margin-bottom: 0;
}

.event-card {
  border-radius: 0 0 2px 2px;
  border: 1px solid #c1c7d0;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fff;
}

@media (min-width: 992px) {
  .event-locate {
    display: flex;
    flex-wrap: wrap;
  }
  .event-locate div:first-child {
    margin-right: 4rem;
  }
}

.event-locate div:not(.event-locate_additional) {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.event-locate_additional {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 0;
}

.event-locate_additional p {
  margin-right: 1.5rem;
  line-height: 16px;
  font-size: 0.875rem;
  margin-bottom: 0;
}

.event-locate .inline {
  margin-right: 0.5rem;
}

.event-locate .inline path {
  fill: #091e42;
}

.event-locate_place, .event-locate_date {
  margin-bottom: 0;
  line-height: 16px;
  font-size: 0.875rem;
}

.event-textblock {
  margin: 1.5rem 0;
}

.event-textblock_text {
  font-size: 1rem;
  color: #6c798f;
  line-height: 1.5;
  margin-bottom: 0;
}

.event-buttons {
  margin-top: auto;
}

.event-buttons .inline path {
  transition: all 0.2s ease-in-out;
}

.event-buttons_action {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0.5rem;
  line-height: 24px;
}

@media (min-width: 768px) {
  .event-buttons_action {
    margin-bottom: 0;
  }
}

.event-buttons_action .inline path {
  fill: #158aff;
}

.event-buttons_action--text {
  color: #158aff;
  font-size: 1rem;
  font-weight: 600;
  margin-left: 0.5rem;
  margin-bottom: 0;
  transition: all 0.2s ease-in-out;
}

.event-buttons_action--text:hover + .inline path {
  fill: #0064c8;
}

.event-buttons_buttonblock [class*="_button"],
.event-buttons_buttonblock [class*="_btn"] {
  font-weight: 600;
  padding: 0;
}

.event-buttons_buttonblock [role="button"] + .button-icon {
  padding-left: 0;
  margin-left: -3px;
  margin-top: 2px;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .event-buttons_buttonblock [role="button"] + .button-icon {
    transition: none;
  }
}

.event-buttons_buttonblock [role="button"] + .button-icon.plus {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.event-buttons_buttonblock [role="button"] + .button-icon .arrow,
.event-buttons_buttonblock [role="button"] + .button-icon .http,
.event-buttons_buttonblock [role="button"] + .button-icon .plus {
  fill: #158aff;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .event-buttons_buttonblock [role="button"] + .button-icon .arrow,
  .event-buttons_buttonblock [role="button"] + .button-icon .http,
  .event-buttons_buttonblock [role="button"] + .button-icon .plus {
    transition: none;
  }
}

.event-buttons_buttonblock [role="button"]:hover + .button-icon {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.event-buttons_buttonblock [role="button"]:hover + .button-icon .arrow,
.event-buttons_buttonblock [role="button"]:hover + .button-icon .http,
.event-buttons_buttonblock [role="button"]:hover + .button-icon .plus {
  fill: #0064c8;
}

.event-buttons_buttonblock [role="button"]:hover + .browser-link {
  -webkit-transform: none;
  transform: none;
}

.event-buttons_buttonblock .action_button {
  line-height: 24px;
}

.event-buttons_buttonblock .action_button:hover + .inline path {
  fill: #0064c8;
}

@media (min-width: 768px) {
  .event-buttons {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}

.subscribe-block {
  padding: 2.5rem 1rem;
  background-color: #f4f5f7;
  border-radius: 0.125rem;
}

@media (min-width: 768px) {
  .subscribe-block {
    display: flex;
    align-items: center;
    padding: 2.5rem 1.5rem;
  }
}

@media (min-width: 992px) {
  .subscribe-block {
    padding: 2.5rem 6rem;
  }
}

.subscribe-block_text {
  margin-bottom: 0;
  text-align: center;
  font-size: 1rem;
  line-height: 24px;
}

@media (min-width: 768px) {
  .subscribe-block_text {
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content;
  }
}

.subscribe-block_input {
  padding: 0.5rem 1rem;
  display: block;
  height: 40px;
  border-radius: 0.25rem;
  width: 100%;
  margin: 0.5rem auto 2rem;
  background: #fff;
  border: 1px solid #c1c7d0;
}

@media (min-width: 768px) {
  .subscribe-block_input {
    margin: 0 1rem;
  }
}

.subscribe-block_input::-webkit-input-placeholder {
  font-size: 1rem;
  line-height: 1.3;
}

.subscribe-block_input::-moz-placeholder {
  font-size: 1rem;
  line-height: 1.3;
}

.subscribe-block_input::-ms-input-placeholder {
  font-size: 1rem;
  line-height: 1.3;
}

.subscribe-block_input::placeholder {
  font-size: 1rem;
  line-height: 1.3;
}

.subscribe-block_button {
  display: block;
  margin: 0 auto;
}

.event__form .video-w-bg-block_bg {
  margin-bottom: 3.5rem;
}

.event__form .register__button {
  padding: 2rem;
  background-color: #158aff;
  box-shadow: 130px 0 0 0 #158aff, -130px 0 0 0 #158aff;
  margin-bottom: 4rem;
}

.event__form-maintitle {
  margin-bottom: 2rem;
}

.event__form-text_main {
  font-size: 1rem;
  line-height: 24px;
  margin-bottom: 2rem;
}

.event__form-text__list {
  list-style: none;
  padding: 0;
  margin-bottom: 2rem;
}

@media (min-width: 992px) {
  .event__form-text__list {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    break-inside: avoid;
    margin-bottom: 4rem;
  }
}

.event__form-text__list_item {
  font-size: 1rem;
  line-height: 24px;
  margin-bottom: 1rem;
  position: relative;
  padding-left: 1rem;
  word-wrap: anywhere;
}

.event__form-text__list_item::before {
  position: absolute;
  height: 8px;
  width: 8px;
  content: "";
  background-image: url("/local/templates/1ci/i/icons/Common/020.svg");
  background-size: cover;
  left: 0;
  top: 8px;
}

.event__form-list {
  position: relative;
  margin-bottom: 2rem;
  padding: 0;
}

@media (min-width: 768px) {
  .event__form-list {
    margin-bottom: 4rem;
  }
}

.event__form-list__container {
  padding-left: 2.5rem;
}

.event__form-list__item {
  margin-bottom: 2rem;
  list-style-type: none;
}

.event__form-list__item_title {
  margin-bottom: 1rem;
}

.event__form-list__item::before {
  content: "";
  background-image: url("/local/templates/1ci/i/icons/features/Blue/023-26x26.svg");
  background-size: cover;
  display: block;
  position: absolute;
  left: 0;
  height: 24px;
  width: 24px;
  margin-right: 1rem;
}

.event__form-speakers {
  margin-bottom: 2rem;
}

.event__form-speakers_item {
  display: flex;
  margin-bottom: 2rem;
}

.event__form-speakers_item--image {
  height: 96px;
  width: 96px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 100%;
  margin-right: 1rem;
}

.event__form-speakers_item--text {
  margin-top: 0.5rem;
}

.event__form-speakers_item--text p {
  margin-bottom: 0;
}

.event__form-speakers_item--speaker {
  font-weight: 600;
}

.event__form-tabs .nav-tabs {
  margin-bottom: 3rem;
  border-bottom: 2px solid #dfe1e6;
}

.event__form-tabs .nav-tabs .nav-link {
  padding: 0 0 1rem;
  margin-right: 2.25rem;
  color: #6c798f;
  border-bottom: 2px solid #dfe1e6;
}

.event__form-tabs .nav-tabs .nav-link.active {
  color: #091e42;
  border-bottom: 2px solid #158aff;
}

.event__form-tabs .tab-content {
  margin-bottom: 4rem;
}

.event__form-tabs .tab-pane {
  border-bottom: 1px solid #dfe1e6;
}

.event__form-tabs .tab-pane_item {
  padding: 2rem 0 1.5rem;
  border-top: 1px solid #dfe1e6;
}

@media (min-width: 992px) {
  .event__form-tabs .tab-pane_item {
    display: flex;
  }
}

.event__form-tabs .tab-pane_time, .event__form-tabs .tab-pane_title, .event__form-tabs .tab-pane_subtitle, .event__form-tabs .tab-pane_text {
  margin-bottom: 0.5rem;
}

.event__form-tabs .tab-pane_subtitle {
  font-weight: 600;
}

.event__form-tabs .tab-pane_time {
  min-width: 160px;
  margin-right: 1.875rem;
}

.event__form-tabs .tab-pane_text {
  color: #6c798f;
  margin-top: 1rem;
}

@media (min-width: 992px) {
  .event__form-tabs .tab-pane_column {
    display: flex;
  }
  .event__form-tabs .tab-pane_column-item:not(:last-child) {
    margin-right: 1.875rem;
  }
}

.event__form-tabs .tab-pane_list {
  list-style: none;
  padding: 0;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.event__form-tabs .tab-pane_list-item {
  color: #6c798f;
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  position: relative;
}

.event__form-tabs .tab-pane_list-item::before {
  position: absolute;
  height: 8px;
  width: 8px;
  content: "";
  background-image: url("/local/templates/1ci/i/icons/Common/020.svg");
  background-size: cover;
  left: 0;
  top: 8px;
}

.event__form-location_imageblock {
  position: relative;
}

@media (min-width: 992px) {
  .event__form-location_imageblock {
    padding-right: 6rem;
  }
}

.event__form-location_text {
  position: absolute;
  background-color: #344563;
  border-radius: 0.125rem;
  padding: 2.5rem;
  color: #fff;
}

@media (max-width: 575.98px) {
  .event__form-location_text {
    width: 232px;
    bottom: -6rem;
    right: 3.5rem;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .event__form-location_text {
    width: 270px;
    bottom: -6rem;
    left: 0;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .event__form-location_text {
    width: 270px;
    left: 0;
    bottom: -4.5rem;
  }
}

@media (min-width: 992px) {
  .event__form-location_text {
    top: 3.5rem;
    right: 0;
    width: 350px;
  }
}

.event__form-location_image {
  width: 80%;
  margin-left: auto;
  display: block;
  -o-object-fit: cover;
  object-fit: cover;
}

@media (max-width: 575.98px) {
  .event__form-location_image {
    width: 232px;
    height: 160px;
    margin-bottom: 5rem;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .event__form-location_image {
    width: 438px;
    height: 296px;
    margin-bottom: 5rem;
    margin-left: 4.5rem;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .event__form-location_image {
    height: 186px;
    width: 270px;
    margin-bottom: 3.5rem;
  }
}

@media (min-width: 992px) {
  .event__form-location_image {
    width: 100%;
    height: 398px;
  }
}

.event__form-form {
  background-color: #158aff;
  width: 100%;
  max-width: 320px;
}

@media (max-width: 767.98px) {
  .event__form-form {
    box-shadow: 130px 0 0 0 #158aff, -130px 0 0 0 #158aff;
    margin-bottom: 4rem;
  }
}

@media (min-width: 768px) {
  .event__form-form {
    position: sticky;
    top: 2rem;
    border-radius: 0.125rem;
  }
}

.event__form-form_title {
  color: #fff;
  margin-bottom: 1.5rem;
}

@media (max-width: 767.98px) {
  .event__form-form_title {
    text-align: center;
  }
}

.event__form-form.forms--blue {
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}

.event__form-form.forms--blue .next__step {
  color: #fff;
}

.event__form-form.forms--blue .next__step-social {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.event__form-form.forms--blue .next__step-text {
  margin-top: 1.5rem;
  font-size: 1.1875rem;
}

.event__form-form.forms--blue .next__step_buttonblock {
  display: flex;
  align-items: center;
}

.event__form-form.forms--blue .next__step_buttonblock [class*="_button"],
.event__form-form.forms--blue .next__step_buttonblock [class*="_btn"] {
  font-weight: 600;
  padding: 0;
}

.event__form-form.forms--blue .next__step_buttonblock [role="button"] + .button-icon {
  padding-left: 0;
  margin-left: -3px;
  margin-top: 2px;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .event__form-form.forms--blue .next__step_buttonblock [role="button"] + .button-icon {
    transition: none;
  }
}

.event__form-form.forms--blue .next__step_buttonblock [role="button"] + .button-icon.plus {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.event__form-form.forms--blue .next__step_buttonblock [role="button"] + .button-icon .arrow,
.event__form-form.forms--blue .next__step_buttonblock [role="button"] + .button-icon .http,
.event__form-form.forms--blue .next__step_buttonblock [role="button"] + .button-icon .plus {
  fill: #fff;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .event__form-form.forms--blue .next__step_buttonblock [role="button"] + .button-icon .arrow,
  .event__form-form.forms--blue .next__step_buttonblock [role="button"] + .button-icon .http,
  .event__form-form.forms--blue .next__step_buttonblock [role="button"] + .button-icon .plus {
    transition: none;
  }
}

.event__form-form.forms--blue .next__step_buttonblock [role="button"]:hover + .button-icon {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.event__form-form.forms--blue .next__step_buttonblock [role="button"]:hover + .button-icon .arrow,
.event__form-form.forms--blue .next__step_buttonblock [role="button"]:hover + .button-icon .http,
.event__form-form.forms--blue .next__step_buttonblock [role="button"]:hover + .button-icon .plus {
  fill: #fff;
}

.event__form-form.forms--blue .next__step_buttonblock [role="button"]:hover + .browser-link {
  -webkit-transform: none;
  transform: none;
}

.event__form-form.forms--blue .next__step .event__form-form_title, .event__form-form.forms--blue .next__step_buttonblock {
  border-bottom: 1px solid #fff;
  padding-bottom: 1.5rem;
}

.event__form-form.forms--blue .next__step-calendar__btn, .event__form-form.forms--blue .next__step-more__btn {
  color: #fff;
  margin-right: 0.5rem;
}

.event__form-form.forms--blue .next__step-calendar__btn {
  margin-left: 0.5rem;
}

.event__form-form.forms--blue .next__step-calendar__icon {
  fill: #fff;
}

.event__form-form.forms--blue .hidden {
  display: none;
}

.event__form-form.forms--blue .form-group .select2-selection__rendered,
.event__form-form.forms--blue .form-group label,
.event__form-form.forms--blue .form-group input {
  font-size: 0.875rem;
  line-height: 16px;
}

.event__form-form.forms--blue .form-button {
  display: flex;
}

.event__form-form.forms--blue .form-button .btn {
  margin-top: auto;
}

@media (max-width: 767.98px) {
  .event__form-form.forms--blue .form-button {
    justify-content: center;
  }
}

@media (max-width: 767.98px) {
  .event__form-form.forms--blue {
    padding: 2rem 0 1rem;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .event__form-form.forms--blue {
    padding: 2rem 2rem 1rem;
  }
}

.event__form-duration_wrapper {
  display: flex;
  flex-direction: column;
}

@media (min-width: 992px) {
  .event__form-duration_wrapper {
    align-items: center;
    flex-direction: row;
  }
}

.event__form-duration_plus {
  position: relative;
  display: flex;
  justify-content: center;
  height: 16px;
  width: 16px;
  margin: 1rem 0;
}

@media (min-width: 992px) {
  .event__form-duration_plus {
    margin: 0 1.5rem;
  }
}

.event__form-duration_plus::before, .event__form-duration_plus::after {
  content: "";
  position: absolute;
  height: 16px;
  width: 2px;
  background-color: #42526e;
  border-radius: 0.125rem;
}

.event__form-duration_plus::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.event__form-duration_item {
  display: flex;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 1.5rem 1.5rem 1.5rem 1rem;
  background-color: #f4f5f7;
}

.event__form-duration_img {
  margin-right: 1rem;
  width: 16px;
  height: 16px;
}

.event__form-duration_text {
  margin-bottom: 0;
  line-height: 16px;
}

.event__form-duration_text.text-bold {
  font-weight: 600;
}

.event__question {
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: #f4f5f7;
}

.event__question-block {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .event__question-block {
    flex-direction: row;
  }
}

.event__question-avatar {
  border-radius: 100%;
  height: 96px;
  width: 96px;
  margin-right: 2rem;
}

@media (max-width: 767.98px) {
  .event__question-avatar {
    margin-bottom: 16px;
  }
}

.event__question-textblock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.event__question-textblock_text {
  margin-bottom: 0;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: transparent;
}

.main-slider {
  background-size: cover;
  background-position: center;
  position: relative;
  background-color: #344563;
  padding-top: 1rem;
}

.main-slider .bg__image {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
}

@media (min-width: 576px) {
  .main-slider {
    padding-top: 2.5rem;
  }
}

@media (min-width: 992px) {
  .main-slider {
    padding-top: 4rem;
  }
}

.main-slider::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 96px;
  width: 100%;
  background-color: #fff;
}

@media (min-width: 576px) {
  .main-slider::after {
    height: 128px;
  }
}

@media (min-width: 992px) {
  .main-slider::after {
    height: 48px;
  }
}

.main-slider .owl-carousel .owl-stage {
  display: flex;
}

.main-slider .owl-carousel .owl-stage .owl-item {
  flex-basis: auto;
  flex-shrink: 0;
}

.main-slider .owl-carousel .container {
  height: 100%;
}

.main-slider .owl-carousel .container .row {
  height: 100%;
}

.main-slider .owl-carousel .slide-text {
  color: #fff;
}

@media (min-width: 992px) {
  .main-slider .owl-carousel .slide-text {
    padding-bottom: 4rem;
  }
}

.main-slider .owl-carousel .slide-headline {
  color: #fff;
}

@media (min-width: 768px) {
  .main-slider .owl-carousel .slide-headline {
    margin-bottom: 1rem;
  }
}

.main-slider .owl-carousel .slide-headline_suptext {
  font-family: "Rubik", sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: .7px;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.main-slider .owl-carousel .slide-headline_suptext--bulb {
  position: relative;
}

.main-slider .owl-carousel .slide-headline_suptext--bulb::before {
  content: "";
  position: relative;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  box-shadow: 0 0 8px 0 #ffdd2d;
  background-color: #ffdd2d;
  margin-right: 0.5rem;
}

.main-slider .owl-carousel .slide-headline_subtext {
  font-size: 1.375rem;
  line-height: 1.45;
  margin-bottom: 1.5rem;
}

@media (min-width: 992px) {
  .main-slider .owl-carousel .slide-headline_subtext {
    margin-bottom: 1rem;
  }
}

.main-slider .owl-carousel .slide-date, .main-slider .owl-carousel .slide-time, .main-slider .owl-carousel .slide-lang {
  display: flex;
  align-items: center;
}

@media (max-width: 575.98px) {
  .main-slider .owl-carousel .slide-date, .main-slider .owl-carousel .slide-time, .main-slider .owl-carousel .slide-lang {
    margin-bottom: 0.5rem;
  }
}

.main-slider .owl-carousel .slide-date_wrapper, .main-slider .owl-carousel .slide-time_wrapper, .main-slider .owl-carousel .slide-lang_wrapper {
  margin: 1.5rem 0;
}

@media (max-width: 575.98px) {
  .main-slider .owl-carousel .slide-date_wrapper, .main-slider .owl-carousel .slide-time_wrapper, .main-slider .owl-carousel .slide-lang_wrapper {
    display: block;
  }
}

@media (min-width: 576px) {
  .main-slider .owl-carousel .slide-date_wrapper, .main-slider .owl-carousel .slide-time_wrapper, .main-slider .owl-carousel .slide-lang_wrapper {
    display: flex;
  }
}

.main-slider .owl-carousel .slide-date_text, .main-slider .owl-carousel .slide-time_text, .main-slider .owl-carousel .slide-lang_text {
  display: flex;
  align-items: center;
  font-size: 1.0625rem;
  line-height: 1.41;
  margin-right: 1.5rem;
  margin-bottom: 0;
}

.main-slider .owl-carousel .slide-date_icon, .main-slider .owl-carousel .slide-time_icon, .main-slider .owl-carousel .slide-lang_icon {
  height: 16px;
  width: 16px;
  margin-right: 0.5rem;
}

.main-slider .owl-carousel .slide-button {
  margin-bottom: 4.5rem;
}

.main-slider .owl-carousel .slide-image {
  position: relative;
  margin: 0 auto;
  max-height: 440px;
  bottom: 0;
  z-index: 2;
}

.main-slider .owl-carousel .slide-image_wrapper {
  padding: 0;
  display: flex;
  align-items: flex-end;
}

@media (min-width: 576px) {
  .main-slider .owl-carousel .slide-image_wrapper {
    padding: 0 15px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .main-slider .owl-carousel .slide-image_wrapper {
    margin: 0 auto;
    padding: 0;
  }
}

@media (min-width: 992px) {
  .main-slider .owl-carousel .slide-image_wrapper {
    min-height: 440px;
    padding: 0 15px;
  }
}

.main-slider .owl-dots {
  position: absolute;
  display: flex;
  height: 8px;
  padding: 0 15px;
  -webkit-transform: translateY(-24px);
  transform: translateY(-24px);
}

@media (min-width: 576px) {
  .main-slider .owl-dots {
    width: 540px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 27rem;
  }
}

@media (min-width: 768px) {
  .main-slider .owl-dots {
    width: 720px;
    justify-content: center;
    bottom: 29rem;
  }
}

@media (min-width: 992px) {
  .main-slider .owl-dots {
    width: 960px;
    justify-content: flex-start;
    -webkit-transform: translateX(-50%) translateY(-40px);
    transform: translateX(-50%) translateY(-40px);
    bottom: 4.5rem;
  }
}

@media (min-width: 1200px) {
  .main-slider .owl-dots {
    width: 1140px;
  }
}

.main-slider .owl-dots .owl-dot.active span {
  background-color: #fff;
}

.main-slider .owl-dots .owl-dot span {
  display: block;
  height: 8px;
  width: 8px;
  border: 1px solid #fff;
  border-radius: 100%;
  margin: 0 2px;
  background-color: transparent;
}

.blog-slider {
  padding: 3rem 0 4.5rem;
}

.blog-slider .owl-carousel .owl-stage {
  display: flex;
}

.blog-slider .owl-carousel .owl-item {
  flex-basis: auto;
  flex-shrink: 0;
}

.blog-slider .owl-carousel .owl-nav {
  display: none;
}

.blog-slider .owl-carousel .owl-dots {
  margin-top: 0.5rem;
}

.blog-slider .owl-carousel .owl-dot {
  outline: none;
}

.blog-slider .owl-carousel .owl-dot span {
  margin: 0 0.25rem;
  height: 8px;
  width: 8px;
  background-color: #dfe1e6;
}

.blog-slider .owl-carousel .owl-dot.active span {
  background-color: #158aff;
}

.blog-slider .owl-carousel .slide {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

@media (min-width: 576px) {
  .blog-slider .owl-carousel .slide {
    width: 330px;
  }
}

.blog-slider .owl-carousel .slide_head {
  position: relative;
  margin-bottom: 1rem;
}

@media (max-width: 575.98px) {
  .blog-slider .owl-carousel .slide_head {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 576px) {
  .blog-slider .owl-carousel .slide_head {
    width: 330px;
    height: 296px;
  }
}

@media (min-width: 992px) {
  .blog-slider .owl-carousel .slide_head {
    width: 350px;
    height: 272px;
  }
}

.blog-slider .owl-carousel .slide_picture {
  width: 88%;
}

.blog-slider .owl-carousel .slide_picture--img {
  width: 100%;
}

@media (max-width: 575.98px) {
  .blog-slider .owl-carousel .slide_picture {
    align-self: flex-end;
    margin-bottom: 70px;
  }
  .blog-slider .owl-carousel .slide_picture--img {
    -o-object-fit: cover;
    object-fit: cover;
  }
}

@media (min-width: 576px) {
  .blog-slider .owl-carousel .slide_picture {
    width: 300px;
    height: 224px;
    float: right;
  }
}

@media (min-width: 992px) {
  .blog-slider .owl-carousel .slide_picture {
    width: 310px;
    height: 224px;
  }
}

.blog-slider .owl-carousel .slide_subtext {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1rem 1.5rem;
  border-radius: 0.25rem;
  width: 258px;
  height: 128px;
  color: #fff;
  margin-bottom: 0;
  opacity: .9;
}

@media (max-width: 575.98px) {
  .blog-slider .owl-carousel .slide_subtext {
    width: 90%;
  }
}

@media (min-width: 576px) {
  .blog-slider .owl-carousel .slide_subtext {
    width: 300px;
  }
}

@media (min-width: 992px) {
  .blog-slider .owl-carousel .slide_subtext {
    width: 310px;
    height: 104px;
  }
}

.blog-slider .owl-carousel .slide_text {
  color: #6c798f;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.0625rem;
  line-height: 1.41;
  margin-bottom: 0.5rem;
}

.blog-slider .owl-carousel .slide_action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
}

.blog-slider .owl-carousel .slide_action--time {
  color: #6c798f;
  font-family: "Rubik", sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: .7px;
  margin-bottom: 0;
}

.blog-slider .owl-carousel .slide_buttonblock {
  display: flex;
  align-items: center;
}

.blog-slider .owl-carousel .slide_buttonblock--link {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  line-height: 1.5;
  margin-right: 4px;
}

.blog-slider .owl-carousel .slide_buttonblock--img {
  width: 16px;
  height: 16px;
}

.blog-slider .owl-carousel .slide_buttonblock [class*="_button"],
.blog-slider .owl-carousel .slide_buttonblock [class*="_btn"] {
  font-weight: 600;
  padding: 0;
}

.blog-slider .owl-carousel .slide_buttonblock [role="button"] + .button-icon {
  padding-left: 0;
  margin-left: -3px;
  margin-top: 2px;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .blog-slider .owl-carousel .slide_buttonblock [role="button"] + .button-icon {
    transition: none;
  }
}

.blog-slider .owl-carousel .slide_buttonblock [role="button"] + .button-icon.plus {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.blog-slider .owl-carousel .slide_buttonblock [role="button"] + .button-icon .arrow,
.blog-slider .owl-carousel .slide_buttonblock [role="button"] + .button-icon .http,
.blog-slider .owl-carousel .slide_buttonblock [role="button"] + .button-icon .plus {
  fill: #158aff;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .blog-slider .owl-carousel .slide_buttonblock [role="button"] + .button-icon .arrow,
  .blog-slider .owl-carousel .slide_buttonblock [role="button"] + .button-icon .http,
  .blog-slider .owl-carousel .slide_buttonblock [role="button"] + .button-icon .plus {
    transition: none;
  }
}

.blog-slider .owl-carousel .slide_buttonblock [role="button"]:hover + .button-icon {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.blog-slider .owl-carousel .slide_buttonblock [role="button"]:hover + .button-icon .arrow,
.blog-slider .owl-carousel .slide_buttonblock [role="button"]:hover + .button-icon .http,
.blog-slider .owl-carousel .slide_buttonblock [role="button"]:hover + .button-icon .plus {
  fill: #0064c8;
}

.blog-slider .owl-carousel .slide_buttonblock [role="button"]:hover + .browser-link {
  -webkit-transform: none;
  transform: none;
}

.comments-slider .owl-carousel .owl-stage,
.comments-slider .owl-carousel.owl-drag .owl-item,
.comment-static .owl-carousel .owl-stage,
.comment-static .owl-carousel.owl-drag .owl-item,
.testimonial-slider .owl-carousel .owl-stage,
.testimonial-slider .owl-carousel.owl-drag .owl-item {
  touch-action: auto;
}

.comments-slider .container,
.comment-static .container,
.testimonial-slider .container {
  margin-top: 1.5rem;
  margin-bottom: 1.125rem;
}

@media (max-width: 767.98px) {
  .comments-slider .container,
  .comment-static .container,
  .testimonial-slider .container {
    background-color: #f4f5f7;
    margin-bottom: 0;
  }
}

.comments-slider .slide,
.comment-static .slide,
.testimonial-slider .slide {
  min-height: 256px;
  display: flex;
  background-color: #f4f5f7;
  flex-direction: column;
  padding: 2rem 1rem;
  border-radius: 4px;
}

@media (min-width: 576px) {
  .comments-slider .slide,
  .comment-static .slide,
  .testimonial-slider .slide {
    border-radius: 4px;
    padding: 2.5rem 2.625rem 2.375rem;
  }
}

@media (min-width: 768px) {
  .comments-slider .slide,
  .comment-static .slide,
  .testimonial-slider .slide {
    padding: 2rem 4rem;
  }
}

.comments-slider .slide h3,
.comments-slider .slide p,
.comment-static .slide h3,
.comment-static .slide p,
.testimonial-slider .slide h3,
.testimonial-slider .slide p {
  width: 100%;
  text-align: left;
  word-break: break-word;
}

.comments-slider .slide_title,
.comment-static .slide_title,
.testimonial-slider .slide_title {
  opacity: .9;
  font-family: "Rubik", sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  color: #091e42;
  text-transform: uppercase;
  margin-bottom: 0;
}

.comments-slider .slide_comment,
.comment-static .slide_comment,
.testimonial-slider .slide_comment {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.4rem;
  line-height: 1.45;
  color: #091e42;
  margin: 24px 0;
}

.comments-slider .slide_author,
.comment-static .slide_author,
.testimonial-slider .slide_author {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.comments-slider .slide_author p,
.comment-static .slide_author p,
.testimonial-slider .slide_author p {
  font-family: "Rubik", sans-serif;
  font-size: 0.75rem;
  font-style: italic;
  line-height: 1.33;
  color: #091e42;
  margin-bottom: 0;
}

.comments-slider .wrapper::before,
.comment-static .wrapper::before,
.testimonial-slider .wrapper::before {
  content: "";
  position: absolute;
  top: -24px;
  left: 0;
  height: 48px;
  width: 59px;
  background: url("/local/templates/1ci/i/icons/Quote.svg") center center no-repeat;
  background-size: contain;
  z-index: 1;
}

@media (min-width: 576px) {
  .comments-slider .wrapper::before,
  .comment-static .wrapper::before,
  .testimonial-slider .wrapper::before {
    left: 26px;
  }
}

@media (min-width: 768px) {
  .comments-slider .wrapper::before,
  .comment-static .wrapper::before,
  .testimonial-slider .wrapper::before {
    left: 48px;
  }
}

.comments-slider .wrapper::after,
.comment-static .wrapper::after,
.testimonial-slider .wrapper::after {
  content: "";
  position: absolute;
  bottom: -18px;
  right: 48px;
  height: 72px;
  width: 73px;
  background: url("/local/templates/1ci/i/icons/Bubble.svg") center center no-repeat;
  background-size: contain;
  z-index: 1;
  display: none;
}

@media (min-width: 768px) {
  .comments-slider .wrapper::after,
  .comment-static .wrapper::after,
  .testimonial-slider .wrapper::after {
    display: block;
  }
}

.testimonial-slider .wrapper::before {
  left: auto;
  right: 16px;
}

@media (min-width: 768px) {
  .testimonial-slider .wrapper::before {
    right: 48px;
  }
}

@media (min-width: 992px) {
  .testimonial-slider .wrapper::before {
    right: 56px;
  }
}

.testimonial-slider .wrapper::after {
  left: 16px;
  height: 48px;
  width: 59px;
  background: url("/local/templates/1ci/i/icons/Quote.svg") center center no-repeat;
  background-size: contain;
  display: block;
}

@media (min-width: 768px) {
  .testimonial-slider .wrapper::after {
    left: 48px;
  }
}

@media (min-width: 992px) {
  .testimonial-slider .wrapper::after {
    left: 56px;
  }
}

.testimonial-slider .owl-carousel .owl-stage {
  display: flex;
}

.testimonial-slider .owl-carousel .owl-item {
  flex-basis: auto;
  flex-shrink: 0;
}

.testimonial-slider .owl-carousel .slide {
  padding: 3rem 0rem 4rem;
  flex-direction: column-reverse;
  align-items: flex-start;
  justify-content: flex-end;
  min-height: 216px;
  height: 100%;
}

@media (min-width: 768px) {
  .testimonial-slider .owl-carousel .slide {
    flex-direction: row;
    align-items: stretch;
    align-content: stretch;
    padding: 3rem 2rem 4rem;
  }
}

@media (min-width: 992px) {
  .testimonial-slider .owl-carousel .slide {
    padding: 3rem 6rem 4rem;
  }
}

.testimonial-slider .owl-carousel .slide_comment {
  margin: 0;
  font-size: 1rem;
  width: 100%;
  line-height: 24px;
}

@media (min-width: 768px) {
  .testimonial-slider .owl-carousel .slide_comment {
    border-right: 1px solid #c1c7d0;
    padding-right: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .testimonial-slider .owl-carousel .slide_comment {
    display: flex;
    align-items: center;
  }
}

.testimonial-slider .owl-carousel .slide_author {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-bottom: 24px;
  min-width: 254px;
}

@media (min-width: 768px) {
  .testimonial-slider .owl-carousel .slide_author {
    padding: 16px 0;
    align-self: center;
  }
}

.testimonial-slider .owl-carousel .slide_author__about {
  display: flex;
  flex-direction: row;
}

.testimonial-slider .owl-carousel .slide_author__avatar {
  width: 72px;
  height: 72px;
  border-radius: 100%;
  margin-right: 24px;
  overflow: hidden;
}

@media (min-width: 768px) {
  .testimonial-slider .owl-carousel .slide_author__avatar {
    margin: 0 24px;
  }
}

.testimonial-slider .owl-carousel .slide_author__avatar--image {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.testimonial-slider .owl-carousel .slide_author__country {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
}

.testimonial-slider .owl-carousel .slide_author__country--flag {
  height: 15px;
  width: 21px;
  min-height: 15px;
  min-width: 21px;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.testimonial-slider .owl-carousel .slide_author__work {
  white-space: nowrap;
}

.newsroom__slider .owl-carousel .owl-stage {
  display: flex;
  height: 100%;
}

.newsroom__slider .owl-carousel .owl-item {
  flex-basis: auto;
  flex-shrink: 0;
}

.newsroom__slider .owl-carousel .owl-item img {
  height: 16px;
  width: 16px;
}

.newsroom__slider .owl-carousel .slide {
  padding-bottom: 4rem;
  min-height: 184px;
}

@media (max-width: 767.98px) {
  .newsroom__slider .owl-carousel .slide {
    display: flex;
    flex-direction: column;
  }
}

.newsroom__slider .owl-carousel .slide-info {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.newsroom__slider .slide {
  padding: 3rem 1rem;
  height: 100%;
}

@media (min-width: 768px) {
  .newsroom__slider .slide {
    padding-left: 2rem;
    padding-right: 2rem;
    display: flex;
  }
}

@media (min-width: 992px) {
  .newsroom__slider .slide {
    padding-left: 95px;
    padding-right: 95px;
  }
}

@media (min-width: 768px) {
  .newsroom__slider .slide-additional {
    padding-top: 0.5rem;
    margin-right: 30px;
    min-width: 206px;
  }
}

@media (min-width: 992px) {
  .newsroom__slider .slide-additional {
    margin-right: 46px;
  }
}

.newsroom__slider .slide-additional_wrapper {
  display: flex;
  align-items: center;
}

.newsroom__slider .slide-additional_wrapper:first-child {
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .newsroom__slider .slide-additional_wrapper:first-child {
    margin-bottom: 1.5rem;
  }
}

@media (max-width: 767.98px) {
  .newsroom__slider .slide-additional_wrapper:last-child {
    margin-bottom: 1.5rem;
  }
}

.newsroom__slider .slide-additional_img {
  height: 16px;
  width: 16px;
  margin-right: 0.5rem;
}

.newsroom__slider .slide-additional_text {
  font-family: "Rubik", sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.03rem;
  text-transform: uppercase;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .newsroom__slider .slide-info {
    padding-left: 30px;
    padding-bottom: 0.25rem;
    border-left: 1px solid #c1c7d0;
  }
}

.newsroom__slider .slide-title {
  margin: 0.5rem 0;
}

@media (min-width: 768px) {
  .newsroom__slider .slide-title {
    margin-top: 0;
  }
}

.newsroom__slider .slide-btnblock {
  margin-top: auto;
}

.newsroom__slider .slide-btnblock [class*="_button"],
.newsroom__slider .slide-btnblock [class*="_btn"] {
  font-weight: 600;
  padding: 0;
}

.newsroom__slider .slide-btnblock [role="button"] + .button-icon {
  padding-left: 0;
  margin-left: -3px;
  margin-top: 2px;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .newsroom__slider .slide-btnblock [role="button"] + .button-icon {
    transition: none;
  }
}

.newsroom__slider .slide-btnblock [role="button"] + .button-icon.plus {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.newsroom__slider .slide-btnblock [role="button"] + .button-icon .arrow,
.newsroom__slider .slide-btnblock [role="button"] + .button-icon .http,
.newsroom__slider .slide-btnblock [role="button"] + .button-icon .plus {
  fill: #158aff;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .newsroom__slider .slide-btnblock [role="button"] + .button-icon .arrow,
  .newsroom__slider .slide-btnblock [role="button"] + .button-icon .http,
  .newsroom__slider .slide-btnblock [role="button"] + .button-icon .plus {
    transition: none;
  }
}

.newsroom__slider .slide-btnblock [role="button"]:hover + .button-icon {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.newsroom__slider .slide-btnblock [role="button"]:hover + .button-icon .arrow,
.newsroom__slider .slide-btnblock [role="button"]:hover + .button-icon .http,
.newsroom__slider .slide-btnblock [role="button"]:hover + .button-icon .plus {
  fill: #0064c8;
}

.newsroom__slider .slide-btnblock [role="button"]:hover + .browser-link {
  -webkit-transform: none;
  transform: none;
}

.career__slider {
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.career__slider .owl-carousel {
  margin-bottom: 2rem;
}

.career__slider .owl-carousel::after {
  content: "";
  width: calc(100% - 30px);
  height: 100%;
  position: absolute;
  top: 2rem;
  left: 4.5rem;
  background-color: #091e42;
  border-radius: 0.125rem;
  z-index: -1;
}

.career__slider .owl-carousel::before {
  content: "";
  position: absolute;
  background-image: url("/local/templates/1ci/i/career/Quotes-circle.svg");
  width: 319px;
  height: 319px;
  z-index: 2;
  bottom: -250px;
  left: 60%;
}

@media (min-width: 768px) {
  .career__slider .owl-carousel::before {
    left: auto;
    right: -36px;
  }
}

@media (min-width: 992px) {
  .career__slider .owl-carousel::before {
    left: 80px;
    right: auto;
  }
}

@media (min-width: 1200px) {
  .career__slider .owl-carousel::before {
    left: 110px;
  }
}

.career__slider .owl-carousel .owl-stage {
  display: flex;
}

.career__slider .owl-carousel .owl-item {
  flex-basis: auto;
  flex-shrink: 0;
}

@media (max-width: 767.98px) {
  .career__slider .owl-carousel .owl-nav {
    position: absolute;
    top: 150px;
    left: 30px;
    width: calc(100% - 30px);
  }
}

@media (max-width: 575.98px) {
  .career__slider .owl-carousel .owl-nav {
    top: 124px;
  }
}

.career__slider .slide__shadow {
  position: relative;
}

.career__slider .slide__shadow::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 2rem;
  left: 3.5rem;
  background-color: #091e42;
  z-index: -1;
}

.career__slider .slide {
  padding: 1.5rem 1rem 4rem;
  background-color: #fff;
  border-radius: 0.125rem;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
}

@media (min-width: 768px) {
  .career__slider .slide {
    padding: 2.5rem 2.5rem 4rem;
    justify-content: space-between;
    flex-direction: row;
  }
}

@media (min-width: 992px) {
  .career__slider .slide {
    padding: 2.5rem 3.5rem 4rem;
  }
}

.career__slider .slide-text_quote {
  margin-bottom: 0;
}

.career__slider .slide-text_icon {
  max-width: 56px;
  min-width: 56px;
  height: 56px;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .career__slider .slide-author {
    margin-left: 1.5rem;
  }
}

@media (min-width: 992px) {
  .career__slider .slide-author {
    margin-left: 2rem;
  }
}

.career__slider .slide-author_image {
  -o-object-fit: cover;
  object-fit: cover;
  margin-bottom: 1rem;
  height: 160px;
  width: 100%;
}

@media (max-width: 575.98px) {
  .career__slider .slide-author_image {
    height: 200px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .career__slider .slide-author_image {
    height: 250px;
  }
}

@media (min-width: 768px) {
  .career__slider .slide-author_image {
    height: 160px;
    max-width: 169px;
    min-width: 169px;
  }
}

@media (min-width: 992px) {
  .career__slider .slide-author_image {
    height: 256px;
    max-width: 294px;
    min-width: 294px;
  }
}

.career__slider .slide-author_text {
  font-size: 0.875rem;
}

.partners__new-text_integrator {
  margin-bottom: 0.5rem;
  color: #ffae1f;
  font-family: "Rubik", sans-serif;
  font-size: 0.75rem;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  font-weight: 500;
}

.partners__new-item {
  padding: 2rem 1rem;
  margin-bottom: 2.5rem;
  border-radius: 0.125rem;
  border: 1px solid #c1c7d0;
}

@media (min-width: 768px) {
  .partners__new-item {
    padding: 2rem 2.5rem;
  }
}

.partners__new-hidesection {
  display: none;
}

.partners__new-wrapper {
  display: grid;
  grid-template: "text" "footer" "additional";
}

@media (min-width: 992px) {
  .partners__new-wrapper {
    grid-template: "text additional" "footer footer";
    grid-template-columns: auto 350px;
  }
}

.partners__new-text {
  grid-area: text;
  height: 100%;
}

@media (min-width: 992px) {
  .partners__new-text {
    border-right: 1px solid #c1c7d0;
    padding-right: 2rem;
    margin-right: 2rem;
  }
}

.partners__new-text_title {
  margin-bottom: 1rem;
}

.partners__new-text_suptitle {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  line-height: 24px;
  color: #6c798f;
  margin-bottom: 0;
}

.partners__new-additional {
  margin-top: 1rem;
  grid-area: additional;
}

@media (max-width: 991.98px) {
  .partners__new-additional {
    padding-top: 2rem;
    margin-top: 2rem;
    border-top: 1px solid #c1c7d0;
  }
}

.partners__new-additional_title {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 16px;
  letter-spacing: .05em;
  text-transform: uppercase;
}

.partners__new-additional_props {
  margin-bottom: 0;
  display: flex;
}

.partners__new-additional_props-left, .partners__new-additional_props-right {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  line-height: 24px;
  margin-bottom: 0;
}

.partners__new-additional_props-left {
  margin-right: 1rem;
  min-width: 58px;
}

.partners__new-additional_props-right {
  color: #6c798f;
}

.partners__new-additional_props-right a {
  word-break: break-all;
}

.partners__new-additional_props-right a:hover::after {
  -webkit-filter: brightness(80%);
  filter: brightness(80%);
}

.partners__new-additional_props-right [href^="http"]::after {
  content: "";
  display: inline-block;
  width: 1rem;
  height: .75rem;
  text-indent: 1rem;
  white-space: nowrap;
  overflow: hidden;
  background-image: url("/local/templates/1ci/i/icons/Common/002-1.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  margin-left: .2rem;
  -webkit-transform: translateY(1px);
  transform: translateY(1px);
}

.partners__new-emptyblock {
  margin-bottom: 2rem;
}

.partners__new-emptyblock.hidden {
  display: none;
}

.partners__new-buttonblock [class*="_button"],
.partners__new-buttonblock [class*="_btn"] {
  font-weight: 600;
  padding: 0;
}

.partners__new-buttonblock [role="button"] + .button-icon {
  padding-left: 0;
  margin-left: -3px;
  margin-top: 2px;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .partners__new-buttonblock [role="button"] + .button-icon {
    transition: none;
  }
}

.partners__new-buttonblock [role="button"] + .button-icon.plus {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.partners__new-buttonblock [role="button"] + .button-icon .arrow,
.partners__new-buttonblock [role="button"] + .button-icon .http,
.partners__new-buttonblock [role="button"] + .button-icon .plus {
  fill: #158aff;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .partners__new-buttonblock [role="button"] + .button-icon .arrow,
  .partners__new-buttonblock [role="button"] + .button-icon .http,
  .partners__new-buttonblock [role="button"] + .button-icon .plus {
    transition: none;
  }
}

.partners__new-buttonblock [role="button"]:hover + .button-icon {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.partners__new-buttonblock [role="button"]:hover + .button-icon .arrow,
.partners__new-buttonblock [role="button"]:hover + .button-icon .http,
.partners__new-buttonblock [role="button"]:hover + .button-icon .plus {
  fill: #0064c8;
}

.partners__new-buttonblock [role="button"]:hover + .browser-link {
  -webkit-transform: none;
  transform: none;
}

.partners__new-buttonblock_button {
  padding: 0;
  margin-right: 4px;
  font-size: 1.0625rem;
  font-weight: 600;
}

.partners__new-buttonblock_button.more {
  position: relative;
  /* Vertical line */
  /* horizontal line */
}

.partners__new-buttonblock_button.more::before, .partners__new-buttonblock_button.more::after {
  content: "";
  position: absolute;
  background-color: #158aff;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .partners__new-buttonblock_button.more::before, .partners__new-buttonblock_button.more::after {
    transition: none;
  }
}

.partners__new-buttonblock_button.more::before {
  top: calc(50% - 0.125rem);
  right: -0.5625rem;
  width: 0.125rem;
  height: 0.5rem;
}

.partners__new-buttonblock_button.more::after {
  top: calc(50% + 0.0625rem);
  right: -0.75rem;
  width: 0.5rem;
  height: 0.125rem;
}

.partners__new-buttonblock_button.more:hover::after, .partners__new-buttonblock_button.more:hover::before {
  background-color: currentColor;
}

.partners__new-buttonblock_button.less {
  position: relative;
  /* horizontal line */
}

.partners__new-buttonblock_button.less::before, .partners__new-buttonblock_button.less::after {
  content: "";
  position: absolute;
  background-color: #158aff;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .partners__new-buttonblock_button.less::before, .partners__new-buttonblock_button.less::after {
    transition: none;
  }
}

.partners__new-buttonblock_button.less::after {
  top: calc(50% + 0.0625rem);
  right: -0.75rem;
  width: 0.5rem;
  height: 0.125rem;
}

.partners__new-buttonblock_button.less:hover::after, .partners__new-buttonblock_button.less:hover::before {
  background-color: currentColor;
}

.partners__new .footer-section {
  grid-area: footer;
  margin-top: 2rem;
}

.partners__new-footer {
  display: flex;
  flex-direction: column;
}

.partners__new-footer_text {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 2rem;
  padding-bottom: 1rem;
  border-top: 1px solid #c1c7d0;
}

@media (min-width: 768px) {
  .partners__new-footer_text {
    padding-bottom: 0;
  }
}

.partners__new-footer_partners {
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-top: 1px solid #c1c7d0;
}

.partners__new-footer_partners--list {
  margin-top: 0.5rem;
}

.partners__new-footer_partners-image {
  width: 100px;
  height: 88px;
  -o-object-fit: contain;
  object-fit: contain;
  margin-right: 1.5rem;
}

.partners__new-footer_item {
  width: 100%;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .partners__new-footer_item {
    width: 50%;
    margin-bottom: 2rem;
  }
  .partners__new-footer_item:not(:last-child) {
    padding-right: 1rem;
  }
}

@media (min-width: 768px) {
  .partners__new-footer_item {
    width: 25%;
  }
}

.partners__new-footer_title {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 16px;
  letter-spacing: .05em;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.partners__new-footer_suptitle {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  line-height: 24px;
  color: #6c798f;
  margin-bottom: 0;
}

.partners-list {
  margin: 1rem 0;
}

.partners-list_item {
  background-color: #f4f5f7;
  padding: 1rem;
  margin-bottom: 2rem;
}

@media (min-width: 576px) {
  .partners-list_item {
    padding: 3rem;
  }
}

.partners-list_title {
  font-size: 1.6875rem;
  padding-bottom: 1rem;
}

.partners-list_contactstitle {
  font-family: "Rubik", sans-serif;
  font-size: 1.375rem;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.partners-list_props {
  padding: 0;
  margin: 0;
}

.partners-list_props strong {
  color: #6c798f;
  font-weight: 400;
}

.partners-menu {
  display: none;
}

.partners-menu_mobile {
  display: block;
}

@media (min-width: 576px) {
  .partners-menu_mobile {
    display: none;
  }
}

@media (min-width: 576px) {
  .partners-menu {
    display: flex;
  }
}

.partners-menu_item {
  flex: 100%;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .partners-menu_item:first-child .partners-menu_item-inner {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .partners-menu_item:last-child .partners-menu_item-inner {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}

@media (min-width: 768px) {
  .partners-menu_item {
    flex: auto;
  }
  .partners-menu_item:first-child .partners-menu_item-inner {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .partners-menu_item:last-child .partners-menu_item-inner {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.partners-menu_item:last-of-type .partners-menu_item-inner {
  border-right: 1px solid #091e42;
}

.partners-menu_item-inner {
  background-color: #fff;
  border-left: 1px solid #091e42;
  border-top: 1px solid #091e42;
  border-bottom: 1px solid #091e42;
  border-right: 1px solid #091e42;
  text-align: center;
}

@media (min-width: 768px) {
  .partners-menu_item-inner {
    border-right: none;
  }
}

.partners-menu_item-inner .partners-menu_item-link {
  color: #091e42;
  font-weight: 400;
}

.partners-menu_item-inner.active {
  color: #fff;
  background-color: #091e42;
}

.partners-menu_item-inner.active .partners-menu_item-link {
  color: #fff;
}

.partners-menu_item-title {
  font-size: 0.875rem;
  margin: 0.5rem 0;
}

.partners-menu_item-link {
  color: #091e42;
  padding-bottom: 0.5rem;
}

.partners-benefits-block {
  margin: 0 0 2rem;
  padding-top: 1rem;
  background-color: #f4f5f7;
}

.partners-benefits-block_header {
  text-align: center;
  font-size: 2.25rem;
  font-weight: 400;
}

.partners-benefits-block_description {
  text-align: center;
  font-size: 1.375rem;
  font-weight: 400;
  padding-bottom: 2rem;
}

.partners-benefits-block_item {
  text-align: center;
}

.partners-benefits-block_img {
  width: 112px;
  height: 112px;
  margin-bottom: 1rem;
}

.partners-benefits-block_title {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.partners-solutions-block {
  padding-bottom: 3rem;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .partners-solutions-block .col-lg-4.partners-solutions-block_item .partners-solutions-block_list {
    display: flex;
  }
  .partners-solutions-block .col-lg-4.partners-solutions-block_item .partners-solutions-block_list-item:not(:last-child) {
    margin-right: 1.5rem;
  }
}

@media (max-width: 767.98px) {
  .partners-solutions-block .col-lg-8.partners-solutions-block_item .partners-solutions-block_card:first-child {
    margin-bottom: 2.5rem;
  }
}

.partners-solutions-block_card--titleblock {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.partners-solutions-block_card--titleblock_img {
  margin-right: 1rem;
  width: 40px;
  height: 40px;
}

.partners-solutions-block_card--titleblock_title {
  margin-bottom: 0;
}

.partners-solutions-block_item {
  padding: 1rem;
}

@media (min-width: 992px) {
  .partners-solutions-block_card:first-child {
    padding-right: 2.5rem;
  }
  .partners-solutions-block_card:only-child {
    padding-right: 15px;
  }
  .partners-solutions-block_card + .partners-solutions-block_card:last-child {
    padding-left: 2.5rem;
  }
}

.partners-solutions-block_text-block {
  padding: 2rem 1.5rem 1.5rem;
  border: 1px solid #dfe1e6;
  border-radius: 0.25rem;
  height: 100%;
}

.partners-solutions-block_description {
  margin-bottom: 2rem;
}

.partners-solutions-block_subdescription {
  margin-bottom: 1.5rem;
}

.partners-solutions-block_list {
  list-style: none;
  padding-left: 0;
}

.partners-solutions-block_list-item {
  position: relative;
  margin-left: 2rem;
  margin-bottom: 1.5rem;
}

.partners-solutions-block_list-item::before {
  content: "";
  position: absolute;
  background: url("/local/templates/1ci/i/icons/dot2.svg") no-repeat 0 0;
  left: -34px;
  top: 0;
  width: 24px;
  height: 24px;
}

.partners-solutions-block_buttonblock [class*="_button"],
.partners-solutions-block_buttonblock [class*="_btn"] {
  font-weight: 600;
  padding: 0;
}

.partners-solutions-block_buttonblock [role="button"] + .button-icon {
  padding-left: 0;
  margin-left: -3px;
  margin-top: 2px;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .partners-solutions-block_buttonblock [role="button"] + .button-icon {
    transition: none;
  }
}

.partners-solutions-block_buttonblock [role="button"] + .button-icon.plus {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.partners-solutions-block_buttonblock [role="button"] + .button-icon .arrow,
.partners-solutions-block_buttonblock [role="button"] + .button-icon .http,
.partners-solutions-block_buttonblock [role="button"] + .button-icon .plus {
  fill: #158aff;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .partners-solutions-block_buttonblock [role="button"] + .button-icon .arrow,
  .partners-solutions-block_buttonblock [role="button"] + .button-icon .http,
  .partners-solutions-block_buttonblock [role="button"] + .button-icon .plus {
    transition: none;
  }
}

.partners-solutions-block_buttonblock [role="button"]:hover + .button-icon {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.partners-solutions-block_buttonblock [role="button"]:hover + .button-icon .arrow,
.partners-solutions-block_buttonblock [role="button"]:hover + .button-icon .http,
.partners-solutions-block_buttonblock [role="button"]:hover + .button-icon .plus {
  fill: #0064c8;
}

.partners-solutions-block_buttonblock [role="button"]:hover + .browser-link {
  -webkit-transform: none;
  transform: none;
}

.partners-catalogue {
  padding: 2.5rem 0 2rem;
}

.partners-catalogue_block--wrapper {
  margin-bottom: 2rem;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 992px) {
  .partners-catalogue_block--wrapper {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.partners-catalogue_item {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem 1px rgba(0, 0, 0, 0.1);
  height: 100%;
}

.partners-catalogue_badges {
  display: flex;
  flex-wrap: wrap;
}

.partners-catalogue_badges--tag {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.partners-catalogue_logo {
  max-height: 32px;
  margin-top: 1rem;
}

.partners-catalogue_headline {
  padding: 2rem 0;
}

.partners-catalogue_headline--subtitle {
  font-size: 1.0625rem;
  line-height: 1.41;
  margin-bottom: 0;
}

.partners-catalogue_list--feature {
  margin-top: 2rem;
  margin-bottom: 0;
}

.partners-catalogue_list--company {
  margin-top: 1rem;
}

@media (min-width: 576px) {
  .partners-catalogue_list--company {
    margin-top: 0;
  }
}

.partners-catalogue_list--title {
  font-family: "Rubik", sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #6c798f;
  letter-spacing: .7px;
  line-height: 1.33;
  margin-bottom: 0;
}

.partners-catalogue_list--subtitle {
  font-size: 1.0625rem;
  line-height: 1.41;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.partners-catalogue_list--flag {
  height: 15px;
  margin-right: 0.5rem;
}

.partners-catalogue_list--features {
  margin-bottom: 2.5rem;
}

.partners-catalogue_list--features__item {
  font-size: 1.0625rem;
  line-height: 1.41;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.partners-catalogue_list--features__item::before {
  content: "";
  display: block;
  position: relative;
  height: 4px;
  min-width: 4px;
  margin: 0.6rem 1rem auto 0;
  border-radius: 0.25rem;
  background-color: #091e42;
}

@media (min-width: 576px) {
  .partners-catalogue_column {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 28px;
    -moz-column-gap: 28px;
    column-gap: 28px;
  }
}

.partners-catalogue_buttonblock {
  margin-top: auto;
}

.partners-catalogue_buttonblock [class*="_button"],
.partners-catalogue_buttonblock [class*="_btn"] {
  font-weight: 600;
  padding: 0;
}

.partners-catalogue_buttonblock [role="button"] + .button-icon {
  padding-left: 0;
  margin-left: -3px;
  margin-top: 2px;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .partners-catalogue_buttonblock [role="button"] + .button-icon {
    transition: none;
  }
}

.partners-catalogue_buttonblock [role="button"] + .button-icon.plus {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.partners-catalogue_buttonblock [role="button"] + .button-icon .arrow,
.partners-catalogue_buttonblock [role="button"] + .button-icon .http,
.partners-catalogue_buttonblock [role="button"] + .button-icon .plus {
  fill: #158aff;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .partners-catalogue_buttonblock [role="button"] + .button-icon .arrow,
  .partners-catalogue_buttonblock [role="button"] + .button-icon .http,
  .partners-catalogue_buttonblock [role="button"] + .button-icon .plus {
    transition: none;
  }
}

.partners-catalogue_buttonblock [role="button"]:hover + .button-icon {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.partners-catalogue_buttonblock [role="button"]:hover + .button-icon .arrow,
.partners-catalogue_buttonblock [role="button"]:hover + .button-icon .http,
.partners-catalogue_buttonblock [role="button"]:hover + .button-icon .plus {
  fill: #0064c8;
}

.partners-catalogue_buttonblock [role="button"]:hover + .browser-link {
  -webkit-transform: none;
  transform: none;
}

.partners-catalogue_buttonblock--button {
  margin-right: 3px;
}

.partners-catalogue__new {
  padding: 2.5rem 0 2rem;
}

.partners-catalogue__new [href="#form"] {
  position: relative;
}

.partners-catalogue__new [href="#form"]::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-image: linear-gradient(90deg, currentColor, currentColor 50%, transparent 50%, transparent 100%);
  background-size: 6px 1px;
}

.partners-catalogue__new_header {
  margin-top: 2rem;
  margin-bottom: 2.5rem;
  text-align: center;
}

.partners-catalogue__new_header.hidden {
  display: none;
}

.partners-catalogue__new_block--wrapper {
  margin-bottom: 2rem;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 992px) {
  .partners-catalogue__new_block--wrapper {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.partners-catalogue__new_item {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border: solid 1px #c1c7d0;
  border-radius: 0.125rem;
  height: 100%;
  overflow: hidden;
}

@media (min-width: 768px) {
  .partners-catalogue__new_item {
    padding-bottom: 3rem;
  }
}

.partners-catalogue__new_badges {
  align-items: center;
}

@media (min-width: 768px) {
  .partners-catalogue__new_badges {
    position: absolute;
    background-color: #fff;
    width: 100%;
    padding-top: 1.5rem;
    padding-right: 1.5rem;
    bottom: -1.5rem;
    height: 3rem;
    overflow: hidden;
  }
  .partners-catalogue__new_badges::before {
    content: "";
    position: absolute;
    top: 16px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #6c798f;
  }
  .partners-catalogue__new_badges.popup {
    display: flex;
    flex-wrap: wrap;
  }
  .partners-catalogue__new_badges.popup:hover {
    cursor: pointer;
  }
}

.partners-catalogue__new_badges__wrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
}

@media (max-width: 767.98px) {
  .partners-catalogue__new_badges__wrapper {
    border-top: 1px solid #6c798f;
    padding-top: 0.5rem;
  }
}

.partners-catalogue__new_badges__wrapper:hover .popup {
  cursor: pointer;
  height: auto;
}

.partners-catalogue__new_badges__wrapper:hover .open {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.partners-catalogue__new_badges__wrapper:hover .open path {
  fill: #158aff;
}

.partners-catalogue__new_badges--tag {
  letter-spacing: .7px;
  opacity: 1;
  transition: all 0.2s ease-in-out;
  margin-right: 1.5rem;
  margin-top: 0.5rem;
}

.partners-catalogue__new_badges--tag .hidden {
  opacity: 0;
}

.partners-catalogue__new_badges--icon {
  display: none;
}

@media (min-width: 768px) {
  .partners-catalogue__new_badges--icon {
    position: absolute;
    align-items: center;
    justify-content: center;
    height: 16px;
    width: 16px;
    margin-top: 0.5rem;
    right: 0;
    z-index: 1;
    display: flex;
  }
}

.partners-catalogue__new_controls {
  display: flex;
  justify-content: space-between;
  height: 16px;
  min-width: 36px;
  margin-left: 1rem;
}

.partners-catalogue__new_controls .prev__element,
.partners-catalogue__new_controls .next__element {
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.partners-catalogue__new_controls .prev__element .inline,
.partners-catalogue__new_controls .next__element .inline {
  fill: #c1c7d0;
}

.partners-catalogue__new_controls .prev__element .inline.active,
.partners-catalogue__new_controls .next__element .inline.active {
  fill: #8993a4;
  cursor: pointer;
}

.partners-catalogue__new_controls .prev__element .inline.active:hover,
.partners-catalogue__new_controls .next__element .inline.active:hover {
  fill: #6c798f;
}

.partners-catalogue__new_controls .next__element {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.partners-catalogue__new_logoblock {
  height: 88px;
}

.partners-catalogue__new_logo {
  max-height: 32px;
}

.partners-catalogue__new_text {
  text-transform: uppercase;
  line-height: 1.33;
  letter-spacing: .7px;
  color: #ffae1f;
  font-family: "Rubik", sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.partners-catalogue__new_headline {
  padding: 0 0 2.5rem;
}

.partners-catalogue__new_headline--subtitle {
  line-height: 1.41;
  margin-bottom: 0;
}

.partners-catalogue__new_list--feature {
  margin-top: 1.5rem;
  margin-bottom: 0;
}

.partners-catalogue__new_list--company {
  margin-top: 1.5rem;
}

.partners-catalogue__new_list--title {
  font-family: "Rubik", sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #6c798f;
  letter-spacing: .7px;
  line-height: 1.33;
  margin-bottom: 0;
}

.partners-catalogue__new_list--flag {
  height: 15px;
  margin-right: 0.5rem;
}

.partners-catalogue__new_list--about {
  display: flex;
  margin-left: 0.5rem;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}

.partners-catalogue__new_list--about .inline {
  overflow: visible;
}

.partners-catalogue__new_list--about .inline path,
.partners-catalogue__new_list--about .inline rect {
  transition: all 0.2s ease-in-out;
}

.partners-catalogue__new_list--about:hover .inline path,
.partners-catalogue__new_list--about:hover .inline rect {
  fill: #158aff;
}

.partners-catalogue__new_list--subtitle, .partners-catalogue__new_list--features__item {
  font-size: 1.0625rem;
  line-height: 1.41;
  margin-bottom: 0;
  display: flex;
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
  break-inside: avoid-column;
}

.partners-catalogue__new_list--subtitle {
  align-items: center;
}

.partners-catalogue__new_list--features__item::before {
  content: "";
  background-image: url("/local/templates/1ci/i/icons/Common/020.svg");
  display: block;
  position: relative;
  height: 8px;
  min-width: 8px;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
}

.partners-catalogue__new_column {
  margin-bottom: 2.5rem;
}

@media (min-width: 576px) {
  .partners-catalogue__new_column {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 28px;
    -moz-column-gap: 28px;
    column-gap: 28px;
  }
}

.partners-catalogue__new_buttonblock {
  margin-top: auto;
  margin-bottom: 2.5rem;
}

@media (min-width: 768px) {
  .partners-catalogue__new_buttonblock {
    margin-bottom: 3rem;
  }
}

.partners-catalogue__new_buttonblock [class*="_button"],
.partners-catalogue__new_buttonblock [class*="_btn"] {
  font-weight: 600;
  padding: 0;
}

.partners-catalogue__new_buttonblock [role="button"] + .button-icon {
  padding-left: 0;
  margin-left: -3px;
  margin-top: 2px;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .partners-catalogue__new_buttonblock [role="button"] + .button-icon {
    transition: none;
  }
}

.partners-catalogue__new_buttonblock [role="button"] + .button-icon.plus {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.partners-catalogue__new_buttonblock [role="button"] + .button-icon .arrow,
.partners-catalogue__new_buttonblock [role="button"] + .button-icon .http,
.partners-catalogue__new_buttonblock [role="button"] + .button-icon .plus {
  fill: #158aff;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .partners-catalogue__new_buttonblock [role="button"] + .button-icon .arrow,
  .partners-catalogue__new_buttonblock [role="button"] + .button-icon .http,
  .partners-catalogue__new_buttonblock [role="button"] + .button-icon .plus {
    transition: none;
  }
}

.partners-catalogue__new_buttonblock [role="button"]:hover + .button-icon {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.partners-catalogue__new_buttonblock [role="button"]:hover + .button-icon .arrow,
.partners-catalogue__new_buttonblock [role="button"]:hover + .button-icon .http,
.partners-catalogue__new_buttonblock [role="button"]:hover + .button-icon .plus {
  fill: #0064c8;
}

.partners-catalogue__new_buttonblock [role="button"]:hover + .browser-link {
  -webkit-transform: none;
  transform: none;
}

.partners-catalogue__new_buttonblock--button {
  margin-right: 3px;
}

.partners-catalogue__new_little .partners-catalogue__new_block--wrapper {
  margin-bottom: 2rem;
}

@media (min-width: 576px) {
  .partners-catalogue__new_little .partners-catalogue__new_block--wrapper {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 992px) {
  .partners-catalogue__new_little .partners-catalogue__new_block--wrapper {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .partners-catalogue__new_little .partners-catalogue__new_item {
    padding-bottom: 3rem;
  }
  .partners-catalogue__new_little .partners-catalogue__new_badges {
    position: absolute;
    background-color: #fff;
    width: 100%;
    padding-top: 1.5rem;
    padding-right: 1.5rem;
    bottom: -1.5rem;
    overflow: hidden;
    height: 3rem;
  }
  .partners-catalogue__new_little .partners-catalogue__new_badges::before {
    content: "";
    position: absolute;
    top: 16px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #6c798f;
  }
  .partners-catalogue__new_little .partners-catalogue__new_badges__wrapper {
    border-top: 1px solid #6c798f;
    padding-top: 0.5rem;
  }
  .partners-catalogue__new_little .partners-catalogue__new_badges--icon {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    height: 16px;
    width: 16px;
    margin-top: 0.5rem;
    right: 0;
    z-index: 1;
  }
  .partners-catalogue__new_little .partners-catalogue__new_badges--icon .icon-arrow {
    transition: all 0.2s ease-in-out;
    fill: #8993a4;
  }
}

.partners-catalogue__new_little .partners-catalogue__new_logoblock {
  height: 5rem;
}

.partners-catalogue__new_little .partners-catalogue__new_text {
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}

.partners-catalogue__new_little .partners-catalogue__new_list {
  display: none;
}

.partners-catalogue__new_little .partners-catalogue__new_headline {
  padding-bottom: 1.5rem;
}

.partners-catalogue__new_little .partners-catalogue__new_buttonblock {
  margin-bottom: 2rem;
}

.partners-catalogue__new .blur {
  -webkit-filter: blur(5px);
  filter: blur(5px);
}

.partners-catalogue__new_empty.hidden {
  display: none;
}

.partners-catalogue__new_empty-header {
  font-size: 1.625rem;
  text-align: center;
  margin-bottom: 1.5rem;
}

.partners-catalogue__new_empty-block {
  font-size: 1.0625rem;
  line-height: 1.41;
  background-color: #f4f5f7;
  padding: 2.5rem 1rem;
  text-align: center;
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .partners-catalogue__new_empty-block {
    padding: 2.5rem 60px;
  }
}

@media (min-width: 992px) {
  .partners-catalogue__new_empty-block {
    padding: 2.5rem 95px;
  }
}

.partners-video-block {
  background-color: transparent;
  padding-bottom: 3.5rem;
}

@media (max-width: 767.98px) {
  .partners-video-block_video:first-child {
    margin-bottom: 2.25rem;
  }
}

.partners-video-block_description {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 1.0625rem;
  margin-top: 1.375rem;
}

.filter__badges {
  margin: 2rem 0 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.filter__badges.hidden {
  display: none;
}

.filter__badges-tag {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  background-color: #158aff;
  border-radius: 0.25rem;
  cursor: default;
  float: left;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 4px 8px;
  font-size: 1.0625rem;
  line-height: 1.4;
  color: #fff;
}

.filter__badges-tag.hidden {
  display: none;
}

.filter__badges-tag--remove {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  margin-left: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 1.875rem;
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}

.filter__badges-button {
  font-weight: 600;
  padding: 4px 8px;
  white-space: nowrap;
}

.filter__badges-button.hidden {
  display: none;
}

.error {
  background: #172b4d url("/local/templates/1ci/i/404-BG.svg") no-repeat center;
  background-size: cover;
}

.error_404 {
  width: 100%;
  max-width: 350px;
  display: block;
  position: relative;
  margin: 0 auto;
}

@media (max-width: 575.98px) {
  .error_404 {
    margin-bottom: 1rem;
    max-width: 260px;
  }
}

@media (min-width: 576px) {
  .error_404 {
    margin-bottom: 3.5rem;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .error_404 {
    max-width: 300px;
  }
}

.error_row {
  height: 640px;
}

@media (max-width: 575.98px) {
  .error_row {
    height: 530px;
  }
}

@media (min-width: 768px) {
  .error_row {
    height: 730px;
  }
}

.error_col {
  align-self: center;
}

.error_title {
  text-align: center;
  color: #fff;
}

.error_subtitle {
  text-align: center;
  color: #fff;
}

.error_thanx {
  font-weight: 500;
  text-align: center;
  color: #fff;
}

.error_btnblock {
  text-align: center;
  margin-bottom: 0;
  margin-top: 1.5rem;
}

.filter_title {
  text-transform: uppercase;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 0.75rem;
  letter-spacing: .7px;
  color: #091e42;
}

.filter_item {
  margin-top: 1.25rem;
}

.filter_item-label {
  display: block;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  color: #091e42;
}

.filter_item-input {
  width: 100%;
  height: 40px;
  border-radius: 0.25rem;
  border: solid 1px #c1c7d0;
  padding: 0.375rem 1rem;
}

.filter_item-hidden {
  display: none;
}

@media (min-width: 576px) {
  .filter_item-hidden {
    display: block;
  }
}

.filter_buttonblock {
  margin: 1rem 0 0;
}

.filter_buttonblock--button {
  margin-right: 4px;
}

.filter_buttonblock [class*="_button"],
.filter_buttonblock [class*="_btn"] {
  font-weight: 600;
  padding: 0;
}

.filter_buttonblock [role="button"] + .button-icon {
  padding-left: 0;
  margin-left: -3px;
  margin-top: 2px;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .filter_buttonblock [role="button"] + .button-icon {
    transition: none;
  }
}

.filter_buttonblock [role="button"] + .button-icon.plus {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.filter_buttonblock [role="button"] + .button-icon .arrow,
.filter_buttonblock [role="button"] + .button-icon .http,
.filter_buttonblock [role="button"] + .button-icon .plus {
  fill: #158aff;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .filter_buttonblock [role="button"] + .button-icon .arrow,
  .filter_buttonblock [role="button"] + .button-icon .http,
  .filter_buttonblock [role="button"] + .button-icon .plus {
    transition: none;
  }
}

.filter_buttonblock [role="button"]:hover + .button-icon {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.filter_buttonblock [role="button"]:hover + .button-icon .arrow,
.filter_buttonblock [role="button"]:hover + .button-icon .http,
.filter_buttonblock [role="button"]:hover + .button-icon .plus {
  fill: #0064c8;
}

.filter_buttonblock [role="button"]:hover + .browser-link {
  -webkit-transform: none;
  transform: none;
}

@media (min-width: 576px) {
  .filter_buttonblock {
    display: none;
  }
}

.video-w-bg-block {
  padding-top: 1rem;
}

.video-w-bg-block_row {
  justify-content: center;
}

.video-w-bg-block_controls {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.video-w-bg-block_image {
  width: 100%;
}

.video-w-bg-block_bg {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: auto;
}

.video-w-bg-block_play {
  width: 50px;
  height: 50px;
}

.video-w-bg-block_play img {
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .video-w-bg-block_play img {
    transition: none;
  }
}

.video-w-bg-block_play:hover img {
  -webkit-transform: scale(1.4);
  transform: scale(1.4);
}

.video-w-bg-block_w_textcard {
  padding: 2rem 0 3rem;
  background-color: #f4f5f7;
}

.video-w-bg-block_before_text {
  margin-bottom: 2.25rem;
}

@media (min-width: 768px) {
  .video-w-bg-block_before_text {
    margin-bottom: 0;
  }
}

@media (min-width: 576px) {
  .video-w-bg-block_card .card-wrapper .video-w-bg-block_card--wrapper:first-child {
    padding-top: 0;
  }
}

.video-w-bg-block_card--wrapper {
  padding: 0.75rem 0;
}

.video-w-bg-block_card-item {
  position: relative;
  padding-left: 2rem;
}

.video-w-bg-block_card-item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  background: url("/local/templates/1ci/i/icons/Common/005s.svg") no-repeat 0 0;
}

.video-w-bg-block_buttonblock {
  margin-top: 0.75rem;
}

.video-w-bg-block_buttonblock [class*="_button"],
.video-w-bg-block_buttonblock [class*="_btn"] {
  font-weight: 600;
  padding: 0;
}

.video-w-bg-block_buttonblock [role="button"] + .button-icon {
  padding-left: 0;
  margin-left: -3px;
  margin-top: 2px;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .video-w-bg-block_buttonblock [role="button"] + .button-icon {
    transition: none;
  }
}

.video-w-bg-block_buttonblock [role="button"] + .button-icon.plus {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.video-w-bg-block_buttonblock [role="button"] + .button-icon .arrow,
.video-w-bg-block_buttonblock [role="button"] + .button-icon .http,
.video-w-bg-block_buttonblock [role="button"] + .button-icon .plus {
  fill: #158aff;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .video-w-bg-block_buttonblock [role="button"] + .button-icon .arrow,
  .video-w-bg-block_buttonblock [role="button"] + .button-icon .http,
  .video-w-bg-block_buttonblock [role="button"] + .button-icon .plus {
    transition: none;
  }
}

.video-w-bg-block_buttonblock [role="button"]:hover + .button-icon {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.video-w-bg-block_buttonblock [role="button"]:hover + .button-icon .arrow,
.video-w-bg-block_buttonblock [role="button"]:hover + .button-icon .http,
.video-w-bg-block_buttonblock [role="button"]:hover + .button-icon .plus {
  fill: #0064c8;
}

.video-w-bg-block_buttonblock [role="button"]:hover + .browser-link {
  -webkit-transform: none;
  transform: none;
}

.video-w-bg-block_buttonblock .btn {
  padding-left: 2rem;
}

[data-card-preset="text"]:not(:last-child) {
  margin-bottom: 2rem;
}

.image-and-text-block,
.text-and-image-block {
  background-color: transparent;
  padding: 3rem 0;
}

@media (max-width: 767.98px) {
  .image-and-text-block_mobile-reverse,
  .text-and-image-block_mobile-reverse {
    flex-direction: column-reverse;
  }
}

.image-and-text-block_text,
.text-and-image-block_text {
  color: #6c798f;
}

@media (max-width: 575.98px) {
  .image-and-text-block_text,
  .text-and-image-block_text {
    margin-bottom: 1.5rem;
  }
}

.image-and-text-block_image,
.text-and-image-block_image {
  width: 100%;
}

.image-and-text-block_list,
.text-and-image-block_list {
  padding-left: 0;
}

.image-and-text-block_list-item,
.image-and-text-block li,
.text-and-image-block_list-item,
.text-and-image-block li {
  padding-left: 2.5rem;
  list-style: none;
  position: relative;
  margin: 1.5rem 0;
}

.image-and-text-block_list-item::before,
.image-and-text-block li::before,
.text-and-image-block_list-item::before,
.text-and-image-block li::before {
  content: "";
  position: absolute;
  background: url("/local/templates/1ci/i/icons/dot2.svg") no-repeat 0 0;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
}

.product-benefits-block {
  background-color: transparent;
  padding: 1.5rem 0 2rem;
}

.product-benefits-block_header {
  text-align: center;
}

.product-benefits-block_image {
  padding: 1rem;
  height: 136px;
}

.product-benefits-block_title {
  margin-bottom: 1.5rem;
}

.product-benefits-block_item {
  padding: 1rem;
}

.product-benefits-block_icon {
  width: 56px;
  height: auto;
  margin-bottom: 0.5rem;
}

.product-benefits-block_text {
  font-size: 1.1875rem;
  line-height: 1.26;
}

.product-benefits-block_text_m {
  font-size: 1.0625rem;
  color: #6c798f;
  line-height: 1.41;
}

.all-products-block {
  padding: 1rem 0 2rem;
  background-color: transparent;
}

.all-products-block_block {
  margin-bottom: 2rem;
}

.all-products-block_item {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  padding-bottom: 2rem;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem 1px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .all-products-block_item {
    transition: none;
  }
}

.all-products-block_item:hover {
  box-shadow: 0 0.5rem 1rem 1px rgba(0, 0, 0, 0.15);
}

.all-products-block_item .all-products-block_textblock-usa + .all-products-block_buttonblock {
  margin-top: 0;
}

.all-products-block_titleblock {
  padding: 1rem 0 0;
}

.all-products-block_textblock {
  color: #6c798f;
  margin-bottom: 1rem;
}

.all-products-block_textblock-usa {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 0.75rem;
  padding-bottom: 1.5rem;
  margin-top: auto;
  color: #6c798f;
}

.all-products-block_textblock-usa img {
  padding-right: 0.5rem;
  vertical-align: top;
}

.all-products-block_buttonblock {
  margin-top: auto;
}

@media (min-width: 576px) {
  .all-products-block_buttonblock {
    flex-direction: row;
  }
}

.all-products-block [class*="_button"],
.all-products-block [class*="_btn"] {
  font-weight: 600;
  padding: 0;
}

.all-products-block [role="button"] + .button-icon {
  padding-left: 0;
  margin-left: -3px;
  margin-top: 2px;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .all-products-block [role="button"] + .button-icon {
    transition: none;
  }
}

.all-products-block [role="button"] + .button-icon.plus {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.all-products-block [role="button"] + .button-icon .arrow,
.all-products-block [role="button"] + .button-icon .http,
.all-products-block [role="button"] + .button-icon .plus {
  fill: #158aff;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .all-products-block [role="button"] + .button-icon .arrow,
  .all-products-block [role="button"] + .button-icon .http,
  .all-products-block [role="button"] + .button-icon .plus {
    transition: none;
  }
}

.all-products-block [role="button"]:hover + .button-icon {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.all-products-block [role="button"]:hover + .button-icon .arrow,
.all-products-block [role="button"]:hover + .button-icon .http,
.all-products-block [role="button"]:hover + .button-icon .plus {
  fill: #0064c8;
}

.all-products-block [role="button"]:hover + .browser-link {
  -webkit-transform: none;
  transform: none;
}

.all-products-block_request-button {
  font-weight: 600;
  padding: 0.4rem 1rem;
  margin-right: 1rem;
  border: solid 1px #ffd21e;
}

.all-products-block_request-button-wa {
  font-weight: 600;
  padding: 0.4rem 0;
}

.application-value-block {
  background-color: transparent;
  margin: 2.5rem 0;
}

@media (min-width: 576px) {
  .application-value-block {
    background-color: #f4f5f7;
  }
}

@media (min-width: 992px) {
  .application-value-block {
    background-color: transparent;
  }
  .application-value-block .disable-lg-row {
    margin: 0;
  }
}

.application-value-block_block {
  background-color: #f4f5f7;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

@media (min-width: 992px) {
  .application-value-block_block {
    padding: 2rem 3.375rem;
  }
}

.application-value-block_base {
  position: relative;
  background-color: #6c798f;
}

@media (min-width: 992px) {
  .application-value-block_base {
    align-items: flex-end;
    padding-bottom: 0.5rem;
  }
}

.application-value-block_progress {
  position: relative;
  background-color: #ffdd2d;
}

@media (min-width: 992px) {
  .application-value-block_progress {
    align-items: center;
  }
}

.application-value-block_item:not(:last-child) .application-value-block_text {
  margin-bottom: 2rem;
}

@media (min-width: 992px) {
  .application-value-block_item:not(:last-child) .application-value-block_text {
    margin-bottom: 0;
  }
}

.application-value-block_text {
  margin-top: 0.5rem;
}

@media (min-width: 992px) {
  .application-value-block_text {
    margin-top: 0.5rem;
    max-width: 164px;
  }
}

.application-value-block_description--big {
  font-size: 1.6875rem;
  font-weight: 600;
  bottom: 0.8rem;
}

.application-value-block .bar-value-1 .application-value-block_base {
  width: 35%;
  height: inherit;
  padding-left: 0.25rem;
}

@media (min-width: 768px) {
  .application-value-block .bar-value-1 .application-value-block_base {
    width: 30.1%;
    height: inherit;
  }
}

@media (min-width: 992px) {
  .application-value-block .bar-value-1 .application-value-block_base {
    height: 45.5%;
    padding-left: 0;
    width: 100%;
  }
}

.application-value-block .bar-value-1 .application-value-block_progress {
  width: 10%;
  height: inherit;
  justify-content: center;
}

@media (min-width: 768px) {
  .application-value-block .bar-value-1 .application-value-block_progress {
    height: inherit;
    width: 8.6%;
  }
}

@media (min-width: 992px) {
  .application-value-block .bar-value-1 .application-value-block_progress {
    height: 13%;
    width: 100%;
  }
}

.application-value-block .bar-value-2 .application-value-block_base {
  width: 35%;
  height: inherit;
  padding-left: 0.25rem;
}

@media (min-width: 768px) {
  .application-value-block .bar-value-2 .application-value-block_base {
    width: 30.1%;
    height: inherit;
  }
}

@media (min-width: 992px) {
  .application-value-block .bar-value-2 .application-value-block_base {
    height: 45.5%;
    padding-left: 0;
    width: 100%;
  }
}

.application-value-block .bar-value-2 .application-value-block_progress {
  width: 30%;
  height: inherit;
  justify-content: center;
}

@media (min-width: 768px) {
  .application-value-block .bar-value-2 .application-value-block_progress {
    height: inherit;
    width: 25.8%;
  }
}

@media (min-width: 992px) {
  .application-value-block .bar-value-2 .application-value-block_progress {
    height: 39%;
    width: 100%;
  }
}

.application-value-block .bar-value-3 .application-value-block_base {
  width: 26.5%;
  height: inherit;
  padding-left: 0.25rem;
}

@media (min-width: 768px) {
  .application-value-block .bar-value-3 .application-value-block_base {
    width: 22.79%;
    height: inherit;
  }
}

@media (min-width: 992px) {
  .application-value-block .bar-value-3 .application-value-block_base {
    height: 34.45%;
    padding-left: 0;
    width: 100%;
  }
}

.application-value-block .bar-value-3 .application-value-block_progress {
  width: 8.5%;
  height: inherit;
  justify-content: center;
}

@media (min-width: 768px) {
  .application-value-block .bar-value-3 .application-value-block_progress {
    height: inherit;
    width: 7.31%;
  }
}

@media (min-width: 992px) {
  .application-value-block .bar-value-3 .application-value-block_progress {
    height: 11.05%;
    width: 100%;
  }
}

.application-value-block .bar-value-4 .application-value-block_base {
  width: 35%;
  height: inherit;
  padding-left: 0.25rem;
}

@media (min-width: 768px) {
  .application-value-block .bar-value-4 .application-value-block_base {
    width: 30.1%;
    height: inherit;
  }
}

@media (min-width: 992px) {
  .application-value-block .bar-value-4 .application-value-block_base {
    height: 45.5%;
    padding-left: 0;
    width: 100%;
  }
}

.application-value-block .bar-value-4 .application-value-block_progress {
  width: 14%;
  height: inherit;
  justify-content: center;
}

@media (min-width: 768px) {
  .application-value-block .bar-value-4 .application-value-block_progress {
    height: inherit;
    width: 12.04%;
  }
}

@media (min-width: 992px) {
  .application-value-block .bar-value-4 .application-value-block_progress {
    height: 18.2%;
    width: 100%;
  }
}

.application-value-block .bar-value-5 .application-value-block_base {
  width: 35%;
  height: inherit;
  padding-left: 0.25rem;
}

@media (min-width: 768px) {
  .application-value-block .bar-value-5 .application-value-block_base {
    width: 30.1%;
    height: inherit;
  }
}

@media (min-width: 992px) {
  .application-value-block .bar-value-5 .application-value-block_base {
    height: 45.5%;
    padding-left: 0;
    width: 100%;
  }
}

.application-value-block .bar-value-5 .application-value-block_progress {
  width: 35%;
  height: inherit;
  justify-content: center;
}

@media (min-width: 768px) {
  .application-value-block .bar-value-5 .application-value-block_progress {
    height: inherit;
    width: 30.1%;
  }
}

@media (min-width: 992px) {
  .application-value-block .bar-value-5 .application-value-block_progress {
    height: 45.5%;
    width: 100%;
  }
}

.disclaimer {
  padding: 0.5rem 0 3rem;
}

.disclaimer-text {
  padding-top: 1rem;
  border-top: 1px solid #c1c7d0;
  margin-bottom: 0;
  text-align: center;
  font-size: 0.875rem;
  color: #6c798f;
}

.usa-only {
  padding: 1rem 0 2rem;
  background-color: transparent;
}

.usa-only_row {
  justify-content: center;
  border-bottom: 1px solid #dfe1e6;
}

.usa-only_block {
  display: flex;
  align-items: center;
  background: transparent url("/local/templates/1ci/i/products/Earth.svg") bottom center no-repeat;
  text-align: center;
  min-height: 4rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.usa-only_image {
  padding-right: 0.5rem;
}

.usa-only_text {
  font-family: "Rubik", sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  white-space: nowrap;
  margin: 0;
}

.testimonial-w-quote-2 {
  margin-bottom: 1rem;
}

.testimonial-w-quote-2_block {
  background-color: #f4f5f7;
  padding: 3rem 4rem 3rem 3rem;
}

.testimonial-w-quote-2_quote {
  color: #ffdd2d;
  font-family: Arial, sans-serif;
  font-size: 114px;
  line-height: 1;
  float: left;
  margin-top: -2rem;
  height: 100%;
}

.testimonial-w-quote-2_text {
  font-size: 1.125rem;
}

.testimonial-w-quote-2_author {
  color: #6c798f;
  font-size: 0.875rem;
  font-style: italic;
}

.brochure {
  background-color: #f4f5f7;
}

@media (min-width: 768px) {
  .brochure {
    background-color: transparent;
  }
}

.brochure-row {
  display: flex;
}

@media (min-width: 768px) {
  .brochure-row {
    padding-top: 3rem;
  }
  .brochure-row > div:first-child {
    padding-right: 0;
  }
  .brochure-row > div:last-child {
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .brochure-row {
    padding-top: 6rem;
  }
}

.brochure_block, .brochure_image-block {
  position: relative;
  background-color: #f4f5f7;
}

.brochure_block {
  padding-top: 1.875rem;
  padding-bottom: 2.125rem;
}

@media (min-width: 768px) {
  .brochure_block {
    padding: 2rem 0 2.25rem 2.625rem;
    border-radius: 4px 0 0 4px;
  }
}

@media (min-width: 992px) {
  .brochure_block {
    padding: 2.5rem 0 3.5rem 3rem;
  }
}

.brochure_image-block {
  display: none;
}

@media (min-width: 768px) {
  .brochure_image-block {
    display: block;
    height: 100%;
    border-radius: 0 4px 4px 0;
  }
}

.brochure_image {
  position: absolute;
  bottom: 10px;
  width: 100%;
}

@media (min-width: 1200px) {
  .brochure_image {
    bottom: -10px;
    right: 32px;
  }
}

.brochure_type {
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 600;
  color: #6c798f;
  margin-bottom: 1rem;
}

.brochure_text {
  color: #6c798f;
  margin-bottom: 1.5rem;
}

@media (min-width: 1200px) {
  .brochure_text {
    max-width: 624px;
  }
}

.brochure_btnblock {
  margin-bottom: 0;
}

.professionals {
  padding: 2.5rem 0 0.5rem;
}

.professionals_block {
  padding: 2rem 1rem;
  height: 100%;
  border: 1px solid #dfe1e6;
  border-radius: 0.25rem;
}

.professionals_block-wrapper {
  margin-bottom: 2rem;
}

@media (min-width: 992px) {
  .professionals_block {
    padding: 2rem;
  }
}

.professionals_header {
  margin-bottom: 2rem;
}

.professionals_item {
  position: relative;
  margin-left: 2.5rem;
  margin-bottom: 1rem;
}

.professionals_item::before {
  content: "";
  position: absolute;
  background: url("/local/templates/1ci/i/icons/dot.svg") no-repeat 0 0;
  left: -40px;
  top: 0;
  width: 24px;
  height: 24px;
}

.professionals_item-header {
  margin-bottom: 0;
}

.professionals_item-description {
  color: #6c798f;
}

.company-benefits {
  background-color: transparent;
  padding: 1rem 0;
}

.company-benefits_card {
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .company-benefits_card {
    margin-bottom: 0;
  }
}

.company-benefits_block {
  margin-left: 2rem;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .company-benefits_block {
    margin-bottom: 2rem;
  }
  .company-benefits_block--partner {
    margin-bottom: 1rem;
  }
}

.company-benefits_block::before {
  content: "";
  position: absolute;
  left: 1rem;
  top: 0;
  width: 24px;
  height: 24px;
  background: transparent url("/local/templates/1ci/i/icons/dot.svg") center no-repeat;
}

@media (min-width: 576px) {
  .company-benefits_block::before {
    left: 0.5rem;
  }
}

.company-benefits_text {
  color: #6c798f;
}

.company-benefits_text--partner {
  font-size: 1.0625rem;
  color: #091e42;
}

.company-grid {
  background-color: #f4f5f7;
}

.company-grid_container {
  display: grid;
  grid-gap: 0.5rem;
  grid-template: "text text text" "post-2 post-2 post-2" "post-3 post-3 post-4" "post-6 post-5 post-5" "post-7 post-7 post-8";
  grid-template-columns: 30% auto 30%;
  grid-template-rows: auto 240px repeat(3, 200px);
}

@media screen and (max-width: 400px) {
  .company-grid_container {
    grid-template: "text text text" "post-2 post-2 post-2" "post-3 post-3 post-4" "post-6 post-5 post-5" "post-7 post-7 post-8";
    grid-template-columns: 88px auto 88px;
    grid-template-rows: auto 184px repeat(3, 128px);
  }
}

@media (min-width: 576px) {
  .company-grid_container {
    grid-template-rows: auto 272px repeat(3, 224px);
  }
}

@media (min-width: 768px) {
  .company-grid_container {
    grid-template: "text text post-2 post-2 post-2" "post-3 post-3 post-4 post-5 post-5" "post-6 post-7 post-7 post-7 post-8";
    grid-template-columns: 160px 124px 160px auto 160px;
    grid-template-rows: 272px repeat(2, 224px);
  }
}

@media (min-width: 992px) {
  .company-grid_container {
    grid-template-columns: repeat(3, 160px) auto 160px;
    grid-template-rows: 328px repeat(2, 224px);
  }
}

@media (min-width: 1200px) {
  .company-grid_container {
    grid-template-columns: 160px 204px 160px auto 160px;
  }
}

.company-grid .grid-item {
  background-color: #fff;
}

.company-grid .grid-item_img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.company-grid .grid-item_text {
  margin: 0;
}

@media (max-width: 767.98px) {
  .company-grid .grid-item_text {
    padding: 0.5rem 0 1.5rem;
  }
}

@media (min-width: 768px) {
  .company-grid .grid-item_text {
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
  }
}

.company-grid .grid-item:nth-child(1) {
  grid-area: text;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

@media (max-width: 767.98px) {
  .company-grid .grid-item:nth-child(1) {
    justify-content: flex-start;
  }
}

@media (min-width: 768px) {
  .company-grid .grid-item:nth-child(1) {
    background-color: #fff;
  }
}

.company-grid .grid-item:nth-child(2) {
  grid-area: post-2;
}

.company-grid .grid-item:nth-child(2) .grid-item_img {
  -o-object-position: top;
  object-position: top;
}

.company-grid .grid-item:nth-child(3) {
  grid-area: post-3;
}

.company-grid .grid-item:nth-child(3) .grid-item_img {
  -o-object-position: left top;
  object-position: left top;
}

.company-grid .grid-item:nth-child(4) {
  grid-area: post-4;
}

.company-grid .grid-item:nth-child(5) {
  grid-area: post-5;
}

.company-grid .grid-item:nth-child(6) {
  grid-area: post-6;
}

.company-grid .grid-item_double {
  grid-area: post-7;
  display: grid;
  grid-gap: 0.5rem;
  grid-template: "image-1 image-3 image-3";
}

@media (max-width: 575.98px) {
  .company-grid .grid-item_double {
    grid-template-columns: 42.8% auto;
    grid-template-rows: 200px;
  }
}

@media screen and (max-width: 400px) {
  .company-grid .grid-item_double {
    grid-template-columns: 88px auto;
    grid-template-rows: 128px;
  }
}

@media (min-width: 576px) {
  .company-grid .grid-item_double {
    grid-template-columns: 42.8% auto;
    grid-template-rows: 224px;
  }
}

@media (min-width: 768px) {
  .company-grid .grid-item_double {
    grid-template-columns: 160px auto auto;
    grid-template-rows: 224px;
  }
}

.company-grid .grid-item_double .grid-item:first-child {
  grid-area: image-1;
}

.company-grid .grid-item_double .grid-item:last-child {
  grid-area: image-3;
}

.company-grid .grid-item_double .grid-item:last-child .grid-item_img {
  -o-object-position: 90%;
  object-position: 90%;
}

.company-grid .grid-item:nth-child(8) {
  grid-area: post-8;
}

.company-about {
  margin: 0;
  padding: 3.5rem 0 4rem;
  background-color: #f4f5f7;
}

.company-about_image {
  width: 100%;
}

.company-contacts {
  margin: 0 0 2rem;
  background-color: transparent;
}

.company-contacts_icon-block {
  text-align: center;
}

.company-contacts_icon {
  padding-bottom: 0.5rem;
}

.company-contacts_header {
  text-align: center;
  font-size: 1.375rem;
}

.contacts {
  padding: 1.875rem 0 1rem;
}

.contacts [class*="_button"],
.contacts [class*="_btn"] {
  font-weight: 600;
  padding: 0;
}

.contacts [role="button"] + .button-icon {
  padding-left: 0;
  margin-left: -3px;
  margin-top: 2px;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .contacts [role="button"] + .button-icon {
    transition: none;
  }
}

.contacts [role="button"] + .button-icon.plus {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.contacts [role="button"] + .button-icon .arrow,
.contacts [role="button"] + .button-icon .http,
.contacts [role="button"] + .button-icon .plus {
  fill: #158aff;
  transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .contacts [role="button"] + .button-icon .arrow,
  .contacts [role="button"] + .button-icon .http,
  .contacts [role="button"] + .button-icon .plus {
    transition: none;
  }
}

.contacts [role="button"]:hover + .button-icon {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.contacts [role="button"]:hover + .button-icon .arrow,
.contacts [role="button"]:hover + .button-icon .http,
.contacts [role="button"]:hover + .button-icon .plus {
  fill: #0064c8;
}

.contacts [role="button"]:hover + .browser-link {
  -webkit-transform: none;
  transform: none;
}

.contacts_header {
  text-align: center;
  margin-bottom: 1rem;
}

.contacts_block {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.contacts_block-title {
  display: flex;
  align-items: center;
  padding: 1.5rem;
  border-top: 1px solid #dfe1e6;
  border-left: 1px solid #dfe1e6;
  border-right: 1px solid #dfe1e6;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.contacts_local {
  margin-bottom: 2rem;
}

.contacts_local-row {
  padding: 1rem 1.5rem;
}

@media (max-width: 991.98px) {
  .contacts_local-row {
    flex-direction: column;
    align-items: flex-start;
    max-height: 81px;
  }
}

@media (min-width: 992px) {
  .contacts_local-row {
    display: flex;
    align-items: center;
  }
}

.contacts_block-body {
  flex: 1;
  padding: 0 1.5rem 1.5rem;
  border: 1px solid #dfe1e6;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

@media (min-width: 992px) {
  .contacts_block-body--row {
    display: flex;
  }
  .contacts_block-body--row_item {
    margin-right: 3.5rem;
  }
}

.contacts_local-block {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #dfe1e6;
  padding: 0.5rem 0 1rem;
  border-radius: 0.25rem;
}

@media (min-width: 992px) {
  .contacts_local-block {
    padding: 1.5rem 0;
  }
}

.contacts_local-block-item {
  padding: 0 1.5rem;
}

@media (max-width: 991.98px) {
  .contacts_local-block-item:first-child {
    border-bottom: 1px solid #dfe1e6;
    padding-bottom: 1rem;
  }
}

.contacts_icon {
  float: left;
  width: 32px;
  height: 32px;
}

.contacts_local-title {
  margin: 0 1rem 0 0;
}

@media (min-width: 992px) {
  .contacts_local-title {
    display: flex;
    align-items: center;
  }
}

.contacts_title {
  margin-bottom: 0;
  margin-left: 1rem;
}

.contacts_label, .contacts_local-label, .contacts_label-email, .contacts_label-address, .contacts_label-phone, .contacts_local-label-email, .contacts_local-label-address, .contacts_local-label-phone {
  margin-top: 1rem;
  color: #6c798f;
  font-size: 1.0625rem;
  line-height: 1.4;
}

.contacts_value, .contacts_local-value, .contacts_value-email, .contacts_value-address, .contacts_value-phone, .contacts_local-value-email, .contacts_local-value-address, .contacts_local-value-phone {
  color: #091e42;
  font-size: 1.0625rem;
  line-height: 1.4;
}

/**
 * career
 */
.career .mission-info {
  position: relative;
  min-height: 104px;
  padding: 0 40px 0 100px;
}

.career .mission-info::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: transparent url("/local/templates/1ci/i/company/careers/tab-bg-left.png") 0 0 no-repeat;
  width: 95px;
  height: 104px;
}

.career .mission-info::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  background: transparent url("/local/templates/1ci/i/company/careers/tab-bg-right.png") 0 0 no-repeat;
  width: 32px;
  height: 32px;
}

.career .item {
  border: solid 1px #dfe1e6;
  padding: 24px;
}

.career .item .top-info {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: solid 1px #dfe1e6;
}

.career .item .top-info .section {
  text-transform: uppercase;
  color: #6c798f;
  font-size: 12px;
  line-height: 16px;
}

.career .item .top-info .location {
  background: transparent url("/local/templates/1ci/i/icons/location.svg") left center no-repeat;
  text-align: right;
  font-size: 14px;
  line-height: 16px;
  padding-left: 20px;
}

.career .item .title {
  font-size: 21px;
  line-height: 24px;
  padding-bottom: 8px;
}

.career .item .link a {
  display: inline-block;
  background: transparent url("/local/templates/1ci/i/icons/arrow.svg") right center no-repeat;
  padding-right: 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.forms {
  width: 100%;
  margin: 0;
  padding: 0.5rem 0 2.5rem;
}

.forms--banner {
  margin: 3rem auto 0;
  position: relative;
  padding: 2.5rem 0 3rem;
  border-radius: 0.25rem;
  box-shadow: 50px 0 0 0 #158aff, -50px 0 0 0 #158aff;
  background-color: #158aff;
  z-index: 1;
}

@media (min-width: 576px) {
  .forms--banner {
    padding: 2.5rem 3.75rem 2rem;
    box-shadow: none;
  }
}

@media (min-width: 768px) {
  .forms--banner {
    margin-top: 2rem;
  }
}

@media (min-width: 992px) {
  .forms--banner {
    max-width: 352px;
    bottom: -1rem;
    margin-top: 0;
    padding: 2.5rem 3.25rem 3rem;
  }
}

.forms--banner_new {
  position: relative;
  border-radius: 0.125rem;
  background-color: #158aff;
  z-index: 1;
}

@media (max-width: 991.98px) {
  .forms--banner_new {
    padding: 2rem 0;
    margin-top: 3rem;
  }
  .forms--banner_new::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #158aff;
    z-index: -1;
    -webkit-transform: scaleX(10);
    transform: scaleX(10);
  }
}

@media (min-width: 992px) {
  .forms--banner_new {
    padding: 2rem;
  }
  .forms--banner_new .row {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .forms--banner_new .form-group {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

@media (max-width: 991.98px) {
  .forms--banner_new .row {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
}

.forms--banner_new .form-button {
  margin-top: 2rem;
  margin-bottom: 0;
}

.forms--banner_new .form-group {
  min-height: auto;
}

@media (max-width: 991.98px) {
  .forms--banner_new .form-group {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.forms--banner_new .form-group:nth-child(6) {
  margin-top: 1rem;
}

.forms--banner_new .form-group .custom-control {
  min-height: 1rem;
}

.forms--banner_new .form-group label {
  color: #fff;
}

.forms--banner_new .form-group label a {
  color: #fff;
  text-decoration: underline;
}

.forms--blue {
  background-color: #158aff;
  padding: 2rem 0 1rem;
}

.forms--blue .select2-container .select2-selection--single .select2-selection__rendered {
  border-color: #fff;
}

.forms--blue .forms_title {
  color: #fff;
}

.forms_title {
  text-align: center;
  color: #091e42;
  margin-bottom: 1.5rem;
}

.forms--blue .forms_description {
  color: #fff;
}

.forms_description {
  text-align: center;
  color: #091e42;
  font-size: 1.375rem;
  font-weight: 400;
}

.forms--blue label {
  color: #fff !important;
}

.forms label {
  font-size: 0.875rem;
  font-weight: 400;
  color: #091e42;
  line-height: 16px;
}

.forms_row {
  align-items: flex-start;
}

.forms--blue .errortext {
  color: #fff;
}

.forms .errortext {
  font-size: 0.875rem;
  font-weight: 400;
  color: #fd3a57;
}

.forms_section:last-of-type {
  display: none;
}

.forms_switch--company, .forms_switch--individual {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.forms_switch--company.active, .forms_switch--individual.active {
  color: #158aff;
}

.form-developer .container {
  display: flex;
  justify-content: center;
}

@media (min-width: 992px) {
  .form-developer .forms_row {
    max-width: 570px;
  }
}

@media (max-width: 991.98px) {
  .form-developer .row {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
}

.form-developer .form-button {
  margin-top: 2rem;
  margin-bottom: 0;
}

.form-developer .form-group {
  min-height: auto;
}

@media (max-width: 991.98px) {
  .form-developer .form-group {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.form-developer .form-group:nth-child(6) {
  margin-top: 1rem;
}

.form-developer .form-group .custom-control {
  min-height: 1rem;
}

.form-developer .form-group label {
  color: #fff;
}

.form-developer .form-group label a {
  color: #fff;
  text-decoration: underline;
}

.form-become__partner .container {
  display: flex;
  justify-content: center;
}

@media (min-width: 768px) {
  .form-become__partner .forms_row {
    max-width: 600px;
  }
}

@media (min-width: 992px) {
  .form-become__partner .forms_row {
    max-width: 570px;
  }
}

.form-become__partner .form-group {
  min-height: auto;
}

.form-become__partner .form-group .custom-control {
  min-height: 1rem;
}

.form-become__partner .form-group_checkbox a {
  color: #fff;
  text-decoration: underline;
}

.form-become__partner .form-button {
  margin-top: 2rem;
  margin-bottom: 0;
}

.form-description {
  text-align: center;
  color: #fff;
  margin: 1rem 0;
}

.form-description [href^="mailto:"] {
  color: currentColor;
  position: relative;
}

.form-description [href^="mailto:"]::before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: currentColor;
  opacity: 0;
  transition: opacity .2s;
}

.form-description [href^="mailto:"]:hover::before {
  opacity: 1;
}

.form-social {
  background-color: #158aff;
  margin: 0;
  padding-bottom: 2rem;
}

.form-social_col {
  display: flex;
  border-top: 1px solid #fff;
  margin: 0;
  padding-top: 1.75rem;
}

.form-social_text {
  color: #fff;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.41;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.form-social_description {
  margin-right: 1rem;
  margin-bottom: 1rem;
  width: 100%;
}

@media (min-width: 768px) {
  .form-social_description {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin-bottom: 0;
  }
}

.form-social_item {
  margin-right: 0.5rem;
}

.customer-challenges {
  padding: 3.375rem 0 2.5rem;
}

.customer-challenges_image-block {
  text-align: left;
}

@media (min-width: 768px) {
  .customer-challenges_image-block {
    text-align: center;
  }
}

.customer-challenges_big-image-block {
  text-align: center;
}

.customer-challenges_image {
  width: 121px;
  height: 131px;
}

.customer-challenges_big-image {
  width: 100%;
  height: auto;
}

.customer-challenges_title {
  padding: 0.5rem 0;
}

.customer-challenges_text {
  color: #6c798f;
}

.customer-challenges_list {
  list-style: none;
}

.customer-challenges_list-item,
.customer-challenges_list li {
  padding-left: 3.5rem;
  list-style: none;
  position: relative;
  margin: 1.5rem 0;
  color: #6c798f;
}

.customer-challenges_list-item::before,
.customer-challenges_list li::before {
  content: "";
  position: absolute;
  background: url("/local/templates/1ci/i/icons/dot.svg") no-repeat 0 0;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
}

.project-objectives {
  background-color: #158aff;
  padding: 2.5rem 0 0.5rem;
}

.project-objectives_row {
  justify-content: center;
}

.project-objectives .w-100 {
  margin-bottom: 0;
}

.project-objectives_kpi {
  margin-bottom: 2rem;
  text-align: center;
}

.project-objectives_icon {
  width: 26px;
  height: 26px;
  margin-bottom: 0.5rem;
}

.project-objectives_text {
  color: #fff;
}

.testimonial-slider .owl-carousel .owl-dots, .comments-slider .owl-carousel .owl-dots, .newsroom__slider .owl-carousel .owl-dots, .career__slider .owl-carousel .owl-dots {
  height: auto;
  justify-content: center;
  position: absolute;
  width: 100%;
  margin: 0 -15px;
  padding: 0 30px;
  bottom: 1rem;
}

.testimonial-slider .owl-carousel .owl-dots .owl-dot.active span, .comments-slider .owl-carousel .owl-dots .owl-dot.active span, .newsroom__slider .owl-carousel .owl-dots .owl-dot.active span, .career__slider .owl-carousel .owl-dots .owl-dot.active span {
  background: #158aff;
}

.testimonial-slider .owl-carousel .owl-dots .owl-dot span, .comments-slider .owl-carousel .owl-dots .owl-dot span, .newsroom__slider .owl-carousel .owl-dots .owl-dot span, .career__slider .owl-carousel .owl-dots .owl-dot span {
  margin: 4px;
  width: 8px;
  height: 8px;
}

.testimonial-slider .owl-carousel .owl-nav, .comments-slider .owl-carousel .owl-nav, .newsroom__slider .owl-carousel .owl-nav, .career__slider .owl-carousel .owl-nav {
  justify-content: space-between;
  margin: 0 -15px;
}

.testimonial-slider .owl-carousel .owl-nav [class*="owl-"], .comments-slider .owl-carousel .owl-nav [class*="owl-"], .newsroom__slider .owl-carousel .owl-nav [class*="owl-"], .career__slider .owl-carousel .owl-nav [class*="owl-"] {
  margin: 0;
  padding: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonial-slider .owl-carousel .owl-nav [class*="owl-"]:hover, .comments-slider .owl-carousel .owl-nav [class*="owl-"]:hover, .newsroom__slider .owl-carousel .owl-nav [class*="owl-"]:hover, .career__slider .owl-carousel .owl-nav [class*="owl-"]:hover {
  background-color: #6c798f;
}

.testimonial-slider .owl-carousel .owl-nav .owl-prev, .comments-slider .owl-carousel .owl-nav .owl-prev, .newsroom__slider .owl-carousel .owl-nav .owl-prev, .career__slider .owl-carousel .owl-nav .owl-prev,
.testimonial-slider .owl-carousel .owl-nav .owl-next,
.comments-slider .owl-carousel .owl-nav .owl-next,
.newsroom__slider .owl-carousel .owl-nav .owl-next,
.career__slider .owl-carousel .owl-nav .owl-next {
  position: absolute;
  top: 50%;
  width: 48px;
  height: 48px;
  background-color: #8993a4;
  border-radius: 100%;
  outline: none;
}

.testimonial-slider .owl-carousel .owl-nav .owl-prev, .comments-slider .owl-carousel .owl-nav .owl-prev, .newsroom__slider .owl-carousel .owl-nav .owl-prev, .career__slider .owl-carousel .owl-nav .owl-prev {
  -webkit-transform: translateY(-50%) scale(-1, 1);
  transform: translateY(-50%) scale(-1, 1);
  left: -10px;
}


.testimonial-slider .owl-carousel .owl-nav .owl-next,
.comments-slider .owl-carousel .owl-nav .owl-next,
.newsroom__slider .owl-carousel .owl-nav .owl-next,
.career__slider .owl-carousel .owl-nav .owl-next {
  right: -10px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.response {
  padding-top: 38px;
  padding-bottom: 16px;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .response {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 768px) {
  .response {
    padding-top: 56px;
    padding-bottom: 56px;
  }
}

@media (min-width: 992px) {
  .response {
    padding-top: 72px;
  }
}

.response .container {
  max-width: 760px;
}

.response .row {
  margin-bottom: 56px;
}

.response .title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 24px;
  line-height: 1.3;
}

@media (min-width: 768px) {
  .response .title {
    margin-bottom: 16px;
    font-size: 26px;
    white-space: nowrap;
  }
  .response .title.custom-ma {
    margin-bottom: 152px;
  }
}

.response .title-main {
  line-height: 1.35;
  font-size: 26px;
}

@media (max-width: 767.98px) {
  .response .title-main {
    order: 3;
    position: relative;
    width: auto;
  }
}

@media (min-width: 768px) {
  .response .title-main {
    font-size: 30px;
    margin-top: 64px;
    white-space: pre;
  }
}

.response .text {
  line-height: 1.45;
  font-size: 17px;
  margin-bottom: 0;
}

.response .logo {
  display: block;
}

@media (max-width: 767.98px) {
  .response .logo {
    order: 1;
  }
}

@media (min-width: 768px) {
  .response .logo {
    padding-top: 22px;
  }
}

@media (max-width: 767.98px) {
  .response-banner {
    display: flex;
    flex-direction: column;
  }
  .response-banner .text {
    order: 5;
  }
  .response-banner .text-ma {
    order: 4;
  }
}

.response-banner_image {
  float: right;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;
}

@media (max-width: 767.98px) {
  .response-banner_image {
    padding: 0 15px;
    order: 2;
  }
}

@media (min-width: 768px) {
  .response-banner_image {
    width: 330px;
    margin: 0;
  }
}

@media (min-width: 992px) {
  .response-banner_image {
    width: 350px;
  }
}

.response-social_icons {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
}

.response-social_link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 2px solid #091e42;
  margin-right: 8px;
  margin-top: auto;
  padding: 4px;
  transition: all .2s;
}

@media screen and (max-width: 364px) {
  .response-social_link:last-child {
    margin-top: 8px;
  }
}

.response-social_link .inline {
  transition: all .2s;
}

.response-social_link:hover {
  border-color: #091e42;
  background-color: #091e42;
}

.response-social_link:hover .inline path {
  fill: #fff;
}

@media (min-width: 992px) {
  .response .quiz {
    background-color: #f4f5f7;
    padding: 32px 32px 40px;
  }
}

.response .quiz-wrapper {
  background-color: #f4f5f7;
  padding-top: 32px;
  padding-bottom: 56px;
}

@media (min-width: 768px) {
  .response .quiz-wrapper {
    border-radius: 2px;
    padding: 32px 17px 40px;
  }
}

@media (min-width: 992px) {
  .response .quiz-wrapper {
    padding: 0 15px 0;
    background-color: transparent;
  }
}

.response .quiz-items {
  margin-top: 16px;
  margin-bottom: 24px;
}

.response .quiz .custom-checkbox {
  margin-bottom: 16px;
  padding-left: 0;
  display: flex;
  min-height: auto;
}

.response .quiz .custom-control-input {
  position: relative;
  height: 16px;
  width: 16px;
  margin: 0 16px 0 0;
}

.response .quiz .custom-control-label {
  line-height: .9;
  min-height: 16px;
}

.response .quiz .custom-control-label::before, .response .quiz .custom-control-label::after {
  left: -2rem;
  top: 0;
  line-height: 0;
}

.response .quiz .button {
  display: block;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  color: #091e42;
  font-size: 17px;
  font-weight: 600;
  padding: 8px 24px;
  border-radius: 4px;
  background-color: #ffdd2d;
}

.response-success .title {
  font-size: 21px;
  line-height: 1.25;
}

.response-success .title-main {
  font-size: 24px;
  line-height: 1.25;
}

@media (min-width: 768px) {
  .response-success .title-main {
    font-size: 26px;
  }
}

.response-success .custom-ma-success {
  margin-bottom: 24px;
}

@media (min-width: 768px) {
  .response-success .custom-ma-success {
    margin-bottom: 24px !important;
  }
}

@media (max-width: 767.98px) {
  .response-success .response-banner_image {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
}

@media (min-width: 768px) {
  .response-success .response-banner {
    margin-bottom: 24px;
  }
}
/*# sourceMappingURL=1ci.css.map */