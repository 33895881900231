/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * 1Ci customization v1.0.0 (https://gitlab.1cinternational.com/)
 * Copyright 2018-2019 1Ci Developers
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import "functions";
@import "variables";
@import "mixins";
@import "root";
@import "reboot";
@import "type";
@import "fonts";
@import "images";
@import "grid";
@import "tables";
@import "forms";
@import "buttons";
@import "transitions";
@import "dropdown";
@import "tooltip";
@import "button-group";
@import "input-group";
@import "custom-forms";
@import "nav";
@import "navbar";
@import "modal";
@import "close";
@import "breadcrumb";
@import "utilities";
@import "progress";
@import "badge";
@import "card";

//* Add Select2 to bundle */
@import "./../../assets/vendor/select2/src/scss/core";
//* Add Owl to bundle */
@import "./../../assets/css/owl.carousel.min";
@import "./../../assets/css/owl.theme.default.min";

@import "custom";
