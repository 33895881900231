.select2-container--mask {
  @import "single";
  @import "multiple";
  min-width: 64px;
  width: 100%;

  .select2-dropdown {
    width: 100% !important;
    &--above {
      top: 2px;
      border: 1px solid #c1c7d0;
    }
    &--below {
      top: -10px;
    }
  }

  &.select2-container--open.select2-container--above {
    .select2-selection--single, .select2-selection--multiple {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &.select2-container--open.select2-container--below {
    .select2-selection--single, .select2-selection--multiple {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .select2-search--dropdown {
    padding: 8px 8px 0;
    .select2-search__field {
      border: 2px solid #158AFF;
      border-radius: 4px;
      box-shadow: none;
      height: 40px;
      width: 100% !important;
    }
  }

  .select2-search--inline {

    .select2-search__field {
      margin-top: 7px;
      background: transparent;
      border: none;
      outline: 0;
      box-shadow: none;
      -webkit-appearance: textfield;

      &::-webkit-input-placeholder,
      &::placeholder {
        color: #091e42;
        opacity: 1;
      }
    }
  }

  .select2-results > .select2-results__options {
    max-height: 295px;
    overflow-y: auto;
  }

  .select2-results__option {
    &[role="treeitem"] {
      margin: 8px;
      padding: 8px 16px 8px 8px;
      min-height: 40px;
      border-radius: 4px;
      //transition: all .2s;
      background-color: #f4f5f7;

      .country {
        display: flex;
        align-items: center;
        &_flag {
          margin-right: 8px;
        }
        &_text,
        &_index {
          margin: 0;
          line-height: 24px;
        }
        &_index {
          margin-left: auto;
        }
      }

      .select2-results__group {
        padding-left: 0;

        &:hover {
          box-shadow: 0 2px 4px 0 rgba(23, 43, 77, 0.3);
        }

      }
    }

    &[role=group] {
      //padding: 0;
    }

    &[aria-disabled=true] {
      color: #999;
    }

    &[aria-selected=true] {
      color: #158aff;
    }

    .select2-results__option {

      .select2-results__option {
        margin-left: -1em;
        padding-left: 2em;

        .select2-results__option {
          margin-left: -2em;
          padding-left: 3em;

          .select2-results__option {
            margin-left: -3em;
            padding-left: 4em;

            .select2-results__option {
              margin-left: -4em;
              padding-left: 5em;

              .select2-results__option {
                margin-left: -5em;
                padding-left: 6em;
              }
            }
          }
        }
      }
    }
  }

  .select2-results__option--highlighted[aria-selected] {
    background-color: #158AFF;
    color: #fff;

    &:last-of-type {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .select2-results__group {
    display: block;
    padding: 8px 16px;
    margin: 8px;
    height: 40px;
    border-radius: 4px;
    background-color: #f4f5f7;
    font-weight: 400;
    position: relative;
    //transition: all .2s;

    &:hover {
      box-shadow: 0 2px 4px 0 rgba(23, 43, 77, 0.3);
    }

    .toggle {
      content: "";
      position: absolute;
      height: 5px;
      width: 8px;
      background-image: url("/local/templates/1ci/i/icons/Common/003.svg");
      background-repeat: no-repeat;
      background-size: contain;
      right: 16px;
      top: 18px;
      transform: rotate(180deg);
      cursor: pointer;

      &.open {
        transform: none;
      }
    }

    &[aria-selected=true] {
      color: #158AFF;

      & .select2-results__option--highlighted {
        color: #fff;
      }
      .toggle {
        background-image: url("/local/templates/1ci/i/icons/Common/003w.svg");
      }
    }
  }

  .show:after {
    transform: rotate(0);
  }
}
