// app/local/blocks/1ci/11.1ci_customer_challenges
// app/local/blocks/1ci/11.1ci_customer_results
.customer-challenges {
  padding: $spacer*3.375 0 $spacer*2.5;

  &_image-block {
    text-align: left;
    @include media-breakpoint-up("md") {
      text-align: center;
    }
  }

  &_big-image-block {
    text-align: center;
  }

  &_image {
    width: 121px;
    height: 131px;
  }

  &_big-image {
    width: 100%;
    height: auto;
  }

  &_title {
    padding: $spacer/2 0;
  }

  &_text {
    color: $dark-gray;
  }

  &_list {
    list-style: none;
  }

  &_list-item,
  &_list li {
    padding-left: $spacer*3.5;
    list-style: none;
    position: relative;
    margin: $spacer*1.5 0;
    color: $dark-gray;

    &::before {
      content: "";
      position: absolute;
      background: url("/local/templates/1ci/i/icons/dot.svg") no-repeat 0 0;
      left: 0;
      top: 0;
      width: 24px;
      height: 24px;
    }
  }
}

// app/local/blocks/1ci/11.1ci_customer_kpi
.project-objectives {
  background-color: $blue;
  padding: $spacer*2.5 0 $spacer/2;

  &_row {
    justify-content: center;
  }

  .w-100 {
    margin-bottom: 0;
  }
  &_kpi {
    margin-bottom: $spacer*2;
    text-align: center;
  }

  &_icon {
    width: 26px;
    height: 26px;
    margin-bottom: $spacer/2;
  }

  &_text {
    color: $white;
  }
}
