@mixin plus($color: $blue, $w: $font-size-base/2, $h: $font-size-base/2, $padding: $font-size-base/4) {
  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    background-color: $color;
    @include transition($transition-base);
  }

  /* Vertical line */
  &::before {
    top: calc(50% - #{$h/4});
    right: -($w/2 + $w/8 + $padding);
    width: $w/4;
    height: $h;
  }

  /* horizontal line */
  &::after {
    // 50% плюс половина толщины линии
    top: calc(50% + #{$h/8});
    right: -($w + $padding);
    width: $w;
    height: $h/4;
  }
}

@mixin minus($color: $blue, $w: $font-size-base/2, $h: $font-size-base/2, $padding: $font-size-base/4) {
  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    background-color: $color;
    @include transition($transition-base);
  }

  /* horizontal line */
  &::after {
    // 50% плюс половина толщины линии
    top: calc(50% + #{$h/8});
    right: -($w + $padding);
    width: $w;
    height: $h/4;
  }
}

@mixin plus-hover {
  @media (hover: hover) {
    &:hover {
      cursor: pointer;

      &::before {
        transform: rotate(90deg);
        background-color: $link-hover-color;
      }

      &::after {
        transform: rotate(180deg);
        background-color: $link-hover-color;
      }
    }
  }
}
