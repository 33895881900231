// 404
.error {
  background: $dark-blue url("/local/templates/1ci/i/404-BG.svg") no-repeat center;
  background-size: cover;

  &_404 {
    width: 100%;
    max-width: 350px;
    display: block;
    position: relative;
    margin: 0 auto;
    @include media-breakpoint-down("xs") {
      margin-bottom: $spacer;
      max-width: 260px;
    }
    @include media-breakpoint-up("sm") {
      margin-bottom: $spacer*3.5;
    }
    @include media-breakpoint-only("md") {
      max-width: 300px;
    }
  }

  &_row {
    height: 640px;
    @include media-breakpoint-down("xs") {
      height: 530px;
    }
    @include media-breakpoint-up("md") {
      height: 730px;
    }
  }

  &_col {
    align-self: center;
  }

  &_title {
    text-align: center;
    color: $white;
  }

  &_subtitle {
    text-align: center;
    color: $white;
  }

  &_thanx {
    font-weight: $font-weight-semibold;
    text-align: center;
    color: $white;
  }

  &_btnblock {
    text-align: center;
    margin-bottom: 0;
    margin-top: $spacer * 1.5;
  }
}
