// app/local/blocks/1ci/1.1ci_main_banner_index
.main-banner {
  position: relative;
  width: 100%;
  padding: 0;
  border: 0;
  background-position: center 0%;
  background-repeat: no-repeat;
  background-size: auto 90%;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 32px;
    width: 100%;
    background-color: $white;
    @include media-breakpoint-up("sm") {
      height: 40px;
    }
  }

  &.g-bg-dark-blue,
  &.g-bg-dark-indigo,
  &.g-bg-dark-grey-blue,
  &.g-bg-blue {
    .main-banner_title,
    .main-banner_description {
      color: $white;
    }
  }

  &-summit {
    &::after {
      content: none;
    }
    @include media-breakpoint-up("lg") {
      padding-bottom: $spacer*3;
      margin-bottom: 0;
    }
  }


  @include media-breakpoint-up("lg") {
    min-height: 360px;
    margin-bottom: $spacer*3;
    &::after {
      content: none;
    }
  }
  @include media-breakpoint-up("xl") {
    min-height: 408px;
  }

  &-blue {
    background-color: $blue;
  }
  &-grey {
    background-color: $gray-300;
  }

  &_image {
    display: flex;
    align-items: flex-end;
  }
  &_img {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    @include media-breakpoint-up("md") {
      margin-top: $spacer;
    }
    @include media-breakpoint-up("lg") {
      bottom: $spacer*-3;
    }
  }

  &_textblock {
    @include media-breakpoint-up("lg") {
      margin-bottom: $spacer*3;
    }
    &--breadcrumb {
      .breadcrumb-item {
        height: 24px;
        margin-top: $spacer/2;
        @include media-breakpoint-up("md") {
          margin-top: $spacer;
        }
      }
    }
  }

  &_title {
    margin-bottom: $spacer;
    margin-top: $spacer*1.5;
    @include media-breakpoint-up("md") {
      margin-top: $spacer*2;
    }
    @include media-breakpoint-up("lg") {
      margin-top: $spacer*3;
    }

    &:not(.crumbs) {
      margin-top: $spacer*2;
      @include media-breakpoint-up("md") {
        margin-top: $spacer*2.5;
      }
      @include media-breakpoint-up("lg") {
        margin-top: $spacer*6.5;
      }
    }
  }

  &_description {
    font-size: $display5-size;
    font-weight: $display4-weight;
    color: $dark-gray;
    margin-bottom: 0;
  }

  &_btnblock {
    margin-top: $spacer;
    @include media-breakpoint-up("lg") {
      margin-bottom: 0;
    }
  }
}

//app/local/blocks/1ci/1.1ci_newsroom_banner
.newsroom__banner {
  background-size: cover;
  position: relative;
  margin-bottom: 0;

  &::after {
    display: none;
  }

  .banner__variant {
    display: flex;
    flex-direction: column;
  }

  &-textblock {
    @include media-breakpoint-down("sm") {
      .main-banner_breadcrumb .breadcrumb-item {
        margin-top: 0;
        height: 16px;
        display: flex;
        align-items: center;
      }
    }
  }

  &-title {
    color: $white;
    margin-bottom: $spacer;
    @include media-breakpoint-down("sm") {
      margin-top: $spacer*.75;
    }
    @include media-breakpoint-up("md") {
      margin-top: $spacer*1.375;
    }
    @include media-breakpoint-up("lg") {
      margin-top: $spacer*2.875;
    }
  }

  .main-banner_description {
    font-size: $spacer*1.375;
    line-height: 32px;
  }

  &-subtitle {
    margin-bottom: 0;
    font-family: $headings-font-family;
    font-weight: $font-weight-semibold;
    font-size: $font-size-xs;
    line-height: 16px;
    letter-spacing: $spacer*.03;
    text-transform: uppercase;
    color: $white;
    @include media-breakpoint-down("sm") {
      margin-top: $spacer*.75;
    }
    @include media-breakpoint-up("md") {
      margin-top: $spacer*.875;
    }
    @include media-breakpoint-up("lg") {
      margin-top: $spacer*2.875;
    }
  }

  .bg-mobile {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    padding-bottom: $spacer*4.5;
    padding-top: $spacer;
    @include media-breakpoint-up("md") {
      min-height: 312px;
      padding-top: $spacer/4;
    }
    @include media-breakpoint-up("lg") {
      background-size: 0;
      min-height: 456px;
      padding-top: $spacer/2;
    }
  }

  &-description_left {
    font-size: $display5-size;
  }

  @include media-breakpoint-down("md") {
    &.bg-desktop {
      background-size: 0;
    }
  }

  .banner-additional {
    .newsroom__banner-title {
      margin-top: $spacer/2;
      margin-bottom: $spacer*1.5;
    }

    .additional {
      display: flex;
      flex-direction: column;
      @include media-breakpoint-up("md") {
        align-items: center;
        flex-direction: row;
      }

      &-place {
        @include media-breakpoint-down("sm") {
          margin-top: $spacer/2;
        }
      }

      &-date,
      &-place {
        display: flex;
        align-items: center;
        margin-right: $spacer*2;

        &_icon {
          margin-right: $spacer/2;
          width: 16px;
          height: 16px;
        }

        &_text {
          margin-bottom: 0;
          color: $white;
        }
      }
    }
  }

  //app/local/blocks/1ci/1.1ci_newsroom_banner_center
  &-center {
    .newsroom__banner-title,
    .main-banner_description {
      @include media-breakpoint-up("lg") {
        text-align: center;
      }
    }

    .newsroom__banner-title {
      @include media-breakpoint-up("md") {
        margin-top: $spacer;
      }
      @include media-breakpoint-up("lg") {
        margin-top: $spacer*4.875;
      }
    }
  }
}

//app/local/blocks/1ci/13.1ci_partner_banner_filter
.banner-select {
  position: relative;

  @include media-breakpoint-up("lg") {
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: $white;
      z-index: 1;
    }
    &__4 {
      &::after {
        height: 220px;
      }
    }
    &__2 {
      &::after {
        height: 46px;
      }
    }
  }

  &--textblock {

    @include media-breakpoint-up("lg") {
      text-align: center;
    }
  }

  .main-banner_description {
    margin-bottom: $spacer*3;
  }

  &--content {
    z-index: 2;
  }

  &_area {
    background-color: $gray-300;
    position: relative;

    @include media-breakpoint-down("md") {
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: $gray-300;
        box-shadow: 150px 0 0 0 $gray-300, -150px 0 0 0 $gray-300;
        z-index: -1;
      }
    }

    @include media-breakpoint-up("lg") {
      border-radius: $border-radius;
    }

    &--row {
      padding: $spacer*2 0 $spacer*1.5;
    }

    &--title {
      text-transform: uppercase;
      font-family: $headings-font-family;
      font-size: $font-size-xs;
      font-weight: $font-weight-semibold;
      letter-spacing: .7px;
      margin-bottom: $spacer*1.5;
    }

    &--label {
      font-size: $font-size-sm;
      line-height: 1.14;
    }

    &--btnblock {
      margin-top: $spacer;
      text-align: center;
    }
  }

  &__4 {
    .main-banner_btnblock {
      margin-bottom: $spacer;
    }

    &::after {
      height: 524px;
      @include media-breakpoint-up("md") {
        height: 338px;
      }
      @include media-breakpoint-up("lg") {
        height: 218px;
      }
    }
  }
}

// app/local/blocks/1ci/1.1ci_main_banner_partner_catalogue
.partner-banner {
  background-size: 0;
  padding-bottom: $spacer*2;
  position: relative;
  overflow: hidden;

  &_mobile-tablet {
    height: 100%;
    width: 100%;
    padding-left: 15px;
    @include media-breakpoint-up("lg") {
      display: none;
    }

    &--oval {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 95%;
      @include media-breakpoint-up("sm") {
        height: 400px;
        width: auto;
        right: -150px;
      }
      @include media-breakpoint-up("md") {
        height: 440px;
        right: -100px;
      }
    }
  }

  @include media-breakpoint-up("lg") {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  &--blue {
    background-color: $blue;
  }

  &--grey {
    background-color: $gray-300;
  }

  &_img {
    width: 100%;
    height: auto;
    display: block;
    position: relative;
    z-index: 2;
  }

  &_textblock {
    display: flex;
    flex-direction: column;

    &--breadcrumb {
      margin-top: $spacer/2;
      @include media-breakpoint-up("sm") {
        margin-top: $spacer;
      }
    }
  }

  &_title {
    margin-bottom: $spacer;
    color: $white;
    margin-top: $spacer/2;

    &--catalogue {
      margin-top: $spacer*1.5;
    }

    @include media-breakpoint-up("sm") {
      margin-top: $spacer;
      &--catalogue {
        margin-top: $spacer*3;
      }
    }
    @include media-breakpoint-up("lg") {
      margin-top: $spacer*2;
      &--catalogue {
        margin-top: $spacer*3.5;
      }
    }
  }

  &_description {
    font-size: $display5-size;
    font-weight: $display4-weight;
    color: $white;
    margin-bottom: 0;
    @include media-breakpoint-up("sm") {
      font-size: $display24-size;
    }
  }

  &_btnblock {
    margin-top: $spacer;
  }

  &_link {
    &--wrapper {
      width: 100%;
      margin-top: $spacer/2;
      @include media-breakpoint-up("md") {
        margin-top: $spacer;
      }
      @include media-breakpoint-up("lg") {
        margin-top: auto;
      }
    }

    position: relative;
    width: max-content;
    color: $white;
    transition: $transition-base;
    font-weight: 600;

    &:hover {
      color: $white;
    }

    @include hover-footer-links;
  }

  &_company {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: $spacer*4;

    &--logo {
      height: 32px;
      margin-bottom: $spacer*1.5;
      display: none;
      @include media-breakpoint-up("lg") {
        display: block;
      }
    }
  }
}

// app/local/blocks/1ci/1.1ci_solution_main_banner
.solution-main-banner {
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding: 0;
  border: 0;

  &_w__form {
    overflow: hidden;
    position: relative;
    @include media-breakpoint-up("sm") {
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: $spacer*3;
        bottom: 0;
        left: 0;
        background-color: $white;
      }
    }
    @include media-breakpoint-up("lg") {
      padding-bottom: $spacer;
    }
  }

  &_w__form_new {
    margin-bottom: $spacer*3;
    @include media-breakpoint-down("md") {
      overflow: hidden;
    }
    @include media-breakpoint-up("lg") {
      max-height: 404px;
      padding-bottom: $spacer*3;
    }
  }

  &_column-row {
    padding-bottom: $spacer*4.5;
    @include media-breakpoint-up("lg") {
      padding: $spacer*2 0 $spacer*4.5;
      justify-content: center;
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  // app/local/blocks/1ci/1.1ci_solution_main_banner_wo_bg
  &--blue {
    background-color: $blue;
  }

  &--partner {
    &_title {
      text-align: left;
      color: $white;
      margin-bottom: $spacer;
    }

    &_subtitle {
      text-align: left;
      font-size: $display5-size;
      font-weight: $display5-weight;
      color: $white;

      [href^="mailto:"] {
        color: currentColor;
        position: relative;
        @include hover-footer-links;
      }
    }

    @include media-breakpoint-up("lg") {
      min-height: 408px;
      &_title {
        text-align: center;
      }
      &_subtitle {
        text-align: center;
      }
    }
  }

  // app/local/blocks/1ci/1.1ci_solution_main_banner_wo_bg_with_form
  &--dark_blue {
    background-color: $dark-blue;
  }

  &--form {
    &_title {
      color: $white;
      line-height: 1;
    }

    &_subtitle {
      font-size: $display5-size;
      font-weight: $display5-weight;
      color: $white;
      margin-bottom: $spacer;
    }

    @include media-breakpoint-up("lg") {
      min-height: 408px;
    }
  }

  &_row {
    min-height: 376px;
    @include media-breakpoint-up("sm") {
      min-height: 344px;
    }
    @include media-breakpoint-up("lg") {
      min-height: 456px;
    }
  }

  &_smallrow {
    min-height: 350px;
  }

  &_textblock {
    align-self: center;
    overflow: hidden;

    &--breadcrumb {
      align-self: initial;
      @include media-breakpoint-up("lg") {
        display: flex;
        flex-direction: column;
      }
    }
  }

  &_breadcrumb {
    margin: $spacer 0 $spacer*1.5;
    color: $white;

    @include media-breakpoint-up("md") {
      margin-bottom: $spacer*2;
    }
    @include media-breakpoint-up("lg") {
      margin-bottom: 0;
    }

    a {
      color: $white;
    }
  }

  &_title {
    text-align: left;
    color: $white;
    @include media-breakpoint-up("lg") {
      text-align: center;
    }
  }

  &_subtitle {
    text-align: left;
    font-size: $display5-size;
    font-weight: $display5-weight;
    color: $white;
    margin-bottom: $spacer;

    [href^="mailto:"] {
      color: currentColor;
      position: relative;
      @include hover-footer-links;
    }

    @include media-breakpoint-up("lg") {
      text-align: center;
    }

    &.text-left {
      @include media-breakpoint-up("md") {
        width: 50%;
        margin-bottom: $spacer;
      }
    }
  }

  &_button {
    &.hidden {
      display: none;
    }
  }

  &_btnblock {
    text-align: left;
    @include media-breakpoint-up("lg") {
      text-align: center;
    }
  }
}

// app/local/blocks/1ci/9.1ci_partners_support_banner
.partners-support-banner {
  background-color: $blue;
  background-position: 54% bottom;
  background-repeat: no-repeat;
  background-size: auto;
  width: 100%;
  padding: $spacer*2 $spacer $spacer*3.125;
  border: 0;
  position: relative;

  &_item {
    text-align: center;
    margin-bottom: $spacer*1.5;
    color: $white;
    @include media-breakpoint-up("sm") {
      text-align: left;
      margin-right: $spacer;
    }
    @include media-breakpoint-up("md") {
      margin-right: 0;
      max-width: 170px;
    }
  }

  &_image {
    display: block;
    position: relative;
    margin: 0 auto $spacer/2;
    @include media-breakpoint-up("sm") {
      margin: 0 0 $spacer/2;
    }
  }

  &_text {
    position: relative;
    margin-bottom: 0;
    word-break: break-word;
    @include media-breakpoint-up("sm") {
      width: 144px;
    }
  }

  &_bg {
    position: absolute;
    height: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    &--img {
      position: absolute;
      display: none;
      @include media-breakpoint-up("md") {
        display: block;
        right: -50px;
        bottom: 0;
        height: 70%;
      }
      @include media-breakpoint-up("lg") {
        height: 100%;
        right: 0;
      }
    }
  }

  @include media-breakpoint-down("sm") {
    // stylelint-disable declaration-no-important
    background-image: none !important;
  }

  @include media-breakpoint-up("md") {
    padding: $spacer*4 0 $spacer*9.25;
  }
  @include media-breakpoint-up("lg") {
    padding: $spacer*4 0 $spacer*2.25;
    background-position: center center;
    background-size: cover;
  }

  &_textblock {
    align-self: center;
    display: flex;
    flex-direction: column;

    &--breadcrumb {
      align-self: initial;
    }
  }

  &_breadcrumb {
    margin: 0 0 $spacer;
    @include media-breakpoint-up("lg") {
      margin: $spacer 0 $spacer*2;
    }
  }

  &_title {
    text-align: center;
    margin: 0 $spacer $spacer*2;
    color: $white;
    @include media-breakpoint-up("sm") {
      text-align: left;
      margin: 0 0 $spacer*2.75;
    }
  }

  &_subtitle {
    text-align: center;
    margin-bottom: $spacer;
    color: $white;
    @include media-breakpoint-up("sm") {
      text-align: left;
      margin-bottom: $spacer*1.75;
    }
  }

  &_btnblock {
    text-align: center;
    font-size: $display24-size / 2;
    line-height: 1;
    margin-top: $spacer*.5;
    width: auto;
    padding: 0 25px;

    .button-icon {
      position: relative;
      top: -2px;
    }

    @include hover-btn-arrow($white, $white);

    @include media-breakpoint-up("sm") {
      text-align: left;
      margin: 0;
      font-size: $spacer;
      padding: 0;
      max-width: 350px;
    }
    @include media-breakpoint-up("sm") {
      margin-top: $spacer*1.875;
    }
  }

  &_link {
    font-family: $headings-font-family;
    font-size: $font-size-xs;
    font-weight: $headings-font-weight;
    color: $white;
    padding: $spacer/2 0;
    text-transform: uppercase;
    line-height: 1.33;
    letter-spacing: .7px;

    &:hover {
      color: $white;

      .button-icon {
        margin-left: $spacer/3;

        .arrow {
          fill: $white;
        }
      }
    }
  }
}

// app/local/blocks/1ci/1.1ci_company_main_banner
.company-main-banner {
  background-color: transparent;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding: 0;
  margin: 0 0 $spacer*3;
  border: 0;

  &_row {
    min-height: 456px;
  }

  &_textblock {
    align-self: center;
  }

  &_title {
    font-size: $display2-size;
    font-weight: $headings-font-weight;
    color: $dark-blue;
  }

  &_subtitle {
    font-size: $display5-size;
    font-weight: $display5-weight;
    color: $dark-gray;
    margin-bottom: $spacer;

    &.text-left {
      @include media-breakpoint-up("md") {
        width: 50%;
      }
    }
  }

  &_btnblock {
    text-align: center;
  }
}
