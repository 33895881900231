.response {
  padding-top: 38px;
  padding-bottom: 16px;
  @include media-breakpoint-only("md") {
    padding-left: 15px;
    padding-right: 15px;
  }
  @include media-breakpoint-up("md") {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  @include media-breakpoint-up("lg") {
    padding-top: 72px;
  }

  .container {
    max-width: 760px;
  }

  .row {
    margin-bottom: 56px;
  }

  .title {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 24px;
    line-height: 1.3;
    @include media-breakpoint-up("md") {
      margin-bottom: 16px;
      font-size: 26px;
      white-space: nowrap;
      &.custom-ma {
        margin-bottom: 152px;
      }
    }

    &-main {
      line-height: 1.35;
      font-size: 26px;
      @include media-breakpoint-down("sm") {
        order: 3;
        position: relative;
        width: auto;
      }
      @include media-breakpoint-up("md") {
        font-size: 30px;
        margin-top: 64px;
        white-space: pre;
      }
    }
  }

  .text {
    line-height: 1.45;
    font-size: 17px;
    margin-bottom: 0;

    //&-ma {
    //  margin-bottom: 8px;
    //}
  }

  .logo {
    display: block;
    @include media-breakpoint-down("sm") {
      order: 1;
    }
    @include media-breakpoint-up("md") {
      padding-top: 22px;
    }
  }

  &-banner {
    @include media-breakpoint-down("sm") {
      display: flex;
      flex-direction: column;
      .text {
        order: 5;

        &-ma {
          order: 4;
        }
      }
    }

    &_image {
      float: right;
      margin-top: 40px;
      margin-bottom: 40px;
      width: 100%;
      @include media-breakpoint-down("sm") {
        padding: 0 15px;
        order: 2;
      }
      @include media-breakpoint-up("md") {
        width: 330px;
        margin: 0;
      }
      @include media-breakpoint-up("lg") {
        width: 350px;
      }
    }
  }

  &-social {
    &_icons {
      display: flex;
      flex-wrap: wrap;
      margin-top: 16px;
    }

    &_link {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      border: 2px solid #091e42;
      margin-right: 8px;
      margin-top: auto;
      padding: 4px;
      transition: all .2s;

      @media screen and (max-width: 364px) {
        &:last-child {
          margin-top: 8px;
        }
      }

      .inline {
        transition: all .2s;
      }

      &:hover {
        border-color: #091e42;
        background-color: #091e42;
        .inline path {
          fill: #fff;
        }
      }
    }
  }

  .quiz {
    @include media-breakpoint-up("lg") {
      background-color: #f4f5f7;
      padding: 32px 32px 40px;
    }

    &-wrapper {
      background-color: #f4f5f7;
      padding-top: 32px;
      padding-bottom: 56px;
      @include media-breakpoint-up("md") {
        border-radius: 2px;
        padding: 32px 17px 40px;
      }
      @include media-breakpoint-up("lg") {
        padding: 0 15px 0;
        background-color: transparent;
      }
    }

    &-items {
      margin-top: 16px;
      margin-bottom: 24px;
    }

    .custom-checkbox {
      margin-bottom: 16px;
      padding-left: 0;
      display: flex;
      min-height: auto;
    }

    .custom-control-input {
      position: relative;
      height: 16px;
      width: 16px;
      margin: 0 16px 0 0;
    }

    .custom-control-label {
      line-height: .9;
      min-height: 16px;

      &::before,
      &::after {
        left: -2rem;
        top: 0;
        line-height: 0;
      }
    }

    .button {
      display: block;
      width: max-content;
      color: #091e42;
      font-size: 17px;
      font-weight: 600;
      padding: 8px 24px;
      border-radius: 4px;
      background-color: #ffdd2d;
    }
  }

  &-success {
    .title {
      font-size: 21px;
      line-height: 1.25;

      &-main {
        font-size: 24px;
        line-height: 1.25;
        @include media-breakpoint-up("md") {
          font-size: 26px;
        }
      }
    }

    & .custom-ma-success {
      margin-bottom: 24px;
      @include media-breakpoint-up("md") {
        margin-bottom: 24px !important; // stylelint-disable-line declaration-no-important
      }
    }

    @include media-breakpoint-down("sm") {
      .response-banner_image {
        transform: translateX(-10px);
      }
    }

    @include media-breakpoint-up("md") {
      .response-banner {
        margin-bottom: 24px;
      }
    }
  }
}
