@mixin breadcrumb-variant($br-color, $br-divider-color, $br-divider) {
  .breadcrumb-item {
    font-size: $breadcrumb-font-size;
    padding-right: $breadcrumb-item-padding;
    color: $br-color;

    a {
      color: $br-color;
      position: relative;

      @include hover-footer-links;
    }
    &:not(:last-child)::after {
      display: inline-block; // Suppress underlining of the separator in modern browsers
      padding-left: $breadcrumb-item-padding-left;
      color: $br-divider-color;
      content: $br-divider;
    }
    // The separator between breadcrumbs (by default, a forward-slash: "/")
    // IE9-11 hack to properly handle hyperlink underlines for breadcrumbs built
    // without `<ul>`s. The `::before` pseudo-element generates an element
    // *within* the .breadcrumb-item and thereby inherits the `text-decoration`.
    //
    // To trick IE into suppressing the underline, we give the pseudo-element an
    // underline and then immediately remove it.
    + .breadcrumb-item:hover::before {
      text-decoration: underline;
    }

    // stylelint-disable-next-line no-duplicate-selectors
    + .breadcrumb-item:hover::before {
      text-decoration: none;
    }

    &.active {
      color: $breadcrumb-active-color;
    }
  }
}
