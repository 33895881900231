.select2-selection--single {
  background-color: #172b4d;
  border-radius: 4px;

  .select2-selection__rendered {
    color: #fff;
    background-color: #172b4d;
    border: 1px solid #c1c7d0;
  }

  .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
  }

  .select2-selection__placeholder {
    color: #c1c7d0;
  }

  .select2-selection__arrow {
    height: 38px;
    position: absolute;
    top: 0;
    right: 10px;
    width: 7px;

    b {
      background-image: url("/local/templates/1ci/i/icons/select_w.svg");
      background-repeat: no-repeat;

      height: 15px;
      left: 50%;

      margin-left: -4px;
      margin-top: -7px;

      position: absolute;

      top: 50%;
      width: 7px;
    }
  }
}

&[dir="rtl"] {
  .select2-selection--single {
    .select2-selection__clear {
      float: left;
    }

    .select2-selection__arrow {
      left: 1px;
      right: auto;
    }
  }
}

&.select2-container--disabled {
  .select2-selection--single {
    background-color: #eee;
    cursor: default;

    .select2-selection__clear {
      display: none;
    }
  }
}

&.select2-container--open {
  .select2-selection--single {
    .select2-selection__arrow {
      b {
        border-color: transparent transparent #091e42 transparent;
        border-width: 0 4px 5px 4px;
      }
    }
  }
}
