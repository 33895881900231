// edit mode overrides
.landing-public-mode {
  .block-wrapper {
    display: initial;
  }

  #career_hidden,
  #hidden_form,
  .newsroom__media-button {
    display: none;
  }
}

.landing-edit-mode {

  .d-none,
  .only-mobile,
  .only-mobile.only-desktop,
  .only-mobile.only-tablet,
  .only-mobile.only-desktop.only-tablet,
  .d-lg-block,
  .only-tablet,
  .d-lg-none,
  .only-desktop {
    display: inherit !important; // stylelint-disable-line declaration-no-important
    opacity: .5;
  }

  .landing-ui-field-input .event__form-list__item::before {
    display: none;
  }

  .event__form-list__item {
    position: inherit !important; // stylelint-disable-line declaration-no-important
  }

  .event__form-location_text {
    position: absolute !important; // stylelint-disable-line declaration-no-important
  }

  .only-mobile,
  .only-tablet,
  .only-desktop {
    position: relative;

    &::after {
      position: absolute;
      content: "";
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      height: 1px;
      width: 100%;
      background-color: $dark-indigo;
    }
  }

  #career_hidden,
  #hidden_form,
  .newsroom__media-button,
  .career__contact,
  .event__form-form.forms--blue .hidden,
  .register__button {
    display: block !important; // stylelint-disable-line declaration-no-important
  }

  .event__form {
    .register__button {
      box-shadow: none;
    }

    &-form {
      position: relative;
      top: 0;
      width: 100%;
    }
  }

  .owl-carousel {
    display: flex;
    flex-wrap: wrap;

    .slide {
      padding-bottom: $spacer;
      height: max-content !important; // stylelint-disable-line declaration-no-important
    }
  }

  .brochure_image,
  .slide_subtext {
    position: absolute !important; // stylelint-disable-line declaration-no-important
  }

  .stretched-link::after {
    display: none;
  }

  .partners-catalogue__new {
    &_header,
    &_empty {
      &.hidden {
        display: block !important; // stylelint-disable-line declaration-no-important
      }
    }
  }

  .partners__new {
    &-emptyblock {
      &.hidden {
        display: block !important; // stylelint-disable-line declaration-no-important
      }
    }
  }

  .filter__badges {
    &.hidden {
      display: flex !important; // stylelint-disable-line declaration-no-important
    }

    &-tag.hidden {
      display: flex !important; // stylelint-disable-line declaration-no-important
    }

    &-button.hidden {
      display: flex !important; // stylelint-disable-line declaration-no-important
    }
  }
}

//app/local/blocks/1ci/1.1ci_header
.site-header {
  background-color: $white;

  &--opened {
    //background-color: $gray-300;
  }

  a {
    font-size: $navbar-menu-font-size;
    @include transition(ease-in-out color .15s);
  }

  .navbar-brand {
    width: 40px;
    height: 32px;
    @include media-breakpoint-between("sm", "md") {
      padding-left: 15px;
    }
  }

  .navbar-toggler {
    @include media-breakpoint-between("sm", "md") {
      padding-right: 15px;
    }
  }

  .nav-link,
  .dropdown-item {
    &[href^="http"]:not(.lang)::after {
      content: "(external link)";
      display: inline-block;
      width: 1rem;
      height: .75rem;
      text-indent: 1rem;
      white-space: nowrap;
      overflow: hidden;
      background-image: url("/local/templates/1ci/i/icons/Common/002.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
      margin-left: .2rem;
    }

    &:hover[href^="http"]:not(.lang)::after {
      background-image: url("/local/templates/1ci/i/icons/Common/002-1.svg");
    }
  }

  .language-select {
    display: none;
    @include media-breakpoint-down("md") {
      display: block;
      margin-bottom: $spacer*2.5;
    }

    &_flag {
      padding: .1rem .5rem .3rem 0;
    }

  }

  .language-dropdown {
    display: none;
    @include media-breakpoint-up("lg") {
      display: block;
      padding-right: 0;
      .nav-link {
        padding-right: 0;
      }
    }

    .nav-link {
      padding-top: $nav-link-padding-y - .2;
    }

    .lang {
      i {
        font-style: normal;
        color: $gray-900;
      }
    }

    &_flag {
      padding: .1rem .5rem .3rem 0;
    }

    &_icon {
      & path {
        transition: $transition-base;
      }

      &:hover,
      &:active,
      &.active {
        fill: $blue;

        path {
          fill: $blue;
        }
      }
    }
  }
}

.navbar-toggler {
  border: none;
  background: transparent;

  .top-bar {
    transform: rotate(45deg);
    transform-origin: 9% 10%;
  }

  .middle-bar {
    opacity: 0;
  }

  .bottom-bar {
    transform: rotate(-45deg);
    transform-origin: 7% 90%;
  }

  &.collapsed .top-bar {
    transform: rotate(0);
  }

  &.collapsed .middle-bar {
    opacity: 1;
  }

  &.collapsed .bottom-bar {
    transform: rotate(0);
  }
}

//app/local/blocks/1ci/2.1ci_footer
.footer {
  position: relative;
  color: $footer-color;
  background-color: $footer-bg-color;
  padding-top: $spacer*2;
  @include media-breakpoint-down("sm") {
    padding-top: $spacer*2.5;
  }
  @include media-breakpoint-up("md") {
    padding-top: $spacer*3;
  }

  &-menu {
    position: relative;

    @include media-breakpoint-only("md") {
      .col-md-9 {
        margin-bottom: $spacer/2;
      }
    }

    [href^="http"]::after {
      content: "(external link)";
      display: inline-block;
      width: 1rem;
      height: .75rem;
      text-indent: 1rem;
      white-space: nowrap;
      overflow: hidden;
      background-image: url("/local/templates/1ci/i/icons/Common/002w.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
      margin-left: .2rem;
      transform: translateY(.125rem);
    }

    &_container {
      padding-left: 0;
      list-style: none;
      flex-direction: row;
      width: 100%;
      margin-bottom: $spacer*1;

      @include media-breakpoint-up("sm") {
        width: percentage(1 / 3);
        &--row {
          padding-left: $grid-gutter-width / 2 + $grid-gutter-width / 4;
        }

        & + &:not(:last-child) {
          padding-left: $grid-gutter-width / 4;
          padding-right: $grid-gutter-width / 2;
        }
      }
      @include media-breakpoint-up("lg") {
        width: 50%;

        & + & {
          padding-left: 0;
          padding-right: 0;
        }

        &--row {
          width: 100%;

          .footer_item:not(.footer_item--header) {
            display: inline-flex;
            padding-right: $spacer* 1.5;
          }
        }

        &--col {
          .footer_item {
            display: flex;
          }
        }
      }

      &--col {
        width: 100%;
      }
    }
  }

  &_wrapper {
    display: flex;
    flex-wrap: wrap;
    @include media-breakpoint-down("sm") {
      padding-left: $spacer;
      display: none;
    }
  }

  &_title {
    font-size: $footer-font-header-size;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    border-bottom: none;
    padding-left: $spacer;
    padding-bottom: $spacer;

    @include media-breakpoint-down("sm") {
      @include caret("left");
      &.show {
        @include caret("left-show");
      }
    }

    @include media-breakpoint-up("md") {
      padding-left: 0;
      border-bottom: 1px solid $close-color;
      padding-bottom: $spacer / 2;
      margin-bottom: $spacer;
    }
  }

  &_item {
    display: flex;
    margin-bottom: $spacer;

    a {
      font-size: $footer-font-size;
      line-height: $line-height-footer;
      color: $footer-color;
      text-decoration: none;
      display: inline;
      position: relative;
      width: max-content;

      &::before {
        display: none;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    &--arrow {
      a::after {
        display: inline-block;
        padding-left: $spacer/5;
        content: $breadcrumb-divider-white;
      }
    }

    &--header {
      text-transform: uppercase;
      margin-bottom: $spacer*.875;
      font-weight: $font-weight-bold;
      color: $gray-900;
      font-size: $font-size-xs;

      @include media-breakpoint-down("sm") {
        border-bottom: 1px solid $close-color;
        padding-bottom: $spacer / 2;
        width: fit-content;
      }
    }
  }

  //copyright --------
  &_copy {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: $footer-font-copy-size;
    padding-top: $spacer*1.5;
    padding-bottom: $spacer*1.5;
  }

  &_copytext {
    text-transform: uppercase;
  }

  &_copyblock {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    @include media-breakpoint-down("sm") {
      justify-content: flex-start;
      margin-top: $spacer;
    }
  }

  &_copylink a {
    color: $white;
    margin-left: $spacer;

    &:hover {
      text-decoration: underline;
    }

    @include media-breakpoint-down("sm") {
      margin-left: 0;
      margin-right: $spacer;
    }
  }

  //copyright --------

  &_logo {
    display: flex;
    justify-content: space-between;
    margin-bottom: 34px;
  }

  &_logoimg {
    width: 40px;
    height: 34px;
  }

  .language-select-footer {
    display: none;
    @include media-breakpoint-up("lg") {
      display: block;
    }

    .language-select_flag {
      padding: .1rem .5rem .3rem 0;
    }
  }

  &_social {
    width: max-content;
    display: flex;
    align-items: center;
    flex-direction: row;
    z-index: 1;
    @include media-breakpoint-up("lg") {
      position: absolute;
      bottom: $spacer*2;
      right: 0;
      margin: 0;
    }

    &__link {
      height: 32px;
      width: 32px;
      margin-right: $spacer/4;
      @include media-breakpoint-up("lg") {
        margin-right: auto;
        margin-left: $spacer/4;
      }

      &[href^="http"]::after {
        display: none;
      }
    }

    @include media-breakpoint-down("sm") {
      order: 1;
      padding-top: $spacer;
      padding-bottom: $spacer;
    }
  }

  &_hr {
    border-top: 1px solid $footer-hr-color;
    margin-top: $spacer;
    @include media-breakpoint-up("md") {
      margin-top: $spacer*2;
    }
    @include media-breakpoint-up("lg") {
      margin-top: $spacer;
    }
  }
}

//cookie policy block
.cookie {
  display: none;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: $blue;
  box-shadow: $box-shadow-cookie;
  z-index: $zindex-cookie;
  padding: $spacer 15px;
  width: 100%;
  @include media-breakpoint-up("md") {
    padding: $spacer*1.5 0;
  }
  @include media-breakpoint-up("lg") {
    border-radius: $border-radius $border-radius 0 0;
    width: 978px;
  }
  @include media-breakpoint-up("xl") {
    width: 1158px;
  }

  &-block {
    display: flex;
    color: $white;
    font-size: $font-size-sm;
    position: relative;
    margin: 0 auto;
    @include media-breakpoint-up("sm") {
      max-width: 540px;
      padding: 0 15px;
    }
    @include media-breakpoint-up("md") {
      max-width: 720px;
    }
    @include media-breakpoint-up("lg") {
      max-width: 960px;
    }
    @include media-breakpoint-up("xl") {
      max-width: 1140px;
    }
  }

  &-text {
    margin-bottom: 0;
    line-height: 1.14;
  }

  .close-policy {
    width: 16px;
    height: 16px;
    padding: 3px;
    display: flex;
    margin-left: $spacer/2;
    @include media-breakpoint-up("md") {
      top: -8px;
      position: absolute;
      right: -3px;
    }
    @include media-breakpoint-up("lg") {
      position: relative;
      right: 0;
    }
    @include media-breakpoint-up("xl") {
      margin-left: $spacer*2;
    }
  }

  .icon {
    path {
      transition: $transition-base;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &-link {
    color: $white;
    text-decoration: underline;

    &:hover {
      color: $white;
      text-decoration: underline;
    }
  }
}

//app/local/blocks/1ci/13.1ci_location_shower
.localization-shower {
  display: none;
  margin-bottom: $spacer/2;

  &_col {
    @include make-col-ready();
    @include media-breakpoint-down("lg") {
      padding-right: 0;
      padding-left: 0;
    }
  }

  &_block {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background: $white;
    box-shadow: $box-shadow-localization;
    -webkit-box-shadow: $box-shadow-localization; // stylelint-disable-line property-no-vendor-prefix
    border-radius: 0;
    @include media-breakpoint-down("sm") {
      flex-direction: column;
      padding: 16px 16px 8px;
    }
    @include media-breakpoint-up("sm") {
      padding: 16px 39px 24px;
    }
    @include media-breakpoint-up("lg") {
      padding: 16px 24px 24px;
      border-bottom-left-radius: $border-radius / 2;
      border-bottom-right-radius: $border-radius / 2;
    }
  }

  &_close {
    @include media-breakpoint-down("sm") {
      position: absolute;
      right: 15px;
      top: 16px;
    }
    @include media-breakpoint-up("md") {
      margin-left: 25px;
    }
    @include media-breakpoint-up("lg") {
      margin-left: $spacer*4;
    }

    svg {
      vertical-align: top;
      width: 14px;
      height: 14px;

      path {
        fill: $close-color;
        transform: scale(1.4);
      }
    }
  }

  &_image {
    margin-top: 8px;
    margin-right: 24px;
    @include media-breakpoint-down("sm") {
      margin-bottom: $spacer;
    }
  }

  &_textblock {
    width: 100%;
    margin-top: 8px;

    p {
      margin: 0;
    }
  }

  &_buttonblock {
    margin-top: 16px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    .left-button {
      color: $black;
      background-color: $yellow;
      margin-right: 24px;
      border: 1px solid $yellow;
      border-radius: 4px;

      @include media-breakpoint-down("sm") {
        margin-bottom: 16px;
      }

      &:hover {
        background-color: $dark-yellow;
        border: 1px solid $dark-yellow;
      }
    }

    .right-button {
      color: $black;
      border: 1px solid currentColor;
      background-color: $white;
      border-radius: 4px;

      @include media-breakpoint-down("sm") {
        margin-bottom: 16px;
      }

      &:hover {
        border: 1px solid $dark-yellow;
        background-color: $dark-yellow;
      }
    }
  }
}

//item Close
.filter__delete,
{
  position: relative;

  &::after {
    content: "";
    display: inline-block;
    position: relative;
    left: 5px;
    height: 14px;
    width: 14px;
    background-image: url("/local/templates/1ci/i/icons/Common/close.svg");
  }
}

//visible bg
.bg__image {
  opacity: 0;
  transition: $transition-base;
}

.visible__bg {
  opacity: 1;
}
