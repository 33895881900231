.select2-selection--multiple {
  background-color: white;
  border: solid 1px #c1c7d0;
  border-radius: 4px;
  cursor: text;
  min-height: 40px;

  .select2-selection__rendered {
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 0 16px;
    width: 100%;

    &::after {
      content: "";
      background-image: url("/local/templates/1ci/i/icons/select.svg");
      background-repeat: no-repeat;

      height: 15px;
      right: 10px;

      margin-left: -4px;
      margin-top: -7px;

      position: absolute;

      top: 50%;
      width: 7px;

    }

    li {
      list-style: none;
    }
  }

  .select2-selection__placeholder {
    color: #091e42;
    margin-top: 5px;
    float: left;
  }

  .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-top: 5px;
    margin-right: 10px;
    display: none;
  }

  .select2-selection__choice {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    background-color: #158aff;

    border-radius: 4px;
    cursor: default;

    float: left;

    margin-right: 4px;
    margin-top: 4px;
    padding: 4px 8px 6px 16px;
    line-height: 1.2;

    color: #fff;
  }

  .select2-selection__choice__remove {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    width: 16px;
    margin-left: 4px;

    color: #fff;
    cursor: pointer;

    font-weight: 400;
    font-size: 30px;
  }
}

&[dir="rtl"] {
  .select2-selection--multiple {
    .select2-selection__choice, .select2-selection__placeholder, .select2-search--inline {
      float: right;
    }

    .select2-selection__choice {
      margin-left: 5px;
      margin-right: auto;
    }

    .select2-selection__choice__remove {
      margin-left: 2px;
      margin-right: auto;
    }
  }
}

&.select2-container--focus {
  .select2-selection--multiple {
    border: solid 1px #c1c7d0;
    outline: 0;
  }
}

&.select2-container--disabled {
  .select2-selection--multiple {
    background-color: #eee;
    cursor: default;
  }

  .select2-selection__choice__remove {
    display: none;
  }
}
